import { OperationVariables } from "apollo-client"
import { DocumentNode } from "graphql"
import { MutationUpdaterFn, RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"
import { ErrorV2 } from "@/gql"
import { FetchResult } from "apollo-link"
import { useNotification } from "./useNotification"
import { useMutation as _useMutation } from "@vue/apollo-composable"
import { provideApolloClient } from "@vue/apollo-composable"
import { apolloClient } from "@/main"
import { ApolloError } from "apollo-client"
type Mutation = {
  mutate: <
    T extends Record<string, any> = Record<string, any>,
    Q extends OperationVariables = OperationVariables
  >(
    options: MutationOptions<T, Q>
  ) => Promise<FetchResult<T> | null | undefined>
}

type MutationOptions<
  T extends Record<string, any> = Record<string, any>,
  Q extends OperationVariables = OperationVariables
> = {
  mutation: DocumentNode
  variables?: Q
  refetchQueries?: RefetchQueryDescription
  done?: (data: FetchResult<T, Record<string, any>, Record<string, any>> | null) => any
  /** @deprecated Handle mutation errors at component level */
  error?: (error: ErrorV2) => any
  onError?: (error: ApolloError) => void
  onSuccess?: (data: T) => void
  update?: MutationUpdaterFn<T>
  handlePayloadErrors?: boolean
  onMutationError?: (error: ErrorV2) => void
}

export const useMutation = (): Mutation => {
  async function mutate<
    T extends Record<string, any> = Record<string, any>,
    Q extends OperationVariables = OperationVariables
  >(options: MutationOptions<T, Q>): Promise<FetchResult<T> | null | undefined> {
    const { addGraphQLError, addMutationError } = useNotification()

    const handlePayloadErrors = options.handlePayloadErrors ?? true
    // returns Promise of T
    const { mutate, onError, onDone } = provideApolloClient(apolloClient)(() =>
      _useMutation<T>(options.mutation, {
        variables: options.variables,
        refetchQueries: options.refetchQueries,
      })
    )

    onError((error) => {
      if (handlePayloadErrors) addGraphQLError(error as Error)
      else if (options.onError) options.onError(error)
    })

    onDone((result) => {
      // Handle mutation errors
      if (!result.data) return

      const mutationKey = Object.keys(result.data)[0],
        errorResponse = (result.data[mutationKey as keyof T] as T[keyof T] & { error: ErrorV2 })
          .error

      // TODO: Remove this, mutation errors should be handled at component level
      if (errorResponse) {
        if (options.onMutationError) options.onMutationError(errorResponse)
        else if (options.error) options.error.call(result, errorResponse)
        else addMutationError(errorResponse)
      } else {
        if (options.onSuccess) options.onSuccess.call(result, result.data)
      }

      return result
    })

    const result = await mutate()

    options.done?.(result)

    return result
  }

  return { mutate }
}
