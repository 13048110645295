import gql from 'graphql-tag';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Represents non-fractional signed whole numeric values. Since the value may exceed the size of a 32-bit integer, it's encoded as a string. */
  BigInt: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
};

/** Autogenerated return type of AcceptConsultantContract */
export type AcceptConsultantContractPayload = {
   __typename?: 'AcceptConsultantContractPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of AcceptInvitation */
export type AcceptInvitationPayload = {
   __typename?: 'AcceptInvitationPayload';
  error?: Maybe<ErrorV2>;
  invitation?: Maybe<Invitation>;
};

/** Autogenerated return type of AcceptMissionRequest */
export type AcceptMissionRequestPayload = {
   __typename?: 'AcceptMissionRequestPayload';
  error?: Maybe<ErrorV2>;
  missionRequest?: Maybe<MissionRequest>;
};

/** Autogenerated return type of AcceptServiceAgreement */
export type AcceptServiceAgreementPayload = {
   __typename?: 'AcceptServiceAgreementPayload';
  client?: Maybe<Client>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ActivatePeraltaAccount */
export type ActivatePeraltaAccountPayload = {
   __typename?: 'ActivatePeraltaAccountPayload';
  error?: Maybe<ErrorV2>;
};

export type ActivityInitiator = {
   __typename?: 'ActivityInitiator';
  avatar?: Maybe<UploadFile>;
  name: Scalars['String'];
};

export type ActivityLog = {
   __typename?: 'ActivityLog';
  action: Scalars['String'];
  actionComposition: Scalars['String'];
  actionables?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  initiator: ActivityInitiator;
  initiatorType: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  updatedBy?: Maybe<User>;
};

/** Autogenerated return type of AddComment */
export type AddCommentPayload = {
   __typename?: 'AddCommentPayload';
  comment?: Maybe<Comment>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of AddConsultantDocument */
export type AddConsultantDocumentPayload = {
   __typename?: 'AddConsultantDocumentPayload';
  consultantDocument?: Maybe<ConsultantApplicationDocument>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of AddConsultantEducation */
export type AddConsultantEducationPayload = {
   __typename?: 'AddConsultantEducationPayload';
  education?: Maybe<ConsultantEducation>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of AddConsultantMissionExpense */
export type AddConsultantMissionExpensePayload = {
   __typename?: 'AddConsultantMissionExpensePayload';
  error?: Maybe<ErrorV2>;
  missionExpense?: Maybe<ConsultantMissionExpense>;
};

/** Autogenerated return type of AddConsultantNationality */
export type AddConsultantNationalityPayload = {
   __typename?: 'AddConsultantNationalityPayload';
  error?: Maybe<ErrorV2>;
  nationality?: Maybe<ConsultantNationality>;
};

/** Autogenerated return type of AddConsultantSpokenLanguage */
export type AddConsultantSpokenLanguagePayload = {
   __typename?: 'AddConsultantSpokenLanguagePayload';
  error?: Maybe<ErrorV2>;
  spokenLanguage?: Maybe<ConsultantSpokenLanguage>;
};

/** Autogenerated return type of AddConsultantWorkExperience */
export type AddConsultantWorkExperiencePayload = {
   __typename?: 'AddConsultantWorkExperiencePayload';
  error?: Maybe<ErrorV2>;
  workExperience?: Maybe<ConsultantWorkExperience>;
};

/** Autogenerated return type of AddDocComment */
export type AddDocCommentPayload = {
   __typename?: 'AddDocCommentPayload';
  comment?: Maybe<DocComment>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of AddMissionPricingProposalActivity */
export type AddMissionPricingProposalActivityPayload = {
   __typename?: 'AddMissionPricingProposalActivityPayload';
  activity?: Maybe<MissionPricingProposalActivity>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of AddMissionScopeActivity */
export type AddMissionScopeActivityPayload = {
   __typename?: 'AddMissionScopeActivityPayload';
  activity?: Maybe<MissionScopeActivity>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of AddMissionScopePhase */
export type AddMissionScopePhasePayload = {
   __typename?: 'AddMissionScopePhasePayload';
  error?: Maybe<ErrorV2>;
  missionScopePhase?: Maybe<MissionScopePhase>;
};

/** Autogenerated return type of AddMissionScopeStaff */
export type AddMissionScopeStaffPayload = {
   __typename?: 'AddMissionScopeStaffPayload';
  error?: Maybe<ErrorV2>;
  staff?: Maybe<MissionScopeStaff>;
};

/** Autogenerated return type of AddRole */
export type AddRolePayload = {
   __typename?: 'AddRolePayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of AddSurveyQuestion */
export type AddSurveyQuestionPayload = {
   __typename?: 'AddSurveyQuestionPayload';
  error?: Maybe<ErrorV2>;
  question?: Maybe<SurveyQuestion>;
};

/** Autogenerated return type of AddSurveySection */
export type AddSurveySectionPayload = {
   __typename?: 'AddSurveySectionPayload';
  error?: Maybe<ErrorV2>;
  section?: Maybe<SurveySection>;
};

/** Autogenerated return type of AddTrainingTag */
export type AddTrainingTagPayload = {
   __typename?: 'AddTrainingTagPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of AddUser */
export type AddUserPayload = {
   __typename?: 'AddUserPayload';
  error?: Maybe<ErrorV2>;
  userInvitation?: Maybe<Invitation>;
};

export type AppConfig = {
   __typename?: 'AppConfig';
  group: Scalars['String'];
  id: Scalars['BigInt'];
  key: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type AppConfigsFilter = {
  group?: Maybe<Scalars['String']>;
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  or?: Maybe<Array<AppConfigsFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
};

/** Autogenerated return type of AppendSignature */
export type AppendSignaturePayload = {
   __typename?: 'AppendSignaturePayload';
  error?: Maybe<ErrorV2>;
  signature?: Maybe<Esignature>;
  signatureSource?: Maybe<SignatureSource>;
};

export type Applicant = {
   __typename?: 'Applicant';
  activityLogs?: Maybe<Array<ActivityLog>>;
  allowedEvents?: Maybe<Array<Scalars['String']>>;
  certificate?: Maybe<UploadFile>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  coverLetter?: Maybe<UploadFile>;
  createdAt: Scalars['ISO8601DateTime'];
  cv?: Maybe<UploadFile>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>;
  documents?: Maybe<Array<UploadFile>>;
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSectionProgresses?: Maybe<Array<FormSectionProgress>>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  jobRole?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  prn: Scalars['String'];
  referrer?: Maybe<ApplicantReferrer>;
  scatterId: Scalars['String'];
  state: Scalars['String'];
  token?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ApplicantBasic = {
   __typename?: 'ApplicantBasic';
  createdAt: Scalars['ISO8601DateTime'];
  emailAddress?: Maybe<Scalars['String']>;
};

export type ApplicantInput = {
  email: Scalars['String'];
  jobTitleId: Scalars['BigInt'];
  resumptionCode: Scalars['String'];
};

export type ApplicantInputV2 = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  referrer?: Maybe<ApplicantReferrer>;
};

export type ApplicantProfileInput = {
  city?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>;
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  jobRole?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<PhoneInput>;
};

export enum ApplicantReferrer {
  /** social_media */
  SocialMedia = 'social_media',
  /** news */
  News = 'news',
  /** friend_colleague */
  FriendColleague = 'friend_colleague',
  /** web_search */
  WebSearch = 'web_search',
  /** chancen */
  Chancen = 'chancen',
  /** other */
  Other = 'other',
  /** linked_in */
  LinkedIn = 'linked_in',
  /** instagram */
  Instagram = 'instagram',
  /** facebook */
  Facebook = 'facebook',
  /** twitter */
  Twitter = 'twitter',
  /** whatsapp */
  Whatsapp = 'whatsapp',
  /** tiktok */
  Tiktok = 'tiktok',
  /** google_ad */
  GoogleAd = 'google_ad'
}

export type ApplicantsFilter = {
  countryId?: Maybe<Scalars['BigInt']>;
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  nameContains?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  or?: Maybe<Array<ApplicantsFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
};

export type ApplicantsSortInput = {
  firstName?: Maybe<SortDirection>;
  lastName?: Maybe<SortDirection>;
};

export type ApplicationFormSection = {
   __typename?: 'ApplicationFormSection';
  active: Scalars['Boolean'];
  applicationTrack: ApplicationTrack;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  externalFormUrl?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  sectionType?: Maybe<ApplicationFormSectionTypeEnum>;
  slug: Scalars['String'];
  stepable: Scalars['Boolean'];
  survey: Survey;
  timer?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum ApplicationFormSectionTypeEnum {
  Assessment = 'assessment',
  Form = 'form',
  ExternalForm = 'external_form',
  Info = 'info',
  Review = 'review'
}

export type ApplicationTrack = {
   __typename?: 'ApplicationTrack';
  applicationFormSections?: Maybe<Array<ApplicationFormSection>>;
  client: ClientBasic;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  overviewContent?: Maybe<Scalars['String']>;
  prn: Scalars['String'];
  scatterId: Scalars['String'];
  timer: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
  url: Scalars['String'];
};

/** Autogenerated return type of ApplyForMissionStaffingPosition */
export type ApplyForMissionStaffingPositionPayload = {
   __typename?: 'ApplyForMissionStaffingPositionPayload';
  application?: Maybe<MissionStaffingApplication>;
  error?: Maybe<ErrorV2>;
};

export type AppraisalScore = {
   __typename?: 'AppraisalScore';
  id: Scalars['Int'];
  score: Scalars['Int'];
  week: Scalars['ISO8601Date'];
};

export type AppraisalSurvey = {
   __typename?: 'AppraisalSurvey';
  id: Scalars['Int'];
  questions: Array<Question>;
};

export type Approval = {
   __typename?: 'Approval';
  approvedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['BigInt'];
  rejectedAt?: Maybe<Scalars['ISO8601DateTime']>;
  rejectionReason?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** Autogenerated return type of ApproveApplication */
export type ApproveApplicationPayload = {
   __typename?: 'ApproveApplicationPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ApproveConsultantPeralteeClaim */
export type ApproveConsultantPeralteeClaimPayload = {
   __typename?: 'ApproveConsultantPeralteeClaimPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ApproveMissionLead */
export type ApproveMissionLeadPayload = {
   __typename?: 'ApproveMissionLeadPayload';
  error?: Maybe<ErrorV2>;
  missionLifecycle?: Maybe<MissionLifecycle>;
};

/** Autogenerated return type of ApproveMissionStaffingApplication */
export type ApproveMissionStaffingApplicationPayload = {
   __typename?: 'ApproveMissionStaffingApplicationPayload';
  error?: Maybe<ErrorV2>;
};

export type ApprovedAttachment = {
   __typename?: 'ApprovedAttachment';
  approvals: Array<Approval>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['BigInt'];
  state: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  uploadFile: UploadFile;
};

/** Autogenerated return type of ArchiveApplication */
export type ArchiveApplicationPayload = {
   __typename?: 'ArchiveApplicationPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ArchiveApplications */
export type ArchiveApplicationsPayload = {
   __typename?: 'ArchiveApplicationsPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ArchiveConsultantBankAccount */
export type ArchiveConsultantBankAccountPayload = {
   __typename?: 'ArchiveConsultantBankAccountPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ArchiveConsultantPayoneerAccount */
export type ArchiveConsultantPayoneerAccountPayload = {
   __typename?: 'ArchiveConsultantPayoneerAccountPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ArchiveMission */
export type ArchiveMissionPayload = {
   __typename?: 'ArchiveMissionPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ArchiveSurveyList */
export type ArchiveSurveyListPayload = {
   __typename?: 'ArchiveSurveyListPayload';
  error?: Maybe<ErrorV2>;
  surveyList?: Maybe<SurveyList>;
};

/** Autogenerated return type of ArchiveSurvey */
export type ArchiveSurveyPayload = {
   __typename?: 'ArchiveSurveyPayload';
  error?: Maybe<ErrorV2>;
  survey?: Maybe<Survey>;
};

export type Assessment = {
   __typename?: 'Assessment';
  deadline: Scalars['ISO8601Date'];
  duration: Scalars['Int'];
  id: Scalars['Int'];
  instructions: Scalars['String'];
  jobTitle: JobTitle;
  participants: Array<Consultant>;
  submissions: Array<ConsultantAssessment>;
};

export enum AssessmentType {
  /** Standard */
  Standard = 'standard',
  /** First case study */
  FirstCaseStudy = 'first_case_study',
  /** Second case study */
  SecondCaseStudy = 'second_case_study',
  /** First final interview */
  FirstFinalInterview = 'first_final_interview',
  /** Second final interview */
  SecondFinalInterview = 'second_final_interview'
}

export type AssessmentsFilter = {
  id?: Maybe<Scalars['Int']>;
  or?: Maybe<Array<AssessmentsFilter>>;
};

/** Autogenerated return type of AssignMissionExecutive */
export type AssignMissionExecutivePayload = {
   __typename?: 'AssignMissionExecutivePayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of AssignMissionPartner */
export type AssignMissionPartnerPayload = {
   __typename?: 'AssignMissionPartnerPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of AssignMissionPrincipal */
export type AssignMissionPrincipalPayload = {
   __typename?: 'AssignMissionPrincipalPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of AssignMissionTeamLead */
export type AssignMissionTeamLeadPayload = {
   __typename?: 'AssignMissionTeamLeadPayload';
  error?: Maybe<ErrorV2>;
};

export type BankAccount = {
   __typename?: 'BankAccount';
  accountAlias: Scalars['String'];
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  bankAddress: Scalars['String'];
  bankName: Scalars['String'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  iban: Scalars['String'];
  id: Scalars['BigInt'];
  sortCode: Scalars['String'];
  swiftCode: Scalars['String'];
};

export type BankAccountsFilter = {
  bankName?: Maybe<Scalars['String']>;
  or?: Maybe<Array<BankAccountsFilter>>;
};

export type BankAccountsSortInput = {
  bankName?: Maybe<SortDirection>;
};

/** Autogenerated return type of BeginApplication */
export type BeginApplicationPayload = {
   __typename?: 'BeginApplicationPayload';
  applicant?: Maybe<Applicant>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of BeginMissionProposal */
export type BeginMissionProposalPayload = {
   __typename?: 'BeginMissionProposalPayload';
  error?: Maybe<ErrorV2>;
  mission?: Maybe<MissionLifecycle>;
};


/** Billing account type */
export type BillingAccount = ConsultantBankAccount | ConsultantPayoneerAccount;

export enum BillingAccountEnum {
  ConsultantPayoneerAccount = 'ConsultantPayoneerAccount',
  ConsultantBankAccount = 'ConsultantBankAccount'
}

export type BillingAddress = {
   __typename?: 'BillingAddress';
  countryId: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['String'];
  lineOne: Scalars['String'];
  lineTwo?: Maybe<Scalars['String']>;
  prn: Scalars['String'];
  scatterId: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  zipCode?: Maybe<Scalars['String']>;
};

export type BrandResourceCollection = {
   __typename?: 'BrandResourceCollection';
  createdAt: Scalars['ISO8601DateTime'];
  files: Array<BrandResourceFile>;
  id: Scalars['Int'];
  label: BrandResourceLabel;
  name: Scalars['String'];
  prn: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type BrandResourceCollectionsFilter = {
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  or?: Maybe<Array<BrandResourceCollectionsFilter>>;
};

export type BrandResourceCollectionsSortInput = {
  name?: Maybe<SortDirection>;
};

export type BrandResourceDocument = {
   __typename?: 'BrandResourceDocument';
  brandResourceCollection: BrandResourceCollection;
  createdAt: Scalars['ISO8601DateTime'];
  embedFileThumbnail?: Maybe<Scalars['String']>;
  embedFileUrl?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  name: Scalars['String'];
  prn: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  uploadFile: UploadFile;
};

export type BrandResourceFile = {
   __typename?: 'BrandResourceFile';
  brandResourceCollection: BrandResourceCollection;
  createdAt: Scalars['ISO8601DateTime'];
  embedFileThumbnail?: Maybe<Scalars['String']>;
  embedFileUrl?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  name: Scalars['String'];
  prn: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  uploadFile?: Maybe<UploadFile>;
};

export type BrandResourceLabel = {
   __typename?: 'BrandResourceLabel';
  collections?: Maybe<Array<BrandResourceCollection>>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  prn: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type BrandResourceLabelsFilter = {
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  or?: Maybe<Array<BrandResourceLabelsFilter>>;
};

export type BrandResourceLabelsSortInput = {
  name?: Maybe<SortDirection>;
};

export type BrandResourceVideo = {
   __typename?: 'BrandResourceVideo';
  brandResourceCollection: BrandResourceCollection;
  createdAt: Scalars['ISO8601DateTime'];
  embedFileThumbnail: Scalars['String'];
  embedFileUrl: Scalars['String'];
  id: Scalars['BigInt'];
  name: Scalars['String'];
  prn: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  uploadFile?: Maybe<UploadFile>;
};

/** Autogenerated return type of BulkArchiveApplication */
export type BulkArchiveApplicationPayload = {
   __typename?: 'BulkArchiveApplicationPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of BulkRejectApplication */
export type BulkRejectApplicationPayload = {
   __typename?: 'BulkRejectApplicationPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of BulkShortlistApplicant */
export type BulkShortlistApplicantPayload = {
   __typename?: 'BulkShortlistApplicantPayload';
  error?: Maybe<ErrorV2>;
};

export type ByAggregationResult = {
   __typename?: 'ByAggregationResult';
  availability?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CancelConsultantPeralteeClaimRequest */
export type CancelConsultantPeralteeClaimRequestPayload = {
   __typename?: 'CancelConsultantPeralteeClaimRequestPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of CancelMissionStaffingApplication */
export type CancelMissionStaffingApplicationPayload = {
   __typename?: 'CancelMissionStaffingApplicationPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of CancelMissionStaffingRequest */
export type CancelMissionStaffingRequestPayload = {
   __typename?: 'CancelMissionStaffingRequestPayload';
  error?: Maybe<ErrorV2>;
};

export enum CandidateRejectionTypeEnum {
  /** Consider for different role */
  ConsiderDifferentRole = 'consider_different_role',
  /** See you later */
  SeeYouLater = 'see_you_later',
  /** Reject due to incomplete assessment */
  IncompleteAssessment = 'incomplete_assessment',
  /** Reject due to insufficient assessment results */
  InsufficientAssessment = 'insufficient_assessment',
  /** Reject due to profile not matching */
  ProfileNotMatching = 'profile_not_matching'
}

export type Card = {
   __typename?: 'Card';
  billingAddress?: Maybe<BillingAddress>;
  cardType: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  lastFour: Scalars['String'];
  name: Scalars['String'];
  ownerType: Scalars['String'];
  prn: Scalars['String'];
  scatterId: Scalars['String'];
  status?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type CardInput = {
  cardType: Scalars['String'];
  isDefault?: Maybe<Scalars['Boolean']>;
  lastFour: Scalars['String'];
  name: Scalars['String'];
  paymentToken: Scalars['String'];
  stripeCardId: Scalars['String'];
};

export type Category = {
   __typename?: 'Category';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type ClaimPeriodChangeStatistics = {
   __typename?: 'ClaimPeriodChangeStatistics';
  count: Scalars['Int'];
  points: Scalars['Int'];
};

export enum ClaimState {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED'
}

export type ClaimStateStatistics = {
   __typename?: 'ClaimStateStatistics';
  change: ClaimPeriodChangeStatistics;
  count: Scalars['Int'];
  points: Scalars['Int'];
};

export type Client = {
   __typename?: 'Client';
  acceptedServiceAgreement: Scalars['Boolean'];
  address: Scalars['String'];
  admins: Array<User>;
  approval?: Maybe<Approval>;
  clientAdminGrants: Array<ClientAdminGrant>;
  clientContacts: Array<ClientContact>;
  clientType: ClientType;
  code?: Maybe<Scalars['String']>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  companySize?: Maybe<Scalars['Int']>;
  companySizeRange?: Maybe<CompanySizeRangeEnum>;
  country: Country;
  createdAt?: Maybe<Scalars['ISO8601Date']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  industry?: Maybe<Scalars['String']>;
  isPortfolioManagementCompany: Scalars['Boolean'];
  isPortofolioCompany: Scalars['Boolean'];
  missionLifecycles: Array<MissionLifecycle>;
  missionsCount: Scalars['Int'];
  name: Scalars['String'];
  ongoingMissions: Array<MissionLifecycleBasic>;
  ownershipStructure?: Maybe<OwnershipStructureEnum>;
  pendingClientAdminInvitations: Array<Invitation>;
  pendingPayments: Array<MissionContractPayment>;
  portfolioManagementCompany?: Maybe<Client>;
  portofolioCompanies?: Maybe<Array<Client>>;
  primaryClientContact?: Maybe<ClientContact>;
  priorityPayments: Array<MissionContractPayment>;
  prn: Scalars['String'];
  product: Product;
  productSlug: ProductSlugEnum;
  proposalsCount: Scalars['Int'];
  state: Scalars['String'];
  subscriptions: Array<Subscription>;
  tagline?: Maybe<Scalars['String']>;
  tasks: Array<Task>;
  taxExempt?: Maybe<Scalars['Boolean']>;
  taxId?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
  website?: Maybe<Scalars['String']>;
};

export type ClientAdminGrant = {
   __typename?: 'ClientAdminGrant';
  client: Client;
  clientAdminPosition?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['String'];
  prn: Scalars['String'];
  scatterId: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: UserAdvanced;
};

export type ClientBasic = {
   __typename?: 'ClientBasic';
  companyLogoUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  prn: Scalars['String'];
  scatterId: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  verified: Scalars['Boolean'];
};

export type ClientContact = {
   __typename?: 'ClientContact';
  email: Scalars['String'];
  id: Scalars['BigInt'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  role: Scalars['String'];
};

export type ClientContactInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  role: Scalars['String'];
};

export type ClientInput = {
  address?: Maybe<Scalars['String']>;
  clientContactsAttributes?: Maybe<Array<ClientContactInput>>;
  clientTypeId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  companySize?: Maybe<Scalars['Int']>;
  companySizeRange?: Maybe<CompanySizeRangeEnum>;
  countryId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  industry?: Maybe<MissionRequestBusinessIndustry>;
  isPortfolioManagementCompany?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  ownershipStructure?: Maybe<OwnershipStructureEnum>;
  taxExempt?: Maybe<Scalars['Boolean']>;
  taxId?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export enum ClientInvoiceState {
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Paid = 'PAID'
}

export type ClientInvoicesFilter = {
  clientId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  missionLifecycleId?: Maybe<Scalars['BigInt']>;
  state?: Maybe<ClientInvoiceState>;
  or?: Maybe<Array<ClientInvoicesFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
  dueAtDate?: Maybe<DateFilterInput>;
  search?: Maybe<Scalars['String']>;
};

export type ClientInvoicesSortInput = {
  createdAt?: Maybe<SortDirection>;
  id?: Maybe<SortDirection>;
  dueAt?: Maybe<SortDirection>;
};

export type ClientMissionRequestFilter = {
  clientId?: Maybe<Scalars['BigInt']>;
  state?: Maybe<Scalars['String']>;
  or?: Maybe<Array<ClientMissionRequestFilter>>;
};

export type ClientMissionRequestsSortInput = {
  id?: Maybe<SortDirection>;
};

export type ClientMissionsFilter = {
  missionId?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  or?: Maybe<Array<ClientMissionsFilter>>;
};

export type ClientMissionsSortInput = {
  name?: Maybe<SortDirection>;
};

export type ClientPayload = {
   __typename?: 'ClientPayload';
  client?: Maybe<Client>;
  missionRequest?: Maybe<MissionRequest>;
  payInvoiceUrl?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['ID']>;
};

export type ClientPaymentStructureInput = {
  /** PRN of MissionScopeActivity */
  deliverables?: Maybe<Array<Scalars['ID']>>;
  paymentType: ContractPayment;
  percentage: Scalars['Float'];
};

export type ClientType = {
   __typename?: 'ClientType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ClientsFilter = {
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  or?: Maybe<Array<ClientsFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
};

export type ClientsSortInput = {
  name?: Maybe<SortDirection>;
};

export enum CohortPeriodTypeEnum {
  /** Cohort 1: 15th May */
  Cohort_1 = 'cohort_1',
  /** Cohort 2: 22nd July */
  Cohort_2 = 'cohort_2',
  /** Cohort 3: 23rd September */
  Cohort_3 = 'cohort_3'
}

/** Autogenerated return type of CommenceMissionContracting */
export type CommenceMissionContractingPayload = {
   __typename?: 'CommenceMissionContractingPayload';
  error?: Maybe<ErrorV2>;
  missionLifecycle?: Maybe<MissionLifecycle>;
};

/** Autogenerated return type of CommenceMissionExecution */
export type CommenceMissionExecutionPayload = {
   __typename?: 'CommenceMissionExecutionPayload';
  error?: Maybe<ErrorV2>;
  missionLifecycle?: Maybe<MissionLifecycle>;
};

/** Autogenerated return type of CommenceMissionStaffing */
export type CommenceMissionStaffingPayload = {
   __typename?: 'CommenceMissionStaffingPayload';
  error?: Maybe<ErrorV2>;
  missionLifecycle?: Maybe<MissionLifecycle>;
};

export type Comment = {
   __typename?: 'Comment';
  conversation: Conversation;
  createdAt: Scalars['ISO8601DateTime'];
  edited: Scalars['Boolean'];
  file?: Maybe<UploadFile>;
  id: Scalars['BigInt'];
  prn: Scalars['String'];
  text: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

export type CommentableTypeInput = {
  commentableId: Scalars['BigInt'];
  commentableType: Scalars['String'];
};

export type CompaniesFilter = {
  name?: Maybe<Scalars['String']>;
  or?: Maybe<Array<CompaniesFilter>>;
};

export type CompaniesSortInput = {
  name?: Maybe<SortDirection>;
};

export type Company = {
   __typename?: 'Company';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum CompanySizeRangeEnum {
  /** 0-10 */
  LessThan_10 = 'less_than_10',
  /** 11-50 */
  LessThan_50 = 'less_than_50',
  /** greater than 50 */
  GreaterThan_50 = 'greater_than_50'
}

/** Autogenerated return type of CompleteApplication */
export type CompleteApplicationPayload = {
   __typename?: 'CompleteApplicationPayload';
  application?: Maybe<ConsultantApplication>;
  error?: Maybe<ErrorV2>;
  surveyResponse?: Maybe<SurveyResponse>;
};

/** Autogenerated return type of CompleteMissionExecution */
export type CompleteMissionExecutionPayload = {
   __typename?: 'CompleteMissionExecutionPayload';
  error?: Maybe<ErrorV2>;
  missionLifecycle?: Maybe<MissionLifecycle>;
};

/** Autogenerated return type of ConfirmEmail */
export type ConfirmEmailPayload = {
   __typename?: 'ConfirmEmailPayload';
  error?: Maybe<ErrorV2>;
  response?: Maybe<ConfirmEmailResponse>;
};

export type ConfirmEmailResponse = {
   __typename?: 'ConfirmEmailResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Consultant = {
   __typename?: 'Consultant';
  availabilityDate?: Maybe<Scalars['ISO8601Date']>;
  bio?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  currentOccupation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  jobTitle?: Maybe<JobTitle>;
  jobTitles?: Maybe<Array<JobTitle>>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nextStates: Array<StateEventPair>;
  phone?: Maybe<Scalars['String']>;
  prn: Scalars['String'];
  profilePresentation?: Maybe<UploadFile>;
  submittedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

export type ConsultantAdvanced = {
   __typename?: 'ConsultantAdvanced';
  availabilityDate?: Maybe<Scalars['ISO8601Date']>;
  bio?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  currentOccupation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  jobTitle?: Maybe<JobTitle>;
  jobTitles?: Maybe<Array<JobTitle>>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nextStates: Array<StateEventPair>;
  phone?: Maybe<Scalars['String']>;
  prn: Scalars['String'];
  profilePresentation?: Maybe<UploadFile>;
  rating?: Maybe<Scalars['Float']>;
  submittedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

export type ConsultantApplication = {
   __typename?: 'ConsultantApplication';
  africaNeeds?: Maybe<Scalars['String']>;
  applicationChannel?: Maybe<Scalars['String']>;
  availability?: Maybe<ConsultantAvailability>;
  availabilityDate?: Maybe<Scalars['ISO8601Date']>;
  availabilityReason?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  consultantBankAccounts: Array<ConsultantBankAccount>;
  consultantContracts: PaginatedConsultantContracts;
  consultantDocuments: Array<UploadFile>;
  consultantEducations?: Maybe<Array<ConsultantEducation>>;
  consultantInvoices?: Maybe<PaginatedConsultantInvoices>;
  consultantNationalities: Array<ConsultantNationality>;
  consultantPayoneerAccounts: Array<ConsultantPayoneerAccount>;
  consultantSpokenLanguages: Array<ConsultantSpokenLanguage>;
  consultantWorkExperiences?: Maybe<Array<ConsultantWorkExperience>>;
  convincedPeerAnswer?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  currentOccupation?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>;
  email: Scalars['String'];
  employmentStatus?: Maybe<Scalars['String']>;
  failedGoalAnswer?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  jobFunctions?: Maybe<Array<JobFunction>>;
  jobTitle?: Maybe<JobTitle>;
  jobTitles?: Maybe<Array<JobTitle>>;
  lastName?: Maybe<Scalars['String']>;
  leadershipRoleAnswer?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  mbti?: Maybe<Scalars['String']>;
  missionDeliverables: Array<ConsultantMissionDeliverable>;
  missionStaffingApplications?: Maybe<PaginatedMissionStaffingApplications>;
  missionStaffingPlacements?: Maybe<PaginatedMissionStaffingPlacements>;
  missions: Array<MissionLifecycleBasic>;
  name?: Maybe<Scalars['String']>;
  nextAvailability?: Maybe<Scalars['ISO8601Date']>;
  nextPayment?: Maybe<ConsultantInvoice>;
  nextStates: Array<StateEventPair>;
  opportunities?: Maybe<PaginatedMissionStaffingPositions>;
  peralteeClaims: PaginatedConsultantPeralteeClaims;
  phone?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  practiceArea?: Maybe<Scalars['String']>;
  prn: Scalars['String'];
  profilePresentation?: Maybe<UploadFile>;
  rating?: Maybe<Scalars['String']>;
  residentialAddress?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  submittedAt?: Maybe<Scalars['ISO8601DateTime']>;
  trainingTagList: Array<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<UserAdvanced>;
  wallet?: Maybe<Wallet>;
  yearJoined?: Maybe<Scalars['String']>;
  yearStartedFreelance?: Maybe<Scalars['String']>;
  yearStartedWork?: Maybe<Scalars['String']>;
};


export type ConsultantApplicationConsultantContractsArgs = {
  scope?: Maybe<ConsultantContractScope>;
  filter?: Maybe<ConsultantContractsFilter>;
  sort?: Maybe<ConsultantContractsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type ConsultantApplicationConsultantInvoicesArgs = {
  paginate?: Maybe<PaginationInput>;
  filter?: Maybe<ConsultantInvoicesFilter>;
  sort?: Maybe<ConsultantInvoicesSortInput>;
};


export type ConsultantApplicationMissionDeliverablesArgs = {
  completed?: Maybe<Scalars['Boolean']>;
};


export type ConsultantApplicationMissionStaffingApplicationsArgs = {
  filter?: Maybe<MissionStaffingApplicationsFilter>;
  sort?: Maybe<MissionStaffingApplicationsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type ConsultantApplicationMissionStaffingPlacementsArgs = {
  filter?: Maybe<MissionStaffingPlacementsFilter>;
  sort?: Maybe<MissionStaffingPlacementsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type ConsultantApplicationMissionsArgs = {
  limit?: Scalars['Int'];
};


export type ConsultantApplicationOpportunitiesArgs = {
  filter?: Maybe<MissionStaffingPositionsFilter>;
  sort?: Maybe<MissionStaffingPositionsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type ConsultantApplicationPeralteeClaimsArgs = {
  filter?: Maybe<ConsultantPeralteeClaimsFilter>;
  sort?: Maybe<ConsultantPeralteeClaimsSortInput>;
  paginate?: Maybe<PaginationInput>;
};

export type ConsultantApplicationDocument = {
   __typename?: 'ConsultantApplicationDocument';
  consultant?: Maybe<Consultant>;
  id: Scalars['Int'];
  uploadFile?: Maybe<UploadFile>;
};

export type ConsultantApplicationInput = {
  africaNeeds?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
  currentOccupation?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>;
  employmentStatus?: Maybe<EmploymentStatus>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  jobFunctions?: Maybe<Array<JobFunction>>;
  jobTitleId?: Maybe<Scalars['BigInt']>;
  lastName?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  mbti?: Maybe<Mbti>;
  phone?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  practiceArea?: Maybe<Scalars['String']>;
  residentialAddress?: Maybe<Scalars['String']>;
  yearStartedFreelance?: Maybe<Scalars['String']>;
  yearStartedWork?: Maybe<Scalars['String']>;
  applicationChannel?: Maybe<Scalars['String']>;
  applicationFormResponse?: Maybe<Scalars['JSON']>;
  availabilityDate?: Maybe<Scalars['ISO8601Date']>;
  convincedPeerAnswer?: Maybe<Scalars['String']>;
  failedGoalAnswer?: Maybe<Scalars['String']>;
  leadershipRoleAnswer?: Maybe<Scalars['String']>;
};

export type ConsultantAssessment = {
   __typename?: 'ConsultantAssessment';
  answer: Scalars['String'];
  assessment: Assessment;
  consultant: Consultant;
  id: Scalars['Int'];
  score: Scalars['Int'];
  state: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum ConsultantAvailability {
  /** Available (part-time) */
  AvailablePartTime = 'available_part_time',
  /** Available (full-time) */
  AvailableFullTime = 'available_full_time',
  /** Not available */
  NotAvailable = 'not_available',
  /** Other */
  Other = 'other'
}

export type ConsultantBankAccount = {
   __typename?: 'ConsultantBankAccount';
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  bankAddress: Scalars['String'];
  bankName: Scalars['String'];
  consultant: ConsultantProfile;
  country: Country;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['String'];
  prn: Scalars['String'];
  routingNumber?: Maybe<Scalars['String']>;
  scatterId: Scalars['String'];
  sortCode?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  swiftCode: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ConsultantBankAccountInput = {
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  bankAddress: Scalars['String'];
  bankName: Scalars['String'];
  countryId: Scalars['BigInt'];
  routingNumber?: Maybe<Scalars['String']>;
  sortCode?: Maybe<Scalars['String']>;
  swiftCode: Scalars['String'];
};

export type ConsultantContract = {
   __typename?: 'ConsultantContract';
  acknowledgedAt?: Maybe<Scalars['ISO8601DateTime']>;
  activityLogs: Array<ActivityLog>;
  address?: Maybe<Scalars['String']>;
  application: MissionStaffingApplication;
  approvals?: Maybe<Array<Approval>>;
  commencementDate?: Maybe<Scalars['ISO8601Date']>;
  companyName?: Maybe<Scalars['String']>;
  conclusionDate?: Maybe<Scalars['ISO8601Date']>;
  consultant: Consultant;
  consultantContractMilestones?: Maybe<Array<ConsultantContractMilestone>>;
  contractDate?: Maybe<Scalars['ISO8601Date']>;
  contractingEntity?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currency: Currency;
  declineReason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  missionLifecycle: MissionLifecycleBasic;
  objective?: Maybe<Scalars['String']>;
  prn: Scalars['String'];
  scatterId: Scalars['String'];
  signatures: Array<Esignature>;
  state: ConsultantContractState;
  terminatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  totalFees: Scalars['Float'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ConsultantContractInput = {
  address: Scalars['String'];
  applicationId: Scalars['BigInt'];
  commencementDate: Scalars['ISO8601Date'];
  companyName?: Maybe<Scalars['String']>;
  conclusionDate: Scalars['ISO8601Date'];
  contractDate: Scalars['ISO8601Date'];
  contractingEntity?: Maybe<ContractingEntity>;
  currencyId: Scalars['BigInt'];
  objective: Scalars['String'];
};

export type ConsultantContractMilestone = {
   __typename?: 'ConsultantContractMilestone';
  description: Scalars['String'];
  fees: Scalars['Float'];
  feesCents?: Maybe<Scalars['Int']>;
  id: Scalars['BigInt'];
  missionContractDeliverable?: Maybe<MissionContractDeliverable>;
  missionStaffingMilestone: MissionStaffingMilestone;
  state: Scalars['String'];
};

export type ConsultantContractMilestoneInput = {
  description: Scalars['String'];
  feesCents?: Maybe<Scalars['Int']>;
};

export type ConsultantContractPayment = {
   __typename?: 'ConsultantContractPayment';
  amount: Money;
  amountCents?: Maybe<Scalars['Int']>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['String'];
  missionContractPayment?: Maybe<MissionContractPaymentBasic>;
  missionStaffingPosition: MissionStaffingPosition;
  name?: Maybe<Scalars['String']>;
  paymentType?: Maybe<ContractPayment>;
  percentage?: Maybe<Scalars['Int']>;
  prn: Scalars['String'];
  scatterId: Scalars['String'];
  staffingPositionDeliverables: Array<StaffingPositionDeliverable>;
  state: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ConsultantContractPaymentInput = {
  amount?: Maybe<MoneyInput>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  name?: Maybe<Scalars['String']>;
  paymentType?: Maybe<ContractPayment>;
  staffingPositionDeliverables?: Maybe<Array<StaffingPositionDeliverableInput>>;
  state?: Maybe<PaymentStateEnum>;
};

export enum ConsultantContractScope {
  Global = 'GLOBAL',
  Foresighter = 'FORESIGHTER'
}

export enum ConsultantContractState {
  Approved = 'approved',
  Accepted = 'accepted',
  Declined = 'declined',
  Terminating = 'terminating',
  Terminated = 'terminated'
}

export type ConsultantContractsFilter = {
  id?: Maybe<Scalars['BigInt']>;
  state?: Maybe<ConsultantContractState>;
  or?: Maybe<Array<ConsultantContractsFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
  search?: Maybe<Scalars['String']>;
};

export type ConsultantContractsSortInput = {
  createdAt?: Maybe<SortDirection>;
};

export type ConsultantEducation = {
   __typename?: 'ConsultantEducation';
  degree: Scalars['String'];
  endDate?: Maybe<Scalars['ISO8601Date']>;
  grade: Scalars['String'];
  id: Scalars['Int'];
  institution?: Maybe<Institution>;
  institutionId?: Maybe<Scalars['Int']>;
  institutionName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startDate?: Maybe<Scalars['ISO8601Date']>;
};

export type ConsultantEducationAttributeInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  degree?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  institutionId?: Maybe<Scalars['BigInt']>;
  institutionName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type ConsultantEducationInput = {
  degree: Scalars['String'];
  endDate: Scalars['String'];
  grade?: Maybe<Scalars['String']>;
  institutionId?: Maybe<Scalars['Int']>;
  institutionName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startDate: Scalars['String'];
};

export type ConsultantInvoice = {
   __typename?: 'ConsultantInvoice';
  activityLogs: Array<ActivityLog>;
  approvals: Array<Approval>;
  approvedAt?: Maybe<Scalars['ISO8601Date']>;
  billingAccount?: Maybe<BillingAccount>;
  consultant: ConsultantProfile;
  createdAt: Scalars['ISO8601DateTime'];
  dueAt?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  invoiceItems: Array<ConsultantInvoiceItem>;
  invoiceType?: Maybe<Scalars['String']>;
  missionContractPaymentStatus?: Maybe<MissionContractPaymentState>;
  missionLifecycle: MissionLifecycle;
  missionStaffingPlacement: MissionStaffingPlacement;
  paidAt?: Maybe<Scalars['ISO8601Date']>;
  payment?: Maybe<ConsultantContractPayment>;
  paymentDescription?: Maybe<Scalars['String']>;
  /** @deprecated Use `staffing_position_deliverables` instead */
  pendingMilestones?: Maybe<Array<ConsultantContractMilestone>>;
  state: Scalars['String'];
  /** @deprecated Use `amount` instead */
  total?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ConsultantInvoiceItem = {
   __typename?: 'ConsultantInvoiceItem';
  description: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  quantity: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
  unitCost: Scalars['Int'];
  uploadFile?: Maybe<UploadFile>;
};

export enum ConsultantInvoiceState {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Processing = 'PROCESSING',
  Paid = 'PAID'
}

export type ConsultantInvoicesFilter = {
  id?: Maybe<Scalars['BigInt']>;
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  missionIdIn?: Maybe<Scalars['BigInt']>;
  state?: Maybe<ConsultantInvoiceState>;
  or?: Maybe<Array<ConsultantInvoicesFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
  dueAtDate?: Maybe<DateFilterInput>;
  search?: Maybe<Scalars['String']>;
};

export type ConsultantInvoicesSortInput = {
  createdAt?: Maybe<SortDirection>;
  id?: Maybe<SortDirection>;
  dueAt?: Maybe<SortDirection>;
};

export type ConsultantMissionDeliverable = {
   __typename?: 'ConsultantMissionDeliverable';
  completed: Scalars['Boolean'];
  missionStaffingPosition: MissionStaffingPosition;
  name: Scalars['String'];
};

export type ConsultantMissionExpense = {
   __typename?: 'ConsultantMissionExpense';
  consultantInvoice: ConsultantInvoice;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  prn: Scalars['String'];
  quantity: Scalars['Int'];
  unitCost: Scalars['Float'];
  updatedAt: Scalars['ISO8601DateTime'];
  uploadFile: UploadFile;
};

export type ConsultantMissionExpenseInput = {
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  unitCost?: Maybe<Scalars['Float']>;
  uploadFile?: Maybe<UploadFileInput>;
};

export type ConsultantMissionLifecycle = {
   __typename?: 'ConsultantMissionLifecycle';
  activityLogs: Array<ActivityLog>;
  client: ClientBasic;
  collaborators?: Maybe<Array<UserAdvanced>>;
  completionProgress: Scalars['Float'];
  completionProgressActivityLog?: Maybe<ActivityLog>;
  contractingEntity?: Maybe<ContractingEntity>;
  createdAt: Scalars['ISO8601DateTime'];
  defaultConversation?: Maybe<Conversation>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['BigInt'];
  missionScope?: Maybe<MissionScope>;
  missionStaffingPlacement: MissionStaffingPlacement;
  missionTeamMemberships: Array<MissionTeamMembership>;
  name?: Maybe<Scalars['String']>;
  prn: Scalars['String'];
  projectLocationCountryCode?: Maybe<Scalars['String']>;
  projectLocationType?: Maybe<Scalars['String']>;
  scopeService?: Maybe<ScopeService>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  state: MissionLifecycleState;
  summary?: Maybe<Scalars['String']>;
  tagList: Array<Scalars['String']>;
  termsOfReferences?: Maybe<Array<UploadFile>>;
  updatedAt: Scalars['ISO8601DateTime'];
};


export type ConsultantMissionLifecycleActivityLogsArgs = {
  limit?: Scalars['Int'];
};

export type ConsultantNationality = {
   __typename?: 'ConsultantNationality';
  country: Country;
  countryId: Scalars['Int'];
  id: Scalars['Int'];
};

export type ConsultantOnboardingInput = {
  availabilityDate?: Maybe<Scalars['ISO8601Date']>;
  bio?: Maybe<Scalars['String']>;
  currentOccupation?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  mbti?: Maybe<Mbti>;
  phone?: Maybe<PhoneInput>;
  residentialAddress?: Maybe<Scalars['String']>;
  consultantEducationsAttributes?: Maybe<Array<ConsultantEducationAttributeInput>>;
  consultantSpokenLanguagesAttributes?: Maybe<Array<ConsultantSpokenLanguageAttributeInput>>;
  consultantWorkExperiencesAttributes?: Maybe<Array<ConsultantWorkExperienceAttributeInput>>;
};

export type ConsultantPaymentStructureInput = {
  /** PRN of Mission Contract Payment */
  missionContractPaymentId?: Maybe<Scalars['ID']>;
  paymentType?: Maybe<ContractPayment>;
  percentage: Scalars['Float'];
  staffingPositionDeliverables?: Maybe<Array<StaffingPositionDeliverableInput>>;
};

export type ConsultantPayoneerAccount = {
   __typename?: 'ConsultantPayoneerAccount';
  consultant: ConsultantProfile;
  createdAt: Scalars['ISO8601DateTime'];
  customerId: Scalars['String'];
  emailAddress: Scalars['String'];
  id: Scalars['String'];
  prn: Scalars['String'];
  scatterId: Scalars['String'];
  state: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ConsultantPeralteeClaim = {
   __typename?: 'ConsultantPeralteeClaim';
  comment?: Maybe<Scalars['String']>;
  consultant: Consultant;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  state: Scalars['String'];
  supportService: PeralteeSupportService;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ConsultantPeralteeClaimsFilter = {
  consultantIdIn?: Maybe<Array<Scalars['BigInt']>>;
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  jobTitleId?: Maybe<Scalars['BigInt']>;
  state?: Maybe<ClaimState>;
  or?: Maybe<Array<ConsultantPeralteeClaimsFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
  search?: Maybe<Scalars['String']>;
};

export type ConsultantPeralteeClaimsSortInput = {
  createdAt?: Maybe<SortDirection>;
  id?: Maybe<SortDirection>;
};

export type ConsultantPeralteeClaimsStatistics = {
   __typename?: 'ConsultantPeralteeClaimsStatistics';
  approved: ClaimStateStatistics;
  pending: ClaimStateStatistics;
  rejected: ClaimStateStatistics;
  total: Scalars['Int'];
};

export type ConsultantProfile = {
   __typename?: 'ConsultantProfile';
  africaNeeds?: Maybe<Scalars['String']>;
  availability?: Maybe<ConsultantAvailability>;
  availabilityDate?: Maybe<Scalars['ISO8601Date']>;
  availabilityReason?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  consultantBankAccounts: Array<ConsultantBankAccount>;
  consultantContracts: PaginatedConsultantContracts;
  consultantDocuments: Array<UploadFile>;
  consultantEducations?: Maybe<Array<ConsultantEducation>>;
  consultantInvoices?: Maybe<PaginatedConsultantInvoices>;
  consultantNationalities: Array<ConsultantNationality>;
  consultantPayoneerAccounts: Array<ConsultantPayoneerAccount>;
  consultantSpokenLanguages: Array<ConsultantSpokenLanguage>;
  consultantWorkExperiences?: Maybe<Array<ConsultantWorkExperience>>;
  country?: Maybe<Country>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  currentOccupation?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>;
  email: Scalars['String'];
  employmentStatus?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  jobFunctions?: Maybe<Array<JobFunction>>;
  jobTitle?: Maybe<JobTitle>;
  jobTitles?: Maybe<Array<JobTitle>>;
  lastName?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  mbti?: Maybe<Scalars['String']>;
  missionDeliverables: Array<ConsultantMissionDeliverable>;
  missionStaffingApplications?: Maybe<PaginatedMissionStaffingApplications>;
  missionStaffingPlacements?: Maybe<PaginatedMissionStaffingPlacements>;
  missions: Array<MissionLifecycleBasic>;
  name?: Maybe<Scalars['String']>;
  nextAvailability?: Maybe<Scalars['ISO8601Date']>;
  nextPayment?: Maybe<ConsultantInvoice>;
  nextStates: Array<StateEventPair>;
  opportunities?: Maybe<PaginatedMissionStaffingPositions>;
  peralteeClaims: PaginatedConsultantPeralteeClaims;
  phone?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  practiceArea?: Maybe<Scalars['String']>;
  prn: Scalars['String'];
  profilePresentation?: Maybe<UploadFile>;
  rating?: Maybe<Scalars['String']>;
  residentialAddress?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  submittedAt?: Maybe<Scalars['ISO8601DateTime']>;
  trainingTagList: Array<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<UserAdvanced>;
  wallet?: Maybe<Wallet>;
  yearJoined?: Maybe<Scalars['String']>;
  yearStartedFreelance?: Maybe<Scalars['String']>;
  yearStartedWork?: Maybe<Scalars['String']>;
};


export type ConsultantProfileConsultantContractsArgs = {
  scope?: Maybe<ConsultantContractScope>;
  filter?: Maybe<ConsultantContractsFilter>;
  sort?: Maybe<ConsultantContractsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type ConsultantProfileConsultantInvoicesArgs = {
  paginate?: Maybe<PaginationInput>;
  filter?: Maybe<ConsultantInvoicesFilter>;
  sort?: Maybe<ConsultantInvoicesSortInput>;
};


export type ConsultantProfileMissionDeliverablesArgs = {
  completed?: Maybe<Scalars['Boolean']>;
};


export type ConsultantProfileMissionStaffingApplicationsArgs = {
  filter?: Maybe<MissionStaffingApplicationsFilter>;
  sort?: Maybe<MissionStaffingApplicationsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type ConsultantProfileMissionStaffingPlacementsArgs = {
  filter?: Maybe<MissionStaffingPlacementsFilter>;
  sort?: Maybe<MissionStaffingPlacementsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type ConsultantProfileMissionsArgs = {
  limit?: Scalars['Int'];
};


export type ConsultantProfileOpportunitiesArgs = {
  filter?: Maybe<MissionStaffingPositionsFilter>;
  sort?: Maybe<MissionStaffingPositionsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type ConsultantProfilePeralteeClaimsArgs = {
  filter?: Maybe<ConsultantPeralteeClaimsFilter>;
  sort?: Maybe<ConsultantPeralteeClaimsSortInput>;
  paginate?: Maybe<PaginationInput>;
};

export type ConsultantProfileInput = {
  africaNeeds?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
  currentOccupation?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>;
  employmentStatus?: Maybe<EmploymentStatus>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  jobFunctions?: Maybe<Array<JobFunction>>;
  jobTitleId?: Maybe<Scalars['BigInt']>;
  lastName?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  mbti?: Maybe<Mbti>;
  phone?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  practiceArea?: Maybe<Scalars['String']>;
  residentialAddress?: Maybe<Scalars['String']>;
  yearStartedFreelance?: Maybe<Scalars['String']>;
  yearStartedWork?: Maybe<Scalars['String']>;
};

export type ConsultantSpokenLanguage = {
   __typename?: 'ConsultantSpokenLanguage';
  fluency: Scalars['String'];
  id: Scalars['Int'];
  language: Language;
  languageId: Scalars['Int'];
};

export type ConsultantSpokenLanguageAttributeInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  fluency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  languageId?: Maybe<Scalars['Int']>;
};

export type ConsultantSpokenLanguageInput = {
  fluency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  languageId?: Maybe<Scalars['Int']>;
};

export enum ConsultantState {
  Applying = 'applying',
  Pending = 'pending',
  IntroCall = 'intro_call',
  Archived = 'archived',
  Shortlisted = 'shortlisted',
  FirstCaseStudy = 'first_case_study',
  SecondCaseStudy = 'second_case_study',
  AwaitingReferences = 'awaiting_references',
  FirstFinalInterview = 'first_final_interview',
  SecondFinalInterview = 'second_final_interview',
  Approved = 'approved',
  Declined = 'declined',
  Rejected = 'rejected'
}

export type ConsultantTalent = {
   __typename?: 'ConsultantTalent';
  accountSetup: Scalars['Boolean'];
  africaNeeds?: Maybe<Scalars['String']>;
  applicationChannel?: Maybe<Scalars['String']>;
  applicationScore: Scalars['Int'];
  availability?: Maybe<ConsultantAvailability>;
  availabilityDate?: Maybe<Scalars['ISO8601Date']>;
  availabilityReason?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  consultantAssessments?: Maybe<Array<ConsultantAssessment>>;
  consultantBankAccounts: Array<ConsultantBankAccount>;
  consultantContracts: PaginatedConsultantContracts;
  consultantDocuments: Array<UploadFile>;
  consultantEducations?: Maybe<Array<ConsultantEducation>>;
  consultantInvoices?: Maybe<PaginatedConsultantInvoices>;
  consultantNationalities: Array<ConsultantNationality>;
  consultantPayoneerAccounts: Array<ConsultantPayoneerAccount>;
  consultantSpokenLanguages: Array<ConsultantSpokenLanguage>;
  consultantWorkExperiences?: Maybe<Array<ConsultantWorkExperience>>;
  convincedPeerAnswer?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  currentOccupation?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>;
  email: Scalars['String'];
  employmentStatus?: Maybe<Scalars['String']>;
  failedGoalAnswer?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  jobFunctions?: Maybe<Array<JobFunction>>;
  jobTitle?: Maybe<JobTitle>;
  jobTitles?: Maybe<Array<JobTitle>>;
  lastName?: Maybe<Scalars['String']>;
  leadershipRoleAnswer?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  mbti?: Maybe<Scalars['String']>;
  missionDeliverables: Array<ConsultantMissionDeliverable>;
  missionStaffingApplications?: Maybe<PaginatedMissionStaffingApplications>;
  missionStaffingPlacements?: Maybe<PaginatedMissionStaffingPlacements>;
  missions: Array<MissionLifecycleBasic>;
  name?: Maybe<Scalars['String']>;
  nextAvailability?: Maybe<Scalars['ISO8601Date']>;
  nextPayment?: Maybe<ConsultantInvoice>;
  nextStates: Array<StateEventPair>;
  opportunities?: Maybe<PaginatedMissionStaffingPositions>;
  peralteeClaims: PaginatedConsultantPeralteeClaims;
  phone?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  practiceArea?: Maybe<Scalars['String']>;
  prn: Scalars['String'];
  profilePresentation?: Maybe<UploadFile>;
  rating?: Maybe<Scalars['String']>;
  residentialAddress?: Maybe<Scalars['String']>;
  state: ConsultantState;
  status?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['ISO8601DateTime']>;
  tagList: Array<Scalars['String']>;
  trainingTagList: Array<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<UserAdvanced>;
  wallet?: Maybe<Wallet>;
  yearJoined?: Maybe<Scalars['String']>;
  yearStartedFreelance?: Maybe<Scalars['String']>;
  yearStartedWork?: Maybe<Scalars['String']>;
};


export type ConsultantTalentConsultantContractsArgs = {
  scope?: Maybe<ConsultantContractScope>;
  filter?: Maybe<ConsultantContractsFilter>;
  sort?: Maybe<ConsultantContractsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type ConsultantTalentConsultantInvoicesArgs = {
  paginate?: Maybe<PaginationInput>;
  filter?: Maybe<ConsultantInvoicesFilter>;
  sort?: Maybe<ConsultantInvoicesSortInput>;
};


export type ConsultantTalentMissionDeliverablesArgs = {
  completed?: Maybe<Scalars['Boolean']>;
};


export type ConsultantTalentMissionStaffingApplicationsArgs = {
  filter?: Maybe<MissionStaffingApplicationsFilter>;
  sort?: Maybe<MissionStaffingApplicationsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type ConsultantTalentMissionStaffingPlacementsArgs = {
  filter?: Maybe<MissionStaffingPlacementsFilter>;
  sort?: Maybe<MissionStaffingPlacementsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type ConsultantTalentMissionsArgs = {
  limit?: Scalars['Int'];
};


export type ConsultantTalentOpportunitiesArgs = {
  filter?: Maybe<MissionStaffingPositionsFilter>;
  sort?: Maybe<MissionStaffingPositionsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type ConsultantTalentPeralteeClaimsArgs = {
  filter?: Maybe<ConsultantPeralteeClaimsFilter>;
  sort?: Maybe<ConsultantPeralteeClaimsSortInput>;
  paginate?: Maybe<PaginationInput>;
};

export type ConsultantTalentInput = {
  comments?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  yearJoined?: Maybe<Scalars['String']>;
};

export type ConsultantWorkExperience = {
   __typename?: 'ConsultantWorkExperience';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  consultantWorkExperienceLocations: Array<ConsultantWorkExperienceLocation>;
  description: Scalars['String'];
  endDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['Int'];
  jobTitle: Scalars['String'];
  otherCompany?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
};

export type ConsultantWorkExperienceAttributeInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['BigInt']>;
  consultantWorkExperienceLocationsAttributes?: Maybe<Array<ConsultantWorkExperienceLocationAttributeInput>>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  jobTitle?: Maybe<Scalars['String']>;
  otherCompany?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type ConsultantWorkExperienceInput = {
  companyId?: Maybe<Scalars['BigInt']>;
  countryIds: Array<Scalars['BigInt']>;
  description: Scalars['String'];
  endDate?: Maybe<Scalars['ISO8601Date']>;
  jobTitle: Scalars['String'];
  otherCompany?: Maybe<Scalars['String']>;
  startDate: Scalars['ISO8601Date'];
};

export type ConsultantWorkExperienceLocation = {
   __typename?: 'ConsultantWorkExperienceLocation';
  country: Country;
  countryId: Scalars['Int'];
  id: Scalars['Int'];
};

export type ConsultantWorkExperienceLocationAttributeInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  countryId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type ConsultantsFilter = {
  jobTitle?: Maybe<Scalars['String']>;
  nameContains?: Maybe<Scalars['String']>;
  status?: Maybe<ConsultantsStatus>;
  tag?: Maybe<Scalars['String']>;
  or?: Maybe<Array<ConsultantsFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
  search?: Maybe<Scalars['String']>;
};

export type ConsultantsSortInput = {
  firstName?: Maybe<SortDirection>;
  lastName?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export enum ConsultantsStatus {
  Pending = 'PENDING',
  IntroCall = 'INTRO_CALL',
  Shortlisted = 'SHORTLISTED',
  FirstCaseStudy = 'FIRST_CASE_STUDY',
  SecondCaseStudy = 'SECOND_CASE_STUDY',
  AwaitingReferences = 'AWAITING_REFERENCES',
  FirstFinalInterview = 'FIRST_FINAL_INTERVIEW',
  SecondFinalInterview = 'SECOND_FINAL_INTERVIEW',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Archived = 'ARCHIVED'
}

export enum ContractPayment {
  /** Final payment */
  FinalPayment = 'final_payment',
  /** Advance payment */
  AdvancePayment = 'advance_payment',
  /** Interim payment */
  InterimPayment = 'interim_payment'
}

export type ContractingCompany = {
   __typename?: 'ContractingCompany';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum ContractingEntity {
  /** Ako Adjei Park, F 393, Fourth Otswe St, Accra */
  AfgGh = 'afg_gh',
  /** 9, 10th Avenue, Parktown North, 2193, South Africa */
  AfgSa = 'afg_sa',
  /** 7 Bell Yard, London, WC2A 2JR, United Kingdom */
  AfgUk = 'afg_uk'
}

export type Conversation = {
   __typename?: 'Conversation';
  comments: Array<Comment>;
  conversableId: Scalars['BigInt'];
  conversableType: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['BigInt'];
  lastComment?: Maybe<Comment>;
  prn: Scalars['String'];
  resolvedAt?: Maybe<Scalars['ISO8601DateTime']>;
  scope?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ConversationFilterInput = {
  conversableId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  scope?: Maybe<Scalars['String']>;
  or?: Maybe<Array<ConversationFilterInput>>;
};

export type ConversationsSortInput = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type CountriesFilter = {
  name?: Maybe<Scalars['String']>;
  or?: Maybe<Array<CountriesFilter>>;
};

export type CountriesSortInput = {
  name?: Maybe<SortDirection>;
};

export type Country = {
   __typename?: 'Country';
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** Autogenerated return type of CreateBrandResourceCollection */
export type CreateBrandResourceCollectionPayload = {
   __typename?: 'CreateBrandResourceCollectionPayload';
  collection?: Maybe<BrandResourceCollection>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of CreateBrandResourceDocument */
export type CreateBrandResourceDocumentPayload = {
   __typename?: 'CreateBrandResourceDocumentPayload';
  document?: Maybe<BrandResourceDocument>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of CreateBrandResourceLabel */
export type CreateBrandResourceLabelPayload = {
   __typename?: 'CreateBrandResourceLabelPayload';
  error?: Maybe<ErrorV2>;
  label?: Maybe<BrandResourceLabel>;
};

/** Autogenerated return type of CreateBrandResourceVideo */
export type CreateBrandResourceVideoPayload = {
   __typename?: 'CreateBrandResourceVideoPayload';
  error?: Maybe<ErrorV2>;
  video?: Maybe<BrandResourceVideo>;
};

/** Autogenerated return type of CreateClientContract */
export type CreateClientContractPayload = {
   __typename?: 'CreateClientContractPayload';
  error?: Maybe<ErrorV2>;
  missionContract?: Maybe<MissionContract>;
};

/** Autogenerated return type of CreateClient */
export type CreateClientPayload = {
   __typename?: 'CreateClientPayload';
  client?: Maybe<Client>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of CreateClientWithUser */
export type CreateClientWithUserPayload = {
   __typename?: 'CreateClientWithUserPayload';
  clientPayload?: Maybe<ClientPayload>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of CreateConsultantBankAccount */
export type CreateConsultantBankAccountPayload = {
   __typename?: 'CreateConsultantBankAccountPayload';
  bankAccount?: Maybe<ConsultantBankAccount>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of CreateConsultantContract */
export type CreateConsultantContractPayload = {
   __typename?: 'CreateConsultantContractPayload';
  consultantContract?: Maybe<ConsultantContract>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of CreateConsultantContractPayment */
export type CreateConsultantContractPaymentPayload = {
   __typename?: 'CreateConsultantContractPaymentPayload';
  error?: Maybe<ErrorV2>;
  payment?: Maybe<ConsultantContractPayment>;
};

/** Autogenerated return type of CreateConsultantInvoice */
export type CreateConsultantInvoicePayload = {
   __typename?: 'CreateConsultantInvoicePayload';
  consultantInvoice?: Maybe<ConsultantInvoice>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of CreateConsultantPayoneerAccount */
export type CreateConsultantPayoneerAccountPayload = {
   __typename?: 'CreateConsultantPayoneerAccountPayload';
  error?: Maybe<ErrorV2>;
  payoneerAccount?: Maybe<ConsultantPayoneerAccount>;
};

/** Autogenerated return type of CreateJobApplicationDeadline */
export type CreateJobApplicationDeadlinePayload = {
   __typename?: 'CreateJobApplicationDeadlinePayload';
  error?: Maybe<ErrorV2>;
  jobApplicationDeadline?: Maybe<JobApplicationDeadline>;
};

/** Autogenerated return type of CreateMissionContractDeliverable */
export type CreateMissionContractDeliverablePayload = {
   __typename?: 'CreateMissionContractDeliverablePayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of CreateMissionContractPayment */
export type CreateMissionContractPaymentPayload = {
   __typename?: 'CreateMissionContractPaymentPayload';
  error?: Maybe<ErrorV2>;
  missionContractPayment?: Maybe<MissionContractPayment>;
};

/** Autogenerated return type of CreateMissionDocument */
export type CreateMissionDocumentPayload = {
   __typename?: 'CreateMissionDocumentPayload';
  document?: Maybe<MissionDocument>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of CreateMissionExecutionStaffingPosition */
export type CreateMissionExecutionStaffingPositionPayload = {
   __typename?: 'CreateMissionExecutionStaffingPositionPayload';
  error?: Maybe<ErrorV2>;
  missionStaffingPosition?: Maybe<MissionStaffingPosition>;
};

/** Autogenerated return type of CreateMissionLead */
export type CreateMissionLeadPayload = {
   __typename?: 'CreateMissionLeadPayload';
  error?: Maybe<ErrorV2>;
  missionLifecycle?: Maybe<MissionLifecycle>;
};

/** Autogenerated return type of CreateMissionProposalStaffingPosition */
export type CreateMissionProposalStaffingPositionPayload = {
   __typename?: 'CreateMissionProposalStaffingPositionPayload';
  error?: Maybe<ErrorV2>;
  missionStaffingPosition?: Maybe<MissionStaffingPosition>;
};

/** Autogenerated return type of CreateMissionRequest */
export type CreateMissionRequestPayload = {
   __typename?: 'CreateMissionRequestPayload';
  error?: Maybe<ErrorV2>;
  missionRequest?: Maybe<MissionRequest>;
};

/** Autogenerated return type of CreateMissionScope */
export type CreateMissionScopePayload = {
   __typename?: 'CreateMissionScopePayload';
  error?: Maybe<ErrorV2>;
  missionScope?: Maybe<MissionScope>;
};

/** Autogenerated return type of CreatePeralteeSupportService */
export type CreatePeralteeSupportServicePayload = {
   __typename?: 'CreatePeralteeSupportServicePayload';
  error?: Maybe<ErrorV2>;
  supportService?: Maybe<PeralteeSupportService>;
};

/** Autogenerated return type of CreateScopeLibraryActivity */
export type CreateScopeLibraryActivityPayload = {
   __typename?: 'CreateScopeLibraryActivityPayload';
  error?: Maybe<ErrorV2>;
  scopeLibraryActivity?: Maybe<ScopeLibraryActivity>;
};

/** Autogenerated return type of CreateScopeLibraryPhase */
export type CreateScopeLibraryPhasePayload = {
   __typename?: 'CreateScopeLibraryPhasePayload';
  error?: Maybe<ErrorV2>;
  scopeLibraryPhase?: Maybe<ScopeLibraryPhase>;
  scopePhaseSubscription?: Maybe<ScopePhaseSubscription>;
};

/** Autogenerated return type of CreateScopePhaseSubscription */
export type CreateScopePhaseSubscriptionPayload = {
   __typename?: 'CreateScopePhaseSubscriptionPayload';
  error?: Maybe<ErrorV2>;
  scopePhaseSubscription?: Maybe<ScopePhaseSubscription>;
};

/** Autogenerated return type of CreateScopeServiceGroup */
export type CreateScopeServiceGroupPayload = {
   __typename?: 'CreateScopeServiceGroupPayload';
  error?: Maybe<ErrorV2>;
  scopeServiceGroup?: Maybe<ScopeServiceGroup>;
};

/** Autogenerated return type of CreateScopeService */
export type CreateScopeServicePayload = {
   __typename?: 'CreateScopeServicePayload';
  error?: Maybe<ErrorV2>;
  scopeService?: Maybe<ScopeService>;
};

/** Autogenerated return type of CreateScopeServiceTeamRole */
export type CreateScopeServiceTeamRolePayload = {
   __typename?: 'CreateScopeServiceTeamRolePayload';
  error?: Maybe<ErrorV2>;
  scopeServiceTeamRole?: Maybe<ScopeServiceTeamRole>;
};

/** Autogenerated return type of CreateScopeTag */
export type CreateScopeTagPayload = {
   __typename?: 'CreateScopeTagPayload';
  error?: Maybe<ErrorV2>;
  scopeServiceTag?: Maybe<ScopeServiceTag>;
  scopeTag?: Maybe<ScopeTag>;
};

/** Autogenerated return type of CreateStaffingMilestone */
export type CreateStaffingMilestonePayload = {
   __typename?: 'CreateStaffingMilestonePayload';
  error?: Maybe<ErrorV2>;
  milestone?: Maybe<MissionStaffingMilestone>;
};

/** Autogenerated return type of CreateSurvey */
export type CreateSurveyPayload = {
   __typename?: 'CreateSurveyPayload';
  error?: Maybe<ErrorV2>;
  survey?: Maybe<Survey>;
};

export type Currency = {
   __typename?: 'Currency';
  code: Scalars['String'];
  factor: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  symbol: Scalars['String'];
};

export type DateFilterInput = {
  /** End date */
  end?: Maybe<Scalars['ISO8601Date']>;
  /** Start date  */
  start?: Maybe<Scalars['ISO8601Date']>;
};

/** Autogenerated return type of DeclineConsultantContract */
export type DeclineConsultantContractPayload = {
   __typename?: 'DeclineConsultantContractPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteBrandResourceCollection */
export type DeleteBrandResourceCollectionPayload = {
   __typename?: 'DeleteBrandResourceCollectionPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteBrandResourceDocument */
export type DeleteBrandResourceDocumentPayload = {
   __typename?: 'DeleteBrandResourceDocumentPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteBrandResourceLabel */
export type DeleteBrandResourceLabelPayload = {
   __typename?: 'DeleteBrandResourceLabelPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteBrandResourceVideo */
export type DeleteBrandResourceVideoPayload = {
   __typename?: 'DeleteBrandResourceVideoPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteClient */
export type DeleteClientPayload = {
   __typename?: 'DeleteClientPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteConsultantContractMilestone */
export type DeleteConsultantContractMilestonePayload = {
   __typename?: 'DeleteConsultantContractMilestonePayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteConsultantContractPayment */
export type DeleteConsultantContractPaymentPayload = {
   __typename?: 'DeleteConsultantContractPaymentPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteConsultantDocument */
export type DeleteConsultantDocumentPayload = {
   __typename?: 'DeleteConsultantDocumentPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteConsultantEducation */
export type DeleteConsultantEducationPayload = {
   __typename?: 'DeleteConsultantEducationPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteConsultantNationality */
export type DeleteConsultantNationalityPayload = {
   __typename?: 'DeleteConsultantNationalityPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteConsultantSpokenLanguage */
export type DeleteConsultantSpokenLanguagePayload = {
   __typename?: 'DeleteConsultantSpokenLanguagePayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteConsultantWorkExperience */
export type DeleteConsultantWorkExperiencePayload = {
   __typename?: 'DeleteConsultantWorkExperiencePayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteJobApplicationDeadline */
export type DeleteJobApplicationDeadlinePayload = {
   __typename?: 'DeleteJobApplicationDeadlinePayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteMissionContractDeliverable */
export type DeleteMissionContractDeliverablePayload = {
   __typename?: 'DeleteMissionContractDeliverablePayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteMissionContractPayment */
export type DeleteMissionContractPaymentPayload = {
   __typename?: 'DeleteMissionContractPaymentPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteMission */
export type DeleteMissionPayload = {
   __typename?: 'DeleteMissionPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteMissionPricingProposalActivity */
export type DeleteMissionPricingProposalActivityPayload = {
   __typename?: 'DeleteMissionPricingProposalActivityPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteMissionScopeActivity */
export type DeleteMissionScopeActivityPayload = {
   __typename?: 'DeleteMissionScopeActivityPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteMissionScopePhase */
export type DeleteMissionScopePhasePayload = {
   __typename?: 'DeleteMissionScopePhasePayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteMissionScopeStaff */
export type DeleteMissionScopeStaffPayload = {
   __typename?: 'DeleteMissionScopeStaffPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteScopeLibraryActivity */
export type DeleteScopeLibraryActivityPayload = {
   __typename?: 'DeleteScopeLibraryActivityPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteScopePhaseSubscription */
export type DeleteScopePhaseSubscriptionPayload = {
   __typename?: 'DeleteScopePhaseSubscriptionPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteScopeServiceTag */
export type DeleteScopeServiceTagPayload = {
   __typename?: 'DeleteScopeServiceTagPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteScopeServiceTeamRole */
export type DeleteScopeServiceTeamRolePayload = {
   __typename?: 'DeleteScopeServiceTeamRolePayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteStaffingMilestone */
export type DeleteStaffingMilestonePayload = {
   __typename?: 'DeleteStaffingMilestonePayload';
  error?: Maybe<ErrorV2>;
  milestone?: Maybe<MissionStaffingMilestone>;
};

/** Autogenerated return type of DeleteSurvey */
export type DeleteSurveyPayload = {
   __typename?: 'DeleteSurveyPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteSurveyQuestion */
export type DeleteSurveyQuestionPayload = {
   __typename?: 'DeleteSurveyQuestionPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of DeleteUploadFile */
export type DeleteUploadFilePayload = {
   __typename?: 'DeleteUploadFilePayload';
  error?: Maybe<ErrorV2>;
};

export type DocComment = {
   __typename?: 'DocComment';
  commentableId: Scalars['BigInt'];
  commentableType: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  file?: Maybe<UploadFile>;
  id: Scalars['BigInt'];
  prn: Scalars['String'];
  resolvedAt?: Maybe<Scalars['ISO8601DateTime']>;
  scope?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** Autogenerated return type of EditComment */
export type EditCommentPayload = {
   __typename?: 'EditCommentPayload';
  comment?: Maybe<Comment>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of EditSurveyQuestion */
export type EditSurveyQuestionPayload = {
   __typename?: 'EditSurveyQuestionPayload';
  error?: Maybe<ErrorV2>;
  question?: Maybe<SurveyQuestion>;
};

export enum EmploymentStatus {
  /** Unknown */
  Unknown = 'unknown',
  /** Unemployed */
  Unemployed = 'unemployed',
  /** Employment Full-time */
  Employed = 'employed',
  /** Self-employed */
  SelfEmployed = 'self_employed',
  /** Freelancer */
  Freelancer = 'freelancer',
  /** Enrolled in an educational program that requires work experience */
  Enrolled = 'enrolled'
}

export type ErrorV2 = {
   __typename?: 'ErrorV2';
  code?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<FieldError>>;
  message: Scalars['String'];
};

export type Esignature = {
   __typename?: 'Esignature';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['BigInt'];
  prn: Scalars['String'];
  signatureSource: SignatureSource;
  signedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

export type EsignatureRequest = {
   __typename?: 'EsignatureRequest';
  createdAt: Scalars['ISO8601DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  mandateSecret: Scalars['String'];
  prn: Scalars['String'];
  requester: User;
  signable: MissionContract;
  signer?: Maybe<User>;
  state?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type EsignatureSignerInput = {
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type FieldError = {
   __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
  property?: Maybe<Scalars['String']>;
};

export type FileSignatureSourceInput = {
  key: Scalars['String'];
};

/** Autogenerated return type of FillMissionStaffingPosition */
export type FillMissionStaffingPositionPayload = {
   __typename?: 'FillMissionStaffingPositionPayload';
  error?: Maybe<ErrorV2>;
};

export type FinanceDashboardStatistics = {
   __typename?: 'FinanceDashboardStatistics';
  key: Scalars['String'];
  label: Scalars['String'];
  money?: Maybe<Scalars['Boolean']>;
  value: Scalars['String'];
};

export type FormSectionProgress = {
   __typename?: 'FormSectionProgress';
  applicationFormSection: ApplicationFormSection;
  createdAt: Scalars['ISO8601DateTime'];
  state: Scalars['String'];
};

/** Autogenerated return type of GenerateClientPaymentStructure */
export type GenerateClientPaymentStructurePayload = {
   __typename?: 'GenerateClientPaymentStructurePayload';
  error?: Maybe<ErrorV2>;
  payments?: Maybe<Array<MissionContractPayment>>;
};

/** Autogenerated return type of GenerateConsultantInvoices */
export type GenerateConsultantInvoicesPayload = {
   __typename?: 'GenerateConsultantInvoicesPayload';
  consultantInvoices?: Maybe<Array<ConsultantInvoice>>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of GenerateConsultantPaymentStructure */
export type GenerateConsultantPaymentStructurePayload = {
   __typename?: 'GenerateConsultantPaymentStructurePayload';
  error?: Maybe<ErrorV2>;
  payments?: Maybe<Array<ConsultantContractPayment>>;
};

/** Autogenerated return type of GenerateMissionStaffingPositions */
export type GenerateMissionStaffingPositionsPayload = {
   __typename?: 'GenerateMissionStaffingPositionsPayload';
  error?: Maybe<ErrorV2>;
  staffingPositions?: Maybe<Array<MissionStaffingPosition>>;
};

/** Autogenerated return type of GeneratePresignedUrl */
export type GeneratePresignedUrlPayload = {
   __typename?: 'GeneratePresignedUrlPayload';
  error?: Maybe<ErrorV2>;
  presignedUrl?: Maybe<PresignedUrl>;
};

/** Autogenerated return type of GradeApplicationAssessment */
export type GradeApplicationAssessmentPayload = {
   __typename?: 'GradeApplicationAssessmentPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of GrantApproval */
export type GrantApprovalPayload = {
   __typename?: 'GrantApprovalPayload';
  approval?: Maybe<Approval>;
  error?: Maybe<ErrorV2>;
};



/** Autogenerated return type of InitiateClientPaymentProcessing */
export type InitiateClientPaymentProcessingPayload = {
   __typename?: 'InitiateClientPaymentProcessingPayload';
  error?: Maybe<ErrorV2>;
  payment?: Maybe<MissionContractPayment>;
};

export type Institution = {
   __typename?: 'Institution';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type InstitutionsFilter = {
  name?: Maybe<Scalars['String']>;
  or?: Maybe<Array<InstitutionsFilter>>;
};

export type InstitutionsSortInput = {
  name?: Maybe<SortDirection>;
};

export type Invitation = {
   __typename?: 'Invitation';
  client?: Maybe<Client>;
  createdAt: Scalars['ISO8601DateTime'];
  creator: User;
  email: Scalars['String'];
  id: Scalars['String'];
  invitationForProduct: Product;
  prn: Scalars['String'];
  roles: Array<Scalars['String']>;
  scatterId: Scalars['String'];
  state: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of InviteClientAdmin */
export type InviteClientAdminPayload = {
   __typename?: 'InviteClientAdminPayload';
  error?: Maybe<ErrorV2>;
  invitation?: Maybe<Invitation>;
};

/** Autogenerated return type of InviteConsultant */
export type InviteConsultantPayload = {
   __typename?: 'InviteConsultantPayload';
  error?: Maybe<ErrorV2>;
  invitation?: Maybe<Invitation>;
};

/** Autogenerated return type of InviteSubscriptionUser */
export type InviteSubscriptionUserPayload = {
   __typename?: 'InviteSubscriptionUserPayload';
  error?: Maybe<ErrorV2>;
  subscriptionUser?: Maybe<SubscriptionUser>;
};


export type JobApplicationDeadline = {
   __typename?: 'JobApplicationDeadline';
  date: Scalars['ISO8601Date'];
  id: Scalars['Int'];
  jobTitle: JobTitle;
};

export enum JobFunction {
  /** Strategy */
  Strategy = 'strategy',
  /** Implementation */
  Implementation = 'implementation',
  /** Due diligence */
  DueDiligence = 'due_diligence',
  /** Research */
  Research = 'research',
  /** Stakeholder engagement */
  StakeholderEngagement = 'stakeholder_engagement',
  /** IT infrastructure */
  ItInfrastructure = 'it_infrastructure',
  /** People and organisation */
  PeopleAndOrganisation = 'people_and_organisation'
}

export type JobTitle = {
   __typename?: 'JobTitle';
  abbreviation?: Maybe<Scalars['String']>;
  capacityFactor?: Maybe<Scalars['Float']>;
  createdAt: Scalars['ISO8601DateTime'];
  dailyRate?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  id: Scalars['BigInt'];
  name: Scalars['String'];
  prn: Scalars['String'];
  questions: Array<Question>;
  questionsCount: Scalars['Int'];
  requirements: Array<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type JobTitlesFilter = {
  hasDeadline?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  slugIn?: Maybe<Array<Scalars['String']>>;
  staffable?: Maybe<Scalars['Boolean']>;
  unelapsed?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<JobTitlesFilter>>;
};

export type JobTitlesSortInput = {
  name?: Maybe<SortDirection>;
};

export type Language = {
   __typename?: 'Language';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type LanguagesFilter = {
  name?: Maybe<Scalars['String']>;
  or?: Maybe<Array<LanguagesFilter>>;
};

export type LanguagesSortInput = {
  name?: Maybe<SortDirection>;
};

export type Lead = {
   __typename?: 'Lead';
  client?: Maybe<Client>;
  companyWebsite?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  followUpDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['Int'];
  keyContactContactInfo: Scalars['String'];
  keyContactName: Scalars['String'];
  keyContactRole: Scalars['String'];
  leadGrade?: Maybe<LeadGrade>;
  leadInteractions: Array<LeadInteraction>;
  leadSource: LeadSource;
  leadStaffs: Array<LeadStaff>;
  practiceGroup?: Maybe<PracticeGroup>;
  proposal?: Maybe<Proposal>;
  proposalDeadline?: Maybe<Scalars['ISO8601Date']>;
  rejectionReason?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  summary: Scalars['String'];
  tor?: Maybe<Scalars['String']>;
  user: User;
};

export type LeadGrade = {
   __typename?: 'LeadGrade';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type LeadGradesSortInput = {
  name?: Maybe<SortDirection>;
};

export type LeadInteraction = {
   __typename?: 'LeadInteraction';
  action: Scalars['String'];
  createdAt: Scalars['ISO8601Date'];
  id: Scalars['Int'];
  leadStaff: LeadStaff;
};

export type LeadSource = {
   __typename?: 'LeadSource';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type LeadSourcesSortInput = {
  name?: Maybe<SortDirection>;
};

export type LeadStaff = {
   __typename?: 'LeadStaff';
  id: Scalars['Int'];
  type: Scalars['String'];
  user: User;
};

export enum LoginMode {
  /** Log in using Azure AD SSO */
  Sso = 'sso',
  /** Log in with password */
  Password = 'password'
}

/** Autogenerated return type of Login */
export type LoginPayload = {
   __typename?: 'LoginPayload';
  error?: Maybe<ErrorV2>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<UserAdvanced>;
};

export enum LogisticsCostBearer {
  /** Separately expensed to client */
  SeparetelyExpensed = 'separetely_expensed',
  /** Included in full contract amount */
  IncludedInContract = 'included_in_contract'
}

export type ManagementStaffingRequest = {
   __typename?: 'ManagementStaffingRequest';
  approval?: Maybe<Approval>;
  consultant?: Maybe<Consultant>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['Int'];
  missionLifecycle?: Maybe<MissionLifecycle>;
  missionStaffingPosition?: Maybe<MissionStaffingPosition>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of MarkInvoiceAsPaid */
export type MarkInvoiceAsPaidPayload = {
   __typename?: 'MarkInvoiceAsPaidPayload';
  consultantInvoice?: Maybe<ConsultantInvoice>;
  error?: Maybe<ErrorV2>;
};

export enum Mbti {
  /** ISTP - Virtuoso */
  Istp = 'ISTP',
  /** ISFP - Adventurer */
  Isfp = 'ISFP',
  /** ISFP - Entrepreneur */
  Estp = 'ESTP',
  /** ESFP - Entertainer */
  Esfp = 'ESFP',
  /** ISTJ - Logistician */
  Istj = 'ISTJ',
  /** ISFJ - Defender */
  Isfj = 'ISFJ',
  /** ESTJ - Executive */
  Estj = 'ESTJ',
  /** ESFJ - Consul */
  Esfj = 'ESFJ',
  /** INFJ - Advocate */
  Infj = 'INFJ',
  /** INFP - Mediator */
  Infp = 'INFP',
  /** ENFJ - Protagonist */
  Enfj = 'ENFJ',
  /** ENFP - Campaigner */
  Enfp = 'ENFP',
  /** INTJ - Architect */
  Intj = 'INTJ',
  /** INTP - Logician */
  Intp = 'INTP',
  /** ENTJ - Commander */
  Entj = 'ENTJ',
  /** ENTP - Debater */
  Entp = 'ENTP'
}

export type Mission = {
   __typename?: 'Mission';
  client: Client;
  createdAt?: Maybe<Scalars['ISO8601Date']>;
  currency: Currency;
  description: Scalars['String'];
  duration: Scalars['Int'];
  endDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['Int'];
  lead: Lead;
  missionLocations: Array<MissionLocation>;
  missionNature: MissionNature;
  missionRequiredStaffs: Array<MissionRequiredStaff>;
  missionStaffs: Array<MissionStaff>;
  missionStatus: MissionStatus;
  name: Scalars['String'];
  published: Scalars['Boolean'];
  quarter: Quarter;
  startDate?: Maybe<Scalars['ISO8601Date']>;
};

export type MissionClientContractTerms = {
   __typename?: 'MissionClientContractTerms';
  afgSignatoryTitle?: Maybe<Scalars['String']>;
  bankAccount: BankAccount;
  clientLegalName?: Maybe<Scalars['String']>;
  clientStakeholders?: Maybe<Scalars['JSON']>;
  commencementDate?: Maybe<Scalars['ISO8601Date']>;
  conclusionDate?: Maybe<Scalars['ISO8601Date']>;
  contractDate?: Maybe<Scalars['ISO8601Date']>;
  contractingEntity?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currency: Currency;
  deliverableSubmissionDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['BigInt'];
  logisticsCostBearer?: Maybe<Scalars['String']>;
  maximumLogisticsCost?: Maybe<Scalars['String']>;
  missionContract: MissionContract;
  missionPartner: ConsultantProfile;
  projectObjective?: Maybe<Scalars['String']>;
  providerObjective?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type MissionContract = {
   __typename?: 'MissionContract';
  createdAt: Scalars['ISO8601DateTime'];
  deliverables: Array<MissionContractDeliverable>;
  draftContract?: Maybe<MissionClientContractTerms>;
  engagementLetter?: Maybe<ApprovedAttachment>;
  engagementLetterDownloadable: Scalars['Boolean'];
  id: Scalars['BigInt'];
  missionContractPayments: Array<MissionContractPayment>;
  missionLifecycle: MissionLifecycle;
  signatureRequest?: Maybe<EsignatureRequest>;
  signatures: Array<Esignature>;
  signedContract?: Maybe<UploadFile>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type MissionContractDeliverable = {
   __typename?: 'MissionContractDeliverable';
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['BigInt'];
  missionScopeActivity?: Maybe<MissionScopeActivity>;
  name?: Maybe<Scalars['String']>;
  state: Scalars['String'];
};

export type MissionContractPayment = {
   __typename?: 'MissionContractPayment';
  amount: Money;
  amountCents: Scalars['BigInt'];
  appliedDiscountAmount?: Maybe<Money>;
  appliedDiscountPercentage?: Maybe<Scalars['Float']>;
  checkoutUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currency: Scalars['String'];
  currencyCode: Scalars['String'];
  description: Scalars['String'];
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['BigInt'];
  invoiceUrl?: Maybe<Scalars['String']>;
  missionContractDeliverables: Array<MissionContractDeliverable>;
  missionLifecycle: MissionLifecycle;
  name?: Maybe<Scalars['String']>;
  paymentType: ContractPayment;
  prn: Scalars['String'];
  state: MissionContractPaymentState;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type MissionContractPaymentBasic = {
   __typename?: 'MissionContractPaymentBasic';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  prn: Scalars['String'];
  scatterId: Scalars['String'];
  state: MissionContractPaymentState;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum MissionContractPaymentState {
  Pending = 'pending',
  Processing = 'processing',
  Paid = 'paid'
}

export type MissionContractTermsInput = {
  afgSignatoryTitle: Scalars['String'];
  bankAccountId: Scalars['BigInt'];
  clientLegalName: Scalars['String'];
  clientStakeholders: Scalars['JSON'];
  commencementDate: Scalars['ISO8601Date'];
  conclusionDate: Scalars['ISO8601Date'];
  contractDate: Scalars['ISO8601Date'];
  contractingEntity: ContractingEntity;
  currencyId: Scalars['BigInt'];
  deliverableSubmissionDate: Scalars['ISO8601Date'];
  logisticsCostBearer: LogisticsCostBearer;
  maximumLogisticsCost?: Maybe<Scalars['String']>;
  missionContractId: Scalars['BigInt'];
  missionPartnerId: Scalars['BigInt'];
  projectObjective: Scalars['String'];
  providerObjective: Scalars['String'];
};

export type MissionDocument = {
   __typename?: 'MissionDocument';
  createdAt: Scalars['ISO8601DateTime'];
  documentType: Scalars['String'];
  id: Scalars['BigInt'];
  missionLifecycle: MissionLifecycle;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  prn: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  uploadFile: UploadFile;
  uploadedBy: User;
};

export type MissionDocumentInput = {
  documentType?: Maybe<MissionDocumentTypeEnum>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  uploadFile?: Maybe<UploadFileInput>;
};

export enum MissionDocumentTypeEnum {
  /** Requirements */
  Requirements = 'requirements',
  /** Contract */
  Contract = 'contract',
  /** Mission Document */
  MissionDocument = 'mission_document',
  /** Revision or Feedback */
  RevisionOrFeedback = 'revision_or_feedback',
  /** Terms of Reference */
  TermsOfReference = 'terms_of_reference'
}

export type MissionDocumentsFilter = {
  id?: Maybe<Scalars['BigInt']>;
  missionLifecycleId?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  or?: Maybe<Array<MissionDocumentsFilter>>;
};

export type MissionDocumentsSortInput = {
  id?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
};

export type MissionExecution = {
   __typename?: 'MissionExecution';
  commencementDate?: Maybe<Scalars['ISO8601Date']>;
  completionDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  missionLifecycleId: Scalars['Int'];
};

export type MissionLead = {
   __typename?: 'MissionLead';
  approver?: Maybe<User>;
  client?: Maybe<Client>;
  clientContactEmail?: Maybe<Scalars['String']>;
  clientContactName?: Maybe<Scalars['String']>;
  clientContactPhone?: Maybe<Scalars['String']>;
  clientContactRole?: Maybe<Scalars['String']>;
  clientDescription?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  clientWebsite?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  eoiDeadline?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['BigInt'];
  leadGrade?: Maybe<LeadGrade>;
  leadSource?: Maybe<LeadSource>;
  missionLifecycle: MissionLifecycle;
  practiceGroup?: Maybe<PracticeGroup>;
  projectLocationCountry?: Maybe<Scalars['String']>;
  projectLocationType?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  rfpDeadline?: Maybe<Scalars['ISO8601Date']>;
  state: Scalars['String'];
  subPracticeGroup?: Maybe<SubPracticeGroup>;
  summary: Scalars['String'];
  tentativeEndDate?: Maybe<Scalars['ISO8601Date']>;
  tentativeStartDate?: Maybe<Scalars['ISO8601Date']>;
  termsOfReference?: Maybe<UploadFile>;
  termsOfReferences: Array<UploadFile>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

export type MissionLeadInput = {
  projectLocationCountry?: Maybe<Scalars['String']>;
  projectLocationType?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['BigInt']>;
  summary: Scalars['String'];
  tentativeEndDate?: Maybe<Scalars['ISO8601Date']>;
  tentativeStartDate?: Maybe<Scalars['ISO8601Date']>;
  eoiDeadline?: Maybe<Scalars['ISO8601Date']>;
  leadGradeId?: Maybe<Scalars['BigInt']>;
  leadSourceId: Scalars['BigInt'];
  practiceGroupId?: Maybe<Scalars['BigInt']>;
  rfpDeadline?: Maybe<Scalars['ISO8601Date']>;
  subPracticeGroupId?: Maybe<Scalars['BigInt']>;
  termsOfReferences?: Maybe<Array<UploadFileInput>>;
  clientContactEmail?: Maybe<Scalars['String']>;
  clientContactName?: Maybe<Scalars['String']>;
  clientContactPhone?: Maybe<Scalars['String']>;
  clientContactRole?: Maybe<Scalars['String']>;
  clientId: Scalars['BigInt'];
  saveClientContact: Scalars['Boolean'];
};

export type MissionLifecycle = {
   __typename?: 'MissionLifecycle';
  activityLogs: Array<ActivityLog>;
  archivalNotes?: Maybe<Scalars['String']>;
  archiveable: Scalars['Boolean'];
  asanteSurveys: Array<SurveyAssignment>;
  budget?: Maybe<Money>;
  client: Client;
  clientSatisfactionSurvey?: Maybe<SurveyAssignment>;
  collaborators?: Maybe<Array<UserAdvanced>>;
  completionProgress: Scalars['Float'];
  completionProgressActivityLog?: Maybe<ActivityLog>;
  consultantContracts?: Maybe<Array<ConsultantContract>>;
  contractingEntity?: Maybe<ContractingEntity>;
  createdAt: Scalars['ISO8601DateTime'];
  defaultConversation?: Maybe<Conversation>;
  editable: Scalars['Boolean'];
  endDate?: Maybe<Scalars['ISO8601Date']>;
  executive?: Maybe<Consultant>;
  id: Scalars['BigInt'];
  inProgress: Scalars['Boolean'];
  managers?: Maybe<Array<User>>;
  missionContract?: Maybe<MissionContract>;
  missionContractPayments?: Maybe<Array<MissionContractPayment>>;
  missionDocuments?: Maybe<Array<MissionDocument>>;
  missionExecution?: Maybe<MissionExecution>;
  missionExecutionStaffingPlacements?: Maybe<Array<MissionStaffingPlacement>>;
  missionExecutionStaffingPositions?: Maybe<Array<MissionStaffingPosition>>;
  /** @deprecated Query fields directly on MissionLifecycleType */
  missionLead?: Maybe<MissionLead>;
  missionPricingProposal?: Maybe<MissionPricingProposal>;
  missionProposal?: Maybe<MissionProposal>;
  missionScope?: Maybe<MissionScope>;
  missionTeamMemberships: Array<MissionTeamMembership>;
  name?: Maybe<Scalars['String']>;
  operational: Scalars['Boolean'];
  pendingPayments?: Maybe<Array<MissionContractPayment>>;
  primaryManager?: Maybe<User>;
  principals: Array<Consultant>;
  prn: Scalars['String'];
  projectLocationCountryCode?: Maybe<Scalars['String']>;
  projectLocationType?: Maybe<Scalars['String']>;
  projectTitle?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  rejectionReason?: Maybe<Scalars['String']>;
  rfpDeadline?: Maybe<Scalars['ISO8601Date']>;
  scopeService?: Maybe<ScopeService>;
  staffable: Scalars['Boolean'];
  staffingRequests?: Maybe<Array<ManagementStaffingRequest>>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  state: MissionLifecycleState;
  summary?: Maybe<Scalars['String']>;
  tagList: Array<Scalars['String']>;
  teamLeads: Array<Consultant>;
  termsOfReferences?: Maybe<Array<UploadFile>>;
  updatedAt: Scalars['ISO8601DateTime'];
};


export type MissionLifecycleActivityLogsArgs = {
  sort?: Maybe<SortDirection>;
};

export type MissionLifecycleBasic = {
   __typename?: 'MissionLifecycleBasic';
  activityLogs: Array<ActivityLog>;
  completionProgress: Scalars['Float'];
  completionProgressActivityLog?: Maybe<ActivityLog>;
  contractingEntity?: Maybe<ContractingEntity>;
  createdAt: Scalars['ISO8601DateTime'];
  endDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['BigInt'];
  name?: Maybe<Scalars['String']>;
  prn: Scalars['String'];
  projectLocationCountryCode?: Maybe<Scalars['String']>;
  projectLocationType?: Maybe<Scalars['String']>;
  scopeService?: Maybe<ScopeService>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  state: MissionLifecycleState;
  summary?: Maybe<Scalars['String']>;
  tagList: Array<Scalars['String']>;
  termsOfReferences?: Maybe<Array<UploadFile>>;
  updatedAt: Scalars['ISO8601DateTime'];
};


export type MissionLifecycleBasicActivityLogsArgs = {
  limit?: Scalars['Int'];
};

export enum MissionLifecycleState {
  LeadStage = 'lead_stage',
  ExpressionOfInterest = 'expression_of_interest',
  RequestForProposal = 'request_for_proposal',
  ScopingStage = 'scoping_stage',
  ProposalStage = 'proposal_stage',
  ContractingStage = 'contracting_stage',
  StaffingStage = 'staffing_stage',
  ExecutionStage = 'execution_stage',
  Completed = 'completed',
  Archived = 'archived',
  Paused = 'paused'
}

export type MissionLifecyclesFilter = {
  clientId?: Maybe<Scalars['BigInt']>;
  country?: Maybe<Scalars['String']>;
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  missionExecutiveId?: Maybe<Scalars['BigInt']>;
  state?: Maybe<MissionLifecycleState>;
  stateIn?: Maybe<Array<Scalars['String']>>;
  or?: Maybe<Array<MissionLifecyclesFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
  search?: Maybe<Scalars['String']>;
};

export type MissionLifecyclesSortInput = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type MissionLocation = {
   __typename?: 'MissionLocation';
  country: Country;
  id: Scalars['Int'];
};

export enum MissionLocationEnum {
  /** Remote */
  Remote = 'remote',
  /** Onground */
  Onground = 'onground',
  /** Remote and Onground */
  RemoteAndOnground = 'remote_and_onground'
}

export type MissionNature = {
   __typename?: 'MissionNature';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type MissionPerformanceAppraisal = {
   __typename?: 'MissionPerformanceAppraisal';
  appraisee: Consultant;
  appraiseeRole?: Maybe<JobTitle>;
  appraiser: Consultant;
  appraiserRole: JobTitle;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['Int'];
  missionLifecycle: MissionLifecycle;
  surveyAssignment: SurveyAssignment;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type MissionPerformanceAppraisalSchedule = {
   __typename?: 'MissionPerformanceAppraisalSchedule';
  appraisee?: Maybe<Consultant>;
  appraiseeRole?: Maybe<JobTitle>;
  appraiser?: Maybe<Consultant>;
  appraiserRole?: Maybe<JobTitle>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  missionLifecycle?: Maybe<MissionLifecycle>;
};

export type MissionPerformanceAppraisalScheduleData = {
   __typename?: 'MissionPerformanceAppraisalScheduleData';
  data?: Maybe<Array<MissionPerformanceAppraisalSchedule>>;
};

export enum MissionPerformanceAppraisalState {
  Pending = 'PENDING',
  Completed = 'COMPLETED'
}

export type MissionPerformanceAppraisalsFilter = {
  appraiseeIdIn?: Maybe<Array<Scalars['BigInt']>>;
  appraiserIdIn?: Maybe<Array<Scalars['BigInt']>>;
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  missionIdIn?: Maybe<Scalars['String']>;
  state?: Maybe<MissionPerformanceAppraisalState>;
  or?: Maybe<Array<MissionPerformanceAppraisalsFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
};

export type MissionPerformanceAppraisalsSortInput = {
  createdAt?: Maybe<SortDirection>;
};

export type MissionPricingProposal = {
   __typename?: 'MissionPricingProposal';
  activities: Array<MissionPricingProposalActivity>;
  approvals: Array<Approval>;
  contractingEntity?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currency?: Maybe<Currency>;
  currencyCode?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  markup?: Maybe<Scalars['Float']>;
  missionProposal: MissionProposal;
  prn: Scalars['String'];
  scatterId: Scalars['String'];
  state: Scalars['String'];
  totalPrice?: Maybe<Money>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type MissionPricingProposalActivity = {
   __typename?: 'MissionPricingProposalActivity';
  distribution?: Maybe<Scalars['JSON']>;
  id: Scalars['BigInt'];
  missionPricingProposal: MissionPricingProposal;
  name: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  unitCost?: Maybe<Scalars['Float']>;
};

export type MissionProblemStatement = {
   __typename?: 'MissionProblemStatement';
  key: Scalars['String'];
  options?: Maybe<Array<Scalars['String']>>;
  problemStatement: Scalars['String'];
  serviceCategories: Array<Scalars['String']>;
  serviceGroups: Array<Scalars['String']>;
};

export type MissionProposal = {
   __typename?: 'MissionProposal';
  clientApproval?: Maybe<Approval>;
  clientApprovals: Array<Approval>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['BigInt'];
  missionLifecycle: MissionLifecycle;
  missionPricingProposal?: Maybe<MissionPricingProposal>;
  missionProposalStaffingPlacements?: Maybe<Array<MissionStaffingPlacement>>;
  missionProposalStaffingPositions?: Maybe<Array<MissionStaffingPosition>>;
  pricingProposal?: Maybe<ApprovedAttachment>;
  state: Scalars['String'];
  technicalProposal?: Maybe<ApprovedAttachment>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type MissionProposalStaffingMilestoneInput = {
  description: Scalars['String'];
  id?: Maybe<Scalars['BigInt']>;
  name: Scalars['String'];
};

export type MissionRequest = {
   __typename?: 'MissionRequest';
  /** @deprecated Use budget field on MissionLifecycleType instead */
  amount?: Maybe<Money>;
  availableProjectSpec: MissionRequestProjectSpec;
  businessCategory: MissionRequestBusinessCategory;
  businessIndustry: MissionRequestBusinessIndustry;
  businessName: Scalars['String'];
  client?: Maybe<Client>;
  companyStaffCount: MissionRequestCompanyStaffCount;
  contactEmail: Scalars['String'];
  contactFirstName: Scalars['String'];
  contactLastName: Scalars['String'];
  contactPhone: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  /** @deprecated Use budget field on MissionLifecycleType instead */
  currency: Scalars['String'];
  id: Scalars['BigInt'];
  missionLifecycle?: Maybe<MissionLifecycle>;
  prn: Scalars['String'];
  problemStatement?: Maybe<Scalars['String']>;
  problemStatementOption?: Maybe<Scalars['String']>;
  projectBrief: Scalars['String'];
  proposedEndDate?: Maybe<Scalars['ISO8601Date']>;
  proposedStartDate?: Maybe<MissionRequestProposedStartDate>;
  receiveCallFromExpert?: Maybe<Scalars['String']>;
  remoteWorkforce: MissionRequestRemoteWorkforce;
  scopeService?: Maybe<ScopeService>;
  state: ConsultantContractState;
  termsOfReference?: Maybe<UploadFile>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum MissionRequestBusinessCategory {
  /** Investment Fund */
  InvestmentFund = 'investment_fund',
  /** Foundation/NGO */
  Ngo = 'ngo',
  /** Business */
  DevelopmentInstitution = 'development_institution',
  /** Multinational corporate */
  MultinationalCorporate = 'multinational_corporate',
  /** Local corporate */
  LocalCorporate = 'local_corporate',
  /** SME */
  Sme = 'sme',
  /** Individual */
  Individual = 'individual'
}

export enum MissionRequestBusinessIndustry {
  /** Financial services */
  FinancialServices = 'financial_services',
  /** Consumer goods */
  CustomerGoods = 'customer_goods',
  /** Agriculture */
  Agriculture = 'agriculture',
  /** Pharmacueticals and Biotech */
  PharmacueticalsAndBiotech = 'pharmacueticals_and_biotech',
  /** Travel and hospitality */
  TravelAndHospitality = 'travel_and_hospitality',
  /** Retail */
  Retail = 'retail',
  /** Manufacturing */
  Manufacturing = 'manufacturing',
  /** Entertainment and media */
  EntertainmentAndMedia = 'entertainment_and_media',
  /** Services */
  Services = 'services',
  /** Education */
  Education = 'education',
  /** Transportation */
  Transportation = 'transportation',
  /** Telecommunication */
  Telecommunication = 'telecommunication'
}

export enum MissionRequestCompanyStaffCount {
  /** 1-10 people */
  OneToTen = 'one_to_ten',
  /** 11-50 people */
  ElevenToFifty = 'eleven_to_fifty',
  /** More than 50 people */
  MoreThanFifty = 'more_than_fifty'
}

export type MissionRequestInput = {
  budget?: Maybe<MoneyInput>;
  clientId?: Maybe<Scalars['BigInt']>;
  projectBrief?: Maybe<Scalars['String']>;
  projectLocationType?: Maybe<MissionLocationEnum>;
  proposedEndDate?: Maybe<Scalars['ISO8601Date']>;
  scopeServiceId?: Maybe<Scalars['ID']>;
  termsOfReferenceAttributes?: Maybe<Array<UploadFileInput>>;
};

export enum MissionRequestProjectSpec {
  /** I have a clear idea of what needs to be done */
  ClearIdea = 'clear_idea',
  /** I have an idea of what needs to be done, but could use some help */
  NeedHelp = 'need_help',
  /** I need help with building the details */
  AbsolutelyNeedHelp = 'absolutely_need_help'
}

export enum MissionRequestProposedStartDate {
  /** 2-4 weeks time */
  OneToFourWeeks = 'one_to_four_weeks',
  /** 2-3 months time */
  TwoToThreeMonths = 'two_to_three_months',
  /** More than 3 months */
  MoreThanThreeMonths = 'more_than_three_months'
}

export enum MissionRequestRemoteWorkforce {
  /** Yes */
  Yes = 'yes',
  /** No, I need people on ground */
  No = 'no',
  /** Not sure */
  NotSure = 'not_sure'
}

export type MissionRequestsFilter = {
  clientId?: Maybe<Scalars['BigInt']>;
  clientName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  missionLifecycleId?: Maybe<Scalars['BigInt']>;
  missionName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  or?: Maybe<Array<MissionRequestsFilter>>;
};

export type MissionRequestsSortInput = {
  id?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
};

export type MissionRequiredStaff = {
   __typename?: 'MissionRequiredStaff';
  id: Scalars['Int'];
  missionStaffRole: MissionStaffRole;
};

export type MissionScope = {
   __typename?: 'MissionScope';
  activities: Array<MissionScopeActivity>;
  activityLogs: Array<ActivityLog>;
  capacityAllocation?: Maybe<Scalars['Int']>;
  collaborators: Array<User>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['BigInt'];
  missionLifecycle: MissionLifecycle;
  phases: Array<MissionScopePhase>;
  scopeDocument?: Maybe<UploadFile>;
  staffs: Array<MissionScopeStaff>;
  targetMissionDuration?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['ISO8601DateTime'];
  version: Scalars['String'];
};

export type MissionScopeActivitiesFilter = {
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  missionScopeIdIn?: Maybe<Array<Scalars['BigInt']>>;
  or?: Maybe<Array<MissionScopeActivitiesFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
};

export type MissionScopeActivitiesSortInput = {
  createdAt?: Maybe<SortDirection>;
};

export type MissionScopeActivity = {
   __typename?: 'MissionScopeActivity';
  capacities?: Maybe<Scalars['JSON']>;
  capacityDistribution?: Maybe<Scalars['Float']>;
  completed: Scalars['Boolean'];
  deliverable: Scalars['Boolean'];
  id: Scalars['BigInt'];
  missionScope: MissionScope;
  missionScopePhase: MissionScopePhase;
  name: Scalars['String'];
  position: Scalars['String'];
  prn: Scalars['String'];
};

export type MissionScopeActivityInput = {
  name: Scalars['String'];
};

export type MissionScopePhase = {
   __typename?: 'MissionScopePhase';
  activities: Array<MissionScopeActivity>;
  id: Scalars['BigInt'];
  missionScope: MissionScope;
  name: Scalars['String'];
  position: Scalars['Int'];
};

export type MissionScopePhaseInput = {
  activities?: Maybe<Array<MissionScopeActivityInput>>;
  name: Scalars['String'];
};

export type MissionScopeStaff = {
   __typename?: 'MissionScopeStaff';
  id: Scalars['Int'];
  jobTitle: JobTitle;
  missionScope: MissionScope;
};

export type MissionScopeStaffSortInput = {
  createdAt?: Maybe<SortDirection>;
};

export type MissionScopeStaffsFilter = {
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  missionScopeIdIn?: Maybe<Array<Scalars['BigInt']>>;
  or?: Maybe<Array<MissionScopeStaffsFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
};

export type MissionStaff = {
   __typename?: 'MissionStaff';
  consultant: Consultant;
  id: Scalars['Int'];
  lastScore?: Maybe<AppraisalScore>;
  mission: Mission;
  missionStaffContract?: Maybe<MissionStaffContract>;
  missionStaffRole: MissionStaffRole;
};

export type MissionStaffContract = {
   __typename?: 'MissionStaffContract';
  content: Scalars['String'];
  id: Scalars['Int'];
  signedOn?: Maybe<Scalars['ISO8601Date']>;
  state: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type MissionStaffRole = {
   __typename?: 'MissionStaffRole';
  appraisalSurvey?: Maybe<AppraisalSurvey>;
  id: Scalars['Int'];
  name: Scalars['String'];
  objective: Scalars['String'];
  responsibility: Scalars['String'];
};

export type MissionStaffingApplication = {
   __typename?: 'MissionStaffingApplication';
  consultant: Consultant;
  consultantContract?: Maybe<ConsultantContract>;
  createdAt: Scalars['ISO8601DateTime'];
  expressionOfInterest?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  missionStaffingPosition: MissionStaffingPosition;
  prn: Scalars['String'];
  rejectReason?: Maybe<Scalars['String']>;
  scatterId: Scalars['String'];
  state: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum MissionStaffingApplicationStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Closed = 'CLOSED'
}

export type MissionStaffingApplicationsFilter = {
  status?: Maybe<MissionStaffingApplicationStatus>;
  or?: Maybe<Array<MissionStaffingApplicationsFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
  search?: Maybe<Scalars['String']>;
};

export type MissionStaffingApplicationsSortInput = {
  createdAt?: Maybe<SortDirection>;
};

export type MissionStaffingMilestone = {
   __typename?: 'MissionStaffingMilestone';
  description: Scalars['String'];
  fees: Scalars['Float'];
  feesCents?: Maybe<Scalars['Int']>;
  id: Scalars['BigInt'];
  missionContractDeliverable?: Maybe<MissionContractDeliverable>;
  name: Scalars['String'];
  state: Scalars['String'];
};

export type MissionStaffingPlacement = {
   __typename?: 'MissionStaffingPlacement';
  consultant?: Maybe<Consultant>;
  consultantContract?: Maybe<ConsultantContract>;
  consultantInvoices: Array<ConsultantInvoice>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['BigInt'];
  missionLifecycle: MissionLifecycle;
  missionStaffingPosition?: Maybe<MissionStaffingPosition>;
  placementType: Scalars['String'];
  state: Scalars['String'];
};

export enum MissionStaffingPlacementPlacement {
  MissionProposal = 'MISSION_PROPOSAL'
}

export enum MissionStaffingPlacementState {
  Executing = 'EXECUTING',
  Terminated = 'TERMINATED',
  Completed = 'COMPLETED'
}

export type MissionStaffingPlacementsFilter = {
  consultant?: Maybe<Scalars['BigInt']>;
  missionLifecycle?: Maybe<Scalars['BigInt']>;
  placementType?: Maybe<MissionStaffingPlacementPlacement>;
  state?: Maybe<MissionStaffingPlacementState>;
  or?: Maybe<Array<MissionStaffingPlacementsFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
  search?: Maybe<Scalars['String']>;
};

export type MissionStaffingPlacementsSortInput = {
  createdAt?: Maybe<SortDirection>;
};

export type MissionStaffingPosition = {
   __typename?: 'MissionStaffingPosition';
  activityLogs: Array<ActivityLog>;
  consultantContractPayments: Array<ConsultantContractPayment>;
  createdAt: Scalars['ISO8601Date'];
  description: Scalars['String'];
  id: Scalars['BigInt'];
  invoiceable: Scalars['Boolean'];
  jobTitle: JobTitle;
  missionLifecycle: MissionLifecycleBasic;
  missionStaffingApplications: Array<MissionStaffingApplication>;
  name: Scalars['String'];
  pendingMissionStaffingApplications: Array<MissionStaffingApplication>;
  positionType: Scalars['String'];
  prn: Scalars['String'];
  requiredExpertise: Array<Tag>;
  role: Scalars['String'];
  scatterId: Scalars['String'];
  staffingPositionDeliverables: Array<StaffingPositionDeliverable>;
  state: Scalars['String'];
  status: Scalars['String'];
  totalFee: Money;
  updatedAt: Scalars['ISO8601DateTime'];
};


export type MissionStaffingPositionMissionStaffingApplicationsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export enum MissionStaffingPositionStatus {
  Unpublished = 'UNPUBLISHED',
  Published = 'PUBLISHED',
  Filled = 'FILLED'
}

export type MissionStaffingPositionsFilter = {
  id?: Maybe<Scalars['BigInt']>;
  status?: Maybe<MissionStaffingPositionStatus>;
  or?: Maybe<Array<MissionStaffingPositionsFilter>>;
};

export type MissionStaffingPositionsSortInput = {
  createdAt?: Maybe<SortDirection>;
};

export type MissionStats = {
   __typename?: 'MissionStats';
  name: Scalars['String'];
  slug: Scalars['String'];
  value: Scalars['Int'];
};

export type MissionStatus = {
   __typename?: 'MissionStatus';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type MissionTeamMembership = {
   __typename?: 'MissionTeamMembership';
  /** For staffing requests */
  approval?: Maybe<Approval>;
  consultant?: Maybe<Consultant>;
  isManager: Scalars['Boolean'];
  placement: MissionStaffingPlacement;
  position?: Maybe<MissionStaffingPosition>;
  role: Scalars['String'];
  state: Scalars['String'];
  user?: Maybe<UserAdvanced>;
};

/** Mission lifecycle union type */
export type MissionUnion = MissionLifecycle | MissionLifecycleBasic;

export type Money = {
   __typename?: 'Money';
  amount: Scalars['Float'];
  cents: Scalars['Int'];
  currency: Scalars['String'];
  format: Scalars['String'];
};

export type MoneyInput = {
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type Mutation = {
   __typename?: 'Mutation';
  acceptConsultantContract: AcceptConsultantContractPayload;
  acceptInvitation: AcceptInvitationPayload;
  acceptMissionRequest: AcceptMissionRequestPayload;
  acceptServiceAgreement: AcceptServiceAgreementPayload;
  activatePeraltaAccount: ActivatePeraltaAccountPayload;
  addComment: AddCommentPayload;
  addConsultantDocument: AddConsultantDocumentPayload;
  addConsultantEducation: AddConsultantEducationPayload;
  addConsultantMissionExpense: AddConsultantMissionExpensePayload;
  addConsultantNationality: AddConsultantNationalityPayload;
  addConsultantSpokenLanguage: AddConsultantSpokenLanguagePayload;
  addConsultantWorkExperience: AddConsultantWorkExperiencePayload;
  addDocComment: AddDocCommentPayload;
  addMissionPricingProposalActivity: AddMissionPricingProposalActivityPayload;
  addMissionScopeActivity: AddMissionScopeActivityPayload;
  addMissionScopePhase: AddMissionScopePhasePayload;
  addMissionScopeStaff: AddMissionScopeStaffPayload;
  addRole: AddRolePayload;
  addSurveyQuestion: AddSurveyQuestionPayload;
  addSurveySection: AddSurveySectionPayload;
  addTrainingTag: AddTrainingTagPayload;
  addUser: AddUserPayload;
  appendSignature: AppendSignaturePayload;
  applyForMissionStaffingPosition: ApplyForMissionStaffingPositionPayload;
  approveApplication: ApproveApplicationPayload;
  approveConsultantPeralteeClaim: ApproveConsultantPeralteeClaimPayload;
  approveMissionLead: ApproveMissionLeadPayload;
  approveMissionStaffingApplication: ApproveMissionStaffingApplicationPayload;
  archiveApplication: ArchiveApplicationPayload;
  archiveApplications: ArchiveApplicationsPayload;
  archiveConsultantBankAccount: ArchiveConsultantBankAccountPayload;
  archiveConsultantPayoneerAccount: ArchiveConsultantPayoneerAccountPayload;
  archiveMission: ArchiveMissionPayload;
  archiveSurvey: ArchiveSurveyPayload;
  archiveSurveyList: ArchiveSurveyListPayload;
  assignMissionExecutive: AssignMissionExecutivePayload;
  assignMissionPartner: AssignMissionPartnerPayload;
  assignMissionPrincipal: AssignMissionPrincipalPayload;
  assignMissionTeamLead: AssignMissionTeamLeadPayload;
  beginApplication: BeginApplicationPayload;
  beginMissionProposal: BeginMissionProposalPayload;
  bulkArchiveApplication: BulkArchiveApplicationPayload;
  bulkRejectApplication: BulkRejectApplicationPayload;
  bulkShortlistApplicant: BulkShortlistApplicantPayload;
  cancelConsultantPeralteeClaimRequest: CancelConsultantPeralteeClaimRequestPayload;
  cancelMissionStaffingApplication: CancelMissionStaffingApplicationPayload;
  cancelMissionStaffingRequest: CancelMissionStaffingRequestPayload;
  commenceContracting: CommenceMissionContractingPayload;
  commenceExecution: CommenceMissionExecutionPayload;
  commenceStaffing: CommenceMissionStaffingPayload;
  completeApplication: CompleteApplicationPayload;
  completeExecution: CompleteMissionExecutionPayload;
  confirmEmail: ConfirmEmailPayload;
  createBrandResourceCollection: CreateBrandResourceCollectionPayload;
  createBrandResourceDocument: CreateBrandResourceDocumentPayload;
  createBrandResourceLabel: CreateBrandResourceLabelPayload;
  createBrandResourceVideo: CreateBrandResourceVideoPayload;
  createClient: CreateClientPayload;
  createClientContract: CreateClientContractPayload;
  createClientWithUser: CreateClientWithUserPayload;
  createConsultantBankAccount: CreateConsultantBankAccountPayload;
  createConsultantContract: CreateConsultantContractPayload;
  createConsultantContractPayment: CreateConsultantContractPaymentPayload;
  createConsultantInvoice: CreateConsultantInvoicePayload;
  createConsultantPayoneerAccount: CreateConsultantPayoneerAccountPayload;
  createJobApplicationDeadline: CreateJobApplicationDeadlinePayload;
  createMissionContractDeliverable: CreateMissionContractDeliverablePayload;
  createMissionContractPayment: CreateMissionContractPaymentPayload;
  createMissionDocument: CreateMissionDocumentPayload;
  createMissionExecutionStaffingPosition: CreateMissionExecutionStaffingPositionPayload;
  /** @deprecated Use createMissionRequest instead */
  createMissionLead: CreateMissionLeadPayload;
  createMissionProposalStaffingPosition: CreateMissionProposalStaffingPositionPayload;
  createMissionRequest: CreateMissionRequestPayload;
  createMissionScope: CreateMissionScopePayload;
  createPeralteeSupportService: CreatePeralteeSupportServicePayload;
  createScopeLibraryActivity: CreateScopeLibraryActivityPayload;
  createScopeLibraryPhase: CreateScopeLibraryPhasePayload;
  createScopePhaseSubscription: CreateScopePhaseSubscriptionPayload;
  createScopeService: CreateScopeServicePayload;
  createScopeServiceGroup: CreateScopeServiceGroupPayload;
  createScopeServiceTeamRole: CreateScopeServiceTeamRolePayload;
  createScopeTag: CreateScopeTagPayload;
  createStaffingMilestone: CreateStaffingMilestonePayload;
  createSurvey: CreateSurveyPayload;
  declineConsultantContract: DeclineConsultantContractPayload;
  deleteBrandResourceCollection: DeleteBrandResourceCollectionPayload;
  deleteBrandResourceDocument: DeleteBrandResourceDocumentPayload;
  deleteBrandResourceLabel: DeleteBrandResourceLabelPayload;
  deleteBrandResourceVideo: DeleteBrandResourceVideoPayload;
  deleteClient: DeleteClientPayload;
  deleteConsultantContractMilestone: DeleteConsultantContractMilestonePayload;
  deleteConsultantContractPayment: DeleteConsultantContractPaymentPayload;
  deleteConsultantDocument: DeleteConsultantDocumentPayload;
  deleteConsultantEducation: DeleteConsultantEducationPayload;
  deleteConsultantNationality: DeleteConsultantNationalityPayload;
  deleteConsultantSpokenLanguage: DeleteConsultantSpokenLanguagePayload;
  deleteConsultantWorkExperience: DeleteConsultantWorkExperiencePayload;
  deleteJobApplicationDeadline: DeleteJobApplicationDeadlinePayload;
  deleteMission: DeleteMissionPayload;
  deleteMissionContractDeliverable: DeleteMissionContractDeliverablePayload;
  deleteMissionContractPayment: DeleteMissionContractPaymentPayload;
  deleteMissionPricingProposalActivity: DeleteMissionPricingProposalActivityPayload;
  deleteMissionScopeActivity: DeleteMissionScopeActivityPayload;
  deleteMissionScopePhase: DeleteMissionScopePhasePayload;
  deleteMissionScopeStaff: DeleteMissionScopeStaffPayload;
  deleteScopeLibraryActivity: DeleteScopeLibraryActivityPayload;
  deleteScopePhaseSubscription: DeleteScopePhaseSubscriptionPayload;
  deleteScopeServiceTag: DeleteScopeServiceTagPayload;
  deleteScopeServiceTeamRole: DeleteScopeServiceTeamRolePayload;
  deleteStaffingMilestone: DeleteStaffingMilestonePayload;
  deleteSurvey: DeleteSurveyPayload;
  deleteSurveyQuestion: DeleteSurveyQuestionPayload;
  deleteUploadFile: DeleteUploadFilePayload;
  editComment: EditCommentPayload;
  editSurveyQuestion: EditSurveyQuestionPayload;
  fillMissionStaffingPosition: FillMissionStaffingPositionPayload;
  generateClientPaymentStructure?: Maybe<GenerateClientPaymentStructurePayload>;
  generateConsultantInvoices: GenerateConsultantInvoicesPayload;
  generateConsultantPaymentStructure: GenerateConsultantPaymentStructurePayload;
  generateMissionStaffingPositions: GenerateMissionStaffingPositionsPayload;
  generatePresignedUrl: GeneratePresignedUrlPayload;
  gradeApplicationAssessment: GradeApplicationAssessmentPayload;
  grantApproval: GrantApprovalPayload;
  initiateClientPaymentProcessing?: Maybe<InitiateClientPaymentProcessingPayload>;
  inviteClientAdmin: InviteClientAdminPayload;
  inviteConsultant: InviteConsultantPayload;
  inviteSubscriptionUser: InviteSubscriptionUserPayload;
  login: LoginPayload;
  markInvoiceAsPaid: MarkInvoiceAsPaidPayload;
  pauseMission: PauseMissionPayload;
  proceedApplication: ProceedApplicationPayload;
  publishMissionStaffingPosition: PublishMissionStaffingPositionPayload;
  publishSurvey: PublishSurveyPayload;
  queueInvoiceForPayment: QueueInvoiceForPaymentPayload;
  rejectApplication: RejectApplicationPayload;
  rejectApproval: RejectApprovalPayload;
  rejectConsultantPeralteeClaim: RejectConsultantPeralteeClaimPayload;
  rejectMissionLead: RejectMissionLeadPayload;
  rejectMissionRequest: RejectMissionRequestPayload;
  rejectMissionStaffingApplication: RejectMissionStaffingApplicationPayload;
  rejectTask: RejectTaskPayload;
  removeClientAdminAccess: RemoveClientAdminAccessPayload;
  removeMissionPartner: RemoveMissionPartnerPayload;
  removeMissionPrincipal: RemoveMissionPrincipalPayload;
  removeMissionTeamLead: RemoveMissionTeamLeadPayload;
  reopenConversation: ReopenConversationPayload;
  requestApplicantPasswordReset: RequestApplicantPasswordResetPayload;
  requestApprovalMissionPricingProposal: RequestApprovalMissionPricingProposalPayload;
  requestConsultantContractApproval: RequestConsultantContractApprovalPayload;
  requestConsultantPeralteeClaim: RequestConsultantPeralteeClaimPayload;
  requestEsignature: RequestEsignaturePayload;
  requestMission: RequestMissionPayload;
  requestMissionExecutive: RequestMissionExecutivePayload;
  requestMissionPartner: RequestMissionPartnerPayload;
  requestMissionProposalApproval: RequestMissionProposalApprovalPayload;
  requestPasswordReset: RequestPasswordResetPayload;
  requestTerminationConsultantContract: RequestTerminationConsultantContractPayload;
  resendEmailConfirmation: ResendEmailConfirmationPayload;
  resendInvitation: ResendInvitationPayload;
  resetApplicantPassword: ResetApplicantPasswordPayload;
  resetPassword: ResetPasswordPayload;
  resolveConversation: ResolveConversationPayload;
  resolveDocComment: ResolveDocCommentPayload;
  resolveTask: ResolveTaskPayload;
  respondToSurvey: RespondToSurveyPayload;
  restoreMissionStaffingPlacement: RestoreMissionStaffingPlacementPayload;
  resumeMission: ResumeMissionPayload;
  revokeInvitation: RevokeInvitationPayload;
  reworkMissionPricingProposal: ReworkMissionPricingProposalPayload;
  saveApplication: SaveApplicationPayload;
  sendBrandResourceEnquiry: SendBrandResourceEnquiryPayload;
  setConsultantTrainingTags: SetConsultantTrainingTagsPayload;
  setMissionClient: SetMissionClientPayload;
  setMissionEngagementLetter: SetMissionEngagementLetterPayload;
  setMissionName: SetMissionNamePayload;
  setMissionSignedContract: SetMissionSignedContractPayload;
  setMissionTags: SetMissionTagsPayload;
  setMissionTechnicalProposal: SetMissionTechnicalProposalPayload;
  setTermsOfReference: SetTermsOfReferencePayload;
  shortlistApplicant: ShortlistApplicantPayload;
  shortlistMissionStaffingApplication: ShortlistMissionStaffingApplicationPayload;
  signConsultantContractDocument: SignConsultantContractDocumentPayload;
  signMissionContract: SignMissionContractPayload;
  startApplication: StartApplicationPayload;
  startAssessment: StartAssessmentPayload;
  startConversation: StartConversationPayload;
  startMissionScopeDevelopment: StartMissionScopeDevelopmentPayload;
  startPricingProposalDevelopment: StartPricingProposalDevelopmentPayload;
  startRfpDevelopment: StartRfpDevelopmentPayload;
  submitApplication: SubmitApplicationPayload;
  submitConsultantInvoice: SubmitConsultantInvoicePayload;
  submitSurveyResponse: SubmitSurveyResponsePayload;
  terminateMissionStaffingPlacement: TerminateMissionStaffingPlacementPayload;
  unarchiveSurvey: UnarchiveSurveyPayload;
  unarchiveSurveyList: UnarchiveSurveyListPayload;
  unpublishMissionStaffingPosition: UnpublishMissionStaffingPositionPayload;
  unpublishSurvey: UnpublishSurveyPayload;
  updateAppConfig: UpdateAppConfigPayload;
  updateApplicantProfile: UpdateApplicantProfilePayload;
  updateBrandResourceCollection: UpdateBrandResourceCollectionPayload;
  updateBrandResourceDocument: UpdateBrandResourceDocumentPayload;
  updateBrandResourceLabel: UpdateBrandResourceLabelPayload;
  updateBrandResourceVideo: UpdateBrandResourceVideoPayload;
  updateClient: UpdateClientPayload;
  updateClientContract: UpdateClientContractPayload;
  updateCompanyProfile: UpdateCompanyProfilePayload;
  updateConsultantAvailability: UpdateConsultantAvailabilityPayload;
  updateConsultantBankAccount: UpdateConsultantBankAccountPayload;
  updateConsultantContract: UpdateConsultantContractPayload;
  updateConsultantContractMilestone: UpdateConsultantContractMilestonePayload;
  updateConsultantContractPayment: UpdateConsultantContractPaymentPayload;
  updateConsultantEducation: UpdateConsultantEducationPayload;
  updateConsultantJobTitles: UpdateConsultantJobTitlesPayload;
  updateConsultantMissionExpense: UpdateConsultantMissionExpensePayload;
  updateConsultantPayoneerAccount: UpdateConsultantPayoneerAccountPayload;
  updateConsultantProfile: UpdateConsultantProfilePayload;
  updateConsultantTalent: UpdateConsultantTalentPayload;
  updateConsultantWorkExperience: UpdateConsultantWorkExperiencePayload;
  updateMissionContractDeliverable: UpdateMissionContractDeliverablePayload;
  updateMissionContractPayment: UpdateMissionContractPaymentPayload;
  updateMissionDocument: UpdateMissionDocumentPayload;
  updateMissionExecutionStaffingPosition: UpdateMissionExecutionStaffingPositionPayload;
  /** @deprecated Use updateMissionRequest instead */
  updateMissionLead: UpdateMissionLeadPayload;
  updateMissionLifecycle: UpdateMissionLifecyclePayload;
  updateMissionPricingProposal: UpdateMissionPricingProposalPayload;
  updateMissionPricingProposalActivity: UpdateMissionPricingProposalActivityPayload;
  updateMissionProposalStaffingPosition?: Maybe<UpdateMissionProposalStaffingPositionPayload>;
  updateMissionScope: UpdateMissionScopePayload;
  updateMissionScopeActivity: UpdateMissionScopeActivityPayload;
  updateMissionScopePhase: UpdateMissionScopePhasePayload;
  updateMissionStaffingPosition: UpdateMissionStaffingPositionPayload;
  updatePassword: UpdatePasswordPayload;
  updatePaymentMethod: UpdatePaymentMethodPayload;
  updateProfile: UpdateProfilePayload;
  updateRole: UpdateRolePayload;
  updateScopeLibraryActivity: UpdateScopeLibraryActivityPayload;
  updateScopeLibraryPhase: UpdateScopeLibraryPhasePayload;
  updateScopePhaseSubscription: UpdateScopePhaseSubscriptionPayload;
  updateScopeService: UpdateScopeServicePayload;
  updateScopeServiceTags: UpdateScopeServiceTagsPayload;
  updateScopeServiceTeamRole: UpdateScopeServiceTeamRolePayload;
  updateStaffingMilestone: UpdateStaffingMilestonePayload;
  updateSurvey: UpdateSurveyPayload;
  updateSurveySection: UpdateSurveySectionPayload;
  updateUser: UpdateUserPayload;
};


export type MutationAcceptConsultantContractArgs = {
  consultantContractId: Scalars['ID'];
};


export type MutationAcceptInvitationArgs = {
  invitationId: Scalars['ID'];
  newClientInput?: Maybe<ClientInput>;
  newConsultantInput?: Maybe<ConsultantOnboardingInput>;
  newSubscriptionUserInput?: Maybe<SubscriptionUserInput>;
  newUserInput?: Maybe<UserInput>;
  token: Scalars['String'];
};


export type MutationAcceptMissionRequestArgs = {
  missionLeadPracticeGroupId: Scalars['ID'];
  missionRequestId: Scalars['ID'];
};


export type MutationActivatePeraltaAccountArgs = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  jobTitleId?: Maybe<Scalars['BigInt']>;
};


export type MutationAddCommentArgs = {
  conversationId: Scalars['ID'];
  fileInput?: Maybe<UploadFileInput>;
  text?: Maybe<Scalars['String']>;
};


export type MutationAddConsultantDocumentArgs = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  uploadFile: UploadFileInput;
};


export type MutationAddConsultantEducationArgs = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  education: ConsultantEducationInput;
};


export type MutationAddConsultantMissionExpenseArgs = {
  expense: ConsultantMissionExpenseInput;
  invoiceId: Scalars['BigInt'];
};


export type MutationAddConsultantNationalityArgs = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  countryId: Scalars['BigInt'];
};


export type MutationAddConsultantSpokenLanguageArgs = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  consultantLanguage: ConsultantSpokenLanguageInput;
};


export type MutationAddConsultantWorkExperienceArgs = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  workExperience: ConsultantWorkExperienceInput;
};


export type MutationAddDocCommentArgs = {
  commentable: CommentableTypeInput;
  file?: Maybe<UploadFileInput>;
  scope?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};


export type MutationAddMissionPricingProposalActivityArgs = {
  distribution?: Maybe<Scalars['JSON']>;
  missionPricingProposalId: Scalars['ID'];
  name: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  unitCost?: Maybe<Scalars['Float']>;
};


export type MutationAddMissionScopeActivityArgs = {
  capacities?: Maybe<Scalars['JSON']>;
  missionScopeId: Scalars['BigInt'];
  missionScopePhaseId: Scalars['BigInt'];
  name: Scalars['String'];
};


export type MutationAddMissionScopePhaseArgs = {
  missionScopeId: Scalars['BigInt'];
  name: Scalars['String'];
};


export type MutationAddMissionScopeStaffArgs = {
  jobTitleId: Scalars['BigInt'];
  missionScopeId: Scalars['BigInt'];
};


export type MutationAddRoleArgs = {
  active: Scalars['Boolean'];
  description: Scalars['String'];
  name: Scalars['String'];
  permissionSlugs: Array<Scalars['String']>;
};


export type MutationAddSurveyQuestionArgs = {
  description?: Maybe<Scalars['String']>;
  helpText?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  options?: Maybe<Scalars['JSON']>;
  questionType: SurveyQuestionTypeEnum;
  required?: Maybe<Scalars['Boolean']>;
  surveyId: Scalars['ID'];
  surveySectionId?: Maybe<Scalars['ID']>;
};


export type MutationAddSurveySectionArgs = {
  description?: Maybe<Scalars['String']>;
  questionIds?: Maybe<Array<Scalars['ID']>>;
  surveyId: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationAddTrainingTagArgs = {
  name: Scalars['String'];
};


export type MutationAddUserArgs = {
  email: Scalars['String'];
  roleIds: Array<Scalars['String']>;
};


export type MutationAppendSignatureArgs = {
  mandateSecret: Scalars['String'];
  requestId: Scalars['BigInt'];
  signatureSource: SignatureSourceInput;
};


export type MutationApplyForMissionStaffingPositionArgs = {
  expressionOfInterest: Scalars['String'];
  missionStaffingPositionId: Scalars['ID'];
};


export type MutationApproveApplicationArgs = {
  id: Scalars['BigInt'];
};


export type MutationApproveConsultantPeralteeClaimArgs = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
};


export type MutationApproveMissionLeadArgs = {
  missionId: Scalars['BigInt'];
};


export type MutationApproveMissionStaffingApplicationArgs = {
  missionStaffingApplicationId: Scalars['ID'];
};


export type MutationArchiveApplicationArgs = {
  applicantId: Scalars['ID'];
};


export type MutationArchiveConsultantBankAccountArgs = {
  accountId: Scalars['ID'];
};


export type MutationArchiveConsultantPayoneerAccountArgs = {
  accountId: Scalars['ID'];
};


export type MutationArchiveMissionArgs = {
  archivalReason: Scalars['String'];
  missionLifecycleId: Scalars['ID'];
};


export type MutationArchiveSurveyArgs = {
  surveyId: Scalars['ID'];
};


export type MutationArchiveSurveyListArgs = {
  surveyListId: Scalars['ID'];
};


export type MutationAssignMissionExecutiveArgs = {
  consultantId: Scalars['BigInt'];
  missionId: Scalars['BigInt'];
};


export type MutationAssignMissionPartnerArgs = {
  consultantId: Scalars['BigInt'];
  missionId: Scalars['BigInt'];
};


export type MutationAssignMissionPrincipalArgs = {
  consultantId: Scalars['BigInt'];
  missionId: Scalars['BigInt'];
};


export type MutationAssignMissionTeamLeadArgs = {
  consultantId: Scalars['BigInt'];
  missionId: Scalars['BigInt'];
};


export type MutationBeginApplicationArgs = {
  applicantInput: ApplicantInputV2;
  applicationTrackId: Scalars['ID'];
  newApplicant?: Maybe<Scalars['Boolean']>;
};


export type MutationBeginMissionProposalArgs = {
  missionId: Scalars['BigInt'];
};


export type MutationBulkArchiveApplicationArgs = {
  applicantsId: Array<Scalars['ID']>;
};


export type MutationBulkRejectApplicationArgs = {
  applicantsId: Array<Scalars['ID']>;
  rejectionReason: CandidateRejectionTypeEnum;
};


export type MutationBulkShortlistApplicantArgs = {
  applicantsId: Array<Scalars['ID']>;
};


export type MutationCancelConsultantPeralteeClaimRequestArgs = {
  id: Scalars['BigInt'];
};


export type MutationCancelMissionStaffingApplicationArgs = {
  id: Scalars['BigInt'];
};


export type MutationCancelMissionStaffingRequestArgs = {
  id: Scalars['BigInt'];
};


export type MutationCommenceContractingArgs = {
  missionId: Scalars['BigInt'];
};


export type MutationCommenceExecutionArgs = {
  commencementDate: Scalars['ISO8601Date'];
  missionId: Scalars['BigInt'];
};


export type MutationCommenceStaffingArgs = {
  missionId: Scalars['BigInt'];
};


export type MutationCompleteApplicationArgs = {
  applicant: ApplicantInput;
  consultantId?: Maybe<Scalars['BigInt']>;
};


export type MutationCompleteExecutionArgs = {
  completionDate: Scalars['ISO8601Date'];
  missionId: Scalars['BigInt'];
};


export type MutationConfirmEmailArgs = {
  confirmationToken: Scalars['String'];
};


export type MutationCreateBrandResourceCollectionArgs = {
  labelId: Scalars['BigInt'];
  name: Scalars['String'];
};


export type MutationCreateBrandResourceDocumentArgs = {
  collectionId: Scalars['BigInt'];
  name: Scalars['String'];
  uploadFile: UploadFileInput;
};


export type MutationCreateBrandResourceLabelArgs = {
  name: Scalars['String'];
};


export type MutationCreateBrandResourceVideoArgs = {
  collectionId: Scalars['BigInt'];
  name: Scalars['String'];
  videoThumbnail: Scalars['String'];
  videoUrl: Scalars['String'];
};


export type MutationCreateClientArgs = {
  clientInput: ClientInput;
};


export type MutationCreateClientContractArgs = {
  contractTerms: MissionContractTermsInput;
};


export type MutationCreateClientWithUserArgs = {
  missionRequestInput?: Maybe<MissionRequestInput>;
  newCardInput?: Maybe<CardInput>;
  newClientInput: ClientInput;
  newSubscriptionInput?: Maybe<SubscriptionInput>;
  newUserInput: UserInput;
  promoCodeInput?: Maybe<PromoCodeInput>;
  subscriptionId?: Maybe<Scalars['ID']>;
};


export type MutationCreateConsultantBankAccountArgs = {
  bankDetails: ConsultantBankAccountInput;
};


export type MutationCreateConsultantContractArgs = {
  consultantContract: ConsultantContractInput;
};


export type MutationCreateConsultantContractPaymentArgs = {
  consultantContractPaymentInput: ConsultantContractPaymentInput;
  missionContractPaymentId: Scalars['ID'];
  missionStaffingPositionId: Scalars['ID'];
};


export type MutationCreateConsultantInvoiceArgs = {
  billingAccountId: Scalars['BigInt'];
  billingAccountType: BillingAccountEnum;
  consultantId: Scalars['BigInt'];
  expenses: Array<ConsultantMissionExpenseInput>;
  missionLifecycleId: Scalars['BigInt'];
  missionStaffingPlacementId: Scalars['BigInt'];
  paymentDescription?: Maybe<Scalars['String']>;
};


export type MutationCreateConsultantPayoneerAccountArgs = {
  customerId: Scalars['String'];
  emailAddress: Scalars['String'];
};


export type MutationCreateJobApplicationDeadlineArgs = {
  date: Scalars['ISO8601Date'];
  jobTitleId: Scalars['Int'];
};


export type MutationCreateMissionContractDeliverableArgs = {
  contractPaymentId: Scalars['BigInt'];
  description: Scalars['String'];
  dueDate: Scalars['ISO8601Date'];
  name: Scalars['String'];
};


export type MutationCreateMissionContractPaymentArgs = {
  amount: Scalars['Int'];
  deliverables?: Maybe<Array<Scalars['ID']>>;
  description: Scalars['String'];
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  missionLifecycleId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  paymentType?: Maybe<ContractPayment>;
};


export type MutationCreateMissionDocumentArgs = {
  missionDocumentInput: MissionDocumentInput;
  missionLifecycleId: Scalars['ID'];
};


export type MutationCreateMissionExecutionStaffingPositionArgs = {
  contractPayments?: Maybe<Array<ConsultantContractPaymentInput>>;
  description: Scalars['String'];
  jobTitleId: Scalars['BigInt'];
  milestones: Array<Scalars['BigInt']>;
  missionId: Scalars['BigInt'];
  name: Scalars['String'];
  requiredExpertiseTags?: Maybe<Array<Scalars['String']>>;
};


export type MutationCreateMissionLeadArgs = {
  missionLead: MissionLeadInput;
};


export type MutationCreateMissionProposalStaffingPositionArgs = {
  description: Scalars['String'];
  jobTitleId: Scalars['BigInt'];
  milestones: Array<MissionProposalStaffingMilestoneInput>;
  missionId: Scalars['BigInt'];
};


export type MutationCreateMissionRequestArgs = {
  missionRequestInput: MissionRequestInput;
};


export type MutationCreateMissionScopeArgs = {
  missionLifecycleId: Scalars['ID'];
  missionScopePhases?: Maybe<Array<MissionScopePhaseInput>>;
};


export type MutationCreatePeralteeSupportServiceArgs = {
  description: Scalars['String'];
  name: Scalars['String'];
  points: Scalars['Int'];
};


export type MutationCreateScopeLibraryActivityArgs = {
  name: Scalars['String'];
  scopePhaseSubscriptionId: Scalars['BigInt'];
};


export type MutationCreateScopeLibraryPhaseArgs = {
  name: Scalars['String'];
  scopeServiceId: Scalars['ID'];
};


export type MutationCreateScopePhaseSubscriptionArgs = {
  scopeLibraryPhaseId: Scalars['BigInt'];
  scopeServiceId: Scalars['BigInt'];
};


export type MutationCreateScopeServiceArgs = {
  scopeServiceInput: ScopeServiceInput;
};


export type MutationCreateScopeServiceGroupArgs = {
  name: Scalars['String'];
};


export type MutationCreateScopeServiceTeamRoleArgs = {
  scopeServiceId: Scalars['ID'];
  scopeServiceTeamRoleInput: ScopeServiceTeamRoleInput;
};


export type MutationCreateScopeTagArgs = {
  name: Scalars['String'];
  serviceId?: Maybe<Scalars['BigInt']>;
};


export type MutationCreateStaffingMilestoneArgs = {
  description: Scalars['String'];
  feesCents?: Maybe<Scalars['Int']>;
  missionContractDeliverableId?: Maybe<Scalars['BigInt']>;
  name: Scalars['String'];
};


export type MutationCreateSurveyArgs = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
  surveyListInput?: Maybe<SurveyListInput>;
  surveyType: SurveyTypeEnum;
  surveyable: SurveyableInput;
};


export type MutationDeclineConsultantContractArgs = {
  declineReason: Scalars['String'];
  id: Scalars['BigInt'];
};


export type MutationDeleteBrandResourceCollectionArgs = {
  id: Scalars['BigInt'];
  reassignCollectionId?: Maybe<Scalars['BigInt']>;
};


export type MutationDeleteBrandResourceDocumentArgs = {
  id: Scalars['BigInt'];
};


export type MutationDeleteBrandResourceLabelArgs = {
  id: Scalars['BigInt'];
  reassignLabelId?: Maybe<Scalars['BigInt']>;
};


export type MutationDeleteBrandResourceVideoArgs = {
  id: Scalars['BigInt'];
};


export type MutationDeleteClientArgs = {
  clientId: Scalars['BigInt'];
};


export type MutationDeleteConsultantContractMilestoneArgs = {
  id: Scalars['BigInt'];
};


export type MutationDeleteConsultantContractPaymentArgs = {
  id: Scalars['BigInt'];
};


export type MutationDeleteConsultantDocumentArgs = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  uploadFileId: Scalars['BigInt'];
};


export type MutationDeleteConsultantEducationArgs = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  educationId: Scalars['BigInt'];
};


export type MutationDeleteConsultantNationalityArgs = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  nationalityId: Scalars['BigInt'];
};


export type MutationDeleteConsultantSpokenLanguageArgs = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  consultantSpokenLanguageId: Scalars['BigInt'];
};


export type MutationDeleteConsultantWorkExperienceArgs = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  workExperienceId: Scalars['BigInt'];
};


export type MutationDeleteJobApplicationDeadlineArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteMissionArgs = {
  id: Scalars['BigInt'];
};


export type MutationDeleteMissionContractDeliverableArgs = {
  id: Scalars['BigInt'];
};


export type MutationDeleteMissionContractPaymentArgs = {
  id: Scalars['BigInt'];
};


export type MutationDeleteMissionPricingProposalActivityArgs = {
  activityId: Scalars['BigInt'];
};


export type MutationDeleteMissionScopeActivityArgs = {
  activityId: Scalars['BigInt'];
};


export type MutationDeleteMissionScopePhaseArgs = {
  forceDelete: Scalars['Boolean'];
  phaseId: Scalars['BigInt'];
};


export type MutationDeleteMissionScopeStaffArgs = {
  missionScopeStaffId: Scalars['BigInt'];
};


export type MutationDeleteScopeLibraryActivityArgs = {
  id: Scalars['BigInt'];
};


export type MutationDeleteScopePhaseSubscriptionArgs = {
  id: Scalars['BigInt'];
};


export type MutationDeleteScopeServiceTagArgs = {
  id: Scalars['BigInt'];
};


export type MutationDeleteScopeServiceTeamRoleArgs = {
  scopeServiceTeamRoleId: Scalars['ID'];
};


export type MutationDeleteStaffingMilestoneArgs = {
  forceDelete: Scalars['Boolean'];
  id: Scalars['BigInt'];
};


export type MutationDeleteSurveyArgs = {
  surveyId: Scalars['ID'];
};


export type MutationDeleteSurveyQuestionArgs = {
  surveyQuestionId: Scalars['ID'];
};


export type MutationDeleteUploadFileArgs = {
  id: Scalars['BigInt'];
};


export type MutationEditCommentArgs = {
  commentId: Scalars['ID'];
  text: Scalars['String'];
};


export type MutationEditSurveyQuestionArgs = {
  description?: Maybe<Scalars['String']>;
  helpText?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  position?: Maybe<Scalars['Int']>;
  questionId: Scalars['ID'];
  questionType?: Maybe<SurveyQuestionTypeEnum>;
  required?: Maybe<Scalars['Boolean']>;
  surveySectionId?: Maybe<Scalars['ID']>;
};


export type MutationFillMissionStaffingPositionArgs = {
  force?: Maybe<Scalars['Boolean']>;
  id: Scalars['BigInt'];
};


export type MutationGenerateClientPaymentStructureArgs = {
  missionLifecycleId: Scalars['ID'];
  paymentsInput: Array<ClientPaymentStructureInput>;
};


export type MutationGenerateConsultantInvoicesArgs = {
  missionLifecycleId: Scalars['ID'];
};


export type MutationGenerateConsultantPaymentStructureArgs = {
  missionStaffingPositionId: Scalars['ID'];
  paymentsInputs: Array<ConsultantPaymentStructureInput>;
};


export type MutationGenerateMissionStaffingPositionsArgs = {
  missionLifecycleId: Scalars['ID'];
};


export type MutationGeneratePresignedUrlArgs = {
  filename: Scalars['String'];
  folder: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
};


export type MutationGradeApplicationAssessmentArgs = {
  assessmentType: AssessmentType;
  id: Scalars['BigInt'];
  score: Scalars['Int'];
};


export type MutationGrantApprovalArgs = {
  id: Scalars['BigInt'];
};


export type MutationInitiateClientPaymentProcessingArgs = {
  missionContractPaymentId: Scalars['ID'];
};


export type MutationInviteClientAdminArgs = {
  clientId: Scalars['ID'];
  roles?: Maybe<Array<Scalars['String']>>;
  userEmail: Scalars['String'];
};


export type MutationInviteConsultantArgs = {
  email: Scalars['String'];
  jobTitleId: Scalars['ID'];
};


export type MutationInviteSubscriptionUserArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  subscriptionId: Scalars['ID'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  mode: LoginMode;
  password: Scalars['String'];
};


export type MutationMarkInvoiceAsPaidArgs = {
  invoiceId: Scalars['BigInt'];
};


export type MutationPauseMissionArgs = {
  id: Scalars['BigInt'];
};


export type MutationProceedApplicationArgs = {
  id: Scalars['BigInt'];
  nextEvent?: Maybe<Scalars['String']>;
};


export type MutationPublishMissionStaffingPositionArgs = {
  id: Scalars['BigInt'];
};


export type MutationPublishSurveyArgs = {
  surveyId: Scalars['ID'];
};


export type MutationQueueInvoiceForPaymentArgs = {
  invoiceId: Scalars['BigInt'];
};


export type MutationRejectApplicationArgs = {
  applicantId: Scalars['ID'];
  rejectionReason: CandidateRejectionTypeEnum;
};


export type MutationRejectApprovalArgs = {
  id: Scalars['BigInt'];
  metadata?: Maybe<Scalars['JSON']>;
  reason: Scalars['String'];
};


export type MutationRejectConsultantPeralteeClaimArgs = {
  comment: Scalars['String'];
  id: Scalars['BigInt'];
};


export type MutationRejectMissionLeadArgs = {
  missionId: Scalars['BigInt'];
};


export type MutationRejectMissionRequestArgs = {
  missionRequestId: Scalars['ID'];
};


export type MutationRejectMissionStaffingApplicationArgs = {
  missionStaffingApplicationId: Scalars['ID'];
  rejectionReason: Scalars['String'];
};


export type MutationRejectTaskArgs = {
  data: Scalars['JSON'];
  id: Scalars['BigInt'];
};


export type MutationRemoveClientAdminAccessArgs = {
  clientId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRemoveMissionPartnerArgs = {
  consultantId: Scalars['BigInt'];
  missionId: Scalars['BigInt'];
};


export type MutationRemoveMissionPrincipalArgs = {
  consultantId: Scalars['BigInt'];
  missionId: Scalars['BigInt'];
};


export type MutationRemoveMissionTeamLeadArgs = {
  consultantId: Scalars['BigInt'];
  missionId: Scalars['BigInt'];
};


export type MutationReopenConversationArgs = {
  conversationId: Scalars['ID'];
};


export type MutationRequestApplicantPasswordResetArgs = {
  emailAddress: Scalars['String'];
  trackId: Scalars['ID'];
};


export type MutationRequestApprovalMissionPricingProposalArgs = {
  id: Scalars['BigInt'];
};


export type MutationRequestConsultantContractApprovalArgs = {
  id: Scalars['BigInt'];
};


export type MutationRequestConsultantPeralteeClaimArgs = {
  description: Scalars['String'];
  supportServiceId: Scalars['BigInt'];
};


export type MutationRequestEsignatureArgs = {
  signableId: Scalars['BigInt'];
  signableType: Scalars['String'];
  signers: Array<EsignatureSignerInput>;
};


export type MutationRequestMissionArgs = {
  clientId: Scalars['ID'];
  missionRequestInput: MissionRequestInput;
};


export type MutationRequestMissionExecutiveArgs = {
  consultantId: Scalars['BigInt'];
  missionId: Scalars['BigInt'];
};


export type MutationRequestMissionPartnerArgs = {
  consultantId: Scalars['BigInt'];
  missionId: Scalars['BigInt'];
};


export type MutationRequestMissionProposalApprovalArgs = {
  missionLifecycleId: Scalars['ID'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationRequestTerminationConsultantContractArgs = {
  id: Scalars['BigInt'];
  terminationReason: Scalars['String'];
};


export type MutationResendInvitationArgs = {
  invitationId: Scalars['ID'];
};


export type MutationResetApplicantPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResolveConversationArgs = {
  conversationId: Scalars['ID'];
};


export type MutationResolveDocCommentArgs = {
  id: Scalars['BigInt'];
};


export type MutationResolveTaskArgs = {
  id: Scalars['BigInt'];
};


export type MutationRespondToSurveyArgs = {
  finalize?: Maybe<Scalars['Boolean']>;
  response: SurveyResponseInput;
  surveyId: Scalars['ID'];
};


export type MutationRestoreMissionStaffingPlacementArgs = {
  id: Scalars['BigInt'];
};


export type MutationResumeMissionArgs = {
  id: Scalars['BigInt'];
};


export type MutationRevokeInvitationArgs = {
  invitationId: Scalars['ID'];
};


export type MutationReworkMissionPricingProposalArgs = {
  pricingProposalId: Scalars['BigInt'];
};


export type MutationSaveApplicationArgs = {
  applicant: ApplicantInput;
  consultantId?: Maybe<Scalars['BigInt']>;
  application: ConsultantApplicationInput;
};


export type MutationSendBrandResourceEnquiryArgs = {
  message: Scalars['String'];
  senderEmail: Scalars['String'];
  senderName: Scalars['String'];
};


export type MutationSetConsultantTrainingTagsArgs = {
  consultantId: Scalars['BigInt'];
  trainingTags: Array<Scalars['String']>;
};


export type MutationSetMissionClientArgs = {
  clientId: Scalars['BigInt'];
  missionId: Scalars['BigInt'];
};


export type MutationSetMissionEngagementLetterArgs = {
  missionId: Scalars['BigInt'];
  uploadFile: UploadFileInput;
};


export type MutationSetMissionNameArgs = {
  missionLifecycleId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationSetMissionSignedContractArgs = {
  missionId: Scalars['BigInt'];
  uploadFile: UploadFileInput;
};


export type MutationSetMissionTagsArgs = {
  missionId: Scalars['BigInt'];
  tags: Array<Scalars['String']>;
};


export type MutationSetMissionTechnicalProposalArgs = {
  missionId: Scalars['BigInt'];
  uploadFile: UploadFileInput;
};


export type MutationSetTermsOfReferenceArgs = {
  missionLifecycleId: Scalars['ID'];
  uploadFiles: Array<UploadFileInput>;
};


export type MutationShortlistApplicantArgs = {
  applicantId: Scalars['ID'];
};


export type MutationShortlistMissionStaffingApplicationArgs = {
  missionStaffingApplicationId: Scalars['ID'];
};


export type MutationSignConsultantContractDocumentArgs = {
  adoptedSignature?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
};


export type MutationSignMissionContractArgs = {
  contractId: Scalars['BigInt'];
};


export type MutationStartApplicationArgs = {
  applicant: ApplicantInput;
  consultantId?: Maybe<Scalars['BigInt']>;
};


export type MutationStartAssessmentArgs = {
  applicantId: Scalars['ID'];
  applicationTrackId: Scalars['ID'];
  sectionType: Scalars['String'];
};


export type MutationStartConversationArgs = {
  conversableId: Scalars['ID'];
  file?: Maybe<UploadFileInput>;
  scope?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};


export type MutationStartMissionScopeDevelopmentArgs = {
  missionId: Scalars['BigInt'];
};


export type MutationStartPricingProposalDevelopmentArgs = {
  missionLifecycleId: Scalars['ID'];
};


export type MutationStartRfpDevelopmentArgs = {
  missionId: Scalars['BigInt'];
  rfpDeadline: Scalars['ISO8601Date'];
};


export type MutationSubmitApplicationArgs = {
  applicantId: Scalars['ID'];
  applicationTrackId: Scalars['ID'];
};


export type MutationSubmitConsultantInvoiceArgs = {
  billingAccountId: Scalars['BigInt'];
  billingAccountType: BillingAccountEnum;
  expenses?: Maybe<Array<ConsultantMissionExpenseInput>>;
  invoiceId: Scalars['BigInt'];
};


export type MutationSubmitSurveyResponseArgs = {
  answer: Scalars['JSON'];
  id: Scalars['BigInt'];
};


export type MutationTerminateMissionStaffingPlacementArgs = {
  id: Scalars['BigInt'];
  terminationReason: Scalars['String'];
};


export type MutationUnarchiveSurveyArgs = {
  publish?: Maybe<Scalars['Boolean']>;
  surveyId: Scalars['ID'];
};


export type MutationUnarchiveSurveyListArgs = {
  surveyListId: Scalars['ID'];
};


export type MutationUnpublishMissionStaffingPositionArgs = {
  id: Scalars['BigInt'];
};


export type MutationUnpublishSurveyArgs = {
  surveyId: Scalars['ID'];
};


export type MutationUpdateAppConfigArgs = {
  group: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
};


export type MutationUpdateApplicantProfileArgs = {
  applicantDocuments: Array<UploadFileInput>;
  applicantId: Scalars['ID'];
  profile: ApplicantProfileInput;
};


export type MutationUpdateBrandResourceCollectionArgs = {
  id: Scalars['BigInt'];
  labelId?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
};


export type MutationUpdateBrandResourceDocumentArgs = {
  collectionId?: Maybe<Scalars['BigInt']>;
  id: Scalars['BigInt'];
  name?: Maybe<Scalars['String']>;
  uploadFile?: Maybe<UploadFileInput>;
};


export type MutationUpdateBrandResourceLabelArgs = {
  id: Scalars['BigInt'];
  name: Scalars['String'];
};


export type MutationUpdateBrandResourceVideoArgs = {
  collectionId?: Maybe<Scalars['BigInt']>;
  id: Scalars['BigInt'];
  name?: Maybe<Scalars['String']>;
  videoThumbnail?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
};


export type MutationUpdateClientArgs = {
  clientId: Scalars['ID'];
  clientInput: ClientInput;
};


export type MutationUpdateClientContractArgs = {
  contractTerms: MissionContractTermsInput;
  contractTermsId: Scalars['BigInt'];
};


export type MutationUpdateCompanyProfileArgs = {
  address?: Maybe<Scalars['String']>;
  clientId: Scalars['ID'];
  companyLogo?: Maybe<UploadFileInput>;
  countryId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};


export type MutationUpdateConsultantAvailabilityArgs = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  availability: ConsultantAvailability;
  availabilityReason?: Maybe<Scalars['String']>;
  nextAvailability?: Maybe<Scalars['ISO8601Date']>;
};


export type MutationUpdateConsultantBankAccountArgs = {
  accountId: Scalars['BigInt'];
  bankDetails: ConsultantBankAccountInput;
};


export type MutationUpdateConsultantContractArgs = {
  consultantContract: ConsultantContractInput;
  id: Scalars['BigInt'];
};


export type MutationUpdateConsultantContractMilestoneArgs = {
  consultantContractMilestone: ConsultantContractMilestoneInput;
  consultantContractMilestoneId: Scalars['BigInt'];
};


export type MutationUpdateConsultantContractPaymentArgs = {
  consultantContractPaymentId: Scalars['ID'];
  consultantContractPaymentInput: ConsultantContractPaymentInput;
};


export type MutationUpdateConsultantEducationArgs = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  education: ConsultantEducationInput;
  educationId: Scalars['BigInt'];
};


export type MutationUpdateConsultantJobTitlesArgs = {
  consultantId: Scalars['ID'];
  jobTitles: Array<Scalars['BigInt']>;
};


export type MutationUpdateConsultantMissionExpenseArgs = {
  expenseId: Scalars['BigInt'];
  expensePayload: ConsultantMissionExpenseInput;
};


export type MutationUpdateConsultantPayoneerAccountArgs = {
  customerId?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  payoneerAccountId: Scalars['ID'];
};


export type MutationUpdateConsultantProfileArgs = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  profile: ConsultantProfileInput;
  profilePresentation?: Maybe<UploadFileInput>;
};


export type MutationUpdateConsultantTalentArgs = {
  consultantId: Scalars['BigInt'];
  talent: ConsultantTalentInput;
};


export type MutationUpdateConsultantWorkExperienceArgs = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  workExperience: ConsultantWorkExperienceInput;
  workExperienceId: Scalars['BigInt'];
};


export type MutationUpdateMissionContractDeliverableArgs = {
  deliverableId: Scalars['BigInt'];
  progress: Scalars['Int'];
};


export type MutationUpdateMissionContractPaymentArgs = {
  amount?: Maybe<Scalars['Float']>;
  deliverables?: Maybe<Array<Scalars['ID']>>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  missionContractPaymentId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  state?: Maybe<MissionContractPaymentState>;
};


export type MutationUpdateMissionDocumentArgs = {
  missionDocumentId: Scalars['ID'];
  missionDocumentInput: MissionDocumentInput;
};


export type MutationUpdateMissionExecutionStaffingPositionArgs = {
  contractPayments?: Maybe<Array<ConsultantContractPaymentInput>>;
  description?: Maybe<Scalars['String']>;
  missionScopeActivityIds?: Maybe<Array<Scalars['ID']>>;
  missionStaffingPositionId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  totalFee?: Maybe<MoneyInput>;
  requiredExpertiseTags?: Maybe<Array<Scalars['String']>>;
};


export type MutationUpdateMissionLeadArgs = {
  missionLead: MissionLeadInput;
  missionLifecycleId: Scalars['BigInt'];
};


export type MutationUpdateMissionLifecycleArgs = {
  missionLifecycleId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  projectLocationCountryCode?: Maybe<Scalars['String']>;
  projectTitle?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  tentativeEndDate?: Maybe<Scalars['ISO8601Date']>;
  tentativeStartDate?: Maybe<Scalars['ISO8601Date']>;
};


export type MutationUpdateMissionPricingProposalArgs = {
  contractingEntity?: Maybe<ContractingEntity>;
  currencyId?: Maybe<Scalars['BigInt']>;
  markup?: Maybe<Scalars['Float']>;
  missionPricingProposalId: Scalars['ID'];
  totalPrice?: Maybe<Scalars['BigInt']>;
};


export type MutationUpdateMissionPricingProposalActivityArgs = {
  distribution?: Maybe<Scalars['JSON']>;
  id: Scalars['BigInt'];
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  unitCost?: Maybe<Scalars['Int']>;
};


export type MutationUpdateMissionProposalStaffingPositionArgs = {
  description: Scalars['String'];
  milestones: Array<MissionProposalStaffingMilestoneInput>;
  positionId: Scalars['BigInt'];
};


export type MutationUpdateMissionScopeArgs = {
  id: Scalars['BigInt'];
  capacityAllocation?: Maybe<Scalars['Int']>;
  targetMissionDuration?: Maybe<Scalars['Float']>;
};


export type MutationUpdateMissionScopeActivityArgs = {
  missionScopeActivityId: Scalars['ID'];
  capacities?: Maybe<Scalars['JSON']>;
  capacityDistribution?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  deliverable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  positionPosition?: Maybe<Scalars['Int']>;
};


export type MutationUpdateMissionScopePhaseArgs = {
  id: Scalars['BigInt'];
  name?: Maybe<Scalars['String']>;
  positionPosition?: Maybe<Scalars['Int']>;
};


export type MutationUpdateMissionStaffingPositionArgs = {
  description: Scalars['String'];
  name: Scalars['String'];
  staffingPositionId: Scalars['ID'];
};


export type MutationUpdatePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationUpdatePaymentMethodArgs = {
  paymentMethodId: Scalars['ID'];
  paymentMethodInput: CardInput;
};


export type MutationUpdateProfileArgs = {
  avatar?: Maybe<UploadFileInput>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<PhoneInput>;
};


export type MutationUpdateRoleArgs = {
  active: Scalars['Boolean'];
  description: Scalars['String'];
  id: Scalars['BigInt'];
  name: Scalars['String'];
  permissionSlugs: Array<Scalars['String']>;
};


export type MutationUpdateScopeLibraryActivityArgs = {
  id: Scalars['BigInt'];
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['BigInt']>;
};


export type MutationUpdateScopeLibraryPhaseArgs = {
  id: Scalars['BigInt'];
  name: Scalars['String'];
};


export type MutationUpdateScopePhaseSubscriptionArgs = {
  id: Scalars['BigInt'];
  libraryPhaseName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['BigInt']>;
};


export type MutationUpdateScopeServiceArgs = {
  scopeServiceId: Scalars['ID'];
  scopeServiceInput: ScopeServiceInput;
};


export type MutationUpdateScopeServiceTagsArgs = {
  serviceId: Scalars['BigInt'];
  tags?: Maybe<Array<Scalars['String']>>;
};


export type MutationUpdateScopeServiceTeamRoleArgs = {
  scopeServiceTeamRoleId: Scalars['ID'];
  scopeServiceTeamRoleInput: ScopeServiceTeamRoleInput;
};


export type MutationUpdateStaffingMilestoneArgs = {
  description: Scalars['String'];
  feesCents?: Maybe<Scalars['Int']>;
  id: Scalars['BigInt'];
  name: Scalars['String'];
};


export type MutationUpdateSurveyArgs = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  surveyId: Scalars['ID'];
};


export type MutationUpdateSurveySectionArgs = {
  description?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  sectionId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserArgs = {
  active: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  forceUpdate: Scalars['Boolean'];
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  roleIds: Array<Scalars['BigInt']>;
};

export type MyMissionsFilter = {
  clientId?: Maybe<Scalars['BigInt']>;
  country?: Maybe<Scalars['String']>;
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  missionExecutiveId?: Maybe<Scalars['BigInt']>;
  state?: Maybe<MissionLifecycleState>;
  stateIn?: Maybe<Array<Scalars['String']>>;
  or?: Maybe<Array<MyMissionsFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
};

export type MyMissionsSortInput = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export enum MyPerformanceAppraisalState {
  Pending = 'PENDING',
  Completed = 'COMPLETED'
}

export type MyPerformanceAppraisalsFilter = {
  appraiseeIdIn?: Maybe<Array<Scalars['BigInt']>>;
  appraiserIdIn?: Maybe<Array<Scalars['BigInt']>>;
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  state?: Maybe<MyPerformanceAppraisalState>;
  or?: Maybe<Array<MyPerformanceAppraisalsFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
};

export type MyPerformanceAppraisalsSortInput = {
  createdAt?: Maybe<SortDirection>;
};

export type OpenMissionStaffingPositionsFilter = {
  id?: Maybe<Scalars['BigInt']>;
  or?: Maybe<Array<OpenMissionStaffingPositionsFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
  search?: Maybe<Scalars['String']>;
};

export type OpenMissionStaffingPositionsSortInput = {
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export enum OwnershipStructureEnum {
  /** Majority African-owned */
  MajorityAfricanOwned = 'majority_african_owned',
  /** Majority Foreign-owned */
  MajorityForeignOwned = 'majority_foreign_owned',
  /** Prefer not to disclose */
  PreferNotToDisclose = 'prefer_not_to_disclose'
}

export type PaginatedAppConfigs = {
   __typename?: 'PaginatedAppConfigs';
  /** The list of items matching your query */
  data: Array<AppConfig>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedApplicants = {
   __typename?: 'PaginatedApplicants';
  /** The list of items matching your query */
  data: Array<Applicant>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedBankAccounts = {
   __typename?: 'PaginatedBankAccounts';
  /** The list of items matching your query */
  data: Array<BankAccount>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedBrandResourceCollections = {
   __typename?: 'PaginatedBrandResourceCollections';
  /** The list of items matching your query */
  data: Array<BrandResourceCollection>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedBrandResourceLabels = {
   __typename?: 'PaginatedBrandResourceLabels';
  /** The list of items matching your query */
  data: Array<BrandResourceLabel>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedClientInvoices = {
   __typename?: 'PaginatedClientInvoices';
  /** The list of items matching your query */
  data: Array<MissionContractPayment>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedClientMissionRequests = {
   __typename?: 'PaginatedClientMissionRequests';
  /** The list of items matching your query */
  data: Array<MissionRequest>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedClientMissions = {
   __typename?: 'PaginatedClientMissions';
  /** The list of items matching your query */
  data: Array<MissionLifecycle>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedClients = {
   __typename?: 'PaginatedClients';
  /** The list of items matching your query */
  data: Array<Client>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedCompanies = {
   __typename?: 'PaginatedCompanies';
  /** The list of items matching your query */
  data: Array<Company>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedConsultantContracts = {
   __typename?: 'PaginatedConsultantContracts';
  /** The list of items matching your query */
  data: Array<ConsultantContract>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedConsultantInvoices = {
   __typename?: 'PaginatedConsultantInvoices';
  /** The list of items matching your query */
  data: Array<ConsultantInvoice>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedConsultantPeralteeClaims = {
   __typename?: 'PaginatedConsultantPeralteeClaims';
  /** The list of items matching your query */
  data: Array<ConsultantPeralteeClaim>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedConsultants = {
   __typename?: 'PaginatedConsultants';
  /** The list of items matching your query */
  data: Array<Consultant>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedCountries = {
   __typename?: 'PaginatedCountries';
  /** The list of items matching your query */
  data: Array<Country>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedInstitutions = {
   __typename?: 'PaginatedInstitutions';
  /** The list of items matching your query */
  data: Array<Institution>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedLanguages = {
   __typename?: 'PaginatedLanguages';
  /** The list of items matching your query */
  data: Array<Language>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedMissionDocuments = {
   __typename?: 'PaginatedMissionDocuments';
  /** The list of items matching your query */
  data: Array<MissionDocument>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedMissionLifecycles = {
   __typename?: 'PaginatedMissionLifecycles';
  /** The list of items matching your query */
  data: Array<MissionUnion>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedMissionPerformanceAppraisals = {
   __typename?: 'PaginatedMissionPerformanceAppraisals';
  /** The list of items matching your query */
  data: Array<MissionPerformanceAppraisal>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedMissionRequests = {
   __typename?: 'PaginatedMissionRequests';
  /** The list of items matching your query */
  data: Array<MissionRequest>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedMissionScopeActivities = {
   __typename?: 'PaginatedMissionScopeActivities';
  /** The list of items matching your query */
  data: Array<MissionScopeActivity>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedMissionScopeStaff = {
   __typename?: 'PaginatedMissionScopeStaff';
  /** The list of items matching your query */
  data: Array<MissionScopeStaff>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedMissionStaffingApplications = {
   __typename?: 'PaginatedMissionStaffingApplications';
  /** The list of items matching your query */
  data: Array<MissionStaffingApplication>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedMissionStaffingPlacements = {
   __typename?: 'PaginatedMissionStaffingPlacements';
  /** The list of items matching your query */
  data: Array<MissionStaffingPlacement>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedMissionStaffingPositions = {
   __typename?: 'PaginatedMissionStaffingPositions';
  /** The list of items matching your query */
  data: Array<MissionStaffingPosition>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedMyMissions = {
   __typename?: 'PaginatedMyMissions';
  /** The list of items matching your query */
  data: Array<ConsultantMissionLifecycle>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedMyPerformanceAppraisals = {
   __typename?: 'PaginatedMyPerformanceAppraisals';
  /** The list of items matching your query */
  data: Array<MissionPerformanceAppraisal>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedOpenMissionStaffingPositions = {
   __typename?: 'PaginatedOpenMissionStaffingPositions';
  /** The list of items matching your query */
  data: Array<MissionStaffingPosition>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedPeralteeSupportServices = {
   __typename?: 'PaginatedPeralteeSupportServices';
  /** The list of items matching your query */
  data: Array<PeralteeSupportService>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedPermissions = {
   __typename?: 'PaginatedPermissions';
  /** The list of items matching your query */
  data: Array<Permission>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedRoles = {
   __typename?: 'PaginatedRoles';
  /** The list of items matching your query */
  data: Array<Role>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedSubscriptionUsers = {
   __typename?: 'PaginatedSubscriptionUsers';
  /** The list of items matching your query */
  data: Array<SubscriptionUser>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedSurveys = {
   __typename?: 'PaginatedSurveys';
  /** The list of items matching your query */
  data: Array<Survey>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedTalentPool = {
   __typename?: 'PaginatedTalentPool';
  /** The list of items matching your query */
  data: Array<ConsultantTalent>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedTrainingTags = {
   __typename?: 'PaginatedTrainingTags';
  /** The list of items matching your query */
  data: Array<TrainingTag>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginatedUsers = {
   __typename?: 'PaginatedUsers';
  /** The list of items matching your query */
  data: Array<UserAdvanced>;
  /** Pagination information */
  pagination: PaginationV2;
};

export type PaginationInput = {
  /** Page of data to return */
  page?: Maybe<Scalars['Int']>;
  /** Max number of items to return */
  per?: Maybe<Scalars['Int']>;
};

export type PaginationV2 = {
   __typename?: 'PaginationV2';
  /** Current page */
  currentPage: Scalars['Int'];
  /** Is this the first page? */
  firstPage: Scalars['Boolean'];
  /** Is this the last page? */
  lastPage: Scalars['Boolean'];
  /** Next page */
  nextPage?: Maybe<Scalars['Int']>;
  /** Number of pages available */
  pageCount?: Maybe<Scalars['Int']>;
  /** Previous page */
  prevPage?: Maybe<Scalars['Int']>;
  /** Total size of items that match this query */
  totalSize: Scalars['Int'];
};

export enum PartnerSource {
  /** Portfolio company of partner */
  PortfolioCompanyOfPartner = 'portfolio_company_of_partner',
  /** Employee of partner */
  EmployeeOfPartner = 'employee_of_partner',
  /** Contractor of partner */
  ContractorOfPartner = 'contractor_of_partner',
  /** Other */
  Other = 'other'
}

/** Autogenerated return type of PauseMission */
export type PauseMissionPayload = {
   __typename?: 'PauseMissionPayload';
  error?: Maybe<ErrorV2>;
};

export type PaymentMethod = Card;

export enum PaymentStateEnum {
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Paid = 'PAID'
}

export type PeralteeSupportService = {
   __typename?: 'PeralteeSupportService';
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  id: Scalars['BigInt'];
  name: Scalars['String'];
  points: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type PeralteeSupportServicesFilter = {
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  or?: Maybe<Array<PeralteeSupportServicesFilter>>;
  search?: Maybe<Scalars['String']>;
};

export type PeralteeSupportServicesSortInput = {
  createdAt?: Maybe<SortDirection>;
  id?: Maybe<SortDirection>;
};

export type Permission = {
   __typename?: 'Permission';
  description: Scalars['String'];
  id: Scalars['BigInt'];
  name: Scalars['String'];
  slug: PermissionEnum;
};

export enum PermissionEnum {
  AddClient = 'add_client',
  AddConsultantTrainingTag = 'add_consultant_training_tag',
  AddMission = 'add_mission',
  AddMissionProposal = 'add_mission_proposal',
  AddMissionScope = 'add_mission_scope',
  AddRole = 'add_role',
  AddStaff = 'add_staff',
  AddTeamMember = 'add_team_member',
  AddTrainingTag = 'add_training_tag',
  AddUser = 'add_user',
  Admin = 'admin',
  ApproveApplication = 'approve_application',
  ApproveConsultantApplication = 'approve_consultant_application',
  ApproveConsultantContract = 'approve_consultant_contract',
  ApproveConsultantPeralteeClaims = 'approve_consultant_peraltee_claims',
  ApproveLead = 'approve_lead',
  ApproveMissionPricingProposal = 'approve_mission_pricing_proposal',
  ApprovePricingProposal = 'approve_pricing_proposal',
  ApproveStaffingApplication = 'approve_staffing_application',
  ArchiveApplication = 'archive_application',
  ArchiveMission = 'archive_mission',
  BizdevTeam = 'bizdev_team',
  ClientFullAccess = 'client_full_access',
  CommenceMissionContracting = 'commence_mission_contracting',
  CommenceMissionStaffing = 'commence_mission_staffing',
  ContractPaymentsFullAccess = 'contract_payments_full_access',
  CoreTeam = 'core_team',
  CreateClient = 'create_client',
  CreateConsultantContracts = 'create_consultant_contracts',
  CreateContractPayments = 'create_contract_payments',
  CreateExecutionStaffingPosition = 'create_execution_staffing_position',
  CreateMissionContracts = 'create_mission_contracts',
  CreateMissionLead = 'create_mission_lead',
  CreateProposalStaffingPosition = 'create_proposal_staffing_position',
  CreateStaffingPosition = 'create_staffing_position',
  CreateSurvey = 'create_survey',
  DeleteMissionLead = 'delete_mission_lead',
  DeleteSurvey = 'delete_survey',
  DeleteTechnicalProposal = 'delete_technical_proposal',
  EditConsultantContracts = 'edit_consultant_contracts',
  EditMissionContractDeliverable = 'edit_mission_contract_deliverable',
  EditMissionContracts = 'edit_mission_contracts',
  EditMissionPricingProposal = 'edit_mission_pricing_proposal',
  EditMissionProposal = 'edit_mission_proposal',
  EditMissionScope = 'edit_mission_scope',
  EditStaffingPosition = 'edit_staffing_position',
  EditTalent = 'edit_talent',
  FinanceHead = 'finance_head',
  FinanceTeam = 'finance_team',
  Foresighter = 'foresighter',
  GradeConsultantAssessment = 'grade_consultant_assessment',
  GrowthTeam = 'growth_team',
  InitiateMissionProposal = 'initiate_mission_proposal',
  ManageApplicants = 'manage_applicants',
  ManageBrandResources = 'manage_brand_resources',
  ManageMissionExecutive = 'manage_mission_executive',
  ManageMissionManager = 'manage_mission_manager',
  ManageMissionPartner = 'manage_mission_partner',
  ManageScopeLibrary = 'manage_scope_library',
  ManageClientAdmin = 'manage_client_admin',
  MissionScopeFullAccess = 'mission_scope_full_access',
  ManageSubscriptionUserInvitation = 'manage_subscription_user_invitation',
  ManageSurvey = 'manage_survey',
  ManageSurveyAssignment = 'manage_survey_assignment',
  ManageUserInvitation = 'manage_user_invitation',
  MarkConsultantInvoiceAsPaid = 'mark_consultant_invoice_as_paid',
  MissionDocumentFullAccess = 'mission_document_full_access',
  MissionExecutives = 'mission_executives',
  MissionLifecycleFullAccess = 'mission_lifecycle_full_access',
  MissionManager = 'mission_manager',
  MissionRequestFullAccess = 'mission_request_full_access',
  MissionStaffingPositionFullAccess = 'mission_staffing_position_full_access',
  ModifyConsultantTalent = 'modify_consultant_talent',
  MutateClient = 'mutate_client',
  MutateLead = 'mutate_lead',
  MutateMissionScope = 'mutate_mission_scope',
  MutateMissionScopeActivity = 'mutate_mission_scope_activity',
  MutateMissionScopePhase = 'mutate_mission_scope_phase',
  MutateMissionScopeStaff = 'mutate_mission_scope_staff',
  MutateMissionStaffingPlacement = 'mutate_mission_staffing_placement',
  MutateStaffPosition = 'mutate_staff_position',
  OperationsHead = 'operations_head',
  OperationsTeam = 'operations_team',
  Partners = 'partners',
  ProceedConsultantApplication = 'proceed_consultant_application',
  ProgressMissionLifecycle = 'progress_mission_lifecycle',
  QueueConsultantInvoice = 'queue_consultant_invoice',
  ReceiveBrandResourceEnquiry = 'receive_brand_resource_enquiry',
  ReceiveCxNotifications = 'receive_cx_notifications',
  ReceiveInvoiceReadyNotifications = 'receive_invoice_ready_notifications',
  ReceiveMissionCreationNotifications = 'receive_mission_creation_notifications',
  ReceivePublishedPositionNotifications = 'receive_published_position_notifications',
  ReceiveStaffingPlacementNotifications = 'receive_staffing_placement_notifications',
  ReceiveSubscriptionNotifications = 'receive_subscription_notifications',
  RejectApplication = 'reject_application',
  RejectConsultantApplication = 'reject_consultant_application',
  RejectConsultantPeralteeClaims = 'reject_consultant_peraltee_claims',
  RejectStaffingApplication = 'reject_staffing_application',
  RemoveActivity = 'remove_activity',
  RemoveStaff = 'remove_staff',
  RequestConsultantContractApproval = 'request_consultant_contract_approval',
  RequestEsignature = 'request_esignature',
  ScopeLibraryFullAccess = 'scope_library_full_access',
  SetMissionClient = 'set_mission_client',
  SetMissionName = 'set_mission_name',
  ShortlistApplicant = 'shortlist_applicant',
  ShortlistStaffingApplication = 'shortlist_staffing_application',
  SignEngagementLetter = 'sign_engagement_letter',
  StartMissionScopeDevelopment = 'start_mission_scope_development',
  TalentHead = 'talent_head',
  TalentTeam = 'talent_team',
  UpdateActivity = 'update_activity',
  UpdateRole = 'update_role',
  ViewAppConfigs = 'view_app_configs',
  ViewApplicants = 'view_applicants',
  ViewAppraisals = 'view_appraisals',
  ViewAppraisalsSchedules = 'view_appraisals_schedules',
  ViewAssessmentQuestions = 'view_assessment_questions',
  ViewClientInvoices = 'view_client_invoices',
  ViewClientTypes = 'view_client_types',
  ViewClients = 'view_clients',
  ViewConsultantContracts = 'view_consultant_contracts',
  ViewConsultantInvoices = 'view_consultant_invoices',
  ViewConsultantPeralteeClaims = 'view_consultant_peraltee_claims',
  ViewConsultants = 'view_consultants',
  ViewCountries = 'view_countries',
  ViewJobTitles = 'view_job_titles',
  ViewMissionContract = 'view_mission_contract',
  ViewMissionDetails = 'view_mission_details',
  ViewMissionFinances = 'view_mission_finances',
  ViewMissionPricingProposal = 'view_mission_pricing_proposal',
  ViewMissionProposal = 'view_mission_proposal',
  ViewMissionProposalStaff = 'view_mission_proposal_staff',
  ViewMissionScope = 'view_mission_scope',
  ViewMissionSettings = 'view_mission_settings',
  ViewMissionStaffingApplications = 'view_mission_staffing_applications',
  ViewMissionStaffingPlacements = 'view_mission_staffing_placements',
  ViewMissionStaffingPositions = 'view_mission_staffing_positions',
  ViewMissions = 'view_missions',
  ViewPerformanceData = 'view_performance_data',
  ViewPermissions = 'view_permissions',
  ViewRoles = 'view_roles',
  ViewScopedMissions = 'view_scoped_missions',
  ViewSubscriptionUsers = 'view_subscription_users',
  ViewSurveyScore = 'view_survey_score',
  ViewTasks = 'view_tasks',
  ViewTechnicalProposal = 'view_technical_proposal',
  ViewUsers = 'view_users'
}

export type PermissionsSortInput = {
  name?: Maybe<SortDirection>;
  description?: Maybe<SortDirection>;
  slug?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type PhoneInput = {
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type PracticeGroup = {
   __typename?: 'PracticeGroup';
  id: Scalars['Int'];
  name: Scalars['String'];
  subGroups: Array<SubPracticeGroup>;
};

export type PracticeGroupsSortInput = {
  name?: Maybe<SortDirection>;
};

export type PresignedUrl = {
   __typename?: 'PresignedUrl';
  key: Scalars['String'];
  url: Scalars['String'];
};

/** Autogenerated return type of ProceedApplication */
export type ProceedApplicationPayload = {
   __typename?: 'ProceedApplicationPayload';
  error?: Maybe<ErrorV2>;
};

export type Product = {
   __typename?: 'Product';
  available: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  prn: Scalars['String'];
  scatterId: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ProductPlan = {
   __typename?: 'ProductPlan';
  allowedSeats?: Maybe<Scalars['Int']>;
  available: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  feesCurrency: Scalars['String'];
  feesSubunit: Scalars['Int'];
  id: Scalars['BigInt'];
  maximumSeats: Scalars['Int'];
  minimumSeats: Scalars['Int'];
  name: Scalars['String'];
  prn: Scalars['String'];
  productSlug: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  subscriptionPeriod: SubscriptionPeriodTypeEnum;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum ProductSlugEnum {
  /** Peralta */
  Peralta = 'peralta',
  /** Ahead Business */
  AheadBusiness = 'ahead_business'
}

export type PromoCode = {
   __typename?: 'PromoCode';
  code: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  freeTrial: Scalars['Boolean'];
  id: Scalars['BigInt'];
  offers: Array<Scalars['String']>;
  plan?: Maybe<ProductPlan>;
  prn: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type PromoCodeInput = {
  code: Scalars['String'];
  partnerSource?: Maybe<PartnerSource>;
};

export type Proposal = {
   __typename?: 'Proposal';
  id: Scalars['Int'];
  keywords: Scalars['String'];
  lead: Lead;
  mission?: Maybe<Mission>;
  name: Scalars['String'];
  proposalChecklistItems: Array<ProposalChecklistItem>;
  proposalFiles: Array<ProposalFile>;
};

export type ProposalChecklistItem = {
   __typename?: 'ProposalChecklistItem';
  deadline?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['Int'];
  state: Scalars['String'];
  task: Scalars['String'];
};

export type ProposalFile = {
   __typename?: 'ProposalFile';
  id: Scalars['Int'];
  publicId: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type PublicScopeService = {
   __typename?: 'PublicScopeService';
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  id: Scalars['BigInt'];
  name: Scalars['String'];
  prn: Scalars['String'];
  serviceGroup: ScopeServiceGroup;
  tags: Array<ScopeTag>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type PublicScopeServicesFilter = {
  id?: Maybe<Scalars['BigInt']>;
  tags?: Maybe<Array<Scalars['String']>>;
  or?: Maybe<Array<PublicScopeServicesFilter>>;
};

export type PublicSurveyFilterInput = {
  id?: Maybe<Scalars['BigInt']>;
  or?: Maybe<Array<PublicSurveyFilterInput>>;
};

export type PublicSurveysSortInput = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

/** Autogenerated return type of PublishMissionStaffingPosition */
export type PublishMissionStaffingPositionPayload = {
   __typename?: 'PublishMissionStaffingPositionPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of PublishSurvey */
export type PublishSurveyPayload = {
   __typename?: 'PublishSurveyPayload';
  error?: Maybe<ErrorV2>;
  survey?: Maybe<Survey>;
};

export type Quarter = {
   __typename?: 'Quarter';
  id: Scalars['Int'];
  missions: Array<Mission>;
  missionsCount: Scalars['Int'];
  missionsTarget: Scalars['Int'];
  name: Scalars['String'];
};

export type Query = {
   __typename?: 'Query';
  appConfigs: PaginatedAppConfigs;
  applicants: PaginatedApplicants;
  applicationTrack?: Maybe<ApplicationTrack>;
  assessments: Array<Assessment>;
  availableMissionScopeServices: Array<ScopeService>;
  bankAccounts: PaginatedBankAccounts;
  brandResourceCollections?: Maybe<PaginatedBrandResourceCollections>;
  brandResourceLabels?: Maybe<PaginatedBrandResourceLabels>;
  clientInvoices: PaginatedClientInvoices;
  clientMissionRequests?: Maybe<PaginatedClientMissionRequests>;
  clientMissions?: Maybe<PaginatedClientMissions>;
  clientTypes: Array<ClientType>;
  clients: PaginatedClients;
  companies: PaginatedCompanies;
  consultantAssessment: ConsultantAssessment;
  consultantContracts: PaginatedConsultantContracts;
  consultantInvoices: PaginatedConsultantInvoices;
  consultantPeralteeClaims: PaginatedConsultantPeralteeClaims;
  consultantPeralteeClaimsStatistics: ConsultantPeralteeClaimsStatistics;
  consultantProfile?: Maybe<ConsultantProfile>;
  consultants: PaginatedConsultants;
  contractingCompanies: Array<ContractingCompany>;
  conversations?: Maybe<Array<Maybe<Conversation>>>;
  countries: PaginatedCountries;
  currencies: Array<Currency>;
  financeStatistics?: Maybe<Array<FinanceDashboardStatistics>>;
  findApplicant?: Maybe<ApplicantBasic>;
  findClient?: Maybe<Client>;
  findConsultant: Consultant;
  findConversation?: Maybe<Conversation>;
  findInvitation?: Maybe<Invitation>;
  findProductPlans?: Maybe<Array<ProductPlan>>;
  findPromoCode?: Maybe<PromoCode>;
  findUser: Array<User>;
  institutions: PaginatedInstitutions;
  jobApplicationDeadlines: Array<JobApplicationDeadline>;
  jobTitles: Array<JobTitle>;
  languages: PaginatedLanguages;
  leadGrades: Array<LeadGrade>;
  leadSources: Array<LeadSource>;
  meApplicant?: Maybe<Applicant>;
  meUser?: Maybe<UserAdvanced>;
  missionDashboardStatistics: Array<MissionStats>;
  missionDocuments?: Maybe<PaginatedMissionDocuments>;
  missionLifecycles: PaginatedMissionLifecycles;
  missionNatures: Array<MissionNature>;
  missionPerformanceAppraisalSchedules?: Maybe<MissionPerformanceAppraisalScheduleData>;
  missionPerformanceAppraisals: PaginatedMissionPerformanceAppraisals;
  missionProblemStatements?: Maybe<Array<MissionProblemStatement>>;
  missionRequests?: Maybe<PaginatedMissionRequests>;
  missionScopeActivities: PaginatedMissionScopeActivities;
  missionScopeStaff: PaginatedMissionScopeStaff;
  missionStaffingApplications: PaginatedMissionStaffingApplications;
  missionStaffingPlacements: PaginatedMissionStaffingPlacements;
  missionStaffingPositions: PaginatedMissionStaffingPositions;
  myClient?: Maybe<Client>;
  myMissions: PaginatedMyMissions;
  myPerformanceAppraisals: PaginatedMyPerformanceAppraisals;
  openMissionStaffingPositions: PaginatedOpenMissionStaffingPositions;
  peralteeSupportServices: PaginatedPeralteeSupportServices;
  permissions: PaginatedPermissions;
  practiceGroups: Array<PracticeGroup>;
  profile: User;
  publicScopeServices?: Maybe<Array<PublicScopeService>>;
  publicSurveys?: Maybe<Array<Survey>>;
  publishedMissions: Array<Mission>;
  quarters: Array<Quarter>;
  regions: Array<Region>;
  roles: PaginatedRoles;
  scopeLibraryPhases?: Maybe<Array<ScopeService>>;
  scopeServiceGroups?: Maybe<Array<ScopeServiceGroup>>;
  scopeServices?: Maybe<Array<ScopeService>>;
  scopeTags?: Maybe<Array<ScopeTag>>;
  subscriptionUsers: PaginatedSubscriptionUsers;
  surveyAssignment?: Maybe<SurveyAssignment>;
  surveys?: Maybe<PaginatedSurveys>;
  talentDashboardData: TalentDashboardStatistics;
  talentPool: PaginatedTalentPool;
  tasks: Array<Task>;
  trainingTags: PaginatedTrainingTags;
  unreadConversations?: Maybe<Array<Conversation>>;
  userSignatures?: Maybe<UserSignatures>;
  users: PaginatedUsers;
};


export type QueryAppConfigsArgs = {
  paginate?: Maybe<PaginationInput>;
  filter?: Maybe<AppConfigsFilter>;
};


export type QueryApplicantsArgs = {
  filter?: Maybe<ApplicantsFilter>;
  sort?: Maybe<ApplicantsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryApplicationTrackArgs = {
  id: Scalars['String'];
};


export type QueryAssessmentsArgs = {
  filter?: Maybe<AssessmentsFilter>;
};


export type QueryBankAccountsArgs = {
  filter?: Maybe<BankAccountsFilter>;
  sort?: Maybe<BankAccountsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryBrandResourceCollectionsArgs = {
  filter?: Maybe<BrandResourceCollectionsFilter>;
  sort?: Maybe<BrandResourceCollectionsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryBrandResourceLabelsArgs = {
  filter?: Maybe<BrandResourceLabelsFilter>;
  sort?: Maybe<BrandResourceLabelsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryClientInvoicesArgs = {
  paginate?: Maybe<PaginationInput>;
  filter?: Maybe<ClientInvoicesFilter>;
  sort?: Maybe<ClientInvoicesSortInput>;
};


export type QueryClientMissionRequestsArgs = {
  filter?: Maybe<ClientMissionRequestFilter>;
  sort?: Maybe<ClientMissionRequestsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryClientMissionsArgs = {
  filter?: Maybe<ClientMissionsFilter>;
  sort?: Maybe<ClientMissionsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryClientsArgs = {
  filter?: Maybe<ClientsFilter>;
  sort?: Maybe<ClientsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryCompaniesArgs = {
  filter?: Maybe<CompaniesFilter>;
  sort?: Maybe<CompaniesSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryConsultantAssessmentArgs = {
  assessmentIdentifier?: Maybe<Scalars['String']>;
};


export type QueryConsultantContractsArgs = {
  scope?: Maybe<ConsultantContractScope>;
  filter?: Maybe<ConsultantContractsFilter>;
  sort?: Maybe<ConsultantContractsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryConsultantInvoicesArgs = {
  paginate?: Maybe<PaginationInput>;
  filter?: Maybe<ConsultantInvoicesFilter>;
  sort?: Maybe<ConsultantInvoicesSortInput>;
};


export type QueryConsultantPeralteeClaimsArgs = {
  filter?: Maybe<ConsultantPeralteeClaimsFilter>;
  sort?: Maybe<ConsultantPeralteeClaimsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryConsultantPeralteeClaimsStatisticsArgs = {
  consultantId?: Maybe<Scalars['BigInt']>;
  dateRange?: Maybe<DateFilterInput>;
};


export type QueryConsultantsArgs = {
  filter?: Maybe<ConsultantsFilter>;
  sort?: Maybe<ConsultantsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryConversationsArgs = {
  filter?: Maybe<ConversationFilterInput>;
  sort?: Maybe<ConversationsSortInput>;
};


export type QueryCountriesArgs = {
  filter?: Maybe<CountriesFilter>;
  sort?: Maybe<CountriesSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryFindApplicantArgs = {
  emailAddress: Scalars['String'];
};


export type QueryFindClientArgs = {
  id: Scalars['ID'];
};


export type QueryFindConsultantArgs = {
  id: Scalars['BigInt'];
};


export type QueryFindConversationArgs = {
  id: Scalars['ID'];
};


export type QueryFindInvitationArgs = {
  token: Scalars['String'];
};


export type QueryFindProductPlansArgs = {
  productSlug?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryFindPromoCodeArgs = {
  code: Scalars['String'];
};


export type QueryFindUserArgs = {
  onboardingIdentifier?: Maybe<Scalars['String']>;
  resetIdentifier?: Maybe<Scalars['String']>;
};


export type QueryInstitutionsArgs = {
  filter?: Maybe<InstitutionsFilter>;
  sort?: Maybe<InstitutionsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryJobTitlesArgs = {
  sort?: Maybe<JobTitlesSortInput>;
  filter?: Maybe<JobTitlesFilter>;
};


export type QueryLanguagesArgs = {
  filter?: Maybe<LanguagesFilter>;
  sort?: Maybe<LanguagesSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryLeadGradesArgs = {
  sort?: Maybe<LeadGradesSortInput>;
};


export type QueryLeadSourcesArgs = {
  sort?: Maybe<LeadSourcesSortInput>;
};


export type QueryMeApplicantArgs = {
  applicantId: Scalars['String'];
};


export type QueryMissionDocumentsArgs = {
  filter?: Maybe<MissionDocumentsFilter>;
  sort?: Maybe<MissionDocumentsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryMissionLifecyclesArgs = {
  filter?: Maybe<MissionLifecyclesFilter>;
  sort?: Maybe<MissionLifecyclesSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryMissionPerformanceAppraisalSchedulesArgs = {
  date?: Maybe<Scalars['ISO8601DateTime']>;
};


export type QueryMissionPerformanceAppraisalsArgs = {
  sort?: Maybe<MissionPerformanceAppraisalsSortInput>;
  filter?: Maybe<MissionPerformanceAppraisalsFilter>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryMissionRequestsArgs = {
  filter?: Maybe<MissionRequestsFilter>;
  sort?: Maybe<MissionRequestsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryMissionScopeActivitiesArgs = {
  filter?: Maybe<MissionScopeActivitiesFilter>;
  sort?: Maybe<MissionScopeActivitiesSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryMissionScopeStaffArgs = {
  filter?: Maybe<MissionScopeStaffsFilter>;
  sort?: Maybe<MissionScopeStaffSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryMissionStaffingApplicationsArgs = {
  filter?: Maybe<MissionStaffingApplicationsFilter>;
  sort?: Maybe<MissionStaffingApplicationsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryMissionStaffingPlacementsArgs = {
  filter?: Maybe<MissionStaffingPlacementsFilter>;
  sort?: Maybe<MissionStaffingPlacementsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryMissionStaffingPositionsArgs = {
  filter?: Maybe<MissionStaffingPositionsFilter>;
  sort?: Maybe<MissionStaffingPositionsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryMyClientArgs = {
  id: Scalars['ID'];
};


export type QueryMyMissionsArgs = {
  filter?: Maybe<MyMissionsFilter>;
  sort?: Maybe<MyMissionsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryMyPerformanceAppraisalsArgs = {
  sort?: Maybe<MyPerformanceAppraisalsSortInput>;
  filter?: Maybe<MyPerformanceAppraisalsFilter>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryOpenMissionStaffingPositionsArgs = {
  filter?: Maybe<OpenMissionStaffingPositionsFilter>;
  sort?: Maybe<OpenMissionStaffingPositionsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryPeralteeSupportServicesArgs = {
  filter?: Maybe<PeralteeSupportServicesFilter>;
  sort?: Maybe<PeralteeSupportServicesSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryPermissionsArgs = {
  sort?: Maybe<PermissionsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryPracticeGroupsArgs = {
  sort?: Maybe<PracticeGroupsSortInput>;
};


export type QueryPublicScopeServicesArgs = {
  filter?: Maybe<PublicScopeServicesFilter>;
};


export type QueryPublicSurveysArgs = {
  filter?: Maybe<PublicSurveyFilterInput>;
  sort?: Maybe<PublicSurveysSortInput>;
};


export type QueryRegionsArgs = {
  sort?: Maybe<RegionsSortInput>;
};


export type QueryRolesArgs = {
  filter?: Maybe<RolesFilter>;
  sort?: Maybe<RolesSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryScopeLibraryPhasesArgs = {
  filter?: Maybe<ScopeLibraryPhasesFilter>;
};


export type QueryScopeServicesArgs = {
  filter?: Maybe<ScopeServicesFilter>;
};


export type QueryScopeTagsArgs = {
  filter?: Maybe<ScopeTagsFilter>;
};


export type QuerySubscriptionUsersArgs = {
  filter?: Maybe<SubscriptionUserFilterInput>;
  sort?: Maybe<SubscriptionUsersSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QuerySurveyAssignmentArgs = {
  id: Scalars['BigInt'];
};


export type QuerySurveysArgs = {
  filter?: Maybe<SurveyFilterInput>;
  sort?: Maybe<SurveysSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryTalentPoolArgs = {
  filter?: Maybe<TalentPoolFilter>;
  sort?: Maybe<TalentPoolSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryTasksArgs = {
  filter?: Maybe<TasksFilter>;
};


export type QueryTrainingTagsArgs = {
  sort?: Maybe<TrainingTagsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type QueryUnreadConversationsArgs = {
  clientId?: Maybe<Scalars['ID']>;
};


export type QueryUserSignaturesArgs = {
  filter?: Maybe<SignatureFilter>;
};


export type QueryUsersArgs = {
  paginate?: Maybe<PaginationInput>;
  filter?: Maybe<UsersFilter>;
  sort?: Maybe<UsersSortInput>;
};

export type Question = {
   __typename?: 'Question';
  followUp?: Maybe<Question>;
  id: Scalars['Int'];
  identifier: Scalars['String'];
  label: Scalars['String'];
  metadata: Scalars['JSON'];
  type: Scalars['String'];
};

/** Autogenerated return type of QueueInvoiceForPayment */
export type QueueInvoiceForPaymentPayload = {
   __typename?: 'QueueInvoiceForPaymentPayload';
  consultantInvoice?: Maybe<ConsultantInvoice>;
  error?: Maybe<ErrorV2>;
};

export type Region = {
   __typename?: 'Region';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type RegionsSortInput = {
  name?: Maybe<SortDirection>;
};

/** Autogenerated return type of RejectApplication */
export type RejectApplicationPayload = {
   __typename?: 'RejectApplicationPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RejectApproval */
export type RejectApprovalPayload = {
   __typename?: 'RejectApprovalPayload';
  approval?: Maybe<Approval>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RejectConsultantPeralteeClaim */
export type RejectConsultantPeralteeClaimPayload = {
   __typename?: 'RejectConsultantPeralteeClaimPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RejectMissionLead */
export type RejectMissionLeadPayload = {
   __typename?: 'RejectMissionLeadPayload';
  error?: Maybe<ErrorV2>;
  missionLifecycle?: Maybe<MissionLifecycle>;
};

/** Autogenerated return type of RejectMissionRequest */
export type RejectMissionRequestPayload = {
   __typename?: 'RejectMissionRequestPayload';
  error?: Maybe<ErrorV2>;
  missionRequest?: Maybe<MissionRequest>;
};

/** Autogenerated return type of RejectMissionStaffingApplication */
export type RejectMissionStaffingApplicationPayload = {
   __typename?: 'RejectMissionStaffingApplicationPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RejectTask */
export type RejectTaskPayload = {
   __typename?: 'RejectTaskPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RemoveClientAdminAccess */
export type RemoveClientAdminAccessPayload = {
   __typename?: 'RemoveClientAdminAccessPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RemoveMissionPartner */
export type RemoveMissionPartnerPayload = {
   __typename?: 'RemoveMissionPartnerPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RemoveMissionPrincipal */
export type RemoveMissionPrincipalPayload = {
   __typename?: 'RemoveMissionPrincipalPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RemoveMissionTeamLead */
export type RemoveMissionTeamLeadPayload = {
   __typename?: 'RemoveMissionTeamLeadPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ReopenConversation */
export type ReopenConversationPayload = {
   __typename?: 'ReopenConversationPayload';
  conversation?: Maybe<Conversation>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RequestApplicantPasswordReset */
export type RequestApplicantPasswordResetPayload = {
   __typename?: 'RequestApplicantPasswordResetPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RequestApprovalMissionPricingProposal */
export type RequestApprovalMissionPricingProposalPayload = {
   __typename?: 'RequestApprovalMissionPricingProposalPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RequestConsultantContractApproval */
export type RequestConsultantContractApprovalPayload = {
   __typename?: 'RequestConsultantContractApprovalPayload';
  consultantContract?: Maybe<ConsultantContract>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RequestConsultantPeralteeClaim */
export type RequestConsultantPeralteeClaimPayload = {
   __typename?: 'RequestConsultantPeralteeClaimPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RequestEsignature */
export type RequestEsignaturePayload = {
   __typename?: 'RequestEsignaturePayload';
  error?: Maybe<ErrorV2>;
  esignatureRequest?: Maybe<Array<EsignatureRequest>>;
};

/** Autogenerated return type of RequestMissionExecutive */
export type RequestMissionExecutivePayload = {
   __typename?: 'RequestMissionExecutivePayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RequestMissionPartner */
export type RequestMissionPartnerPayload = {
   __typename?: 'RequestMissionPartnerPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RequestMission */
export type RequestMissionPayload = {
   __typename?: 'RequestMissionPayload';
  error?: Maybe<ErrorV2>;
  missionRequest?: Maybe<MissionRequest>;
};

/** Autogenerated return type of RequestMissionProposalApproval */
export type RequestMissionProposalApprovalPayload = {
   __typename?: 'RequestMissionProposalApprovalPayload';
  error?: Maybe<ErrorV2>;
  missionLifecycle?: Maybe<MissionLifecycle>;
};

/** Autogenerated return type of RequestPasswordReset */
export type RequestPasswordResetPayload = {
   __typename?: 'RequestPasswordResetPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RequestTerminationConsultantContract */
export type RequestTerminationConsultantContractPayload = {
   __typename?: 'RequestTerminationConsultantContractPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ResendEmailConfirmation */
export type ResendEmailConfirmationPayload = {
   __typename?: 'ResendEmailConfirmationPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ResendInvitation */
export type ResendInvitationPayload = {
   __typename?: 'ResendInvitationPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ResetApplicantPassword */
export type ResetApplicantPasswordPayload = {
   __typename?: 'ResetApplicantPasswordPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ResetPassword */
export type ResetPasswordPayload = {
   __typename?: 'ResetPasswordPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ResolveConversation */
export type ResolveConversationPayload = {
   __typename?: 'ResolveConversationPayload';
  conversation?: Maybe<Conversation>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ResolveDocComment */
export type ResolveDocCommentPayload = {
   __typename?: 'ResolveDocCommentPayload';
  comment?: Maybe<DocComment>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ResolveTask */
export type ResolveTaskPayload = {
   __typename?: 'ResolveTaskPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RespondToSurvey */
export type RespondToSurveyPayload = {
   __typename?: 'RespondToSurveyPayload';
  error?: Maybe<ErrorV2>;
  publicResponse?: Maybe<SurveyPublicResponse>;
};

/** Autogenerated return type of RestoreMissionStaffingPlacement */
export type RestoreMissionStaffingPlacementPayload = {
   __typename?: 'RestoreMissionStaffingPlacementPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ResumeMission */
export type ResumeMissionPayload = {
   __typename?: 'ResumeMissionPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of RevokeInvitation */
export type RevokeInvitationPayload = {
   __typename?: 'RevokeInvitationPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ReworkMissionPricingProposal */
export type ReworkMissionPricingProposalPayload = {
   __typename?: 'ReworkMissionPricingProposalPayload';
  error?: Maybe<ErrorV2>;
  missionPricingProposal?: Maybe<MissionPricingProposal>;
};

export type Role = {
   __typename?: 'Role';
  active: Scalars['Boolean'];
  description: Scalars['String'];
  id: Scalars['BigInt'];
  name: Scalars['String'];
  permissions: Array<Permission>;
};

export type RolesFilter = {
  active?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  or?: Maybe<Array<RolesFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
};

export type RolesSortInput = {
  name?: Maybe<SortDirection>;
  description?: Maybe<SortDirection>;
  active?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

/** Autogenerated return type of SaveApplication */
export type SaveApplicationPayload = {
   __typename?: 'SaveApplicationPayload';
  application?: Maybe<ConsultantApplication>;
  error?: Maybe<ErrorV2>;
  surveyResponse?: Maybe<SurveyResponse>;
};

export type ScopeLibraryActivity = {
   __typename?: 'ScopeLibraryActivity';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['BigInt'];
  name: Scalars['String'];
  phase: ScopeLibraryPhase;
  position: Scalars['BigInt'];
  prn: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ScopeLibraryPhase = {
   __typename?: 'ScopeLibraryPhase';
  activities: Array<ScopeLibraryActivity>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['BigInt'];
  name: Scalars['String'];
  prn: Scalars['String'];
  subscriptionCount: Scalars['BigInt'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ScopeLibraryPhasesFilter = {
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  or?: Maybe<Array<ScopeLibraryPhasesFilter>>;
};

export type ScopePhaseSubscription = {
   __typename?: 'ScopePhaseSubscription';
  activities: Array<ScopeLibraryActivity>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['BigInt'];
  phase: ScopeLibraryPhase;
  position: Scalars['BigInt'];
  prn: Scalars['String'];
  scopeService: ScopeService;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ScopeService = {
   __typename?: 'ScopeService';
  availableForMissions: Scalars['Boolean'];
  averageDuration?: Maybe<Scalars['Int']>;
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  phaseSubscriptions: Array<ScopePhaseSubscription>;
  phases: Array<ScopeLibraryPhase>;
  priceMax?: Maybe<Money>;
  priceMin?: Maybe<Money>;
  prn: Scalars['String'];
  scatterId: Scalars['String'];
  serviceGroup: ScopeServiceGroup;
  tags: Array<ScopeTag>;
  teamRoles: Array<ScopeServiceTeamRole>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ScopeServiceGroup = {
   __typename?: 'ScopeServiceGroup';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  prn: Scalars['String'];
  services: Array<ScopeService>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ScopeServiceInput = {
  availableForMissions: Scalars['Boolean'];
  averageDuration?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priceMax?: Maybe<MoneyInput>;
  priceMin?: Maybe<MoneyInput>;
  scopeServiceGroupId?: Maybe<Scalars['BigInt']>;
};

export type ScopeServiceTag = {
   __typename?: 'ScopeServiceTag';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['BigInt'];
  prn: Scalars['String'];
  scopeService: ScopeService;
  scopeTag: ScopeTag;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ScopeServiceTeamRole = {
   __typename?: 'ScopeServiceTeamRole';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['String'];
  prn: Scalars['String'];
  quantity: Scalars['Int'];
  role: JobTitle;
  scatterId: Scalars['String'];
  scopeService: ScopeService;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ScopeServiceTeamRoleInput = {
  quantity: Scalars['Int'];
  roleId: Scalars['BigInt'];
};

export type ScopeServicesFilter = {
  id?: Maybe<Scalars['ID']>;
  tags?: Maybe<Array<Scalars['String']>>;
  or?: Maybe<Array<ScopeServicesFilter>>;
};

export type ScopeTag = {
   __typename?: 'ScopeTag';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['BigInt'];
  name: Scalars['String'];
  prn: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ScopeTagsFilter = {
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  or?: Maybe<Array<ScopeTagsFilter>>;
};

export type ScriptSignatureSourceInput = {
  key: Scalars['String'];
};

/** Autogenerated return type of SendBrandResourceEnquiry */
export type SendBrandResourceEnquiryPayload = {
   __typename?: 'SendBrandResourceEnquiryPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of SetConsultantTrainingTags */
export type SetConsultantTrainingTagsPayload = {
   __typename?: 'SetConsultantTrainingTagsPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of SetMissionClient */
export type SetMissionClientPayload = {
   __typename?: 'SetMissionClientPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of SetMissionEngagementLetter */
export type SetMissionEngagementLetterPayload = {
   __typename?: 'SetMissionEngagementLetterPayload';
  error?: Maybe<ErrorV2>;
  missionLifecycle?: Maybe<MissionLifecycle>;
};

/** Autogenerated return type of SetMissionName */
export type SetMissionNamePayload = {
   __typename?: 'SetMissionNamePayload';
  error?: Maybe<ErrorV2>;
  missionLifecycle: MissionLifecycle;
};

/** Autogenerated return type of SetMissionSignedContract */
export type SetMissionSignedContractPayload = {
   __typename?: 'SetMissionSignedContractPayload';
  error?: Maybe<ErrorV2>;
  missionLifecycle?: Maybe<MissionLifecycle>;
};

/** Autogenerated return type of SetMissionTags */
export type SetMissionTagsPayload = {
   __typename?: 'SetMissionTagsPayload';
  error?: Maybe<ErrorV2>;
  mission?: Maybe<MissionLifecycle>;
};

/** Autogenerated return type of SetMissionTechnicalProposal */
export type SetMissionTechnicalProposalPayload = {
   __typename?: 'SetMissionTechnicalProposalPayload';
  error?: Maybe<ErrorV2>;
  missionLifecycle?: Maybe<MissionLifecycle>;
};

/** Autogenerated return type of SetTermsOfReference */
export type SetTermsOfReferencePayload = {
   __typename?: 'SetTermsOfReferencePayload';
  error?: Maybe<ErrorV2>;
  missionLifecycle?: Maybe<MissionLifecycle>;
};

/** Autogenerated return type of ShortlistApplicant */
export type ShortlistApplicantPayload = {
   __typename?: 'ShortlistApplicantPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of ShortlistMissionStaffingApplication */
export type ShortlistMissionStaffingApplicationPayload = {
   __typename?: 'ShortlistMissionStaffingApplicationPayload';
  error?: Maybe<ErrorV2>;
  missionStaffingApplication?: Maybe<MissionStaffingApplication>;
};

/** Autogenerated return type of SignConsultantContractDocument */
export type SignConsultantContractDocumentPayload = {
   __typename?: 'SignConsultantContractDocumentPayload';
  consultantContract?: Maybe<ConsultantContract>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of SignMissionContract */
export type SignMissionContractPayload = {
   __typename?: 'SignMissionContractPayload';
  error?: Maybe<ErrorV2>;
  missionContract?: Maybe<MissionContract>;
};

export type SignatureFilter = {
  id?: Maybe<Scalars['BigInt']>;
  or?: Maybe<Array<SignatureFilter>>;
};

export type SignatureSource = {
   __typename?: 'SignatureSource';
  createdAt: Scalars['ISO8601DateTime'];
  deleteable: Scalars['Boolean'];
  id: Scalars['BigInt'];
  imageUrl?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  prn: Scalars['String'];
  type: SignatureSourceTypeEnum;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

export type SignatureSourceInput = {
  file?: Maybe<FileSignatureSourceInput>;
  script?: Maybe<ScriptSignatureSourceInput>;
};

export enum SignatureSourceTypeEnum {
  FileSignatureSource = 'FileSignatureSource',
  ScriptSignatureSource = 'ScriptSignatureSource'
}

export enum SortDirection {
  /** Sort in ascending order */
  Asc = 'asc',
  /** Sort in descending order */
  Desc = 'desc'
}

export type StaffingPositionDeliverable = {
   __typename?: 'StaffingPositionDeliverable';
  completed: Scalars['Boolean'];
  id: Scalars['BigInt'];
  missionScopeActivity?: Maybe<MissionScopeActivity>;
  missionStaffingPosition: MissionStaffingPosition;
  name: Scalars['String'];
};

export type StaffingPositionDeliverableInput = {
  missionScopeActivityId: Scalars['ID'];
};

/** Autogenerated return type of StartApplication */
export type StartApplicationPayload = {
   __typename?: 'StartApplicationPayload';
  application?: Maybe<ConsultantApplication>;
  completed?: Maybe<Scalars['Boolean']>;
  error?: Maybe<ErrorV2>;
  surveyResponse?: Maybe<SurveyResponse>;
};

/** Autogenerated return type of StartAssessment */
export type StartAssessmentPayload = {
   __typename?: 'StartAssessmentPayload';
  applicant?: Maybe<Applicant>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of StartConversation */
export type StartConversationPayload = {
   __typename?: 'StartConversationPayload';
  conversation?: Maybe<Conversation>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of StartMissionScopeDevelopment */
export type StartMissionScopeDevelopmentPayload = {
   __typename?: 'StartMissionScopeDevelopmentPayload';
  error?: Maybe<ErrorV2>;
  missionScope?: Maybe<MissionScope>;
};

/** Autogenerated return type of StartPricingProposalDevelopment */
export type StartPricingProposalDevelopmentPayload = {
   __typename?: 'StartPricingProposalDevelopmentPayload';
  error?: Maybe<ErrorV2>;
  missionPricingProposal?: Maybe<MissionPricingProposal>;
};

/** Autogenerated return type of StartRfpDevelopment */
export type StartRfpDevelopmentPayload = {
   __typename?: 'StartRfpDevelopmentPayload';
  error?: Maybe<ErrorV2>;
  mission?: Maybe<MissionLifecycle>;
};

export type StateEventPair = {
   __typename?: 'StateEventPair';
  event: Scalars['String'];
  state: Scalars['String'];
};

export type SubPracticeGroup = {
   __typename?: 'SubPracticeGroup';
  id: Scalars['Int'];
  name: Scalars['String'];
  practiceGroup: PracticeGroup;
};

/** Autogenerated return type of SubmitApplication */
export type SubmitApplicationPayload = {
   __typename?: 'SubmitApplicationPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of SubmitConsultantInvoice */
export type SubmitConsultantInvoicePayload = {
   __typename?: 'SubmitConsultantInvoicePayload';
  consultantInvoice?: Maybe<ConsultantInvoice>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of SubmitSurveyResponse */
export type SubmitSurveyResponsePayload = {
   __typename?: 'SubmitSurveyResponsePayload';
  error?: Maybe<ErrorV2>;
};

export type Subscription = {
   __typename?: 'Subscription';
  cohortPeriod?: Maybe<CohortPeriodTypeEnum>;
  createdAt: Scalars['ISO8601DateTime'];
  expiredAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['BigInt'];
  invitations: Array<Invitation>;
  lastBillingAt?: Maybe<Scalars['ISO8601DateTime']>;
  nextBillingAt?: Maybe<Scalars['ISO8601DateTime']>;
  ownerId: Scalars['Int'];
  ownerType: Scalars['String'];
  paymentMethod?: Maybe<PaymentMethod>;
  prn: Scalars['String'];
  product: Product;
  productPlan: ProductPlan;
  remainingSeats: Scalars['Int'];
  seatsUsageCount: Scalars['Int'];
  state: Scalars['String'];
  subscriptionPeriod: SubscriptionPeriodTypeEnum;
  subscriptionUsers: Array<SubscriptionUser>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type SubscriptionInput = {
  cohortPeriod: CohortPeriodTypeEnum;
  productPlanId: Scalars['ID'];
  promoCode?: Maybe<Scalars['String']>;
  seatsUsageCount: Scalars['Int'];
};

export enum SubscriptionPeriodTypeEnum {
  /** Monthly */
  Monthly = 'monthly',
  /** One off */
  OneOff = 'one_off'
}

export type SubscriptionUser = {
   __typename?: 'SubscriptionUser';
  active: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  prn: Scalars['String'];
  scatterId: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type SubscriptionUserFilterInput = {
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  subscriptionId?: Maybe<Scalars['BigInt']>;
  or?: Maybe<Array<SubscriptionUserFilterInput>>;
  search?: Maybe<Scalars['String']>;
};

export type SubscriptionUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type SubscriptionUsersSortInput = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type Survey = {
   __typename?: 'Survey';
  activityLogs: Array<ActivityLog>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  prn: Scalars['String'];
  public: Scalars['Boolean'];
  questions: Array<SurveyQuestion>;
  responses?: Maybe<Array<SurveyResponse>>;
  scatterId: Scalars['String'];
  slug: Scalars['String'];
  state: SurveyStateEnum;
  surveySections: Array<SurveySection>;
  surveyType?: Maybe<Scalars['String']>;
  surveyableId: Scalars['BigInt'];
  surveyableType: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type SurveyAssignment = {
   __typename?: 'SurveyAssignment';
  assignmentType?: Maybe<Scalars['String']>;
  consultant?: Maybe<Consultant>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['Int'];
  response?: Maybe<Array<SurveyResponseData>>;
  score?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  survey: Survey;
  surveyableId: Scalars['BigInt'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

export type SurveyFilterInput = {
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  nameContains?: Maybe<Scalars['String']>;
  state?: Maybe<SurveyStateEnum>;
  surveyType?: Maybe<SurveyTypeEnum>;
  surveyable?: Maybe<SurveyableInput>;
  or?: Maybe<Array<SurveyFilterInput>>;
  search?: Maybe<Scalars['String']>;
};

export type SurveyList = {
   __typename?: 'SurveyList';
  activityLogs: Array<ActivityLog>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  name?: Maybe<Scalars['String']>;
  prn: Scalars['String'];
  state: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type SurveyListInput = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type SurveyPublicResponse = {
   __typename?: 'SurveyPublicResponse';
  averageScore: Scalars['Float'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['BigInt'];
  prn: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type SurveyQuestion = {
   __typename?: 'SurveyQuestion';
  ancestry?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  helpText?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  label: Scalars['String'];
  options?: Maybe<Scalars['JSON']>;
  position: Scalars['Int'];
  prn: Scalars['String'];
  questionType: SurveyQuestionTypeEnum;
  required: Scalars['Boolean'];
  scatterId: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  surveySection?: Maybe<SurveySection>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum SurveyQuestionTypeEnum {
  /** Freeform */
  Freeform = 'freeform',
  /** Scale */
  Scale = 'scale',
  /** Scored Single Choice */
  ScoredSingleChoice = 'scored_single_choice',
  /** Short Answer */
  ShortAnswer = 'short_answer',
  /** Long Answer */
  LongAnswer = 'long_answer',
  /** Multiple Choice */
  MultipleChoice = 'multiple_choice',
  /** Scored Multiple Choice */
  ScoredMultipleChoice = 'scored_multiple_choice',
  /** Dropdown */
  Dropdown = 'dropdown',
  /** Multi-select */
  MultiSelect = 'multi_select'
}

export type SurveyResponse = {
   __typename?: 'SurveyResponse';
  averageScore: Scalars['Float'];
  consultant?: Maybe<Consultant>;
  createdAt: Scalars['ISO8601DateTime'];
  data: Array<SurveyResponseData>;
  id: Scalars['BigInt'];
  prn: Scalars['String'];
  score: Scalars['Int'];
  survey: Survey;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

export type SurveyResponseData = {
   __typename?: 'SurveyResponseData';
  choice: Scalars['String'];
  label: Scalars['String'];
  questionId: Scalars['ID'];
};

export type SurveyResponseInput = {
  data: Scalars['JSON'];
};

export type SurveySection = {
   __typename?: 'SurveySection';
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  position: Scalars['Int'];
  prn: Scalars['String'];
  questions: Array<SurveyQuestion>;
  scatterId: Scalars['String'];
  surveyId: Scalars['BigInt'];
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum SurveyStateEnum {
  /** Unpublished */
  Unpublished = 'unpublished',
  /** Published */
  Published = 'published',
  /** Archived */
  Archived = 'archived'
}

export enum SurveyTypeEnum {
  /** Consultant Application Form */
  ApplicationForm = 'application_form',
  /** Legacy Consultant Application Form */
  ApplicationFormLegacy = 'application_form_legacy',
  /** Consultant Mission Appraisal Survey */
  AppraisalSurvey = 'appraisal_survey',
  /** Legacy Consultant Mission Appraisal Survey */
  AppraisalSurveyLegacy = 'appraisal_survey_legacy',
  /** Generic Surveys */
  General = 'general'
}

export type SurveyableInput = {
  jobTitleId?: Maybe<Scalars['BigInt']>;
  missionLifecycleId?: Maybe<Scalars['BigInt']>;
  surveyListId?: Maybe<Scalars['BigInt']>;
};

export type SurveysSortInput = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type Tag = {
   __typename?: 'Tag';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type TalentApplicationsDistribution = {
   __typename?: 'TalentApplicationsDistribution';
  by: TalentDistributionAggregation;
  values: Array<TalentDistributionTypeEntry>;
};

export type TalentApplicationsDistributionFilter = {
  by: TalentDistributionAggregation;
  or?: Maybe<Array<TalentApplicationsDistributionFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
};

export type TalentDashboardStatistics = {
   __typename?: 'TalentDashboardStatistics';
  talentApplicationsDistribution: TalentApplicationsDistribution;
  talentDistribution: TalentDistribution;
  talentRatings: TalentRatings;
  talentUtilization: TalentUtilization;
};


export type TalentDashboardStatisticsTalentApplicationsDistributionArgs = {
  filter?: Maybe<TalentApplicationsDistributionFilter>;
};


export type TalentDashboardStatisticsTalentDistributionArgs = {
  filter?: Maybe<TalentDistributionFilter>;
};


export type TalentDashboardStatisticsTalentRatingsArgs = {
  filter?: Maybe<TalentRatingsFilter>;
};


export type TalentDashboardStatisticsTalentUtilizationArgs = {
  filter?: Maybe<TalentUtilizationFilter>;
};

export type TalentDistribution = {
   __typename?: 'TalentDistribution';
  by: Array<TalentDistributionAggregation>;
  values: Array<TalentDistributionTypeEntry>;
};

export enum TalentDistributionAggregation {
  /** Gender */
  Gender = 'gender',
  /** Role */
  Role = 'role',
  /** Availability */
  Availability = 'availability',
  /** Country */
  Country = 'country',
  /** Region */
  Region = 'region'
}

export type TalentDistributionFilter = {
  by: Array<TalentDistributionAggregation>;
  or?: Maybe<Array<TalentDistributionFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
};

export type TalentDistributionTypeEntry = {
   __typename?: 'TalentDistributionTypeEntry';
  by: ByAggregationResult;
  count: Scalars['Int'];
  keys: Array<Scalars['String']>;
};

export type TalentPoolFilter = {
  availability?: Maybe<ConsultantAvailability>;
  countryId?: Maybe<Scalars['BigInt']>;
  gender?: Maybe<Scalars['String']>;
  idIn?: Maybe<Array<Scalars['BigInt']>>;
  jobFunctions?: Maybe<Array<JobFunction>>;
  jobTitleId?: Maybe<Scalars['BigInt']>;
  nameContains?: Maybe<Scalars['String']>;
  practiceArea?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['BigInt']>;
  status?: Maybe<Scalars['String']>;
  trainingTagsIn?: Maybe<Array<Scalars['String']>>;
  or?: Maybe<Array<TalentPoolFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
};

export type TalentPoolSortInput = {
  firstName?: Maybe<SortDirection>;
  lastName?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type TalentRatingEntry = {
   __typename?: 'TalentRatingEntry';
  bottom: Array<ConsultantAdvanced>;
  key: Scalars['String'];
  top: Array<ConsultantAdvanced>;
};

export type TalentRatings = {
   __typename?: 'TalentRatings';
  by: TalentDistributionAggregation;
  values: Array<TalentRatingEntry>;
};

export type TalentRatingsFilter = {
  by: TalentDistributionAggregation;
  or?: Maybe<Array<TalentRatingsFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
};

export type TalentUtilization = {
   __typename?: 'TalentUtilization';
  by: TalentDistributionAggregation;
  values: Array<TalentUtilizationEntry>;
};

export type TalentUtilizationEntry = {
   __typename?: 'TalentUtilizationEntry';
  by: ByAggregationResult;
  count: Scalars['Int'];
  percent: Scalars['Float'];
  total: Scalars['Int'];
};

export type TalentUtilizationFilter = {
  by: TalentDistributionAggregation;
  or?: Maybe<Array<TalentUtilizationFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
};

export type Task = {
   __typename?: 'Task';
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  deadline?: Maybe<Scalars['ISO8601Date']>;
  description: Scalars['String'];
  id: Scalars['BigInt'];
  metadata: Scalars['JSON'];
  priority: Scalars['String'];
  state: Scalars['String'];
  taskableId?: Maybe<Scalars['BigInt']>;
  taskableName?: Maybe<Scalars['String']>;
  taskableType?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type TasksFilter = {
  id?: Maybe<Scalars['BigInt']>;
  or?: Maybe<Array<TasksFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
};

/** Autogenerated return type of TerminateMissionStaffingPlacement */
export type TerminateMissionStaffingPlacementPayload = {
   __typename?: 'TerminateMissionStaffingPlacementPayload';
  error?: Maybe<ErrorV2>;
};

export type TrainingTag = {
   __typename?: 'TrainingTag';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['BigInt'];
  name: Scalars['String'];
};

export type TrainingTagsSortInput = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

/** Autogenerated return type of UnarchiveSurveyList */
export type UnarchiveSurveyListPayload = {
   __typename?: 'UnarchiveSurveyListPayload';
  error?: Maybe<ErrorV2>;
  surveyList?: Maybe<SurveyList>;
};

/** Autogenerated return type of UnarchiveSurvey */
export type UnarchiveSurveyPayload = {
   __typename?: 'UnarchiveSurveyPayload';
  error?: Maybe<ErrorV2>;
  survey?: Maybe<Survey>;
};

/** Autogenerated return type of UnpublishMissionStaffingPosition */
export type UnpublishMissionStaffingPositionPayload = {
   __typename?: 'UnpublishMissionStaffingPositionPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UnpublishSurvey */
export type UnpublishSurveyPayload = {
   __typename?: 'UnpublishSurveyPayload';
  error?: Maybe<ErrorV2>;
  survey?: Maybe<Survey>;
};

/** Autogenerated return type of UpdateAppConfig */
export type UpdateAppConfigPayload = {
   __typename?: 'UpdateAppConfigPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateApplicantProfile */
export type UpdateApplicantProfilePayload = {
   __typename?: 'UpdateApplicantProfilePayload';
  applicant?: Maybe<Applicant>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateBrandResourceCollection */
export type UpdateBrandResourceCollectionPayload = {
   __typename?: 'UpdateBrandResourceCollectionPayload';
  collection?: Maybe<BrandResourceCollection>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateBrandResourceDocument */
export type UpdateBrandResourceDocumentPayload = {
   __typename?: 'UpdateBrandResourceDocumentPayload';
  document?: Maybe<BrandResourceDocument>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateBrandResourceLabel */
export type UpdateBrandResourceLabelPayload = {
   __typename?: 'UpdateBrandResourceLabelPayload';
  error?: Maybe<ErrorV2>;
  label?: Maybe<BrandResourceLabel>;
};

/** Autogenerated return type of UpdateBrandResourceVideo */
export type UpdateBrandResourceVideoPayload = {
   __typename?: 'UpdateBrandResourceVideoPayload';
  error?: Maybe<ErrorV2>;
  video?: Maybe<BrandResourceVideo>;
};

/** Autogenerated return type of UpdateClientContract */
export type UpdateClientContractPayload = {
   __typename?: 'UpdateClientContractPayload';
  error?: Maybe<ErrorV2>;
  missionContract?: Maybe<MissionContract>;
};

/** Autogenerated return type of UpdateClient */
export type UpdateClientPayload = {
   __typename?: 'UpdateClientPayload';
  client?: Maybe<Client>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateCompanyProfile */
export type UpdateCompanyProfilePayload = {
   __typename?: 'UpdateCompanyProfilePayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateConsultantAvailability */
export type UpdateConsultantAvailabilityPayload = {
   __typename?: 'UpdateConsultantAvailabilityPayload';
  consultant?: Maybe<ConsultantProfile>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateConsultantBankAccount */
export type UpdateConsultantBankAccountPayload = {
   __typename?: 'UpdateConsultantBankAccountPayload';
  bankAccount?: Maybe<ConsultantBankAccount>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateConsultantContractMilestone */
export type UpdateConsultantContractMilestonePayload = {
   __typename?: 'UpdateConsultantContractMilestonePayload';
  consultantContract?: Maybe<ConsultantContract>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateConsultantContract */
export type UpdateConsultantContractPayload = {
   __typename?: 'UpdateConsultantContractPayload';
  consultantContract?: Maybe<ConsultantContract>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateConsultantContractPayment */
export type UpdateConsultantContractPaymentPayload = {
   __typename?: 'UpdateConsultantContractPaymentPayload';
  error?: Maybe<ErrorV2>;
  payment?: Maybe<ConsultantContractPayment>;
};

/** Autogenerated return type of UpdateConsultantEducation */
export type UpdateConsultantEducationPayload = {
   __typename?: 'UpdateConsultantEducationPayload';
  education?: Maybe<ConsultantEducation>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateConsultantJobTitles */
export type UpdateConsultantJobTitlesPayload = {
   __typename?: 'UpdateConsultantJobTitlesPayload';
  consultant?: Maybe<Consultant>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateConsultantMissionExpense */
export type UpdateConsultantMissionExpensePayload = {
   __typename?: 'UpdateConsultantMissionExpensePayload';
  error?: Maybe<ErrorV2>;
  missionExpense?: Maybe<ConsultantMissionExpense>;
};

/** Autogenerated return type of UpdateConsultantPayoneerAccount */
export type UpdateConsultantPayoneerAccountPayload = {
   __typename?: 'UpdateConsultantPayoneerAccountPayload';
  error?: Maybe<ErrorV2>;
  payoneerAccount?: Maybe<ConsultantPayoneerAccount>;
};

/** Autogenerated return type of UpdateConsultantProfile */
export type UpdateConsultantProfilePayload = {
   __typename?: 'UpdateConsultantProfilePayload';
  error?: Maybe<ErrorV2>;
  profile?: Maybe<ConsultantProfile>;
};

/** Autogenerated return type of UpdateConsultantTalent */
export type UpdateConsultantTalentPayload = {
   __typename?: 'UpdateConsultantTalentPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateConsultantWorkExperience */
export type UpdateConsultantWorkExperiencePayload = {
   __typename?: 'UpdateConsultantWorkExperiencePayload';
  error?: Maybe<ErrorV2>;
  workExperience?: Maybe<ConsultantWorkExperience>;
};

/** Autogenerated return type of UpdateMissionContractDeliverable */
export type UpdateMissionContractDeliverablePayload = {
   __typename?: 'UpdateMissionContractDeliverablePayload';
  deliverable?: Maybe<MissionContractDeliverable>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateMissionContractPayment */
export type UpdateMissionContractPaymentPayload = {
   __typename?: 'UpdateMissionContractPaymentPayload';
  error?: Maybe<ErrorV2>;
  missionContractPayment?: Maybe<MissionContractPayment>;
};

/** Autogenerated return type of UpdateMissionDocument */
export type UpdateMissionDocumentPayload = {
   __typename?: 'UpdateMissionDocumentPayload';
  document?: Maybe<MissionDocument>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateMissionExecutionStaffingPosition */
export type UpdateMissionExecutionStaffingPositionPayload = {
   __typename?: 'UpdateMissionExecutionStaffingPositionPayload';
  error?: Maybe<ErrorV2>;
  missionStaffingPosition?: Maybe<MissionStaffingPosition>;
};

/** Autogenerated return type of UpdateMissionLead */
export type UpdateMissionLeadPayload = {
   __typename?: 'UpdateMissionLeadPayload';
  error?: Maybe<ErrorV2>;
  missionLifecycle?: Maybe<MissionLifecycle>;
};

/** Autogenerated return type of UpdateMissionLifecycle */
export type UpdateMissionLifecyclePayload = {
   __typename?: 'UpdateMissionLifecyclePayload';
  error?: Maybe<ErrorV2>;
  missionLifecycle?: Maybe<MissionLifecycle>;
};

/** Autogenerated return type of UpdateMissionPricingProposalActivity */
export type UpdateMissionPricingProposalActivityPayload = {
   __typename?: 'UpdateMissionPricingProposalActivityPayload';
  activity?: Maybe<MissionPricingProposalActivity>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateMissionPricingProposal */
export type UpdateMissionPricingProposalPayload = {
   __typename?: 'UpdateMissionPricingProposalPayload';
  error?: Maybe<ErrorV2>;
  missionPricingProposal?: Maybe<MissionPricingProposal>;
};

/** Autogenerated return type of UpdateMissionProposalStaffingPosition */
export type UpdateMissionProposalStaffingPositionPayload = {
   __typename?: 'UpdateMissionProposalStaffingPositionPayload';
  error?: Maybe<ErrorV2>;
  missionStaffingPosition?: Maybe<MissionStaffingPosition>;
};

/** Autogenerated return type of UpdateMissionScopeActivity */
export type UpdateMissionScopeActivityPayload = {
   __typename?: 'UpdateMissionScopeActivityPayload';
  activity?: Maybe<MissionScopeActivity>;
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateMissionScope */
export type UpdateMissionScopePayload = {
   __typename?: 'UpdateMissionScopePayload';
  error?: Maybe<ErrorV2>;
  missionScope?: Maybe<MissionScope>;
};

/** Autogenerated return type of UpdateMissionScopePhase */
export type UpdateMissionScopePhasePayload = {
   __typename?: 'UpdateMissionScopePhasePayload';
  error?: Maybe<ErrorV2>;
  missionScopePhase?: Maybe<MissionScopePhase>;
};

/** Autogenerated return type of UpdateMissionStaffingPosition */
export type UpdateMissionStaffingPositionPayload = {
   __typename?: 'UpdateMissionStaffingPositionPayload';
  error?: Maybe<ErrorV2>;
  missionStaffingPosition?: Maybe<MissionStaffingPosition>;
};

/** Autogenerated return type of UpdatePassword */
export type UpdatePasswordPayload = {
   __typename?: 'UpdatePasswordPayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdatePaymentMethod */
export type UpdatePaymentMethodPayload = {
   __typename?: 'UpdatePaymentMethodPayload';
  error?: Maybe<ErrorV2>;
  paymentMethod?: Maybe<Card>;
};

/** Autogenerated return type of UpdateProfile */
export type UpdateProfilePayload = {
   __typename?: 'UpdateProfilePayload';
  error?: Maybe<ErrorV2>;
  profile?: Maybe<UserAdvanced>;
};

/** Autogenerated return type of UpdateRole */
export type UpdateRolePayload = {
   __typename?: 'UpdateRolePayload';
  error?: Maybe<ErrorV2>;
};

/** Autogenerated return type of UpdateScopeLibraryActivity */
export type UpdateScopeLibraryActivityPayload = {
   __typename?: 'UpdateScopeLibraryActivityPayload';
  error?: Maybe<ErrorV2>;
  scopeLibraryActivity?: Maybe<ScopeLibraryActivity>;
};

/** Autogenerated return type of UpdateScopeLibraryPhase */
export type UpdateScopeLibraryPhasePayload = {
   __typename?: 'UpdateScopeLibraryPhasePayload';
  error?: Maybe<ErrorV2>;
  scopeLibraryPhase?: Maybe<ScopeLibraryPhase>;
};

/** Autogenerated return type of UpdateScopePhaseSubscription */
export type UpdateScopePhaseSubscriptionPayload = {
   __typename?: 'UpdateScopePhaseSubscriptionPayload';
  error?: Maybe<ErrorV2>;
  scopePhaseSubscription?: Maybe<ScopePhaseSubscription>;
};

/** Autogenerated return type of UpdateScopeService */
export type UpdateScopeServicePayload = {
   __typename?: 'UpdateScopeServicePayload';
  error?: Maybe<ErrorV2>;
  scopeService?: Maybe<ScopeService>;
};

/** Autogenerated return type of UpdateScopeServiceTags */
export type UpdateScopeServiceTagsPayload = {
   __typename?: 'UpdateScopeServiceTagsPayload';
  error?: Maybe<ErrorV2>;
  scopeService?: Maybe<ScopeService>;
};

/** Autogenerated return type of UpdateScopeServiceTeamRole */
export type UpdateScopeServiceTeamRolePayload = {
   __typename?: 'UpdateScopeServiceTeamRolePayload';
  error?: Maybe<ErrorV2>;
  scopeServiceTeamRole?: Maybe<ScopeServiceTeamRole>;
};

/** Autogenerated return type of UpdateStaffingMilestone */
export type UpdateStaffingMilestonePayload = {
   __typename?: 'UpdateStaffingMilestonePayload';
  error?: Maybe<ErrorV2>;
  milestone?: Maybe<MissionStaffingMilestone>;
};

/** Autogenerated return type of UpdateSurvey */
export type UpdateSurveyPayload = {
   __typename?: 'UpdateSurveyPayload';
  error?: Maybe<ErrorV2>;
  survey?: Maybe<Survey>;
};

/** Autogenerated return type of UpdateSurveySection */
export type UpdateSurveySectionPayload = {
   __typename?: 'UpdateSurveySectionPayload';
  error?: Maybe<ErrorV2>;
  section?: Maybe<SurveySection>;
};

/** Autogenerated return type of UpdateUser */
export type UpdateUserPayload = {
   __typename?: 'UpdateUserPayload';
  error?: Maybe<ErrorV2>;
};

export type UploadFile = {
   __typename?: 'UploadFile';
  category: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  extension: Scalars['String'];
  id: Scalars['BigInt'];
  name: Scalars['String'];
  public: Scalars['Boolean'];
  url: Scalars['String'];
};

export type UploadFileInput = {
  category: Scalars['String'];
  extension: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
  public: Scalars['Boolean'];
};

export type User = {
   __typename?: 'User';
  active: Scalars['Boolean'];
  avatar?: Maybe<UploadFile>;
  consultant?: Maybe<Consultant>;
  createdAt: Scalars['ISO8601DateTime'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  prn: Scalars['String'];
  scatterId: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type UserAdvanced = {
   __typename?: 'UserAdvanced';
  active: Scalars['Boolean'];
  avatar?: Maybe<UploadFile>;
  clientsAsAdmin?: Maybe<Array<Client>>;
  consultant?: Maybe<Consultant>;
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  prn: Scalars['String'];
  roles: Array<Role>;
  scatterId: Scalars['String'];
  unreadConversations: Array<Conversation>;
  updatedAt: Scalars['ISO8601DateTime'];
  verified: Scalars['Boolean'];
};


export type UserAdvancedUnreadConversationsArgs = {
  clientId?: Maybe<Scalars['Int']>;
};

export type UserInput = {
  avatar?: Maybe<UploadFileInput>;
  clientAdminPosition?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<PhoneInput>;
};

export type UserSignatures = {
   __typename?: 'UserSignatures';
  files: Array<SignatureSource>;
  scripts: Array<SignatureSource>;
};

export type UsersFilter = {
  idIn?: Maybe<Array<Scalars['ID']>>;
  or?: Maybe<Array<UsersFilter>>;
  createdAtDate?: Maybe<DateFilterInput>;
  search?: Maybe<Scalars['String']>;
};

export type UsersSortInput = {
  firstName?: Maybe<SortDirection>;
  lastName?: Maybe<SortDirection>;
  email?: Maybe<SortDirection>;
  active?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type Wallet = {
   __typename?: 'Wallet';
  consultant: ConsultantProfile;
  createdAt: Scalars['ISO8601DateTime'];
  currentValue: Scalars['Float'];
  id: Scalars['ID'];
  totalEarned: Scalars['Float'];
  totalOutstanding: Scalars['Float'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type AppendSignatureMutationMutationVariables = {
  mandateSecret: Scalars['String'];
  requestId: Scalars['BigInt'];
  signatureSource: SignatureSourceInput;
};


export type AppendSignatureMutationMutation = (
  { __typename?: 'Mutation' }
  & { appendSignature: (
    { __typename?: 'AppendSignaturePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, signature?: Maybe<(
      { __typename?: 'Esignature' }
      & EsignatureFragmentFragment
    )> }
  ) }
);

export type EsignatureRequestFragmentFragment = (
  { __typename?: 'EsignatureRequest' }
  & Pick<EsignatureRequest, 'id' | 'mandateSecret' | 'state'>
  & { signer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )> }
);

export type ApplicantFragmentFragment = (
  { __typename?: 'Applicant' }
  & Pick<Applicant, 'id' | 'city' | 'phoneCountryCode' | 'phoneNumber' | 'dateOfBirth' | 'emailAddress' | 'gender' | 'jobRole' | 'name' | 'phone' | 'prn' | 'firstName' | 'lastName' | 'scatterId' | 'state' | 'allowedEvents'>
  & { country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  )>, coverLetter?: Maybe<(
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  )>, cv?: Maybe<(
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  )>, certificate?: Maybe<(
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  )>, documents?: Maybe<Array<(
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  )>> }
);

export type MeApplicantQueryQueryVariables = {
  applicantId: Scalars['String'];
};


export type MeApplicantQueryQuery = (
  { __typename?: 'Query' }
  & { meApplicant?: Maybe<(
    { __typename?: 'Applicant' }
    & ApplicantFragmentFragment
  )> }
);

export type FindApplicantQueryQueryVariables = {
  emailAddress: Scalars['String'];
};


export type FindApplicantQueryQuery = (
  { __typename?: 'Query' }
  & { findApplicant?: Maybe<(
    { __typename?: 'ApplicantBasic' }
    & Pick<ApplicantBasic, 'emailAddress'>
  )> }
);

export type ClientDetailsQueryQueryVariables = {
  filter?: Maybe<ClientsFilter>;
};


export type ClientDetailsQueryQuery = (
  { __typename?: 'Query' }
  & { clients: (
    { __typename?: 'PaginatedClients' }
    & { data: Array<(
      { __typename?: 'Client' }
      & Pick<Client, 'createdAt' | 'description' | 'id' | 'name'>
      & { tasks: Array<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'title' | 'type' | 'taskableId' | 'description'>
      )> }
    )> }
  ) }
);

export type MyClientQueryQueryVariables = {
  id: Scalars['ID'];
};


export type MyClientQueryQuery = (
  { __typename?: 'Query' }
  & { myClient?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'name' | 'taxExempt' | 'website'>
    & { ongoingMissions: Array<(
      { __typename?: 'MissionLifecycleBasic' }
      & MissionLifecycleBasicFragmentFragment
    )>, priorityPayments: Array<(
      { __typename?: 'MissionContractPayment' }
      & Pick<MissionContractPayment, 'amountCents' | 'description' | 'dueDate' | 'id' | 'name'>
      & { amount: (
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      ), missionLifecycle: (
        { __typename?: 'MissionLifecycle' }
        & Pick<MissionLifecycle, 'id' | 'name'>
      ) }
    )>, tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'completedAt' | 'createdAt' | 'deadline' | 'description' | 'id' | 'metadata' | 'state' | 'taskableId' | 'taskableType' | 'title' | 'type' | 'taskableName' | 'priority'>
    )> }
  )> }
);

export type UnreadConversationsQueryQueryVariables = {
  clientId?: Maybe<Scalars['ID']>;
};


export type UnreadConversationsQueryQuery = (
  { __typename?: 'Query' }
  & { unreadConversations?: Maybe<Array<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'scope' | 'createdAt' | 'id' | 'prn' | 'updatedAt'>
    & { comments: Array<(
      { __typename?: 'Comment' }
      & CommentFragmentFragment
    )>, lastComment?: Maybe<(
      { __typename?: 'Comment' }
      & CommentFragmentFragment
    )> }
  )>> }
);

export type DocumentsQueryQueryVariables = {
  filter?: Maybe<MissionDocumentsFilter>;
};


export type DocumentsQueryQuery = (
  { __typename?: 'Query' }
  & { missionDocuments?: Maybe<(
    { __typename?: 'PaginatedMissionDocuments' }
    & { data: Array<(
      { __typename?: 'MissionDocument' }
      & Pick<MissionDocument, 'createdAt' | 'documentType' | 'id' | 'note' | 'name' | 'prn' | 'updatedAt'>
      & { uploadFile: (
        { __typename?: 'UploadFile' }
        & UploadFileFragmentFragment
      ), uploadedBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'lastName' | 'firstName'>
        & { avatar?: Maybe<(
          { __typename?: 'UploadFile' }
          & Pick<UploadFile, 'id' | 'url'>
        )> }
      ) }
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  )> }
);

export type ClientAdminsQueryQueryVariables = {
  id: Scalars['ID'];
};


export type ClientAdminsQueryQuery = (
  { __typename?: 'Query' }
  & { findClient?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'name' | 'prn' | 'acceptedServiceAgreement' | 'industry' | 'description' | 'address' | 'companyLogoUrl' | 'tagline' | 'website'>
    & { clientAdminGrants: Array<(
      { __typename?: 'ClientAdminGrant' }
      & Pick<ClientAdminGrant, 'id' | 'prn' | 'clientAdminPosition'>
      & { client: (
        { __typename?: 'Client' }
        & Pick<Client, 'email' | 'id' | 'prn'>
      ), user: (
        { __typename?: 'UserAdvanced' }
        & Pick<UserAdvanced, 'id' | 'active' | 'firstName' | 'lastName' | 'prn' | 'email'>
        & { avatar?: Maybe<(
          { __typename?: 'UploadFile' }
          & Pick<UploadFile, 'id' | 'url'>
        )> }
      ) }
    )>, pendingClientAdminInvitations: Array<(
      { __typename?: 'Invitation' }
      & Pick<Invitation, 'id' | 'prn' | 'email' | 'state' | 'roles'>
    )>, country: (
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    ) }
  )> }
);

export type InviteClientAdminMutationMutationVariables = {
  clientId: Scalars['ID'];
  roles?: Maybe<Array<Scalars['String']>>;
  userEmail: Scalars['String'];
};


export type InviteClientAdminMutationMutation = (
  { __typename?: 'Mutation' }
  & { inviteClientAdmin: (
    { __typename?: 'InviteClientAdminPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, invitation?: Maybe<(
      { __typename?: 'Invitation' }
      & Pick<Invitation, 'createdAt' | 'email' | 'id' | 'prn' | 'updatedAt'>
    )> }
  ) }
);

export type RemoveClientAdminAccessMutationMutationVariables = {
  clientId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type RemoveClientAdminAccessMutationMutation = (
  { __typename?: 'Mutation' }
  & { removeClientAdminAccess: (
    { __typename?: 'RemoveClientAdminAccessPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DocumentFragmentFragment = (
  { __typename?: 'MissionDocument' }
  & Pick<MissionDocument, 'createdAt' | 'documentType' | 'id' | 'name' | 'note' | 'prn' | 'updatedAt'>
  & { uploadFile: (
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  ), uploadedBy: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'lastName' | 'firstName'>
    & { avatar?: Maybe<(
      { __typename?: 'UploadFile' }
      & Pick<UploadFile, 'id' | 'url'>
    )> }
  ) }
);

export type CreateMissionDocumentMutationMutationVariables = {
  missionLifecycleId: Scalars['ID'];
  missionDocumentInput: MissionDocumentInput;
};


export type CreateMissionDocumentMutationMutation = (
  { __typename?: 'Mutation' }
  & { createMissionDocument: (
    { __typename?: 'CreateMissionDocumentPayload' }
    & { document?: Maybe<(
      { __typename?: 'MissionDocument' }
      & DocumentFragmentFragment
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateMissionDocumentMutationMutationVariables = {
  missionDocumentId: Scalars['ID'];
  missionDocumentInput: MissionDocumentInput;
};


export type UpdateMissionDocumentMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateMissionDocument: (
    { __typename?: 'UpdateMissionDocumentPayload' }
    & { document?: Maybe<(
      { __typename?: 'MissionDocument' }
      & DocumentFragmentFragment
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateProfileMutationMutationVariables = {
  avatar?: Maybe<UploadFileInput>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<PhoneInput>;
  email?: Maybe<Scalars['String']>;
};


export type UpdateProfileMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile: (
    { __typename?: 'UpdateProfilePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, profile?: Maybe<(
      { __typename?: 'UserAdvanced' }
      & Pick<UserAdvanced, 'active' | 'firstName' | 'id' | 'lastName' | 'name'>
      & { avatar?: Maybe<(
        { __typename?: 'UploadFile' }
        & Pick<UploadFile, 'id' | 'url'>
      )> }
    )> }
  ) }
);

export type UpdatePasswordMutationMutationVariables = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type UpdatePasswordMutationMutation = (
  { __typename?: 'Mutation' }
  & { updatePassword: (
    { __typename?: 'UpdatePasswordPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UsersDetailsQueryQueryVariables = {
  page?: Maybe<Scalars['Int']>;
  per?: Maybe<Scalars['Int']>;
  filter?: Maybe<UsersFilter>;
  sort?: Maybe<UsersSortInput>;
};


export type UsersDetailsQueryQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'PaginatedUsers' }
    & { data: Array<(
      { __typename?: 'UserAdvanced' }
      & Pick<UserAdvanced, 'firstName' | 'lastName' | 'email' | 'phoneCountryCode' | 'phoneNumber'>
      & { avatar?: Maybe<(
        { __typename?: 'UploadFile' }
        & UploadFileFragmentFragment
      )>, clientsAsAdmin?: Maybe<Array<(
        { __typename?: 'Client' }
        & Pick<Client, 'name' | 'companyLogoUrl' | 'description' | 'address' | 'prn' | 'tagline' | 'website' | 'industry'>
        & { country: (
          { __typename?: 'Country' }
          & Pick<Country, 'id' | 'name'>
        ) }
      )>> }
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type ClientUserDetailsQueryQueryVariables = {};


export type ClientUserDetailsQueryQuery = (
  { __typename?: 'Query' }
  & { meUser?: Maybe<(
    { __typename?: 'UserAdvanced' }
    & Pick<UserAdvanced, 'active' | 'createdAt' | 'email' | 'firstName' | 'id' | 'lastName' | 'name' | 'phoneCountryCode' | 'phoneNumber' | 'prn' | 'scatterId' | 'updatedAt'>
    & { avatar?: Maybe<(
      { __typename?: 'UploadFile' }
      & UploadFileFragmentFragment
    )>, clientsAsAdmin?: Maybe<Array<(
      { __typename?: 'Client' }
      & Pick<Client, 'id'>
    )>>, consultant?: Maybe<(
      { __typename?: 'Consultant' }
      & Pick<Consultant, 'createdAt' | 'firstName' | 'id'>
    )>, permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'description' | 'id' | 'name' | 'slug'>
    )>, roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'active' | 'description' | 'id' | 'name'>
    )> }
  )> }
);

export type UpdateCompanyProfileMutationMutationVariables = {
  clientId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['ID']>;
  tagline?: Maybe<Scalars['String']>;
  companyLogo?: Maybe<UploadFileInput>;
  industry?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};


export type UpdateCompanyProfileMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanyProfile: (
    { __typename?: 'UpdateCompanyProfilePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ClientDetailFragmentFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'id' | 'name' | 'address' | 'email' | 'companyLogoUrl' | 'companySizeRange' | 'website' | 'verified' | 'description' | 'proposalsCount' | 'taxExempt' | 'prn' | 'industry' | 'ownershipStructure' | 'productSlug' | 'missionsCount' | 'createdAt'>
  & { approval?: Maybe<(
    { __typename?: 'Approval' }
    & ApprovalFragmentFragment
  )>, product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'logoUrl'>
  ), subscriptions: Array<(
    { __typename?: 'Subscription' }
    & Pick<Subscription, 'id' | 'expiredAt' | 'state' | 'seatsUsageCount' | 'createdAt'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'logoUrl'>
    ) }
  )>, clientType: (
    { __typename?: 'ClientType' }
    & Pick<ClientType, 'id' | 'name'>
  ), primaryClientContact?: Maybe<(
    { __typename?: 'ClientContact' }
    & Pick<ClientContact, 'email' | 'name' | 'phone' | 'role'>
  )>, clientContacts: Array<(
    { __typename?: 'ClientContact' }
    & Pick<ClientContact, 'id' | 'email' | 'name' | 'phone' | 'role'>
  )>, country: (
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  ), missionLifecycles: Array<(
    { __typename?: 'MissionLifecycle' }
    & Pick<MissionLifecycle, 'id' | 'name' | 'state'>
    & { missionProposal?: Maybe<(
      { __typename?: 'MissionProposal' }
      & Pick<MissionProposal, 'id'>
    )> }
  )> }
);

export type ClientsQueryQueryVariables = {
  filter?: Maybe<ClientsFilter>;
};


export type ClientsQueryQuery = (
  { __typename?: 'Query' }
  & { clients: (
    { __typename?: 'PaginatedClients' }
    & { data: Array<(
      { __typename?: 'Client' }
      & ClientDetailFragmentFragment
    )> }
  ) }
);

export type SubscriptionUserFragmentFragment = (
  { __typename?: 'SubscriptionUser' }
  & Pick<SubscriptionUser, 'active' | 'createdAt' | 'email' | 'id' | 'name' | 'prn' | 'updatedAt'>
);

export type ClientSubscriptionsQueryQueryVariables = {
  id: Scalars['ID'];
};


export type ClientSubscriptionsQueryQuery = (
  { __typename?: 'Query' }
  & { myClient?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'id'>
    & { subscriptions: Array<(
      { __typename?: 'Subscription' }
      & Pick<Subscription, 'createdAt' | 'expiredAt' | 'id' | 'lastBillingAt' | 'nextBillingAt' | 'prn' | 'seatsUsageCount' | 'remainingSeats' | 'state' | 'subscriptionPeriod' | 'updatedAt'>
      & { productPlan: (
        { __typename?: 'ProductPlan' }
        & ProductPlanFragmentFragment
      ), paymentMethod?: Maybe<(
        { __typename?: 'Card' }
        & Pick<Card, 'id'>
      )>, subscriptionUsers: Array<(
        { __typename?: 'SubscriptionUser' }
        & SubscriptionUserFragmentFragment
      )>, invitations: Array<(
        { __typename?: 'Invitation' }
        & Pick<Invitation, 'id' | 'prn' | 'state' | 'email'>
      )> }
    )> }
  )> }
);

export type UpdatePaymentMethodMutationMutationVariables = {
  paymentMethodId: Scalars['ID'];
  paymentMethodInput: CardInput;
};


export type UpdatePaymentMethodMutationMutation = (
  { __typename?: 'Mutation' }
  & { updatePaymentMethod: (
    { __typename?: 'UpdatePaymentMethodPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, paymentMethod?: Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'id' | 'prn'>
    )> }
  ) }
);

export type InviteSubscriptionUserMutationMutationVariables = {
  email: Scalars['String'];
  name: Scalars['String'];
  subscriptionId: Scalars['ID'];
};


export type InviteSubscriptionUserMutationMutation = (
  { __typename?: 'Mutation' }
  & { inviteSubscriptionUser: (
    { __typename?: 'InviteSubscriptionUserPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, subscriptionUser?: Maybe<(
      { __typename?: 'SubscriptionUser' }
      & Pick<SubscriptionUser, 'email' | 'id'>
    )> }
  ) }
);

export type RevokeInvitationMutationMutationVariables = {
  id: Scalars['ID'];
};


export type RevokeInvitationMutationMutation = (
  { __typename?: 'Mutation' }
  & { revokeInvitation: (
    { __typename?: 'RevokeInvitationPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ResendInvitationMutationMutationVariables = {
  id: Scalars['ID'];
};


export type ResendInvitationMutationMutation = (
  { __typename?: 'Mutation' }
  & { resendInvitation: (
    { __typename?: 'ResendInvitationPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ScopeServiceFragmentFragment = (
  { __typename?: 'ScopeService' }
  & Pick<ScopeService, 'createdAt' | 'description' | 'id' | 'name' | 'prn' | 'averageDuration' | 'updatedAt' | 'availableForMissions'>
  & { serviceGroup: (
    { __typename?: 'ScopeServiceGroup' }
    & Pick<ScopeServiceGroup, 'createdAt' | 'id' | 'name' | 'prn' | 'updatedAt'>
  ), priceMax?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, priceMin?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, teamRoles: Array<(
    { __typename?: 'ScopeServiceTeamRole' }
    & Pick<ScopeServiceTeamRole, 'id' | 'prn' | 'quantity'>
    & { role: (
      { __typename?: 'JobTitle' }
      & Pick<JobTitle, 'id' | 'name'>
    ) }
  )>, phases: Array<(
    { __typename?: 'ScopeLibraryPhase' }
    & Pick<ScopeLibraryPhase, 'id' | 'name'>
    & { activities: Array<(
      { __typename?: 'ScopeLibraryActivity' }
      & Pick<ScopeLibraryActivity, 'id' | 'name'>
    )> }
  )> }
);

export type AvailableMissionScopeServicesQueryQueryVariables = {};


export type AvailableMissionScopeServicesQueryQuery = (
  { __typename?: 'Query' }
  & { availableMissionScopeServices: Array<(
    { __typename?: 'ScopeService' }
    & ScopeServiceFragmentFragment
  )> }
);

export type PublicScopeServicesQueryQueryVariables = {};


export type PublicScopeServicesQueryQuery = (
  { __typename?: 'Query' }
  & { publicScopeServices?: Maybe<Array<(
    { __typename?: 'PublicScopeService' }
    & Pick<PublicScopeService, 'createdAt' | 'description' | 'id' | 'name' | 'prn' | 'updatedAt'>
    & { serviceGroup: (
      { __typename?: 'ScopeServiceGroup' }
      & Pick<ScopeServiceGroup, 'createdAt' | 'id' | 'name' | 'prn' | 'updatedAt'>
    ) }
  )>> }
);

export type MissionProblemStatementsQueryQueryVariables = {};


export type MissionProblemStatementsQueryQuery = (
  { __typename?: 'Query' }
  & { missionProblemStatements?: Maybe<Array<(
    { __typename?: 'MissionProblemStatement' }
    & Pick<MissionProblemStatement, 'options' | 'problemStatement' | 'serviceCategories' | 'serviceGroups' | 'key'>
  )>> }
);

export type CreateMissionRequestMutationMutationVariables = {
  missionRequestInput: MissionRequestInput;
};


export type CreateMissionRequestMutationMutation = (
  { __typename?: 'Mutation' }
  & { createMissionRequest: (
    { __typename?: 'CreateMissionRequestPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, missionRequest?: Maybe<(
      { __typename?: 'MissionRequest' }
      & Pick<MissionRequest, 'id'>
    )> }
  ) }
);

export type ClientsTaskQueryQueryVariables = {
  id: Scalars['ID'];
};


export type ClientsTaskQueryQuery = (
  { __typename?: 'Query' }
  & { myClient?: Maybe<(
    { __typename?: 'Client' }
    & { tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'completedAt' | 'createdAt' | 'deadline' | 'description' | 'id' | 'metadata' | 'state' | 'taskableId' | 'taskableName' | 'taskableType' | 'title' | 'type' | 'priority'>
    )> }
  )> }
);

export type AllScopeServicesQueryQueryVariables = {
  filter?: Maybe<ScopeServicesFilter>;
};


export type AllScopeServicesQueryQuery = (
  { __typename?: 'Query' }
  & { scopeServices?: Maybe<Array<(
    { __typename?: 'ScopeService' }
    & Pick<ScopeService, 'id' | 'name'>
  )>> }
);

export type CreateMissionScopeMutationMutationVariables = {
  missionLifecycleId: Scalars['ID'];
  missionScopePhases?: Maybe<Array<MissionScopePhaseInput>>;
};


export type CreateMissionScopeMutationMutation = (
  { __typename?: 'Mutation' }
  & { createMissionScope: (
    { __typename?: 'CreateMissionScopePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, missionScope?: Maybe<(
      { __typename?: 'MissionScope' }
      & Pick<MissionScope, 'id'>
    )> }
  ) }
);

export type ScopeTagsQueryQueryVariables = {
  filter?: Maybe<ScopeTagsFilter>;
};


export type ScopeTagsQueryQuery = (
  { __typename?: 'Query' }
  & { scopeTags?: Maybe<Array<(
    { __typename?: 'ScopeTag' }
    & Pick<ScopeTag, 'id' | 'name' | 'prn'>
  )>> }
);

export type ScopePhaseSubscriptionFragmentFragment = (
  { __typename?: 'CreateScopeLibraryPhasePayload' }
  & { scopePhaseSubscription?: Maybe<(
    { __typename?: 'ScopePhaseSubscription' }
    & Pick<ScopePhaseSubscription, 'createdAt' | 'id' | 'position' | 'updatedAt'>
    & { activities: Array<(
      { __typename?: 'ScopeLibraryActivity' }
      & Pick<ScopeLibraryActivity, 'createdAt' | 'id' | 'name' | 'position' | 'updatedAt'>
    )>, phase: (
      { __typename?: 'ScopeLibraryPhase' }
      & Pick<ScopeLibraryPhase, 'createdAt' | 'id' | 'name' | 'subscriptionCount' | 'updatedAt'>
    ) }
  )> }
);

export type PhaseSubscriptionsFragmentFragment = (
  { __typename?: 'ScopeService' }
  & { phaseSubscriptions: Array<(
    { __typename?: 'ScopePhaseSubscription' }
    & Pick<ScopePhaseSubscription, 'createdAt' | 'id' | 'position' | 'updatedAt'>
    & { activities: Array<(
      { __typename?: 'ScopeLibraryActivity' }
      & Pick<ScopeLibraryActivity, 'createdAt' | 'id' | 'name' | 'position' | 'updatedAt'>
    )>, phase: (
      { __typename?: 'ScopeLibraryPhase' }
      & Pick<ScopeLibraryPhase, 'createdAt' | 'id' | 'name' | 'subscriptionCount' | 'updatedAt'>
    ) }
  )> }
);

export type ScopeServiceGroupsQueryQueryVariables = {};


export type ScopeServiceGroupsQueryQuery = (
  { __typename?: 'Query' }
  & { scopeServiceGroups?: Maybe<Array<(
    { __typename?: 'ScopeServiceGroup' }
    & Pick<ScopeServiceGroup, 'createdAt' | 'id' | 'name' | 'updatedAt'>
  )>> }
);

export type CreateScopeServiceMutationMutationVariables = {
  scopeServiceInput: ScopeServiceInput;
};


export type CreateScopeServiceMutationMutation = (
  { __typename?: 'Mutation' }
  & { createScopeService: (
    { __typename?: 'CreateScopeServicePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, scopeService?: Maybe<(
      { __typename?: 'ScopeService' }
      & Pick<ScopeService, 'availableForMissions' | 'createdAt' | 'description' | 'id' | 'name' | 'updatedAt'>
      & { serviceGroup: (
        { __typename?: 'ScopeServiceGroup' }
        & Pick<ScopeServiceGroup, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type CreateScopeLibraryPhaseMutationMutationVariables = {
  name: Scalars['String'];
  scopeServiceId: Scalars['ID'];
};


export type CreateScopeLibraryPhaseMutationMutation = (
  { __typename?: 'Mutation' }
  & { createScopeLibraryPhase: (
    { __typename?: 'CreateScopeLibraryPhasePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
    & ScopePhaseSubscriptionFragmentFragment
  ) }
);

export type ScopeServicesListQueryQueryVariables = {};


export type ScopeServicesListQueryQuery = (
  { __typename?: 'Query' }
  & { scopeServiceGroups?: Maybe<Array<(
    { __typename?: 'ScopeServiceGroup' }
    & Pick<ScopeServiceGroup, 'createdAt' | 'id' | 'name' | 'updatedAt'>
    & { services: Array<(
      { __typename?: 'ScopeService' }
      & Pick<ScopeService, 'id' | 'name' | 'description' | 'createdAt' | 'updatedAt'>
    )> }
  )>> }
);

export type ScopeServiceQueryQueryVariables = {
  id?: Maybe<Scalars['ID']>;
};


export type ScopeServiceQueryQuery = (
  { __typename?: 'Query' }
  & { scopeServices?: Maybe<Array<(
    { __typename?: 'ScopeService' }
    & Pick<ScopeService, 'createdAt' | 'description' | 'id' | 'name' | 'prn' | 'averageDuration' | 'updatedAt' | 'availableForMissions'>
    & { serviceGroup: (
      { __typename?: 'ScopeServiceGroup' }
      & Pick<ScopeServiceGroup, 'id' | 'name'>
    ), tags: Array<(
      { __typename?: 'ScopeTag' }
      & Pick<ScopeTag, 'createdAt' | 'id' | 'name' | 'updatedAt'>
    )>, teamRoles: Array<(
      { __typename?: 'ScopeServiceTeamRole' }
      & Pick<ScopeServiceTeamRole, 'id' | 'prn' | 'quantity'>
      & { role: (
        { __typename?: 'JobTitle' }
        & Pick<JobTitle, 'id' | 'name' | 'abbreviation'>
      ) }
    )>, priceMax?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency'>
    )>, priceMin?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency'>
    )> }
    & PhaseSubscriptionsFragmentFragment
  )>> }
);

export type AllScopeLibraryPhasesQueryQueryVariables = {
  filter?: Maybe<ScopeLibraryPhasesFilter>;
};


export type AllScopeLibraryPhasesQueryQuery = (
  { __typename?: 'Query' }
  & { scopeLibraryPhases?: Maybe<Array<(
    { __typename?: 'ScopeService' }
    & Pick<ScopeService, 'createdAt' | 'id' | 'name' | 'updatedAt'>
  )>> }
);

export type CreateScopePhaseSubscriptionMutationMutationVariables = {
  scopeLibraryPhaseId: Scalars['BigInt'];
  scopeServiceId: Scalars['BigInt'];
};


export type CreateScopePhaseSubscriptionMutationMutation = (
  { __typename?: 'Mutation' }
  & { createScopePhaseSubscription: (
    { __typename?: 'CreateScopePhaseSubscriptionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, scopePhaseSubscription?: Maybe<(
      { __typename?: 'ScopePhaseSubscription' }
      & Pick<ScopePhaseSubscription, 'createdAt' | 'id' | 'updatedAt'>
    )> }
  ) }
);

export type UpdateScopeLibraryPhaseMutationMutationVariables = {
  id: Scalars['BigInt'];
  name: Scalars['String'];
};


export type UpdateScopeLibraryPhaseMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateScopeLibraryPhase: (
    { __typename?: 'UpdateScopeLibraryPhasePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, scopeLibraryPhase?: Maybe<(
      { __typename?: 'ScopeLibraryPhase' }
      & Pick<ScopeLibraryPhase, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      & { activities: Array<(
        { __typename?: 'ScopeLibraryActivity' }
        & Pick<ScopeLibraryActivity, 'id'>
      )> }
    )> }
  ) }
);

export type CreateScopeLibraryActivityMutationMutationVariables = {
  scopePhaseSubscriptionId: Scalars['BigInt'];
  name: Scalars['String'];
};


export type CreateScopeLibraryActivityMutationMutation = (
  { __typename?: 'Mutation' }
  & { createScopeLibraryActivity: (
    { __typename?: 'CreateScopeLibraryActivityPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, scopeLibraryActivity?: Maybe<(
      { __typename?: 'ScopeLibraryActivity' }
      & Pick<ScopeLibraryActivity, 'createdAt' | 'id' | 'name' | 'updatedAt'>
    )> }
  ) }
);

export type UpdateScopeLibraryActivityMutationMutationVariables = {
  id: Scalars['BigInt'];
  position?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
};


export type UpdateScopeLibraryActivityMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateScopeLibraryActivity: (
    { __typename?: 'UpdateScopeLibraryActivityPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, scopeLibraryActivity?: Maybe<(
      { __typename?: 'ScopeLibraryActivity' }
      & Pick<ScopeLibraryActivity, 'id' | 'name' | 'updatedAt' | 'position'>
    )> }
  ) }
);

export type DeleteScopeLibraryActivityMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type DeleteScopeLibraryActivityMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteScopeLibraryActivity: (
    { __typename?: 'DeleteScopeLibraryActivityPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateScopeServiceTagsMutationMutationVariables = {
  serviceId: Scalars['BigInt'];
  tags?: Maybe<Array<Scalars['String']>>;
};


export type UpdateScopeServiceTagsMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateScopeServiceTags: (
    { __typename?: 'UpdateScopeServiceTagsPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, scopeService?: Maybe<(
      { __typename?: 'ScopeService' }
      & Pick<ScopeService, 'id'>
      & { tags: Array<(
        { __typename?: 'ScopeTag' }
        & Pick<ScopeTag, 'id' | 'name' | 'createdAt' | 'updatedAt'>
      )> }
    )> }
  ) }
);

export type UpdateScopePhaseSubscriptionMutationMutationVariables = {
  id: Scalars['BigInt'];
  libraryPhaseName: Scalars['String'];
};


export type UpdateScopePhaseSubscriptionMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateScopePhaseSubscription: (
    { __typename?: 'UpdateScopePhaseSubscriptionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, scopePhaseSubscription?: Maybe<(
      { __typename?: 'ScopePhaseSubscription' }
      & Pick<ScopePhaseSubscription, 'createdAt' | 'id' | 'updatedAt'>
      & { phase: (
        { __typename?: 'ScopeLibraryPhase' }
        & Pick<ScopeLibraryPhase, 'id' | 'name' | 'subscriptionCount' | 'updatedAt' | 'createdAt'>
      ) }
    )> }
  ) }
);

export type DeleteScopePhaseSubscriptionMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type DeleteScopePhaseSubscriptionMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteScopePhaseSubscription: (
    { __typename?: 'DeleteScopePhaseSubscriptionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateScopeServiceMutationMutationVariables = {
  scopeServiceId: Scalars['ID'];
  scopeServiceInput: ScopeServiceInput;
};


export type UpdateScopeServiceMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateScopeService: (
    { __typename?: 'UpdateScopeServicePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, scopeService?: Maybe<(
      { __typename?: 'ScopeService' }
      & Pick<ScopeService, 'description' | 'id' | 'name' | 'updatedAt' | 'averageDuration'>
      & { priceMax?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      )>, priceMin?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      )> }
    )> }
  ) }
);

export type ScopeServiceTagsQueryQueryVariables = {};


export type ScopeServiceTagsQueryQuery = (
  { __typename?: 'Query' }
  & { scopeServices?: Maybe<Array<(
    { __typename?: 'ScopeService' }
    & Pick<ScopeService, 'createdAt' | 'id' | 'name' | 'updatedAt'>
    & { tags: Array<(
      { __typename?: 'ScopeTag' }
      & Pick<ScopeTag, 'createdAt' | 'id' | 'name' | 'updatedAt'>
    )> }
  )>> }
);

export type UpdateActivityPositionMutationMutationVariables = {
  id: Scalars['BigInt'];
  position?: Maybe<Scalars['BigInt']>;
};


export type UpdateActivityPositionMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateScopeLibraryActivity: (
    { __typename?: 'UpdateScopeLibraryActivityPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ScopeLibraryTreeDataQueryQueryVariables = {};


export type ScopeLibraryTreeDataQueryQuery = (
  { __typename?: 'Query' }
  & { scopeServiceGroups?: Maybe<Array<(
    { __typename?: 'ScopeServiceGroup' }
    & Pick<ScopeServiceGroup, 'id' | 'name'>
    & { services: Array<(
      { __typename?: 'ScopeService' }
      & Pick<ScopeService, 'id' | 'name'>
      & { phases: Array<(
        { __typename?: 'ScopeLibraryPhase' }
        & Pick<ScopeLibraryPhase, 'id' | 'name'>
        & { activities: Array<(
          { __typename?: 'ScopeLibraryActivity' }
          & Pick<ScopeLibraryActivity, 'id' | 'name'>
        )> }
      )> }
    )> }
  )>> }
);

export type UpdatePhaseSubscriptionPositionMutationMutationVariables = {
  id: Scalars['BigInt'];
  position?: Maybe<Scalars['BigInt']>;
};


export type UpdatePhaseSubscriptionPositionMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateScopePhaseSubscription: (
    { __typename?: 'UpdateScopePhaseSubscriptionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CreateScopeServiceTeamRoleMutationMutationVariables = {
  scopeServiceId: Scalars['ID'];
  scopeServiceTeamRoleInput: ScopeServiceTeamRoleInput;
};


export type CreateScopeServiceTeamRoleMutationMutation = (
  { __typename?: 'Mutation' }
  & { createScopeServiceTeamRole: (
    { __typename?: 'CreateScopeServiceTeamRolePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, scopeServiceTeamRole?: Maybe<(
      { __typename?: 'ScopeServiceTeamRole' }
      & Pick<ScopeServiceTeamRole, 'createdAt' | 'id' | 'prn' | 'quantity' | 'updatedAt'>
      & { role: (
        { __typename?: 'JobTitle' }
        & Pick<JobTitle, 'id'>
      ) }
    )> }
  ) }
);

export type UpdateScopeServiceTeamRoleMutationMutationVariables = {
  scopeServiceTeamRoleId: Scalars['ID'];
  scopeServiceTeamRoleInput: ScopeServiceTeamRoleInput;
};


export type UpdateScopeServiceTeamRoleMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateScopeServiceTeamRole: (
    { __typename?: 'UpdateScopeServiceTeamRolePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, scopeServiceTeamRole?: Maybe<(
      { __typename?: 'ScopeServiceTeamRole' }
      & Pick<ScopeServiceTeamRole, 'createdAt' | 'id' | 'prn' | 'quantity' | 'updatedAt'>
      & { role: (
        { __typename?: 'JobTitle' }
        & Pick<JobTitle, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type DeleteScopeServiceTeamRoleMutationMutationVariables = {
  scopeServiceTeamRoleId: Scalars['ID'];
};


export type DeleteScopeServiceTeamRoleMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteScopeServiceTeamRole: (
    { __typename?: 'DeleteScopeServiceTeamRolePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type AddUserMutationMutationVariables = {
  email: Scalars['String'];
  roleIds: Array<Scalars['String']>;
};


export type AddUserMutationMutation = (
  { __typename?: 'Mutation' }
  & { addUser: (
    { __typename?: 'AddUserPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateUserMutationMutationVariables = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  active: Scalars['Boolean'];
  forceUpdate: Scalars['Boolean'];
  roleIds: Array<Scalars['BigInt']>;
};


export type UpdateUserMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UpdateUserPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type AddUserRoleMutationMutationVariables = {
  name: Scalars['String'];
  description: Scalars['String'];
  permissionSlugs: Array<Scalars['String']>;
  active: Scalars['Boolean'];
};


export type AddUserRoleMutationMutation = (
  { __typename?: 'Mutation' }
  & { addRole: (
    { __typename?: 'AddRolePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateUserRoleMutationMutationVariables = {
  id: Scalars['BigInt'];
  name: Scalars['String'];
  description: Scalars['String'];
  permissionSlugs: Array<Scalars['String']>;
  active: Scalars['Boolean'];
};


export type UpdateUserRoleMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateRole: (
    { __typename?: 'UpdateRolePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UsersQueryQueryVariables = {
  page?: Maybe<Scalars['Int']>;
  per?: Maybe<Scalars['Int']>;
  filter?: Maybe<UsersFilter>;
  sort?: Maybe<UsersSortInput>;
};


export type UsersQueryQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'PaginatedUsers' }
    & { data: Array<(
      { __typename?: 'UserAdvanced' }
      & Pick<UserAdvanced, 'id' | 'firstName' | 'lastName' | 'email' | 'active'>
      & { avatar?: Maybe<(
        { __typename?: 'UploadFile' }
        & UploadFileFragmentFragment
      )>, roles: Array<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name' | 'description' | 'active'>
      )>, permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id' | 'name' | 'slug' | 'description'>
      )> }
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type PermissionsQueryQueryVariables = {
  page?: Maybe<Scalars['Int']>;
  per?: Maybe<Scalars['Int']>;
  sort?: Maybe<PermissionsSortInput>;
};


export type PermissionsQueryQuery = (
  { __typename?: 'Query' }
  & { permissions: (
    { __typename?: 'PaginatedPermissions' }
    & { data: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id' | 'slug' | 'description' | 'name'>
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type UserRolesQueryQueryVariables = {
  filter?: Maybe<RolesFilter>;
  sort?: Maybe<RolesSortInput>;
  page?: Maybe<Scalars['Int']>;
  per?: Maybe<Scalars['Int']>;
};


export type UserRolesQueryQuery = (
  { __typename?: 'Query' }
  & { roles: (
    { __typename?: 'PaginatedRoles' }
    & { data: Array<(
      { __typename?: 'Role' }
      & RoleFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type RoleFragmentFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name' | 'description' | 'active'>
  & { permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'slug' | 'description' | 'name'>
  )> }
);

export type ShortlistBulkApplicantionsMutationMutationVariables = {
  ids: Array<Scalars['ID']>;
};


export type ShortlistBulkApplicantionsMutationMutation = (
  { __typename?: 'Mutation' }
  & { bulkShortlistApplicant: (
    { __typename?: 'BulkShortlistApplicantPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ArchiveBulkApplicantionsMutationMutationVariables = {
  ids: Array<Scalars['ID']>;
};


export type ArchiveBulkApplicantionsMutationMutation = (
  { __typename?: 'Mutation' }
  & { bulkArchiveApplication: (
    { __typename?: 'BulkArchiveApplicationPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RejectBulkApplicantionsMutationMutationVariables = {
  ids: Array<Scalars['ID']>;
  rejectionReason: CandidateRejectionTypeEnum;
};


export type RejectBulkApplicantionsMutationMutation = (
  { __typename?: 'Mutation' }
  & { bulkRejectApplication: (
    { __typename?: 'BulkRejectApplicationPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ArchiveApplicationsMutationMutationVariables = {};


export type ArchiveApplicationsMutationMutation = (
  { __typename?: 'Mutation' }
  & { archiveApplications: (
    { __typename?: 'ArchiveApplicationsPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type StartApplicationMutationMutationVariables = {
  applicant: ApplicantInput;
};


export type StartApplicationMutationMutation = (
  { __typename?: 'Mutation' }
  & { startApplication: (
    { __typename?: 'StartApplicationPayload' }
    & Pick<StartApplicationPayload, 'completed'>
    & { application?: Maybe<(
      { __typename?: 'ConsultantApplication' }
      & ConsultantApplicationFragmentFragment
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type SaveApplicationMutationMutationVariables = {
  applicant: ApplicantInput;
  application: ConsultantApplicationInput;
};


export type SaveApplicationMutationMutation = (
  { __typename?: 'Mutation' }
  & { saveApplication: (
    { __typename?: 'SaveApplicationPayload' }
    & { application?: Maybe<(
      { __typename?: 'ConsultantApplication' }
      & ConsultantApplicationFragmentFragment
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CompleteApplicationMutationMutationVariables = {
  applicant: ApplicantInput;
};


export type CompleteApplicationMutationMutation = (
  { __typename?: 'Mutation' }
  & { completeApplication: (
    { __typename?: 'CompleteApplicationPayload' }
    & { application?: Maybe<(
      { __typename?: 'ConsultantApplication' }
      & ConsultantApplicationFragmentFragment
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type OpenJobTitlesQueryQueryVariables = {};


export type OpenJobTitlesQueryQuery = (
  { __typename?: 'Query' }
  & { jobTitles: Array<(
    { __typename?: 'JobTitle' }
    & Pick<JobTitle, 'id' | 'name' | 'description' | 'requirements' | 'questionsCount'>
    & { questions: Array<(
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'type' | 'identifier' | 'label' | 'metadata'>
    )> }
  )> }
);

export type AllJobTitlesQueryQueryVariables = {
  filter?: Maybe<JobTitlesFilter>;
};


export type AllJobTitlesQueryQuery = (
  { __typename?: 'Query' }
  & { jobTitles: Array<(
    { __typename?: 'JobTitle' }
    & Pick<JobTitle, 'id' | 'name' | 'prn' | 'questionsCount' | 'abbreviation' | 'dailyRate' | 'capacityFactor'>
    & { questions: Array<(
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'type' | 'identifier' | 'label' | 'metadata'>
    )> }
  )> }
);

export type ApplicationTrackQueryQueryVariables = {
  id: Scalars['String'];
};


export type ApplicationTrackQueryQuery = (
  { __typename?: 'Query' }
  & { applicationTrack?: Maybe<(
    { __typename?: 'ApplicationTrack' }
    & Pick<ApplicationTrack, 'createdAt' | 'description' | 'id' | 'name' | 'overviewContent' | 'prn' | 'scatterId' | 'updatedAt' | 'url'>
    & { client: (
      { __typename?: 'ClientBasic' }
      & Pick<ClientBasic, 'id'>
    ), applicationFormSections?: Maybe<Array<(
      { __typename?: 'ApplicationFormSection' }
      & Pick<ApplicationFormSection, 'createdAt' | 'description' | 'id' | 'sectionType' | 'timer' | 'title' | 'updatedAt' | 'slug' | 'externalFormUrl' | 'stepable'>
    )>> }
  )> }
);

export type BeginApplicationMutationMutationVariables = {
  applicationTrackId: Scalars['ID'];
  applicantInput: ApplicantInputV2;
  newApplicant: Scalars['Boolean'];
};


export type BeginApplicationMutationMutation = (
  { __typename?: 'Mutation' }
  & { beginApplication: (
    { __typename?: 'BeginApplicationPayload' }
    & { applicant?: Maybe<(
      { __typename?: 'Applicant' }
      & Pick<Applicant, 'id' | 'prn' | 'token'>
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateApplicantProfileMutationMutationVariables = {
  applicantId: Scalars['ID'];
  profile: ApplicantProfileInput;
  applicantDocuments: Array<UploadFileInput>;
};


export type UpdateApplicantProfileMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateApplicantProfile: (
    { __typename?: 'UpdateApplicantProfilePayload' }
    & { applicant?: Maybe<(
      { __typename?: 'Applicant' }
      & Pick<Applicant, 'id' | 'prn'>
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type SubmitApplicationMutationMutationVariables = {
  applicantId: Scalars['ID'];
  applicationTrackId: Scalars['ID'];
};


export type SubmitApplicationMutationMutation = (
  { __typename?: 'Mutation' }
  & { submitApplication: (
    { __typename?: 'SubmitApplicationPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type LoginMutationMutationVariables = {
  email: Scalars['String'];
  password: Scalars['String'];
  mode: LoginMode;
};


export type LoginMutationMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginPayload' }
    & Pick<LoginPayload, 'token'>
    & { user?: Maybe<(
      { __typename?: 'UserAdvanced' }
      & UserAdvancedFragmentFragment
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & Pick<ErrorV2, 'message'>
    )> }
  ) }
);

export type RequestPasswordResetMutationMutationVariables = {
  email: Scalars['String'];
};


export type RequestPasswordResetMutationMutation = (
  { __typename?: 'Mutation' }
  & { requestPasswordReset: (
    { __typename?: 'RequestPasswordResetPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & Pick<ErrorV2, 'message'>
    )> }
  ) }
);

export type ResetPasswordMutationMutationVariables = {
  token: Scalars['String'];
  password: Scalars['String'];
};


export type ResetPasswordMutationMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'ResetPasswordPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & Pick<ErrorV2, 'message'>
    )> }
  ) }
);

export type UserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName'>
  & { consultant?: Maybe<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id'>
  )>, avatar?: Maybe<(
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  )> }
);

export type UserAdvancedFragmentFragment = (
  { __typename?: 'UserAdvanced' }
  & Pick<UserAdvanced, 'id' | 'prn' | 'firstName' | 'lastName' | 'name' | 'email' | 'verified'>
  & { consultant?: Maybe<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id'>
  )>, avatar?: Maybe<(
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  )>, roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name' | 'active'>
  )>, permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'id' | 'name' | 'slug'>
  )>, clientsAsAdmin?: Maybe<Array<(
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'name' | 'prn' | 'acceptedServiceAgreement' | 'productSlug'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id'>
    ) }
  )>> }
);

export type RequestApplicantPasswordResetMutationMutationVariables = {
  emailAddress: Scalars['String'];
  trackId: Scalars['ID'];
};


export type RequestApplicantPasswordResetMutationMutation = (
  { __typename?: 'Mutation' }
  & { requestApplicantPasswordReset: (
    { __typename?: 'RequestApplicantPasswordResetPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ResetApplicantPasswordMutationMutationVariables = {
  token: Scalars['String'];
  password: Scalars['String'];
};


export type ResetApplicantPasswordMutationMutation = (
  { __typename?: 'Mutation' }
  & { resetApplicantPassword: (
    { __typename?: 'ResetApplicantPasswordPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ConfirmEmailMutationMutationVariables = {
  confirmationToken: Scalars['String'];
};


export type ConfirmEmailMutationMutation = (
  { __typename?: 'Mutation' }
  & { confirmEmail: (
    { __typename?: 'ConfirmEmailPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, response?: Maybe<(
      { __typename?: 'ConfirmEmailResponse' }
      & Pick<ConfirmEmailResponse, 'message' | 'success'>
    )> }
  ) }
);

export type ResendEmailConfirmationMutationMutationVariables = {};


export type ResendEmailConfirmationMutationMutation = (
  { __typename?: 'Mutation' }
  & { resendEmailConfirmation: (
    { __typename?: 'ResendEmailConfirmationPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type MeUserQueryQueryVariables = {};


export type MeUserQueryQuery = (
  { __typename?: 'Query' }
  & { meUser?: Maybe<(
    { __typename?: 'UserAdvanced' }
    & UserAdvancedFragmentFragment
  )> }
);

export type BrandResourceCollectionsQueryQueryVariables = {
  filter?: Maybe<BrandResourceCollectionsFilter>;
  sort?: Maybe<BrandResourceCollectionsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type BrandResourceCollectionsQueryQuery = (
  { __typename?: 'Query' }
  & { brandResourceCollections?: Maybe<(
    { __typename?: 'PaginatedBrandResourceCollections' }
    & { data: Array<(
      { __typename?: 'BrandResourceCollection' }
      & Pick<BrandResourceCollection, 'id' | 'name' | 'updatedAt' | 'createdAt'>
      & { files: Array<(
        { __typename?: 'BrandResourceFile' }
        & Pick<BrandResourceFile, 'id' | 'name' | 'embedFileUrl' | 'embedFileThumbnail' | 'createdAt' | 'updatedAt'>
        & { brandResourceCollection: (
          { __typename?: 'BrandResourceCollection' }
          & Pick<BrandResourceCollection, 'id' | 'name'>
        ), uploadFile?: Maybe<(
          { __typename?: 'UploadFile' }
          & Pick<UploadFile, 'name' | 'id' | 'extension' | 'public' | 'url'>
        )> }
      )>, label: (
        { __typename?: 'BrandResourceLabel' }
        & Pick<BrandResourceLabel, 'id' | 'name'>
      ) }
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  )> }
);

export type CreateBrandResourceCollectionMutationMutationVariables = {
  labelId: Scalars['BigInt'];
  name: Scalars['String'];
};


export type CreateBrandResourceCollectionMutationMutation = (
  { __typename?: 'Mutation' }
  & { createBrandResourceCollection: (
    { __typename?: 'CreateBrandResourceCollectionPayload' }
    & { collection?: Maybe<(
      { __typename?: 'BrandResourceCollection' }
      & Pick<BrandResourceCollection, 'id' | 'name' | 'updatedAt' | 'createdAt'>
      & { files: Array<(
        { __typename?: 'BrandResourceFile' }
        & Pick<BrandResourceFile, 'id' | 'name' | 'embedFileUrl' | 'embedFileThumbnail' | 'createdAt' | 'updatedAt'>
        & { brandResourceCollection: (
          { __typename?: 'BrandResourceCollection' }
          & Pick<BrandResourceCollection, 'id' | 'name'>
        ), uploadFile?: Maybe<(
          { __typename?: 'UploadFile' }
          & Pick<UploadFile, 'name' | 'id' | 'extension' | 'public' | 'url'>
        )> }
      )>, label: (
        { __typename?: 'BrandResourceLabel' }
        & Pick<BrandResourceLabel, 'id' | 'name'>
      ) }
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateBrandResourceCollectionMutationMutationVariables = {
  id: Scalars['BigInt'];
  labelId: Scalars['BigInt'];
  name: Scalars['String'];
};


export type UpdateBrandResourceCollectionMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateBrandResourceCollection: (
    { __typename?: 'UpdateBrandResourceCollectionPayload' }
    & { collection?: Maybe<(
      { __typename?: 'BrandResourceCollection' }
      & Pick<BrandResourceCollection, 'id' | 'name' | 'updatedAt' | 'createdAt'>
      & { files: Array<(
        { __typename?: 'BrandResourceFile' }
        & Pick<BrandResourceFile, 'id' | 'name' | 'embedFileUrl' | 'embedFileThumbnail' | 'createdAt' | 'updatedAt'>
        & { brandResourceCollection: (
          { __typename?: 'BrandResourceCollection' }
          & Pick<BrandResourceCollection, 'id' | 'name'>
        ), uploadFile?: Maybe<(
          { __typename?: 'UploadFile' }
          & Pick<UploadFile, 'name' | 'id' | 'extension' | 'public' | 'url'>
        )> }
      )>, label: (
        { __typename?: 'BrandResourceLabel' }
        & Pick<BrandResourceLabel, 'id' | 'name'>
      ) }
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DeleteBrandResourceCollectionMutationMutationVariables = {
  id: Scalars['BigInt'];
  reassignCollectionId?: Maybe<Scalars['BigInt']>;
};


export type DeleteBrandResourceCollectionMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteBrandResourceCollection: (
    { __typename?: 'DeleteBrandResourceCollectionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CreateBrandResourceDocumentMutationMutationVariables = {
  collectionId: Scalars['BigInt'];
  name: Scalars['String'];
  uploadFile: UploadFileInput;
};


export type CreateBrandResourceDocumentMutationMutation = (
  { __typename?: 'Mutation' }
  & { createBrandResourceDocument: (
    { __typename?: 'CreateBrandResourceDocumentPayload' }
    & { document?: Maybe<(
      { __typename?: 'BrandResourceDocument' }
      & Pick<BrandResourceDocument, 'createdAt' | 'embedFileThumbnail' | 'embedFileUrl' | 'id' | 'name' | 'type' | 'updatedAt'>
      & { brandResourceCollection: (
        { __typename?: 'BrandResourceCollection' }
        & Pick<BrandResourceCollection, 'name' | 'id'>
        & { label: (
          { __typename?: 'BrandResourceLabel' }
          & Pick<BrandResourceLabel, 'id' | 'name'>
        ) }
      ), uploadFile: (
        { __typename?: 'UploadFile' }
        & Pick<UploadFile, 'name' | 'id' | 'extension' | 'public' | 'url'>
      ) }
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateBrandResourceDocumentMutationMutationVariables = {
  id: Scalars['BigInt'];
  name?: Maybe<Scalars['String']>;
  uploadFile?: Maybe<UploadFileInput>;
  collectionId?: Maybe<Scalars['BigInt']>;
};


export type UpdateBrandResourceDocumentMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateBrandResourceDocument: (
    { __typename?: 'UpdateBrandResourceDocumentPayload' }
    & { document?: Maybe<(
      { __typename?: 'BrandResourceDocument' }
      & Pick<BrandResourceDocument, 'createdAt' | 'embedFileThumbnail' | 'embedFileUrl' | 'id' | 'name' | 'type' | 'updatedAt'>
      & { brandResourceCollection: (
        { __typename?: 'BrandResourceCollection' }
        & Pick<BrandResourceCollection, 'name' | 'id'>
        & { label: (
          { __typename?: 'BrandResourceLabel' }
          & Pick<BrandResourceLabel, 'id' | 'name'>
        ) }
      ), uploadFile: (
        { __typename?: 'UploadFile' }
        & Pick<UploadFile, 'name' | 'id' | 'extension' | 'public' | 'url'>
      ) }
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DeleteBrandResourceDocumentMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type DeleteBrandResourceDocumentMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteBrandResourceDocument: (
    { __typename?: 'DeleteBrandResourceDocumentPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CreateBrandResourceVideoMutationMutationVariables = {
  collectionId: Scalars['BigInt'];
  name: Scalars['String'];
  videoUrl: Scalars['String'];
  videoThumbnail: Scalars['String'];
};


export type CreateBrandResourceVideoMutationMutation = (
  { __typename?: 'Mutation' }
  & { createBrandResourceVideo: (
    { __typename?: 'CreateBrandResourceVideoPayload' }
    & { video?: Maybe<(
      { __typename?: 'BrandResourceVideo' }
      & Pick<BrandResourceVideo, 'createdAt' | 'embedFileThumbnail' | 'embedFileUrl' | 'id' | 'name' | 'type' | 'updatedAt'>
      & { brandResourceCollection: (
        { __typename?: 'BrandResourceCollection' }
        & Pick<BrandResourceCollection, 'name' | 'id'>
        & { label: (
          { __typename?: 'BrandResourceLabel' }
          & Pick<BrandResourceLabel, 'id' | 'name'>
        ) }
      ), uploadFile?: Maybe<(
        { __typename?: 'UploadFile' }
        & Pick<UploadFile, 'name' | 'id' | 'extension' | 'public' | 'url'>
      )> }
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateBrandResourceVideoMutationMutationVariables = {
  id: Scalars['BigInt'];
  collectionId: Scalars['BigInt'];
  name: Scalars['String'];
  videoUrl: Scalars['String'];
  videoThumbnail: Scalars['String'];
};


export type UpdateBrandResourceVideoMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateBrandResourceVideo: (
    { __typename?: 'UpdateBrandResourceVideoPayload' }
    & { video?: Maybe<(
      { __typename?: 'BrandResourceVideo' }
      & Pick<BrandResourceVideo, 'createdAt' | 'embedFileThumbnail' | 'embedFileUrl' | 'id' | 'name' | 'type' | 'updatedAt'>
      & { brandResourceCollection: (
        { __typename?: 'BrandResourceCollection' }
        & Pick<BrandResourceCollection, 'name' | 'id'>
        & { label: (
          { __typename?: 'BrandResourceLabel' }
          & Pick<BrandResourceLabel, 'id' | 'name'>
        ) }
      ), uploadFile?: Maybe<(
        { __typename?: 'UploadFile' }
        & Pick<UploadFile, 'name' | 'id' | 'extension' | 'public' | 'url'>
      )> }
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DeleteBrandResourceVideoMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type DeleteBrandResourceVideoMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteBrandResourceVideo: (
    { __typename?: 'DeleteBrandResourceVideoPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ListClientsQueryQueryVariables = {
  page?: Maybe<Scalars['Int']>;
  per: Scalars['Int'];
  sort?: Maybe<ClientsSortInput>;
  filter?: Maybe<ClientsFilter>;
};


export type ListClientsQueryQuery = (
  { __typename?: 'Query' }
  & { clients: (
    { __typename?: 'PaginatedClients' }
    & { data: Array<(
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'name' | 'proposalsCount' | 'missionsCount' | 'createdAt'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      ), subscriptions: Array<(
        { __typename?: 'Subscription' }
        & Pick<Subscription, 'id'>
      )>, country: (
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name'>
      ), clientType: (
        { __typename?: 'ClientType' }
        & Pick<ClientType, 'id' | 'name'>
      ) }
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type AcceptServiceAgreementMutationMutationVariables = {};


export type AcceptServiceAgreementMutationMutation = (
  { __typename?: 'Mutation' }
  & { acceptServiceAgreement: (
    { __typename?: 'AcceptServiceAgreementPayload' }
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & Pick<Client, 'id'>
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CreateClientMutationMutationVariables = {
  clientInput: ClientInput;
};


export type CreateClientMutationMutation = (
  { __typename?: 'Mutation' }
  & { createClient: (
    { __typename?: 'CreateClientPayload' }
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'name' | 'proposalsCount' | 'missionsCount' | 'createdAt'>
      & { country: (
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name'>
      ), clientType: (
        { __typename?: 'ClientType' }
        & Pick<ClientType, 'id' | 'name'>
      ) }
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateClientMutationMutationVariables = {
  id: Scalars['ID'];
  clientInput: ClientInput;
};


export type UpdateClientMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateClient: (
    { __typename?: 'UpdateClientPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DeleteClientMutationMutationVariables = {
  clientId: Scalars['BigInt'];
};


export type DeleteClientMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteClient: (
    { __typename?: 'DeleteClientPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ErrorFragmentFragment = (
  { __typename?: 'ErrorV2' }
  & Pick<ErrorV2, 'message' | 'code'>
  & { fields?: Maybe<Array<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'field' | 'message'>
  )>> }
);

export type PaginationFragmentFragment = (
  { __typename?: 'PaginationV2' }
  & Pick<PaginationV2, 'currentPage' | 'firstPage' | 'lastPage' | 'nextPage' | 'prevPage' | 'pageCount' | 'totalSize'>
);

export type ClientTypesQueryQueryVariables = {};


export type ClientTypesQueryQuery = (
  { __typename?: 'Query' }
  & { clientTypes: Array<(
    { __typename?: 'ClientType' }
    & Pick<ClientType, 'id' | 'name'>
  )> }
);

export type CurrenciesQueryQueryVariables = {};


export type CurrenciesQueryQuery = (
  { __typename?: 'Query' }
  & { currencies: Array<(
    { __typename?: 'Currency' }
    & CurrencyFragmentFragment
  )> }
);

export type QuartersQueryQueryVariables = {};


export type QuartersQueryQuery = (
  { __typename?: 'Query' }
  & { quarters: Array<(
    { __typename?: 'Quarter' }
    & Pick<Quarter, 'id' | 'name'>
  )> }
);

export type MissionNaturesQueryQueryVariables = {};


export type MissionNaturesQueryQuery = (
  { __typename?: 'Query' }
  & { missionNatures: Array<(
    { __typename?: 'MissionNature' }
    & Pick<MissionNature, 'id' | 'name'>
  )> }
);

export type FindOnboardingUserQueryQueryVariables = {
  onboardingIdentifier?: Maybe<Scalars['String']>;
};


export type FindOnboardingUserQueryQuery = (
  { __typename?: 'Query' }
  & { findUser: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  )> }
);

export type GrantApprovalMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type GrantApprovalMutationMutation = (
  { __typename?: 'Mutation' }
  & { grantApproval: (
    { __typename?: 'GrantApprovalPayload' }
    & { approval?: Maybe<(
      { __typename?: 'Approval' }
      & ApprovalFragmentFragment
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RejectApprovalMutationMutationVariables = {
  id: Scalars['BigInt'];
  reason: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
};


export type RejectApprovalMutationMutation = (
  { __typename?: 'Mutation' }
  & { rejectApproval: (
    { __typename?: 'RejectApprovalPayload' }
    & { approval?: Maybe<(
      { __typename?: 'Approval' }
      & ApprovalFragmentFragment
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ApprovalFragmentFragment = (
  { __typename?: 'Approval' }
  & Pick<Approval, 'approvedAt' | 'createdAt' | 'id' | 'rejectedAt' | 'rejectionReason' | 'state' | 'updatedAt'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  ) }
);

export type AppConfigsQueryQueryVariables = {
  filter?: Maybe<AppConfigsFilter>;
};


export type AppConfigsQueryQuery = (
  { __typename?: 'Query' }
  & { appConfigs: (
    { __typename?: 'PaginatedAppConfigs' }
    & { data: Array<(
      { __typename?: 'AppConfig' }
      & Pick<AppConfig, 'id' | 'group' | 'key' | 'name' | 'value'>
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type UpdateAppConfigMutationMutationVariables = {
  key: Scalars['String'];
  group: Scalars['String'];
  value: Scalars['String'];
};


export type UpdateAppConfigMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateAppConfig: (
    { __typename?: 'UpdateAppConfigPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RegionsQueryQueryVariables = {};


export type RegionsQueryQuery = (
  { __typename?: 'Query' }
  & { regions: Array<(
    { __typename?: 'Region' }
    & Pick<Region, 'id' | 'name'>
  )> }
);

export type MoneyFragmentFragment = (
  { __typename?: 'Money' }
  & Pick<Money, 'amount' | 'currency' | 'format'>
);

export type ConsultantApplicationFragmentFragment = (
  { __typename?: 'ConsultantApplication' }
  & Pick<ConsultantApplication, 'convincedPeerAnswer' | 'applicationChannel' | 'availabilityDate' | 'failedGoalAnswer' | 'leadershipRoleAnswer' | 'africaNeeds' | 'bio' | 'dateOfBirth' | 'yearStartedFreelance' | 'yearStartedWork' | 'availability' | 'currentOccupation' | 'email' | 'firstName' | 'gender' | 'id' | 'lastName' | 'linkedinUrl' | 'mbti' | 'name' | 'phone' | 'postalAddress' | 'residentialAddress' | 'createdAt' | 'employmentStatus' | 'jobFunctions' | 'practiceArea'>
  & { consultantDocuments: Array<(
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  )>, consultantEducations?: Maybe<Array<(
    { __typename?: 'ConsultantEducation' }
    & ConsultantEducationFragmentFragment
  )>>, consultantNationalities: Array<(
    { __typename?: 'ConsultantNationality' }
    & Pick<ConsultantNationality, 'countryId' | 'id'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    ) }
  )>, consultantSpokenLanguages: Array<(
    { __typename?: 'ConsultantSpokenLanguage' }
    & Pick<ConsultantSpokenLanguage, 'fluency' | 'id' | 'languageId'>
    & { language: (
      { __typename?: 'Language' }
      & Pick<Language, 'id' | 'name'>
    ) }
  )>, consultantWorkExperiences?: Maybe<Array<(
    { __typename?: 'ConsultantWorkExperience' }
    & ConsultantWorkExperienceFragmentFragment
  )>>, country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  )>, jobTitle?: Maybe<(
    { __typename?: 'JobTitle' }
    & JobTitleFragmentFragment
  )>, user?: Maybe<(
    { __typename?: 'UserAdvanced' }
    & Pick<UserAdvanced, 'id' | 'name'>
  )> }
);

export type ConsultantContractPaymentFragmentFragment = (
  { __typename?: 'ConsultantContractPayment' }
  & Pick<ConsultantContractPayment, 'id' | 'prn' | 'createdAt' | 'updatedAt' | 'description' | 'name' | 'percentage' | 'amountCents' | 'dueDate' | 'paymentType' | 'state'>
  & { amount: (
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  ), missionContractPayment?: Maybe<(
    { __typename?: 'MissionContractPaymentBasic' }
    & Pick<MissionContractPaymentBasic, 'id' | 'name'>
  )>, staffingPositionDeliverables: Array<(
    { __typename?: 'StaffingPositionDeliverable' }
    & Pick<StaffingPositionDeliverable, 'id' | 'name'>
    & { missionScopeActivity?: Maybe<(
      { __typename?: 'MissionScopeActivity' }
      & Pick<MissionScopeActivity, 'id' | 'name' | 'prn'>
    )>, missionStaffingPosition: (
      { __typename?: 'MissionStaffingPosition' }
      & Pick<MissionStaffingPosition, 'id'>
    ) }
  )> }
);

export type ConsultantContractFragmentFragment = (
  { __typename?: 'ConsultantContract' }
  & Pick<ConsultantContract, 'id' | 'prn' | 'updatedAt' | 'acknowledgedAt' | 'state' | 'totalFees' | 'objective' | 'contractDate' | 'terminatedAt' | 'address' | 'companyName' | 'createdAt' | 'contractingEntity' | 'commencementDate' | 'conclusionDate' | 'declineReason'>
  & { activityLogs: Array<(
    { __typename?: 'ActivityLog' }
    & ActivityLogFragmentFragment
  )>, application: (
    { __typename?: 'MissionStaffingApplication' }
    & Pick<MissionStaffingApplication, 'id'>
    & { missionStaffingPosition: (
      { __typename?: 'MissionStaffingPosition' }
      & Pick<MissionStaffingPosition, 'id' | 'description' | 'name'>
      & { jobTitle: (
        { __typename?: 'JobTitle' }
        & Pick<JobTitle, 'id' | 'name'>
      ), totalFee: (
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      ), consultantContractPayments: Array<(
        { __typename?: 'ConsultantContractPayment' }
        & ConsultantContractPaymentFragmentFragment
      )> }
    ), consultant: (
      { __typename?: 'Consultant' }
      & Pick<Consultant, 'id' | 'name'>
      & { jobTitle?: Maybe<(
        { __typename?: 'JobTitle' }
        & Pick<JobTitle, 'id' | 'name'>
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { avatar?: Maybe<(
          { __typename?: 'UploadFile' }
          & UploadFileFragmentFragment
        )> }
      )> }
    ) }
  ), missionLifecycle: (
    { __typename?: 'MissionLifecycleBasic' }
    & Pick<MissionLifecycleBasic, 'id' | 'name' | 'projectLocationType' | 'projectLocationCountryCode' | 'contractingEntity'>
    & { scopeService?: Maybe<(
      { __typename?: 'ScopeService' }
      & Pick<ScopeService, 'id' | 'name'>
      & { serviceGroup: (
        { __typename?: 'ScopeServiceGroup' }
        & Pick<ScopeServiceGroup, 'id' | 'name'>
      ) }
    )> }
  ), consultantContractMilestones?: Maybe<Array<(
    { __typename?: 'ConsultantContractMilestone' }
    & Pick<ConsultantContractMilestone, 'id' | 'description' | 'feesCents' | 'fees'>
  )>>, approvals?: Maybe<Array<(
    { __typename?: 'Approval' }
    & ApprovalFragmentFragment
  )>>, currency: (
    { __typename?: 'Currency' }
    & CurrencyFragmentFragment
  ), consultant: (
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id' | 'name'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  ), signatures: Array<(
    { __typename?: 'Esignature' }
    & EsignatureFragmentFragment
  )> }
);

export type EsignatureFragmentFragment = (
  { __typename?: 'Esignature' }
  & Pick<Esignature, 'id' | 'signedAt'>
  & { signatureSource: (
    { __typename?: 'SignatureSource' }
    & Pick<SignatureSource, 'id' | 'type' | 'key' | 'imageUrl'>
  ), user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  ) }
);

export type SignConsultantContractMutationMutationVariables = {
  contractId: Scalars['BigInt'];
  adoptedSignature?: Maybe<Scalars['String']>;
};


export type SignConsultantContractMutationMutation = (
  { __typename?: 'Mutation' }
  & { signConsultantContractDocument: (
    { __typename?: 'SignConsultantContractDocumentPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, consultantContract?: Maybe<(
      { __typename?: 'ConsultantContract' }
      & ConsultantContractFragmentFragment
    )> }
  ) }
);

export type ConsultantProfileFragmentFragment = (
  { __typename?: 'ConsultantProfile' }
  & Pick<ConsultantProfile, 'africaNeeds' | 'availability' | 'availabilityReason' | 'bio' | 'employmentStatus' | 'currentOccupation' | 'email' | 'firstName' | 'gender' | 'id' | 'jobFunctions' | 'lastName' | 'linkedinUrl' | 'mbti' | 'name' | 'phone' | 'practiceArea' | 'prn' | 'postalAddress' | 'rating' | 'residentialAddress' | 'trainingTagList' | 'yearJoined' | 'yearStartedWork' | 'yearStartedFreelance' | 'dateOfBirth' | 'createdAt' | 'state'>
  & { wallet?: Maybe<(
    { __typename?: 'Wallet' }
    & Pick<Wallet, 'id' | 'totalEarned' | 'totalOutstanding'>
  )>, consultantEducations?: Maybe<Array<(
    { __typename?: 'ConsultantEducation' }
    & ConsultantEducationFragmentFragment
  )>>, consultantNationalities: Array<(
    { __typename?: 'ConsultantNationality' }
    & Pick<ConsultantNationality, 'id'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    ) }
  )>, consultantSpokenLanguages: Array<(
    { __typename?: 'ConsultantSpokenLanguage' }
    & Pick<ConsultantSpokenLanguage, 'fluency' | 'id' | 'languageId'>
    & { language: (
      { __typename?: 'Language' }
      & Pick<Language, 'id' | 'name'>
    ) }
  )>, consultantWorkExperiences?: Maybe<Array<(
    { __typename?: 'ConsultantWorkExperience' }
    & ConsultantWorkExperienceFragmentFragment
  )>>, country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  )>, jobTitle?: Maybe<(
    { __typename?: 'JobTitle' }
    & JobTitleFragmentFragment
  )>, jobTitles?: Maybe<Array<(
    { __typename?: 'JobTitle' }
    & JobTitleFragmentFragment
  )>>, missionStaffingApplications?: Maybe<(
    { __typename?: 'PaginatedMissionStaffingApplications' }
    & { data: Array<(
      { __typename?: 'MissionStaffingApplication' }
      & MissionStaffingApplicationFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  )>, missionStaffingPlacements?: Maybe<(
    { __typename?: 'PaginatedMissionStaffingPlacements' }
    & { data: Array<(
      { __typename?: 'MissionStaffingPlacement' }
      & { consultantInvoices: Array<(
        { __typename?: 'ConsultantInvoice' }
        & Pick<ConsultantInvoice, 'total' | 'id' | 'paymentDescription' | 'state' | 'paidAt'>
        & { payment?: Maybe<(
          { __typename?: 'ConsultantContractPayment' }
          & Pick<ConsultantContractPayment, 'description' | 'id' | 'paymentType'>
          & { amount: (
            { __typename?: 'Money' }
            & MoneyFragmentFragment
          ) }
        )> }
      )> }
      & MissionStaffingPlacementFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  )>, profilePresentation?: Maybe<(
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  )>, user?: Maybe<(
    { __typename?: 'UserAdvanced' }
    & Pick<UserAdvanced, 'id' | 'name'>
    & { avatar?: Maybe<(
      { __typename?: 'UploadFile' }
      & UploadFileFragmentFragment
    )> }
  )>, consultantPayoneerAccounts: Array<(
    { __typename?: 'ConsultantPayoneerAccount' }
    & Pick<ConsultantPayoneerAccount, 'createdAt' | 'customerId' | 'emailAddress' | 'id' | 'state' | 'updatedAt'>
  )>, consultantBankAccounts: Array<(
    { __typename?: 'ConsultantBankAccount' }
    & ConsultantBankAccountFragmentFragment
  )>, consultantDocuments: Array<(
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  )>, missions: Array<(
    { __typename?: 'MissionLifecycleBasic' }
    & MissionLifecycleBasicFragmentFragment
  )> }
);

export type FindConsultantQueryQueryVariables = {
  id: Scalars['BigInt'];
};


export type FindConsultantQueryQuery = (
  { __typename?: 'Query' }
  & { findConsultant: (
    { __typename?: 'Consultant' }
    & ConsultantFragmentFragment
  ) }
);

export type ArchiveApplicationMutationMutationVariables = {
  applicantId: Scalars['ID'];
};


export type ArchiveApplicationMutationMutation = (
  { __typename?: 'Mutation' }
  & { archiveApplication: (
    { __typename?: 'ArchiveApplicationPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ApproveApplicationMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type ApproveApplicationMutationMutation = (
  { __typename?: 'Mutation' }
  & { approveApplication: (
    { __typename?: 'ApproveApplicationPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ShortlistApplicantForAssessmentMutationMutationVariables = {
  id: Scalars['ID'];
};


export type ShortlistApplicantForAssessmentMutationMutation = (
  { __typename?: 'Mutation' }
  & { shortlistApplicant: (
    { __typename?: 'ShortlistApplicantPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ActivatePeraltaAccountMutationMutationVariables = {
  id?: Maybe<Scalars['BigInt']>;
  email?: Maybe<Scalars['String']>;
  jobTitleId?: Maybe<Scalars['BigInt']>;
};


export type ActivatePeraltaAccountMutationMutation = (
  { __typename?: 'Mutation' }
  & { activatePeraltaAccount: (
    { __typename?: 'ActivatePeraltaAccountPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RejectApplicationMutationMutationVariables = {
  applicantId: Scalars['ID'];
  rejectionReason: CandidateRejectionTypeEnum;
};


export type RejectApplicationMutationMutation = (
  { __typename?: 'Mutation' }
  & { rejectApplication: (
    { __typename?: 'RejectApplicationPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ApplyForMissionStaffingPositionMutationMutationVariables = {
  id: Scalars['ID'];
  expressionOfInterest: Scalars['String'];
};


export type ApplyForMissionStaffingPositionMutationMutation = (
  { __typename?: 'Mutation' }
  & { applyForMissionStaffingPosition: (
    { __typename?: 'ApplyForMissionStaffingPositionPayload' }
    & { application?: Maybe<(
      { __typename?: 'MissionStaffingApplication' }
      & Pick<MissionStaffingApplication, 'id' | 'state'>
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CancelMissionStaffingApplicationMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type CancelMissionStaffingApplicationMutationMutation = (
  { __typename?: 'Mutation' }
  & { cancelMissionStaffingApplication: (
    { __typename?: 'CancelMissionStaffingApplicationPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type OpenMissionStaffingPositionsQueryQueryVariables = {
  sort?: Maybe<OpenMissionStaffingPositionsSortInput>;
  paginate?: Maybe<PaginationInput>;
  filter?: Maybe<OpenMissionStaffingPositionsFilter>;
};


export type OpenMissionStaffingPositionsQueryQuery = (
  { __typename?: 'Query' }
  & { openMissionStaffingPositions: (
    { __typename?: 'PaginatedOpenMissionStaffingPositions' }
    & { data: Array<(
      { __typename?: 'MissionStaffingPosition' }
      & ListMissionStaffingPositionFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type ListMissionStaffingPositionFragmentFragment = (
  { __typename?: 'MissionStaffingPosition' }
  & Pick<MissionStaffingPosition, 'id' | 'prn' | 'description' | 'name' | 'state' | 'updatedAt' | 'createdAt'>
  & { jobTitle: (
    { __typename?: 'JobTitle' }
    & Pick<JobTitle, 'id' | 'name'>
  ), staffingPositionDeliverables: Array<(
    { __typename?: 'StaffingPositionDeliverable' }
    & Pick<StaffingPositionDeliverable, 'id' | 'completed' | 'name'>
    & { missionStaffingPosition: (
      { __typename?: 'MissionStaffingPosition' }
      & Pick<MissionStaffingPosition, 'id' | 'prn' | 'name'>
      & { totalFee: (
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      ) }
    ) }
  )>, missionLifecycle: (
    { __typename?: 'MissionLifecycleBasic' }
    & Pick<MissionLifecycleBasic, 'id' | 'name' | 'projectLocationCountryCode' | 'projectLocationType' | 'startDate' | 'tagList'>
    & { scopeService?: Maybe<(
      { __typename?: 'ScopeService' }
      & Pick<ScopeService, 'id' | 'name'>
      & { serviceGroup: (
        { __typename?: 'ScopeServiceGroup' }
        & Pick<ScopeServiceGroup, 'id' | 'name'>
      ) }
    )> }
  ), requiredExpertise: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )> }
);

export type UpdateConsultantProfileMutationMutationVariables = {
  applicant?: Maybe<ApplicantInput>;
  profile: ConsultantProfileInput;
  profilePresentation?: Maybe<UploadFileInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
};


export type UpdateConsultantProfileMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantProfile: (
    { __typename?: 'UpdateConsultantProfilePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateConsultantJobTitlesMutationMutationVariables = {
  consultantId: Scalars['ID'];
  jobTitles: Array<Scalars['BigInt']>;
};


export type UpdateConsultantJobTitlesMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantJobTitles: (
    { __typename?: 'UpdateConsultantJobTitlesPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateConsultantAvailabilityMutationMutationVariables = {
  availability: ConsultantAvailability;
  availabilityReason?: Maybe<Scalars['String']>;
  consultantId?: Maybe<Scalars['BigInt']>;
  missionsLimit?: Maybe<Scalars['Int']>;
};


export type UpdateConsultantAvailabilityMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantAvailability: (
    { __typename?: 'UpdateConsultantAvailabilityPayload' }
    & { consultant?: Maybe<(
      { __typename?: 'ConsultantProfile' }
      & ConsultantProfileFragmentFragment
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ConsultantFragmentFragment = (
  { __typename?: 'Consultant' }
  & Pick<Consultant, 'id' | 'firstName' | 'lastName' | 'createdAt' | 'name'>
  & { jobTitle?: Maybe<(
    { __typename?: 'JobTitle' }
    & Pick<JobTitle, 'id' | 'name'>
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
    & { avatar?: Maybe<(
      { __typename?: 'UploadFile' }
      & UploadFileFragmentFragment
    )> }
  )> }
);

export type ConsultantEducationFragmentFragment = (
  { __typename?: 'ConsultantEducation' }
  & Pick<ConsultantEducation, 'degree' | 'startDate' | 'endDate' | 'grade' | 'id' | 'institutionId' | 'institutionName' | 'name'>
  & { institution?: Maybe<(
    { __typename?: 'Institution' }
    & Pick<Institution, 'id' | 'name'>
  )> }
);

export type ConsultantWorkExperienceFragmentFragment = (
  { __typename?: 'ConsultantWorkExperience' }
  & Pick<ConsultantWorkExperience, 'companyId' | 'description' | 'endDate' | 'id' | 'jobTitle' | 'otherCompany' | 'startDate'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>, consultantWorkExperienceLocations: Array<(
    { __typename?: 'ConsultantWorkExperienceLocation' }
    & Pick<ConsultantWorkExperienceLocation, 'countryId' | 'id'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    ) }
  )> }
);

export type AddConsultantWorkExperienceMutationMutationVariables = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  workExperience: ConsultantWorkExperienceInput;
};


export type AddConsultantWorkExperienceMutationMutation = (
  { __typename?: 'Mutation' }
  & { addConsultantWorkExperience: (
    { __typename?: 'AddConsultantWorkExperiencePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, workExperience?: Maybe<(
      { __typename?: 'ConsultantWorkExperience' }
      & ConsultantWorkExperienceFragmentFragment
    )> }
  ) }
);

export type UpdateConsultantWorkExperienceMutationMutationVariables = {
  applicant?: Maybe<ApplicantInput>;
  workExperienceId: Scalars['BigInt'];
  consultantId?: Maybe<Scalars['BigInt']>;
  workExperience: ConsultantWorkExperienceInput;
};


export type UpdateConsultantWorkExperienceMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantWorkExperience: (
    { __typename?: 'UpdateConsultantWorkExperiencePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, workExperience?: Maybe<(
      { __typename?: 'ConsultantWorkExperience' }
      & ConsultantWorkExperienceFragmentFragment
    )> }
  ) }
);

export type DeleteConsultantWorkExperienceMutationMutationVariables = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  workExperienceId: Scalars['BigInt'];
};


export type DeleteConsultantWorkExperienceMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteConsultantWorkExperience: (
    { __typename?: 'DeleteConsultantWorkExperiencePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type AddConsultantEducationMutationMutationVariables = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  education: ConsultantEducationInput;
};


export type AddConsultantEducationMutationMutation = (
  { __typename?: 'Mutation' }
  & { addConsultantEducation: (
    { __typename?: 'AddConsultantEducationPayload' }
    & { education?: Maybe<(
      { __typename?: 'ConsultantEducation' }
      & ConsultantEducationFragmentFragment
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateConsultantEducationMutationMutationVariables = {
  applicant?: Maybe<ApplicantInput>;
  educationId: Scalars['BigInt'];
  education: ConsultantEducationInput;
  consultantId?: Maybe<Scalars['BigInt']>;
};


export type UpdateConsultantEducationMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantEducation: (
    { __typename?: 'UpdateConsultantEducationPayload' }
    & { education?: Maybe<(
      { __typename?: 'ConsultantEducation' }
      & ConsultantEducationFragmentFragment
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DeleteConsultantEducationMutationMutationVariables = {
  applicant?: Maybe<ApplicantInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
  educationId: Scalars['BigInt'];
};


export type DeleteConsultantEducationMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteConsultantEducation: (
    { __typename?: 'DeleteConsultantEducationPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type AddConsultantNationalityMutationMutationVariables = {
  applicant?: Maybe<ApplicantInput>;
  countryId: Scalars['BigInt'];
  consultantId?: Maybe<Scalars['BigInt']>;
};


export type AddConsultantNationalityMutationMutation = (
  { __typename?: 'Mutation' }
  & { addConsultantNationality: (
    { __typename?: 'AddConsultantNationalityPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, nationality?: Maybe<(
      { __typename?: 'ConsultantNationality' }
      & Pick<ConsultantNationality, 'countryId' | 'id'>
      & { country: (
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type DeleteConsultantNationalityMutationMutationVariables = {
  applicant?: Maybe<ApplicantInput>;
  nationalityId: Scalars['BigInt'];
  consultantId?: Maybe<Scalars['BigInt']>;
};


export type DeleteConsultantNationalityMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteConsultantNationality: (
    { __typename?: 'DeleteConsultantNationalityPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type AddConsultantDocumentMutationMutationVariables = {
  applicant?: Maybe<ApplicantInput>;
  uploadFile: UploadFileInput;
};


export type AddConsultantDocumentMutationMutation = (
  { __typename?: 'Mutation' }
  & { addConsultantDocument: (
    { __typename?: 'AddConsultantDocumentPayload' }
    & { consultantDocument?: Maybe<(
      { __typename?: 'ConsultantApplicationDocument' }
      & Pick<ConsultantApplicationDocument, 'id'>
      & { uploadFile?: Maybe<(
        { __typename?: 'UploadFile' }
        & UploadFileFragmentFragment
      )> }
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DeleteConsultantDocumentMutationMutationVariables = {
  applicant?: Maybe<ApplicantInput>;
  uploadFileId: Scalars['BigInt'];
};


export type DeleteConsultantDocumentMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteConsultantDocument: (
    { __typename?: 'DeleteConsultantDocumentPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type AddConsultantSpokenLanguageMutationMutationVariables = {
  applicant?: Maybe<ApplicantInput>;
  consultantLanguage: ConsultantSpokenLanguageInput;
  consultantId?: Maybe<Scalars['BigInt']>;
};


export type AddConsultantSpokenLanguageMutationMutation = (
  { __typename?: 'Mutation' }
  & { addConsultantSpokenLanguage: (
    { __typename?: 'AddConsultantSpokenLanguagePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, spokenLanguage?: Maybe<(
      { __typename?: 'ConsultantSpokenLanguage' }
      & Pick<ConsultantSpokenLanguage, 'fluency' | 'id' | 'languageId'>
      & { language: (
        { __typename?: 'Language' }
        & Pick<Language, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type DeleteConsultantSpokenLanguageMutationMutationVariables = {
  applicant?: Maybe<ApplicantInput>;
  languageId: Scalars['BigInt'];
  consultantId?: Maybe<Scalars['BigInt']>;
};


export type DeleteConsultantSpokenLanguageMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteConsultantSpokenLanguage: (
    { __typename?: 'DeleteConsultantSpokenLanguagePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ConsultantMissionRolesQueryQueryVariables = {
  openPositionsSort?: Maybe<OpenMissionStaffingPositionsSortInput>;
  applicationsSort?: Maybe<MissionStaffingApplicationsSortInput>;
  openPositionsFilter?: Maybe<OpenMissionStaffingPositionsFilter>;
  applicationsFilter?: Maybe<MissionStaffingApplicationsFilter>;
  paginateOpen?: Maybe<PaginationInput>;
  paginateApplications?: Maybe<PaginationInput>;
};


export type ConsultantMissionRolesQueryQuery = (
  { __typename?: 'Query' }
  & { openMissionStaffingPositions: (
    { __typename?: 'PaginatedOpenMissionStaffingPositions' }
    & { data: Array<(
      { __typename?: 'MissionStaffingPosition' }
      & ListMissionStaffingPositionFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ), consultantProfile?: Maybe<(
    { __typename?: 'ConsultantProfile' }
    & Pick<ConsultantProfile, 'id'>
    & { missionStaffingApplications?: Maybe<(
      { __typename?: 'PaginatedMissionStaffingApplications' }
      & { data: Array<(
        { __typename?: 'MissionStaffingApplication' }
        & MissionStaffingApplicationFragmentFragment
      )>, pagination: (
        { __typename?: 'PaginationV2' }
        & PaginationFragmentFragment
      ) }
    )> }
  )> }
);

export type ConsultantOpenOpportunitiesQueryQueryVariables = {
  sort?: Maybe<OpenMissionStaffingPositionsSortInput>;
  filter?: Maybe<OpenMissionStaffingPositionsFilter>;
  paginate?: Maybe<PaginationInput>;
};


export type ConsultantOpenOpportunitiesQueryQuery = (
  { __typename?: 'Query' }
  & { openMissionStaffingPositions: (
    { __typename?: 'PaginatedOpenMissionStaffingPositions' }
    & { data: Array<(
      { __typename?: 'MissionStaffingPosition' }
      & ListMissionStaffingPositionFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type ConsultantApplicationsQueryQueryVariables = {
  sort?: Maybe<MissionStaffingApplicationsSortInput>;
  filter?: Maybe<MissionStaffingApplicationsFilter>;
  paginate?: Maybe<PaginationInput>;
};


export type ConsultantApplicationsQueryQuery = (
  { __typename?: 'Query' }
  & { consultantProfile?: Maybe<(
    { __typename?: 'ConsultantProfile' }
    & Pick<ConsultantProfile, 'id'>
    & { missionStaffingApplications?: Maybe<(
      { __typename?: 'PaginatedMissionStaffingApplications' }
      & { data: Array<(
        { __typename?: 'MissionStaffingApplication' }
        & MissionStaffingApplicationFragmentFragment
      )>, pagination: (
        { __typename?: 'PaginationV2' }
        & PaginationFragmentFragment
      ) }
    )> }
  )> }
);

export type ListApplicantsQueryQueryVariables = {
  filter?: Maybe<ApplicantsFilter>;
  sort?: Maybe<ApplicantsSortInput>;
  page?: Maybe<Scalars['Int']>;
  per: Scalars['Int'];
};


export type ListApplicantsQueryQuery = (
  { __typename?: 'Query' }
  & { applicants: (
    { __typename?: 'PaginatedApplicants' }
    & { data: Array<(
      { __typename?: 'Applicant' }
      & Pick<Applicant, 'id' | 'phone' | 'createdAt' | 'emailAddress' | 'firstName' | 'lastName' | 'jobRole' | 'phoneNumber' | 'state' | 'referrer' | 'gender' | 'city' | 'dateOfBirth' | 'scatterId'>
      & { country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name'>
      )> }
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type ApplicantDetailQueryQueryVariables = {
  filter?: Maybe<ApplicantsFilter>;
};


export type ApplicantDetailQueryQuery = (
  { __typename?: 'Query' }
  & { applicants: (
    { __typename?: 'PaginatedApplicants' }
    & { data: Array<(
      { __typename?: 'Applicant' }
      & ApplicantFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type MyContractsQueryQueryVariables = {
  filter?: Maybe<ConsultantContractsFilter>;
  sort?: Maybe<ConsultantContractsSortInput>;
  paginate?: Maybe<PaginationInput>;
  scope?: Maybe<ConsultantContractScope>;
};


export type MyContractsQueryQuery = (
  { __typename?: 'Query' }
  & { consultantProfile?: Maybe<(
    { __typename?: 'ConsultantProfile' }
    & Pick<ConsultantProfile, 'id'>
    & { consultantContracts: (
      { __typename?: 'PaginatedConsultantContracts' }
      & { data: Array<(
        { __typename?: 'ConsultantContract' }
        & ConsultantContractFragmentFragment
      )>, pagination: (
        { __typename?: 'PaginationV2' }
        & PaginationFragmentFragment
      ) }
    ) }
  )> }
);

export type ConsultantContractsQueryQueryVariables = {
  filter?: Maybe<ConsultantContractsFilter>;
  sort?: Maybe<ConsultantContractsSortInput>;
  paginate?: Maybe<PaginationInput>;
  scope?: Maybe<ConsultantContractScope>;
};


export type ConsultantContractsQueryQuery = (
  { __typename?: 'Query' }
  & { consultantContracts: (
    { __typename?: 'PaginatedConsultantContracts' }
    & { data: Array<(
      { __typename?: 'ConsultantContract' }
      & ConsultantContractFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type CreateConsultantPayoneerAccountMutationMutationVariables = {
  customerId: Scalars['String'];
  emailAddress: Scalars['String'];
};


export type CreateConsultantPayoneerAccountMutationMutation = (
  { __typename?: 'Mutation' }
  & { createConsultantPayoneerAccount: (
    { __typename?: 'CreateConsultantPayoneerAccountPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, payoneerAccount?: Maybe<(
      { __typename?: 'ConsultantPayoneerAccount' }
      & Pick<ConsultantPayoneerAccount, 'createdAt' | 'customerId' | 'emailAddress' | 'id' | 'state' | 'updatedAt'>
    )> }
  ) }
);

export type CreateConsultantBankAccountMutationMutationVariables = {
  bankDetails: ConsultantBankAccountInput;
};


export type CreateConsultantBankAccountMutationMutation = (
  { __typename?: 'Mutation' }
  & { createConsultantBankAccount: (
    { __typename?: 'CreateConsultantBankAccountPayload' }
    & { bankAccount?: Maybe<(
      { __typename?: 'ConsultantBankAccount' }
      & ConsultantBankAccountFragmentFragment
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ConsultantBankAccountFragmentFragment = (
  { __typename?: 'ConsultantBankAccount' }
  & Pick<ConsultantBankAccount, 'accountName' | 'accountNumber' | 'bankName' | 'createdAt' | 'id' | 'routingNumber' | 'sortCode' | 'state' | 'swiftCode' | 'bankAddress' | 'updatedAt'>
  & { country: (
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  ) }
);

export type ArchiveConsultantPayoneerAccountMutationMutationVariables = {
  accountId: Scalars['ID'];
};


export type ArchiveConsultantPayoneerAccountMutationMutation = (
  { __typename?: 'Mutation' }
  & { archiveConsultantPayoneerAccount: (
    { __typename?: 'ArchiveConsultantPayoneerAccountPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ArchiveConsultantBankAccountMutationMutationVariables = {
  accountId: Scalars['ID'];
};


export type ArchiveConsultantBankAccountMutationMutation = (
  { __typename?: 'Mutation' }
  & { archiveConsultantBankAccount: (
    { __typename?: 'ArchiveConsultantBankAccountPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateConsultantPayoneerAccountMutationMutationVariables = {
  payoneerAccountId: Scalars['ID'];
  customerId: Scalars['String'];
  emailAddress: Scalars['String'];
};


export type UpdateConsultantPayoneerAccountMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantPayoneerAccount: (
    { __typename?: 'UpdateConsultantPayoneerAccountPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, payoneerAccount?: Maybe<(
      { __typename?: 'ConsultantPayoneerAccount' }
      & Pick<ConsultantPayoneerAccount, 'id' | 'customerId' | 'emailAddress'>
    )> }
  ) }
);

export type UpdateConsultantBankAccountMutationMutationVariables = {
  accountId: Scalars['BigInt'];
  bankDetails: ConsultantBankAccountInput;
};


export type UpdateConsultantBankAccountMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantBankAccount: (
    { __typename?: 'UpdateConsultantBankAccountPayload' }
    & { bankAccount?: Maybe<(
      { __typename?: 'ConsultantBankAccount' }
      & ConsultantBankAccountFragmentFragment
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DeleteConsultantContractMilestoneMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type DeleteConsultantContractMilestoneMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteConsultantContractMilestone: (
    { __typename?: 'DeleteConsultantContractMilestonePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateConsultantContractMilestoneMutationMutationVariables = {
  consultantContractMilestoneId: Scalars['BigInt'];
  consultantContractMilestone: ConsultantContractMilestoneInput;
};


export type UpdateConsultantContractMilestoneMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantContractMilestone: (
    { __typename?: 'UpdateConsultantContractMilestonePayload' }
    & { consultantContract?: Maybe<(
      { __typename?: 'ConsultantContract' }
      & Pick<ConsultantContract, 'id'>
      & { consultantContractMilestones?: Maybe<Array<(
        { __typename?: 'ConsultantContractMilestone' }
        & Pick<ConsultantContractMilestone, 'description' | 'fees' | 'state'>
      )>> }
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type MyPeralteesQueryQueryVariables = {
  filter?: Maybe<ConsultantPeralteeClaimsFilter>;
};


export type MyPeralteesQueryQuery = (
  { __typename?: 'Query' }
  & { consultantProfile?: Maybe<(
    { __typename?: 'ConsultantProfile' }
    & Pick<ConsultantProfile, 'id'>
    & { peralteeClaims: (
      { __typename?: 'PaginatedConsultantPeralteeClaims' }
      & { data: Array<(
        { __typename?: 'ConsultantPeralteeClaim' }
        & Pick<ConsultantPeralteeClaim, 'id' | 'comment' | 'state' | 'description' | 'createdAt'>
        & { supportService: (
          { __typename?: 'PeralteeSupportService' }
          & Pick<PeralteeSupportService, 'id' | 'points' | 'description' | 'name'>
        ) }
      )>, pagination: (
        { __typename?: 'PaginationV2' }
        & PaginationFragmentFragment
      ) }
    ) }
  )> }
);

export type RequestConsultantPeralteeClaimMutationMutationVariables = {
  supportServiceId: Scalars['BigInt'];
  description: Scalars['String'];
};


export type RequestConsultantPeralteeClaimMutationMutation = (
  { __typename?: 'Mutation' }
  & { requestConsultantPeralteeClaim: (
    { __typename?: 'RequestConsultantPeralteeClaimPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CancelConsultantPeralteeClaimRequestMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type CancelConsultantPeralteeClaimRequestMutationMutation = (
  { __typename?: 'Mutation' }
  & { cancelConsultantPeralteeClaimRequest: (
    { __typename?: 'CancelConsultantPeralteeClaimRequestPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type TrainingTagsQueryQueryVariables = {};


export type TrainingTagsQueryQuery = (
  { __typename?: 'Query' }
  & { trainingTags: (
    { __typename?: 'PaginatedTrainingTags' }
    & { data: Array<(
      { __typename?: 'TrainingTag' }
      & Pick<TrainingTag, 'id' | 'name'>
    )> }
  ) }
);

export type SetConsultantTrainingTagsMutationMutationVariables = {
  consultantId: Scalars['BigInt'];
  trainingTags: Array<Scalars['String']>;
};


export type SetConsultantTrainingTagsMutationMutation = (
  { __typename?: 'Mutation' }
  & { setConsultantTrainingTags: (
    { __typename?: 'SetConsultantTrainingTagsPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ConsultantBillingAccountsQueryQueryVariables = {};


export type ConsultantBillingAccountsQueryQuery = (
  { __typename?: 'Query' }
  & { consultantProfile?: Maybe<(
    { __typename?: 'ConsultantProfile' }
    & Pick<ConsultantProfile, 'id'>
    & { consultantBankAccounts: Array<(
      { __typename?: 'ConsultantBankAccount' }
      & ConsultantBankAccountFragmentFragment
    )>, consultantPayoneerAccounts: Array<(
      { __typename?: 'ConsultantPayoneerAccount' }
      & Pick<ConsultantPayoneerAccount, 'createdAt' | 'customerId' | 'emailAddress' | 'id' | 'state' | 'updatedAt'>
    )> }
  )> }
);

export type SetMissionEngagementLetterMutationMutationVariables = {
  missionId: Scalars['BigInt'];
  uploadFile: UploadFileInput;
};


export type SetMissionEngagementLetterMutationMutation = (
  { __typename?: 'Mutation' }
  & { setMissionEngagementLetter: (
    { __typename?: 'SetMissionEngagementLetterPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type SetMissionSignedContractMutationMutationVariables = {
  missionId: Scalars['BigInt'];
  uploadFile: UploadFileInput;
};


export type SetMissionSignedContractMutationMutation = (
  { __typename?: 'Mutation' }
  & { setMissionSignedContract: (
    { __typename?: 'SetMissionSignedContractPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CreateMissionContractPaymentMutationMutationVariables = {
  missionLifecycleId: Scalars['ID'];
  description: Scalars['String'];
  amount: Scalars['Int'];
  paymentType: ContractPayment;
  dueDate: Scalars['ISO8601Date'];
  deliverables?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
};


export type CreateMissionContractPaymentMutationMutation = (
  { __typename?: 'Mutation' }
  & { createMissionContractPayment: (
    { __typename?: 'CreateMissionContractPaymentPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, missionContractPayment?: Maybe<(
      { __typename?: 'MissionContractPayment' }
      & Pick<MissionContractPayment, 'id'>
    )> }
  ) }
);

export type UpdateMissionContractPaymentMutationMutationVariables = {
  missionContractPaymentId: Scalars['ID'];
  description: Scalars['String'];
  amount: Scalars['Float'];
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  deliverables?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<MissionContractPaymentState>;
};


export type UpdateMissionContractPaymentMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateMissionContractPayment: (
    { __typename?: 'UpdateMissionContractPaymentPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CreateMissionContractDeliverableMutationMutationVariables = {
  contractPaymentId: Scalars['BigInt'];
  name: Scalars['String'];
  description: Scalars['String'];
  dueDate: Scalars['ISO8601Date'];
};


export type CreateMissionContractDeliverableMutationMutation = (
  { __typename?: 'Mutation' }
  & { createMissionContractDeliverable: (
    { __typename?: 'CreateMissionContractDeliverablePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DeleteMissionContractDeliverableMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type DeleteMissionContractDeliverableMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteMissionContractDeliverable: (
    { __typename?: 'DeleteMissionContractDeliverablePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CreateClientContractMutationMutationVariables = {
  contractTerms: MissionContractTermsInput;
};


export type CreateClientContractMutationMutation = (
  { __typename?: 'Mutation' }
  & { createClientContract: (
    { __typename?: 'CreateClientContractPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, missionContract?: Maybe<(
      { __typename?: 'MissionContract' }
      & Pick<MissionContract, 'id'>
    )> }
  ) }
);

export type UpdateClientContractMutationMutationVariables = {
  contractTermsId: Scalars['BigInt'];
  contractTerms: MissionContractTermsInput;
};


export type UpdateClientContractMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateClientContract: (
    { __typename?: 'UpdateClientContractPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type BankAccountsListQueryQueryVariables = {
  filter?: Maybe<BankAccountsFilter>;
  sort?: Maybe<BankAccountsSortInput>;
  per?: Maybe<Scalars['Int']>;
};


export type BankAccountsListQueryQuery = (
  { __typename?: 'Query' }
  & { bankAccounts: (
    { __typename?: 'PaginatedBankAccounts' }
    & { data: Array<(
      { __typename?: 'BankAccount' }
      & Pick<BankAccount, 'accountAlias' | 'accountName' | 'accountNumber' | 'bankAddress' | 'bankName' | 'createdAt' | 'iban' | 'id' | 'sortCode' | 'swiftCode'>
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type DeleteMissionContractPaymentMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type DeleteMissionContractPaymentMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteMissionContractPayment: (
    { __typename?: 'DeleteMissionContractPaymentPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CreateConsultantContractMutationMutationVariables = {
  consultantContract: ConsultantContractInput;
};


export type CreateConsultantContractMutationMutation = (
  { __typename?: 'Mutation' }
  & { createConsultantContract: (
    { __typename?: 'CreateConsultantContractPayload' }
    & { consultantContract?: Maybe<(
      { __typename?: 'ConsultantContract' }
      & ConsultantContractFragmentFragment
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RequestConsultantContractApprovalMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type RequestConsultantContractApprovalMutationMutation = (
  { __typename?: 'Mutation' }
  & { requestConsultantContractApproval: (
    { __typename?: 'RequestConsultantContractApprovalPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, consultantContract?: Maybe<(
      { __typename?: 'ConsultantContract' }
      & Pick<ConsultantContract, 'id' | 'state'>
    )> }
  ) }
);

export type UpdateConsultantContractMutationMutationVariables = {
  id: Scalars['BigInt'];
  consultantContract: ConsultantContractInput;
};


export type UpdateConsultantContractMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantContract: (
    { __typename?: 'UpdateConsultantContractPayload' }
    & { consultantContract?: Maybe<(
      { __typename?: 'ConsultantContract' }
      & Pick<ConsultantContract, 'id'>
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type AcceptConsultantContractMutationMutationVariables = {
  id: Scalars['ID'];
};


export type AcceptConsultantContractMutationMutation = (
  { __typename?: 'Mutation' }
  & { acceptConsultantContract: (
    { __typename?: 'AcceptConsultantContractPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RequestTerminationConsultantContractMutationMutationVariables = {
  id: Scalars['BigInt'];
  terminationReason: Scalars['String'];
};


export type RequestTerminationConsultantContractMutationMutation = (
  { __typename?: 'Mutation' }
  & { requestTerminationConsultantContract: (
    { __typename?: 'RequestTerminationConsultantContractPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DeclineConsultantContractMutationMutationVariables = {
  id: Scalars['BigInt'];
  declineReason: Scalars['String'];
};


export type DeclineConsultantContractMutationMutation = (
  { __typename?: 'Mutation' }
  & { declineConsultantContract: (
    { __typename?: 'DeclineConsultantContractPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DeleteConsultantContractPaymentMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type DeleteConsultantContractPaymentMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteConsultantContractPayment: (
    { __typename?: 'DeleteConsultantContractPaymentPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DeleteStaffingMilestoneMutationMutationVariables = {
  id: Scalars['BigInt'];
  forceDelete: Scalars['Boolean'];
};


export type DeleteStaffingMilestoneMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteStaffingMilestone: (
    { __typename?: 'DeleteStaffingMilestonePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RequestEsignatureMutationMutationVariables = {
  signableId: Scalars['BigInt'];
  signableType: Scalars['String'];
  signers: Array<EsignatureSignerInput>;
};


export type RequestEsignatureMutationMutation = (
  { __typename?: 'Mutation' }
  & { requestEsignature: (
    { __typename?: 'RequestEsignaturePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, esignatureRequest?: Maybe<Array<(
      { __typename?: 'EsignatureRequest' }
      & Pick<EsignatureRequest, 'createdAt' | 'email' | 'id' | 'state' | 'updatedAt'>
      & { requester: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      ), signable: (
        { __typename?: 'MissionContract' }
        & Pick<MissionContract, 'id'>
      ), signer?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )> }
    )>> }
  ) }
);

export type CommentsQueryQueryVariables = {
  filter?: Maybe<ClientMissionsFilter>;
  sort?: Maybe<ClientMissionsSortInput>;
  page?: Maybe<Scalars['Int']>;
  per: Scalars['Int'];
};


export type CommentsQueryQuery = (
  { __typename?: 'Query' }
  & { clientMissions?: Maybe<(
    { __typename?: 'PaginatedClientMissions' }
    & { data: Array<(
      { __typename?: 'MissionLifecycle' }
      & { defaultConversation?: Maybe<(
        { __typename?: 'Conversation' }
        & Pick<Conversation, 'prn'>
        & { comments: Array<(
          { __typename?: 'Comment' }
          & Pick<Comment, 'id' | 'prn' | 'text' | 'createdAt' | 'edited' | 'updatedAt'>
          & { file?: Maybe<(
            { __typename?: 'UploadFile' }
            & UploadFileFragmentFragment
          )>, conversation: (
            { __typename?: 'Conversation' }
            & Pick<Conversation, 'id' | 'prn'>
          ), user: (
            { __typename?: 'User' }
            & UserFragmentFragment
          ) }
        )> }
      )> }
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  )> }
);

export type FindConversationQueryQueryVariables = {
  id: Scalars['ID'];
};


export type FindConversationQueryQuery = (
  { __typename?: 'Query' }
  & { findConversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'conversableId' | 'conversableType' | 'createdAt' | 'id' | 'prn' | 'updatedAt'>
    & { comments: Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'prn' | 'text' | 'createdAt' | 'edited' | 'updatedAt'>
      & { file?: Maybe<(
        { __typename?: 'UploadFile' }
        & UploadFileFragmentFragment
      )>, conversation: (
        { __typename?: 'Conversation' }
        & Pick<Conversation, 'id' | 'prn'>
      ), user: (
        { __typename?: 'User' }
        & UserFragmentFragment
      ) }
    )> }
  )> }
);

export type AddCommentMutationMutationVariables = {
  conversationId: Scalars['ID'];
  fileInput?: Maybe<UploadFileInput>;
  text: Scalars['String'];
};


export type AddCommentMutationMutation = (
  { __typename?: 'Mutation' }
  & { addComment: (
    { __typename?: 'AddCommentPayload' }
    & { comment?: Maybe<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'prn' | 'text' | 'createdAt' | 'edited' | 'updatedAt'>
      & { file?: Maybe<(
        { __typename?: 'UploadFile' }
        & UploadFileFragmentFragment
      )>, conversation: (
        { __typename?: 'Conversation' }
        & Pick<Conversation, 'id' | 'prn'>
      ), user: (
        { __typename?: 'User' }
        & UserFragmentFragment
      ) }
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CurrencyFragmentFragment = (
  { __typename?: 'Currency' }
  & Pick<Currency, 'id' | 'code' | 'name' | 'symbol' | 'factor'>
);

export type ClientFragmentFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'address' | 'id' | 'missionsCount' | 'name' | 'proposalsCount' | 'taxExempt'>
  & { approval?: Maybe<(
    { __typename?: 'Approval' }
    & ApprovalFragmentFragment
  )>, clientType: (
    { __typename?: 'ClientType' }
    & Pick<ClientType, 'id' | 'name'>
  ), country: (
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  ) }
);

export type ActivityLogFragmentFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'action' | 'actionComposition' | 'actionables' | 'createdAt' | 'id' | 'updatedAt' | 'initiatorType'>
  & { initiator: (
    { __typename?: 'ActivityInitiator' }
    & Pick<ActivityInitiator, 'name'>
    & { avatar?: Maybe<(
      { __typename?: 'UploadFile' }
      & Pick<UploadFile, 'url'>
    )> }
  ) }
);

export type CommentFragmentFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'createdAt' | 'edited' | 'id' | 'prn' | 'text' | 'updatedAt'>
  & { user: (
    { __typename?: 'User' }
    & UserFragmentFragment
  ) }
);

export type InvoiceDetailQueryQueryVariables = {
  filter?: Maybe<ConsultantInvoicesFilter>;
};


export type InvoiceDetailQueryQuery = (
  { __typename?: 'Query' }
  & { consultantInvoices: (
    { __typename?: 'PaginatedConsultantInvoices' }
    & { data: Array<(
      { __typename?: 'ConsultantInvoice' }
      & Pick<ConsultantInvoice, 'id' | 'createdAt' | 'dueAt' | 'paidAt' | 'paymentDescription' | 'state' | 'updatedAt' | 'total'>
      & { payment?: Maybe<(
        { __typename?: 'ConsultantContractPayment' }
        & Pick<ConsultantContractPayment, 'id' | 'description'>
        & { missionStaffingPosition: (
          { __typename?: 'MissionStaffingPosition' }
          & Pick<MissionStaffingPosition, 'id' | 'name'>
        ), amount: (
          { __typename?: 'Money' }
          & MoneyFragmentFragment
        ) }
      )>, missionLifecycle: (
        { __typename?: 'MissionLifecycle' }
        & Pick<MissionLifecycle, 'id' | 'name'>
      ), activityLogs: Array<(
        { __typename?: 'ActivityLog' }
        & ActivityLogFragmentFragment
      )>, invoiceItems: Array<(
        { __typename?: 'ConsultantInvoiceItem' }
        & Pick<ConsultantInvoiceItem, 'id' | 'description' | 'quantity' | 'unitCost'>
      )>, consultant: (
        { __typename?: 'ConsultantProfile' }
        & Pick<ConsultantProfile, 'id' | 'name' | 'postalAddress' | 'email' | 'phone'>
        & { user?: Maybe<(
          { __typename?: 'UserAdvanced' }
          & Pick<UserAdvanced, 'id'>
        )> }
      ), billingAccount?: Maybe<(
        { __typename?: 'ConsultantBankAccount' }
        & ConsultantBankAccountFragmentFragment
      ) | (
        { __typename?: 'ConsultantPayoneerAccount' }
        & Pick<ConsultantPayoneerAccount, 'emailAddress' | 'customerId' | 'state'>
      )>, missionStaffingPlacement: (
        { __typename?: 'MissionStaffingPlacement' }
        & Pick<MissionStaffingPlacement, 'id'>
        & { missionStaffingPosition?: Maybe<(
          { __typename?: 'MissionStaffingPosition' }
          & Pick<MissionStaffingPosition, 'id' | 'name'>
        )> }
      ), approvals: Array<(
        { __typename?: 'Approval' }
        & ApprovalFragmentFragment
      )> }
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type MyInvoiceQueryQueryVariables = {
  filter?: Maybe<ConsultantInvoicesFilter>;
};


export type MyInvoiceQueryQuery = (
  { __typename?: 'Query' }
  & { consultantProfile?: Maybe<(
    { __typename?: 'ConsultantProfile' }
    & Pick<ConsultantProfile, 'id'>
    & { consultantInvoices?: Maybe<(
      { __typename?: 'PaginatedConsultantInvoices' }
      & { data: Array<(
        { __typename?: 'ConsultantInvoice' }
        & Pick<ConsultantInvoice, 'id' | 'createdAt' | 'dueAt' | 'invoiceType' | 'paidAt' | 'paymentDescription' | 'state' | 'updatedAt' | 'total'>
        & { payment?: Maybe<(
          { __typename?: 'ConsultantContractPayment' }
          & Pick<ConsultantContractPayment, 'id' | 'description'>
          & { missionStaffingPosition: (
            { __typename?: 'MissionStaffingPosition' }
            & Pick<MissionStaffingPosition, 'id' | 'name'>
          ) }
        )>, missionLifecycle: (
          { __typename?: 'MissionLifecycle' }
          & Pick<MissionLifecycle, 'id' | 'name'>
        ), consultant: (
          { __typename?: 'ConsultantProfile' }
          & Pick<ConsultantProfile, 'id' | 'name' | 'postalAddress' | 'email' | 'phone'>
          & { user?: Maybe<(
            { __typename?: 'UserAdvanced' }
            & Pick<UserAdvanced, 'id'>
          )> }
        ), invoiceItems: Array<(
          { __typename?: 'ConsultantInvoiceItem' }
          & Pick<ConsultantInvoiceItem, 'id' | 'description' | 'quantity' | 'unitCost' | 'type'>
          & { uploadFile?: Maybe<(
            { __typename?: 'UploadFile' }
            & UploadFileFragmentFragment
          )> }
        )>, billingAccount?: Maybe<(
          { __typename?: 'ConsultantBankAccount' }
          & ConsultantBankAccountFragmentFragment
        ) | (
          { __typename?: 'ConsultantPayoneerAccount' }
          & Pick<ConsultantPayoneerAccount, 'id' | 'prn' | 'emailAddress' | 'customerId' | 'state'>
        )>, missionStaffingPlacement: (
          { __typename?: 'MissionStaffingPlacement' }
          & Pick<MissionStaffingPlacement, 'id'>
          & { missionStaffingPosition?: Maybe<(
            { __typename?: 'MissionStaffingPosition' }
            & Pick<MissionStaffingPosition, 'id' | 'name'>
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type SubmitConsultantInvoiceMutationMutationVariables = {
  invoiceId: Scalars['BigInt'];
  billingAccountType: BillingAccountEnum;
  billingAccountId: Scalars['BigInt'];
  expenses?: Maybe<Array<ConsultantMissionExpenseInput>>;
};


export type SubmitConsultantInvoiceMutationMutation = (
  { __typename?: 'Mutation' }
  & { submitConsultantInvoice: (
    { __typename?: 'SubmitConsultantInvoicePayload' }
    & { consultantInvoice?: Maybe<(
      { __typename?: 'ConsultantInvoice' }
      & Pick<ConsultantInvoice, 'id' | 'state'>
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CreateConsultantInvoiceMutationMutationVariables = {
  consultantId: Scalars['BigInt'];
  missionLifecycleId: Scalars['BigInt'];
  missionStaffingPlacementId: Scalars['BigInt'];
  billingAccountType: BillingAccountEnum;
  billingAccountId: Scalars['BigInt'];
  expenses: Array<ConsultantMissionExpenseInput>;
  paymentDescription?: Maybe<Scalars['String']>;
};


export type CreateConsultantInvoiceMutationMutation = (
  { __typename?: 'Mutation' }
  & { createConsultantInvoice: (
    { __typename?: 'CreateConsultantInvoicePayload' }
    & { consultantInvoice?: Maybe<(
      { __typename?: 'ConsultantInvoice' }
      & Pick<ConsultantInvoice, 'id'>
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type QueueInvoiceForPaymentMutationMutationVariables = {
  invoiceId: Scalars['BigInt'];
};


export type QueueInvoiceForPaymentMutationMutation = (
  { __typename?: 'Mutation' }
  & { queueInvoiceForPayment: (
    { __typename?: 'QueueInvoiceForPaymentPayload' }
    & { consultantInvoice?: Maybe<(
      { __typename?: 'ConsultantInvoice' }
      & Pick<ConsultantInvoice, 'id' | 'state'>
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type MarkInvoiceAsPaidMutationMutationVariables = {
  invoiceId: Scalars['BigInt'];
};


export type MarkInvoiceAsPaidMutationMutation = (
  { __typename?: 'Mutation' }
  & { markInvoiceAsPaid: (
    { __typename?: 'MarkInvoiceAsPaidPayload' }
    & { consultantInvoice?: Maybe<(
      { __typename?: 'ConsultantInvoice' }
      & Pick<ConsultantInvoice, 'id' | 'state'>
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type InvoicesListQueryQueryVariables = {
  filter?: Maybe<ConsultantInvoicesFilter>;
  sort?: Maybe<ConsultantInvoicesSortInput>;
  page?: Maybe<Scalars['Int']>;
  per: Scalars['Int'];
};


export type InvoicesListQueryQuery = (
  { __typename?: 'Query' }
  & { consultantInvoices: (
    { __typename?: 'PaginatedConsultantInvoices' }
    & { data: Array<(
      { __typename?: 'ConsultantInvoice' }
      & Pick<ConsultantInvoice, 'id' | 'createdAt' | 'total' | 'state' | 'dueAt'>
      & { consultant: (
        { __typename?: 'ConsultantProfile' }
        & Pick<ConsultantProfile, 'id' | 'name'>
      ), missionLifecycle: (
        { __typename?: 'MissionLifecycle' }
        & Pick<MissionLifecycle, 'id' | 'name'>
      ), missionStaffingPlacement: (
        { __typename?: 'MissionStaffingPlacement' }
        & Pick<MissionStaffingPlacement, 'id'>
        & { missionStaffingPosition?: Maybe<(
          { __typename?: 'MissionStaffingPosition' }
          & Pick<MissionStaffingPosition, 'id' | 'name'>
        )> }
      ) }
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type ClientInvoicesListQueryQueryVariables = {
  filter?: Maybe<ClientInvoicesFilter>;
  sort?: Maybe<ClientInvoicesSortInput>;
  page?: Maybe<Scalars['Int']>;
  per: Scalars['Int'];
};


export type ClientInvoicesListQueryQuery = (
  { __typename?: 'Query' }
  & { clientInvoices: (
    { __typename?: 'PaginatedClientInvoices' }
    & { data: Array<(
      { __typename?: 'MissionContractPayment' }
      & Pick<MissionContractPayment, 'amountCents' | 'createdAt' | 'dueDate' | 'id' | 'invoiceUrl' | 'checkoutUrl' | 'state' | 'updatedAt'>
      & { amount: (
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      ), missionLifecycle: (
        { __typename?: 'MissionLifecycle' }
        & Pick<MissionLifecycle, 'id' | 'name'>
      ) }
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & Pick<PaginationV2, 'currentPage' | 'firstPage' | 'lastPage' | 'nextPage' | 'pageCount' | 'prevPage' | 'totalSize'>
    ) }
  ) }
);

export type FinanceStatisticsQueryQueryVariables = {};


export type FinanceStatisticsQueryQuery = (
  { __typename?: 'Query' }
  & { financeStatistics?: Maybe<Array<(
    { __typename?: 'FinanceDashboardStatistics' }
    & Pick<FinanceDashboardStatistics, 'key' | 'label' | 'value' | 'money'>
  )>> }
);

export type RequestMissionPartnerMutationMutationVariables = {
  missionLifecycleId: Scalars['BigInt'];
  consultantId: Scalars['BigInt'];
};


export type RequestMissionPartnerMutationMutation = (
  { __typename?: 'Mutation' }
  & { requestMissionPartner: (
    { __typename?: 'RequestMissionPartnerPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CreateMissionLeadMutationMutationVariables = {
  missionLead: MissionLeadInput;
};


export type CreateMissionLeadMutationMutation = (
  { __typename?: 'Mutation' }
  & { createMissionLead: (
    { __typename?: 'CreateMissionLeadPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, missionLifecycle?: Maybe<(
      { __typename?: 'MissionLifecycle' }
      & MissionLifecycleFragmentFragment
    )> }
  ) }
);

export type UpdateMissionLeadMutationMutationVariables = {
  missionLifecycleId: Scalars['BigInt'];
  missionLead: MissionLeadInput;
};


export type UpdateMissionLeadMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateMissionLead: (
    { __typename?: 'UpdateMissionLeadPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type SetMissionNameMutationMutationVariables = {
  missionLifecycleId: Scalars['ID'];
  name: Scalars['String'];
};


export type SetMissionNameMutationMutation = (
  { __typename?: 'Mutation' }
  & { setMissionName: (
    { __typename?: 'SetMissionNamePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type SetMissionClientMutationMutationVariables = {
  missionLifecycleId: Scalars['BigInt'];
  clientId: Scalars['BigInt'];
};


export type SetMissionClientMutationMutation = (
  { __typename?: 'Mutation' }
  & { setMissionClient: (
    { __typename?: 'SetMissionClientPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ApproveMissionLeadMutationMutationVariables = {
  missionLifecycleId: Scalars['BigInt'];
};


export type ApproveMissionLeadMutationMutation = (
  { __typename?: 'Mutation' }
  & { approveMissionLead: (
    { __typename?: 'ApproveMissionLeadPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RejectMissionLeadMutationMutationVariables = {
  missionLifecycleId: Scalars['BigInt'];
};


export type RejectMissionLeadMutationMutation = (
  { __typename?: 'Mutation' }
  & { rejectMissionLead: (
    { __typename?: 'RejectMissionLeadPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type SetTermsOfReferenceMutationMutationVariables = {
  missionId: Scalars['ID'];
  uploadFiles: Array<UploadFileInput>;
};


export type SetTermsOfReferenceMutationMutation = (
  { __typename?: 'Mutation' }
  & { setTermsOfReference: (
    { __typename?: 'SetTermsOfReferencePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type StartRfpDevelopmentMutationMutationVariables = {
  rfpDeadline: Scalars['ISO8601Date'];
  missionId: Scalars['BigInt'];
};


export type StartRfpDevelopmentMutationMutation = (
  { __typename?: 'Mutation' }
  & { startRfpDevelopment: (
    { __typename?: 'StartRfpDevelopmentPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, mission?: Maybe<(
      { __typename?: 'MissionLifecycle' }
      & Pick<MissionLifecycle, 'id' | 'state'>
    )> }
  ) }
);

export type CancelMissionStaffingRequestMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type CancelMissionStaffingRequestMutationMutation = (
  { __typename?: 'Mutation' }
  & { cancelMissionStaffingRequest: (
    { __typename?: 'CancelMissionStaffingRequestPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type MissionScopeFragmentFragment = (
  { __typename?: 'MissionScope' }
  & Pick<MissionScope, 'id' | 'targetMissionDuration' | 'capacityAllocation' | 'version' | 'updatedAt'>
  & { scopeDocument?: Maybe<(
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  )>, activityLogs: Array<(
    { __typename?: 'ActivityLog' }
    & ActivityLogFragmentFragment
  )>, phases: Array<(
    { __typename?: 'MissionScopePhase' }
    & Pick<MissionScopePhase, 'id' | 'name' | 'position'>
    & { activities: Array<(
      { __typename?: 'MissionScopeActivity' }
      & Pick<MissionScopeActivity, 'capacities' | 'id' | 'prn' | 'name' | 'position' | 'deliverable' | 'capacityDistribution' | 'completed'>
      & { missionScopePhase: (
        { __typename?: 'MissionScopePhase' }
        & Pick<MissionScopePhase, 'id'>
      ) }
    )> }
  )>, activities: Array<(
    { __typename?: 'MissionScopeActivity' }
    & Pick<MissionScopeActivity, 'capacities' | 'id' | 'prn' | 'name' | 'position' | 'deliverable' | 'capacityDistribution' | 'completed'>
    & { missionScopePhase: (
      { __typename?: 'MissionScopePhase' }
      & Pick<MissionScopePhase, 'id'>
    ) }
  )>, staffs: Array<(
    { __typename?: 'MissionScopeStaff' }
    & Pick<MissionScopeStaff, 'id'>
    & { jobTitle: (
      { __typename?: 'JobTitle' }
      & Pick<JobTitle, 'id' | 'name' | 'abbreviation' | 'dailyRate' | 'capacityFactor'>
    ) }
  )>, collaborators: Array<(
    { __typename?: 'User' }
    & UserFragmentFragment
  )> }
);

export type UpdateMissionScopeActivityMutationMutationVariables = {
  missionScopeActivityId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  capacities?: Maybe<Scalars['JSON']>;
  deliverable?: Maybe<Scalars['Boolean']>;
  capacityDistribution?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
};


export type UpdateMissionScopeActivityMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateMissionScopeActivity: (
    { __typename?: 'UpdateMissionScopeActivityPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type AddMissionScopeStaffMutationMutationVariables = {
  missionScopeId: Scalars['BigInt'];
  jobTitleId: Scalars['BigInt'];
};


export type AddMissionScopeStaffMutationMutation = (
  { __typename?: 'Mutation' }
  & { addMissionScopeStaff: (
    { __typename?: 'AddMissionScopeStaffPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, staff?: Maybe<(
      { __typename?: 'MissionScopeStaff' }
      & Pick<MissionScopeStaff, 'id'>
    )> }
  ) }
);

export type DeleteMissionScopeStaffMutationMutationVariables = {
  missionScopeStaffId: Scalars['BigInt'];
};


export type DeleteMissionScopeStaffMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteMissionScopeStaff: (
    { __typename?: 'DeleteMissionScopeStaffPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DeleteMissionScopeActivityMutationMutationVariables = {
  activityId: Scalars['BigInt'];
};


export type DeleteMissionScopeActivityMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteMissionScopeActivity: (
    { __typename?: 'DeleteMissionScopeActivityPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type AddMissionScopePhaseMutationMutationVariables = {
  missionScopeId: Scalars['BigInt'];
  name: Scalars['String'];
};


export type AddMissionScopePhaseMutationMutation = (
  { __typename?: 'Mutation' }
  & { addMissionScopePhase: (
    { __typename?: 'AddMissionScopePhasePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, missionScopePhase?: Maybe<(
      { __typename?: 'MissionScopePhase' }
      & Pick<MissionScopePhase, 'id' | 'name'>
    )> }
  ) }
);

export type UpdateMissionScopePhaseMutationMutationVariables = {
  phaseId: Scalars['BigInt'];
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};


export type UpdateMissionScopePhaseMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateMissionScopePhase: (
    { __typename?: 'UpdateMissionScopePhasePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, missionScopePhase?: Maybe<(
      { __typename?: 'MissionScopePhase' }
      & Pick<MissionScopePhase, 'id'>
    )> }
  ) }
);

export type DeleteMissionScopePhaseMutationMutationVariables = {
  id: Scalars['BigInt'];
  forceDelete: Scalars['Boolean'];
};


export type DeleteMissionScopePhaseMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteMissionScopePhase: (
    { __typename?: 'DeleteMissionScopePhasePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateMissionScopeMutationMutationVariables = {
  id: Scalars['BigInt'];
  targetMissionDuration?: Maybe<Scalars['Float']>;
  capacityAllocation?: Maybe<Scalars['Int']>;
};


export type UpdateMissionScopeMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateMissionScope: (
    { __typename?: 'UpdateMissionScopePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DeleteMissionMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type DeleteMissionMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteMission: (
    { __typename?: 'DeleteMissionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type GenerateMissionStaffingPositionsMutationMutationVariables = {
  id: Scalars['ID'];
};


export type GenerateMissionStaffingPositionsMutationMutation = (
  { __typename?: 'Mutation' }
  & { generateMissionStaffingPositions: (
    { __typename?: 'GenerateMissionStaffingPositionsPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, staffingPositions?: Maybe<Array<(
      { __typename?: 'MissionStaffingPosition' }
      & Pick<MissionStaffingPosition, 'createdAt' | 'description' | 'id'>
    )>> }
  ) }
);

export type GenerateConsultantPaymentStructureMutationMutationVariables = {
  missionStaffingPositionId: Scalars['ID'];
  paymentsInputs: Array<ConsultantPaymentStructureInput>;
};


export type GenerateConsultantPaymentStructureMutationMutation = (
  { __typename?: 'Mutation' }
  & { generateConsultantPaymentStructure: (
    { __typename?: 'GenerateConsultantPaymentStructurePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, payments?: Maybe<Array<(
      { __typename?: 'ConsultantContractPayment' }
      & Pick<ConsultantContractPayment, 'amountCents' | 'createdAt' | 'description' | 'dueDate' | 'id' | 'paymentType' | 'percentage' | 'state' | 'updatedAt'>
      & { amount: (
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      ), missionStaffingPosition: (
        { __typename?: 'MissionStaffingPosition' }
        & MissionStaffingPositionDetailFragmentFragment
      ) }
    )>> }
  ) }
);

export type CreateConsultantMissionExpenseMutationMutationVariables = {
  invoiceId: Scalars['BigInt'];
  expense: ConsultantMissionExpenseInput;
};


export type CreateConsultantMissionExpenseMutationMutation = (
  { __typename?: 'Mutation' }
  & { addConsultantMissionExpense: (
    { __typename?: 'AddConsultantMissionExpensePayload' }
    & { missionExpense?: Maybe<(
      { __typename?: 'ConsultantMissionExpense' }
      & Pick<ConsultantMissionExpense, 'id'>
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateConsultantMissionExpenseMutationMutationVariables = {
  expenseId: Scalars['BigInt'];
  expense: ConsultantMissionExpenseInput;
};


export type UpdateConsultantMissionExpenseMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantMissionExpense: (
    { __typename?: 'UpdateConsultantMissionExpensePayload' }
    & { missionExpense?: Maybe<(
      { __typename?: 'ConsultantMissionExpense' }
      & Pick<ConsultantMissionExpense, 'id'>
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type MissionLifecycleBasicFragmentFragment = (
  { __typename?: 'MissionLifecycleBasic' }
  & Pick<MissionLifecycleBasic, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'prn' | 'completionProgress' | 'summary' | 'projectLocationCountryCode' | 'projectLocationType' | 'endDate' | 'startDate' | 'state' | 'tagList'>
  & { scopeService?: Maybe<(
    { __typename?: 'ScopeService' }
    & Pick<ScopeService, 'id' | 'name'>
    & { serviceGroup: (
      { __typename?: 'ScopeServiceGroup' }
      & Pick<ScopeServiceGroup, 'id' | 'name'>
    ) }
  )>, completionProgressActivityLog?: Maybe<(
    { __typename?: 'ActivityLog' }
    & Pick<ActivityLog, 'id' | 'updatedAt'>
    & { updatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName'>
    )> }
  )>, activityLogs: Array<(
    { __typename?: 'ActivityLog' }
    & Pick<ActivityLog, 'id' | 'createdAt' | 'updatedAt' | 'action' | 'initiatorType' | 'actionComposition' | 'actionables'>
    & { initiator: (
      { __typename?: 'ActivityInitiator' }
      & Pick<ActivityInitiator, 'name'>
      & { avatar?: Maybe<(
        { __typename?: 'UploadFile' }
        & Pick<UploadFile, 'url'>
      )> }
    ) }
  )> }
);

export type MissionLifecycleFragmentFragment = (
  { __typename?: 'MissionLifecycle' }
  & Pick<MissionLifecycle, 'createdAt' | 'inProgress' | 'archivalNotes' | 'archiveable' | 'endDate' | 'startDate' | 'id' | 'prn' | 'completionProgress' | 'projectLocationCountryCode' | 'projectLocationType' | 'summary' | 'name' | 'rejectionReason' | 'state' | 'tagList' | 'operational' | 'staffable'>
  & { missionExecution?: Maybe<(
    { __typename?: 'MissionExecution' }
    & Pick<MissionExecution, 'commencementDate' | 'completionDate' | 'id'>
  )>, scopeService?: Maybe<(
    { __typename?: 'ScopeService' }
    & Pick<ScopeService, 'id' | 'name'>
    & { serviceGroup: (
      { __typename?: 'ScopeServiceGroup' }
      & Pick<ScopeServiceGroup, 'id' | 'name'>
    ) }
  )>, completionProgressActivityLog?: Maybe<(
    { __typename?: 'ActivityLog' }
    & { updatedBy?: Maybe<(
      { __typename?: 'User' }
      & UserFragmentFragment
    )> }
    & ActivityLogFragmentFragment
  )>, collaborators?: Maybe<Array<(
    { __typename?: 'UserAdvanced' }
    & UserAdvancedFragmentFragment
  )>>, defaultConversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'prn' | 'id'>
    & { lastComment?: Maybe<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'prn' | 'text' | 'createdAt' | 'updatedAt'>
      & { user: (
        { __typename?: 'User' }
        & UserFragmentFragment
      ) }
    )> }
  )>, termsOfReferences?: Maybe<Array<(
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  )>>, missionLead?: Maybe<(
    { __typename?: 'MissionLead' }
    & Pick<MissionLead, 'clientContactEmail' | 'clientContactName' | 'clientContactPhone' | 'clientContactRole' | 'clientDescription' | 'clientName' | 'eoiDeadline' | 'rfpDeadline' | 'clientWebsite' | 'createdAt' | 'id' | 'projectLocationCountry' | 'projectLocationType' | 'summary' | 'state' | 'tentativeEndDate' | 'tentativeStartDate'>
    & { approver?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, client?: Maybe<(
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'name' | 'verified' | 'companyLogoUrl' | 'email'>
      & { clientType: (
        { __typename?: 'ClientType' }
        & Pick<ClientType, 'name' | 'id'>
      ), clientContacts: Array<(
        { __typename?: 'ClientContact' }
        & Pick<ClientContact, 'id' | 'email' | 'name' | 'phone'>
      )> }
    )>, missionLifecycle: (
      { __typename?: 'MissionLifecycle' }
      & Pick<MissionLifecycle, 'id'>
    ), region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'id' | 'name'>
    )>, leadSource?: Maybe<(
      { __typename?: 'LeadSource' }
      & Pick<LeadSource, 'id' | 'name'>
    )>, leadGrade?: Maybe<(
      { __typename?: 'LeadGrade' }
      & Pick<LeadGrade, 'id' | 'name'>
    )>, practiceGroup?: Maybe<(
      { __typename?: 'PracticeGroup' }
      & Pick<PracticeGroup, 'id' | 'name'>
      & { subGroups: Array<(
        { __typename?: 'SubPracticeGroup' }
        & Pick<SubPracticeGroup, 'id' | 'name'>
      )> }
    )>, subPracticeGroup?: Maybe<(
      { __typename?: 'SubPracticeGroup' }
      & Pick<SubPracticeGroup, 'id' | 'name'>
    )>, termsOfReferences: Array<(
      { __typename?: 'UploadFile' }
      & UploadFileFragmentFragment
    )>, user: (
      { __typename?: 'User' }
      & UserFragmentFragment
    ) }
  )>, client: (
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'name' | 'email' | 'companyLogoUrl' | 'verified'>
  ), missionTeamMemberships: Array<(
    { __typename?: 'MissionTeamMembership' }
    & MissionTeamMembershipFragmentFragment
  )>, executive?: Maybe<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id' | 'firstName' | 'lastName' | 'name'>
    & { jobTitle?: Maybe<(
      { __typename?: 'JobTitle' }
      & Pick<JobTitle, 'id' | 'name'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & UserFragmentFragment
    )> }
  )>, teamLeads: Array<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id' | 'firstName' | 'lastName' | 'name'>
    & { jobTitle?: Maybe<(
      { __typename?: 'JobTitle' }
      & Pick<JobTitle, 'id' | 'name'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & UserFragmentFragment
    )> }
  )>, principals: Array<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id' | 'firstName' | 'lastName' | 'name'>
    & { jobTitle?: Maybe<(
      { __typename?: 'JobTitle' }
      & Pick<JobTitle, 'id' | 'name'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & UserFragmentFragment
    )> }
  )>, staffingRequests?: Maybe<Array<(
    { __typename?: 'ManagementStaffingRequest' }
    & Pick<ManagementStaffingRequest, 'id'>
    & { consultant?: Maybe<(
      { __typename?: 'Consultant' }
      & Pick<Consultant, 'id' | 'name'>
      & { jobTitle?: Maybe<(
        { __typename?: 'JobTitle' }
        & Pick<JobTitle, 'id' | 'name'>
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { avatar?: Maybe<(
          { __typename?: 'UploadFile' }
          & UploadFileFragmentFragment
        )> }
      )> }
    )>, approval?: Maybe<(
      { __typename?: 'Approval' }
      & ApprovalFragmentFragment
    )>, missionStaffingPosition?: Maybe<(
      { __typename?: 'MissionStaffingPosition' }
      & Pick<MissionStaffingPosition, 'name'>
    )> }
  )>>, missionScope?: Maybe<(
    { __typename?: 'MissionScope' }
    & MissionScopeFragmentFragment
  )>, missionPricingProposal?: Maybe<(
    { __typename?: 'MissionPricingProposal' }
    & MissionPricingProposalFragmentFragment
  )>, missionProposal?: Maybe<(
    { __typename?: 'MissionProposal' }
    & Pick<MissionProposal, 'createdAt' | 'id' | 'state' | 'updatedAt'>
    & { clientApproval?: Maybe<(
      { __typename?: 'Approval' }
      & ApprovalFragmentFragment
    )>, clientApprovals: Array<(
      { __typename?: 'Approval' }
      & ApprovalFragmentFragment
    )>, pricingProposal?: Maybe<(
      { __typename?: 'ApprovedAttachment' }
      & ApprovedAttachmentFragmentFragment
    )>, missionPricingProposal?: Maybe<(
      { __typename?: 'MissionPricingProposal' }
      & Pick<MissionPricingProposal, 'id' | 'markup' | 'currencyCode' | 'contractingEntity' | 'updatedAt' | 'state'>
      & { totalPrice?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      )>, activities: Array<(
        { __typename?: 'MissionPricingProposalActivity' }
        & Pick<MissionPricingProposalActivity, 'id' | 'quantity' | 'name' | 'unitCost' | 'distribution'>
      )>, currency?: Maybe<(
        { __typename?: 'Currency' }
        & CurrencyFragmentFragment
      )>, approvals: Array<(
        { __typename?: 'Approval' }
        & ApprovalFragmentFragment
      )> }
    )>, missionProposalStaffingPlacements?: Maybe<Array<(
      { __typename?: 'MissionStaffingPlacement' }
      & Pick<MissionStaffingPlacement, 'id' | 'placementType' | 'state'>
      & { consultant?: Maybe<(
        { __typename?: 'Consultant' }
        & Pick<Consultant, 'id' | 'name'>
        & { jobTitle?: Maybe<(
          { __typename?: 'JobTitle' }
          & Pick<JobTitle, 'id' | 'name'>
        )>, user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
          & { avatar?: Maybe<(
            { __typename?: 'UploadFile' }
            & UploadFileFragmentFragment
          )> }
        )> }
      )> }
    )>>, missionProposalStaffingPositions?: Maybe<Array<(
      { __typename?: 'MissionStaffingPosition' }
      & MissionStaffingPositionFragmentFragment
    )>>, technicalProposal?: Maybe<(
      { __typename?: 'ApprovedAttachment' }
      & ApprovedAttachmentFragmentFragment
    )> }
  )>, missionExecutionStaffingPositions?: Maybe<Array<(
    { __typename?: 'MissionStaffingPosition' }
    & MissionStaffingPositionFragmentFragment
  )>>, missionExecutionStaffingPlacements?: Maybe<Array<(
    { __typename?: 'MissionStaffingPlacement' }
    & Pick<MissionStaffingPlacement, 'id' | 'placementType' | 'state'>
    & { consultant?: Maybe<(
      { __typename?: 'Consultant' }
      & Pick<Consultant, 'id' | 'name'>
      & { jobTitle?: Maybe<(
        { __typename?: 'JobTitle' }
        & Pick<JobTitle, 'id' | 'name'>
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { avatar?: Maybe<(
          { __typename?: 'UploadFile' }
          & UploadFileFragmentFragment
        )> }
      )> }
    )>, consultantContract?: Maybe<(
      { __typename?: 'ConsultantContract' }
      & Pick<ConsultantContract, 'id'>
    )> }
  )>>, missionContract?: Maybe<(
    { __typename?: 'MissionContract' }
    & Pick<MissionContract, 'createdAt' | 'id' | 'updatedAt'>
    & { engagementLetter?: Maybe<(
      { __typename?: 'ApprovedAttachment' }
      & ApprovedAttachmentFragmentFragment
    )>, signedContract?: Maybe<(
      { __typename?: 'UploadFile' }
      & UploadFileFragmentFragment
    )>, signatures: Array<(
      { __typename?: 'Esignature' }
      & EsignatureFragmentFragment
    )>, signatureRequest?: Maybe<(
      { __typename?: 'EsignatureRequest' }
      & EsignatureRequestFragmentFragment
    )>, draftContract?: Maybe<(
      { __typename?: 'MissionClientContractTerms' }
      & Pick<MissionClientContractTerms, 'clientLegalName' | 'clientStakeholders' | 'commencementDate' | 'conclusionDate' | 'contractDate' | 'createdAt' | 'deliverableSubmissionDate' | 'id' | 'logisticsCostBearer' | 'maximumLogisticsCost' | 'afgSignatoryTitle' | 'projectObjective' | 'providerObjective' | 'updatedAt'>
      & { bankAccount: (
        { __typename?: 'BankAccount' }
        & Pick<BankAccount, 'accountAlias' | 'accountName' | 'accountNumber' | 'bankAddress' | 'bankName' | 'createdAt' | 'iban' | 'id' | 'sortCode' | 'swiftCode'>
      ), currency: (
        { __typename?: 'Currency' }
        & CurrencyFragmentFragment
      ), missionPartner: (
        { __typename?: 'ConsultantProfile' }
        & Pick<ConsultantProfile, 'id' | 'name' | 'email'>
      ) }
    )>, missionContractPayments: Array<(
      { __typename?: 'MissionContractPayment' }
      & Pick<MissionContractPayment, 'invoiceUrl' | 'checkoutUrl' | 'createdAt' | 'updatedAt' | 'amountCents' | 'description' | 'dueDate' | 'id' | 'state' | 'prn' | 'name' | 'paymentType'>
      & { amount: (
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      ), missionContractDeliverables: Array<(
        { __typename?: 'MissionContractDeliverable' }
        & Pick<MissionContractDeliverable, 'dueDate' | 'id' | 'name' | 'state'>
        & { missionScopeActivity?: Maybe<(
          { __typename?: 'MissionScopeActivity' }
          & Pick<MissionScopeActivity, 'id' | 'name' | 'prn'>
        )> }
      )> }
    )>, deliverables: Array<(
      { __typename?: 'MissionContractDeliverable' }
      & Pick<MissionContractDeliverable, 'dueDate' | 'id' | 'name' | 'state'>
      & { missionScopeActivity?: Maybe<(
        { __typename?: 'MissionScopeActivity' }
        & Pick<MissionScopeActivity, 'id' | 'name'>
      )> }
    )> }
  )>, asanteSurveys: Array<(
    { __typename?: 'SurveyAssignment' }
    & SurveyAssignmentFragmentFragment
  )>, clientSatisfactionSurvey?: Maybe<(
    { __typename?: 'SurveyAssignment' }
    & SurveyAssignmentFragmentFragment
  )>, activityLogs: Array<(
    { __typename?: 'ActivityLog' }
    & ActivityLogFragmentFragment
  )> }
);

export type MissionTeamMembershipFragmentFragment = (
  { __typename?: 'MissionTeamMembership' }
  & Pick<MissionTeamMembership, 'isManager' | 'state' | 'role'>
  & { consultant?: Maybe<(
    { __typename?: 'Consultant' }
    & ConsultantFragmentFragment
  )>, user?: Maybe<(
    { __typename?: 'UserAdvanced' }
    & UserAdvancedFragmentFragment
  )>, position?: Maybe<(
    { __typename?: 'MissionStaffingPosition' }
    & Pick<MissionStaffingPosition, 'id' | 'name' | 'state'>
  )>, placement: (
    { __typename?: 'MissionStaffingPlacement' }
    & Pick<MissionStaffingPlacement, 'id' | 'state' | 'placementType'>
    & { consultantContract?: Maybe<(
      { __typename?: 'ConsultantContract' }
      & Pick<ConsultantContract, 'id'>
    )> }
  ) }
);

export type MissionPricingProposalFragmentFragment = (
  { __typename?: 'MissionPricingProposal' }
  & Pick<MissionPricingProposal, 'id' | 'markup' | 'currencyCode' | 'contractingEntity' | 'updatedAt' | 'state'>
  & { totalPrice?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  )>, activities: Array<(
    { __typename?: 'MissionPricingProposalActivity' }
    & Pick<MissionPricingProposalActivity, 'id' | 'quantity' | 'name' | 'unitCost' | 'distribution'>
  )>, currency?: Maybe<(
    { __typename?: 'Currency' }
    & CurrencyFragmentFragment
  )>, approvals: Array<(
    { __typename?: 'Approval' }
    & ApprovalFragmentFragment
  )> }
);

export type MissionLifecyclesListQueryQueryVariables = {
  filter?: Maybe<MissionLifecyclesFilter>;
  sort?: Maybe<MissionLifecyclesSortInput>;
  page?: Maybe<Scalars['Int']>;
  per: Scalars['Int'];
};


export type MissionLifecyclesListQueryQuery = (
  { __typename?: 'Query' }
  & { missionLifecycles: (
    { __typename?: 'PaginatedMissionLifecycles' }
    & { data: Array<(
      { __typename?: 'MissionLifecycle' }
      & Pick<MissionLifecycle, 'id' | 'name' | 'projectLocationCountryCode' | 'projectLocationType' | 'summary' | 'state' | 'createdAt'>
      & { client: (
        { __typename?: 'Client' }
        & Pick<Client, 'id' | 'name'>
      ), primaryManager?: Maybe<(
        { __typename?: 'User' }
        & UserFragmentFragment
      )>, missionLead?: Maybe<(
        { __typename?: 'MissionLead' }
        & Pick<MissionLead, 'id' | 'summary' | 'clientName'>
        & { practiceGroup?: Maybe<(
          { __typename?: 'PracticeGroup' }
          & Pick<PracticeGroup, 'id' | 'name'>
          & { subGroups: Array<(
            { __typename?: 'SubPracticeGroup' }
            & Pick<SubPracticeGroup, 'id' | 'name'>
          )> }
        )> }
      )>, collaborators?: Maybe<Array<(
        { __typename?: 'UserAdvanced' }
        & UserAdvancedFragmentFragment
      )>>, scopeService?: Maybe<(
        { __typename?: 'ScopeService' }
        & Pick<ScopeService, 'id' | 'name'>
        & { serviceGroup: (
          { __typename?: 'ScopeServiceGroup' }
          & Pick<ScopeServiceGroup, 'id' | 'name'>
        ) }
      )>, executive?: Maybe<(
        { __typename?: 'Consultant' }
        & Pick<Consultant, 'id' | 'firstName' | 'lastName' | 'name'>
        & { jobTitle?: Maybe<(
          { __typename?: 'JobTitle' }
          & Pick<JobTitle, 'id' | 'name'>
        )>, user?: Maybe<(
          { __typename?: 'User' }
          & UserFragmentFragment
          & UserFragmentFragment
        )> }
      )> }
    ) | { __typename?: 'MissionLifecycleBasic' }>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type MissionLifecyclesQueryQueryVariables = {
  filter?: Maybe<MissionLifecyclesFilter>;
  sort?: Maybe<MissionLifecyclesSortInput>;
  page?: Maybe<Scalars['Int']>;
  per: Scalars['Int'];
};


export type MissionLifecyclesQueryQuery = (
  { __typename?: 'Query' }
  & { missionLifecycles: (
    { __typename?: 'PaginatedMissionLifecycles' }
    & { data: Array<(
      { __typename?: 'MissionLifecycle' }
      & MissionLifecycleFragmentFragment
    ) | { __typename?: 'MissionLifecycleBasic' }>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type MissionLifecycleSelectQueryQueryVariables = {
  filter?: Maybe<MissionLifecyclesFilter>;
  sort?: Maybe<MissionLifecyclesSortInput>;
  page?: Maybe<Scalars['Int']>;
  per: Scalars['Int'];
};


export type MissionLifecycleSelectQueryQuery = (
  { __typename?: 'Query' }
  & { missionLifecycles: (
    { __typename?: 'PaginatedMissionLifecycles' }
    & { data: Array<(
      { __typename?: 'MissionLifecycle' }
      & Pick<MissionLifecycle, 'id' | 'name'>
    ) | { __typename?: 'MissionLifecycleBasic' }>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type MissionDashboardStatisticsQueryQueryVariables = {};


export type MissionDashboardStatisticsQueryQuery = (
  { __typename?: 'Query' }
  & { missionDashboardStatistics: Array<(
    { __typename?: 'MissionStats' }
    & Pick<MissionStats, 'name' | 'value' | 'slug'>
  )> }
);

export type MissionStaffingApplicationFragmentFragment = (
  { __typename?: 'MissionStaffingApplication' }
  & Pick<MissionStaffingApplication, 'id' | 'prn' | 'state' | 'expressionOfInterest' | 'rejectReason' | 'createdAt' | 'updatedAt'>
  & { missionStaffingPosition: (
    { __typename?: 'MissionStaffingPosition' }
    & ListMissionStaffingPositionFragmentFragment
  ) }
);

export type MissionStaffingPlacementFragmentFragment = (
  { __typename?: 'MissionStaffingPlacement' }
  & Pick<MissionStaffingPlacement, 'id' | 'createdAt'>
  & { missionLifecycle: (
    { __typename?: 'MissionLifecycle' }
    & Pick<MissionLifecycle, 'id' | 'name' | 'state' | 'updatedAt'>
    & { missionLead?: Maybe<(
      { __typename?: 'MissionLead' }
      & Pick<MissionLead, 'id' | 'clientName' | 'projectLocationCountry' | 'projectLocationType' | 'tentativeStartDate' | 'tentativeEndDate'>
      & { client?: Maybe<(
        { __typename?: 'Client' }
        & Pick<Client, 'id' | 'name'>
      )> }
    )>, missionExecution?: Maybe<(
      { __typename?: 'MissionExecution' }
      & Pick<MissionExecution, 'id' | 'commencementDate' | 'completionDate'>
    )> }
  ), missionStaffingPosition?: Maybe<(
    { __typename?: 'MissionStaffingPosition' }
    & Pick<MissionStaffingPosition, 'id' | 'name' | 'description' | 'updatedAt'>
    & { jobTitle: (
      { __typename?: 'JobTitle' }
      & Pick<JobTitle, 'id' | 'name'>
    ), consultantContractPayments: Array<(
      { __typename?: 'ConsultantContractPayment' }
      & ConsultantContractPaymentFragmentFragment
    )> }
  )> }
);

export type TerminateMissionStaffingPlacementMutationMutationVariables = {
  id: Scalars['BigInt'];
  terminationReason: Scalars['String'];
};


export type TerminateMissionStaffingPlacementMutationMutation = (
  { __typename?: 'Mutation' }
  & { terminateMissionStaffingPlacement: (
    { __typename?: 'TerminateMissionStaffingPlacementPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RestoreMissionStaffingPlacementMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type RestoreMissionStaffingPlacementMutationMutation = (
  { __typename?: 'Mutation' }
  & { restoreMissionStaffingPlacement: (
    { __typename?: 'RestoreMissionStaffingPlacementPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ApprovedAttachmentFragmentFragment = (
  { __typename?: 'ApprovedAttachment' }
  & Pick<ApprovedAttachment, 'id' | 'createdAt' | 'state' | 'updatedAt'>
  & { approvals: Array<(
    { __typename?: 'Approval' }
    & ApprovalFragmentFragment
  )>, uploadFile: (
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  ) }
);

export type MissionStaffingPositionFragmentFragment = (
  { __typename?: 'MissionStaffingPosition' }
  & Pick<MissionStaffingPosition, 'description' | 'id' | 'prn' | 'name' | 'state'>
  & { jobTitle: (
    { __typename?: 'JobTitle' }
    & Pick<JobTitle, 'id' | 'name'>
  ), requiredExpertise: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>, consultantContractPayments: Array<(
    { __typename?: 'ConsultantContractPayment' }
    & ConsultantContractPaymentFragmentFragment
  )>, pendingMissionStaffingApplications: Array<(
    { __typename?: 'MissionStaffingApplication' }
    & Pick<MissionStaffingApplication, 'id' | 'state' | 'expressionOfInterest' | 'rejectReason'>
    & { consultant: (
      { __typename?: 'Consultant' }
      & Pick<Consultant, 'id' | 'name'>
      & { jobTitle?: Maybe<(
        { __typename?: 'JobTitle' }
        & Pick<JobTitle, 'id' | 'name'>
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { avatar?: Maybe<(
          { __typename?: 'UploadFile' }
          & UploadFileFragmentFragment
        )> }
      )> }
    ), missionStaffingPosition: (
      { __typename?: 'MissionStaffingPosition' }
      & Pick<MissionStaffingPosition, 'id' | 'name'>
    ) }
  )> }
);

export type CommenceStaffingMutationMutationVariables = {
  missionId: Scalars['BigInt'];
};


export type CommenceStaffingMutationMutation = (
  { __typename?: 'Mutation' }
  & { commenceStaffing: (
    { __typename?: 'CommenceMissionStaffingPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CommenceContractingMutationMutationVariables = {
  missionId: Scalars['BigInt'];
};


export type CommenceContractingMutationMutation = (
  { __typename?: 'Mutation' }
  & { commenceContracting: (
    { __typename?: 'CommenceMissionContractingPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CommenceExecutionMutationMutationVariables = {
  missionId: Scalars['BigInt'];
  commencementDate: Scalars['ISO8601Date'];
};


export type CommenceExecutionMutationMutation = (
  { __typename?: 'Mutation' }
  & { commenceExecution: (
    { __typename?: 'CommenceMissionExecutionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CreateMissionExecutionStaffingPositionMutationMutationVariables = {
  missionId: Scalars['BigInt'];
  jobTitleId: Scalars['BigInt'];
  name: Scalars['String'];
  description: Scalars['String'];
  milestones: Array<Scalars['BigInt']>;
  payments?: Maybe<Array<ConsultantContractPaymentInput>>;
  requiredExpertiseTags?: Maybe<Array<Scalars['String']>>;
};


export type CreateMissionExecutionStaffingPositionMutationMutation = (
  { __typename?: 'Mutation' }
  & { createMissionExecutionStaffingPosition: (
    { __typename?: 'CreateMissionExecutionStaffingPositionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateMissionStaffingPositionMutationMutationVariables = {
  staffingPositionId: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
};


export type UpdateMissionStaffingPositionMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateMissionStaffingPosition: (
    { __typename?: 'UpdateMissionStaffingPositionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type SetMissionTagsMutationMutationVariables = {
  missionId: Scalars['BigInt'];
  tags: Array<Scalars['String']>;
};


export type SetMissionTagsMutationMutation = (
  { __typename?: 'Mutation' }
  & { setMissionTags: (
    { __typename?: 'SetMissionTagsPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CompleteExecutionMutationMutationVariables = {
  missionId: Scalars['BigInt'];
  completionDate: Scalars['ISO8601Date'];
};


export type CompleteExecutionMutationMutation = (
  { __typename?: 'Mutation' }
  & { completeExecution: (
    { __typename?: 'CompleteMissionExecutionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RequestMissionExecutiveMutationMutationVariables = {
  missionLifecycleId: Scalars['BigInt'];
  consultantId: Scalars['BigInt'];
};


export type RequestMissionExecutiveMutationMutation = (
  { __typename?: 'Mutation' }
  & { requestMissionExecutive: (
    { __typename?: 'RequestMissionExecutivePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type AssignMissionTeamLeadMutationMutationVariables = {
  missionLifecycleId: Scalars['BigInt'];
  consultantId: Scalars['BigInt'];
};


export type AssignMissionTeamLeadMutationMutation = (
  { __typename?: 'Mutation' }
  & { assignMissionTeamLead: (
    { __typename?: 'AssignMissionTeamLeadPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type AssignMissionPrincipalMutationMutationVariables = {
  missionLifecycleId: Scalars['BigInt'];
  consultantId: Scalars['BigInt'];
};


export type AssignMissionPrincipalMutationMutation = (
  { __typename?: 'Mutation' }
  & { assignMissionPrincipal: (
    { __typename?: 'AssignMissionPrincipalPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RemoveMissionPartnerMutationMutationVariables = {
  missionLifecycleId: Scalars['BigInt'];
  consultantId: Scalars['BigInt'];
};


export type RemoveMissionPartnerMutationMutation = (
  { __typename?: 'Mutation' }
  & { removeMissionPartner: (
    { __typename?: 'RemoveMissionPartnerPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RemoveMissionPrincipalMutationMutationVariables = {
  missionLifecycleId: Scalars['BigInt'];
  consultantId: Scalars['BigInt'];
};


export type RemoveMissionPrincipalMutationMutation = (
  { __typename?: 'Mutation' }
  & { removeMissionPrincipal: (
    { __typename?: 'RemoveMissionPrincipalPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RemoveMissionTeamLeadMutationMutationVariables = {
  missionLifecycleId: Scalars['BigInt'];
  consultantId: Scalars['BigInt'];
};


export type RemoveMissionTeamLeadMutationMutation = (
  { __typename?: 'Mutation' }
  & { removeMissionTeamLead: (
    { __typename?: 'RemoveMissionTeamLeadPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ResumeMissionMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type ResumeMissionMutationMutation = (
  { __typename?: 'Mutation' }
  & { resumeMission: (
    { __typename?: 'ResumeMissionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type PauseMissionMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type PauseMissionMutationMutation = (
  { __typename?: 'Mutation' }
  & { pauseMission: (
    { __typename?: 'PauseMissionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type AddMissionScopeActivityMutationMutationVariables = {
  missionScopeId: Scalars['BigInt'];
  name: Scalars['String'];
  capacities?: Maybe<Scalars['JSON']>;
  missionScopePhaseId: Scalars['BigInt'];
};


export type AddMissionScopeActivityMutationMutation = (
  { __typename?: 'Mutation' }
  & { addMissionScopeActivity: (
    { __typename?: 'AddMissionScopeActivityPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, activity?: Maybe<(
      { __typename?: 'MissionScopeActivity' }
      & Pick<MissionScopeActivity, 'id' | 'prn' | 'name'>
    )> }
  ) }
);

export type CreateStaffingMilestoneMutationMutationVariables = {
  missionContractDeliverableId?: Maybe<Scalars['BigInt']>;
  name: Scalars['String'];
  description: Scalars['String'];
  feesCents?: Maybe<Scalars['Int']>;
};


export type CreateStaffingMilestoneMutationMutation = (
  { __typename?: 'Mutation' }
  & { createStaffingMilestone: (
    { __typename?: 'CreateStaffingMilestonePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, milestone?: Maybe<(
      { __typename?: 'MissionStaffingMilestone' }
      & Pick<MissionStaffingMilestone, 'id' | 'fees'>
    )> }
  ) }
);

export type StartMissionScopeDevelopmentMutationMutationVariables = {
  missionId: Scalars['BigInt'];
};


export type StartMissionScopeDevelopmentMutationMutation = (
  { __typename?: 'Mutation' }
  & { startMissionScopeDevelopment: (
    { __typename?: 'StartMissionScopeDevelopmentPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, missionScope?: Maybe<(
      { __typename?: 'MissionScope' }
      & Pick<MissionScope, 'id'>
    )> }
  ) }
);

export type StartPricingProposalDevelopmentMutationMutationVariables = {
  id: Scalars['ID'];
};


export type StartPricingProposalDevelopmentMutationMutation = (
  { __typename?: 'Mutation' }
  & { startPricingProposalDevelopment: (
    { __typename?: 'StartPricingProposalDevelopmentPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, missionPricingProposal?: Maybe<(
      { __typename?: 'MissionPricingProposal' }
      & Pick<MissionPricingProposal, 'id'>
    )> }
  ) }
);

export type GenerateClientPaymentStructureMutationMutationVariables = {
  missionLifecycleId: Scalars['ID'];
  paymentsInput: Array<ClientPaymentStructureInput>;
};


export type GenerateClientPaymentStructureMutationMutation = (
  { __typename?: 'Mutation' }
  & { generateClientPaymentStructure?: Maybe<(
    { __typename?: 'GenerateClientPaymentStructurePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, payments?: Maybe<Array<(
      { __typename?: 'MissionContractPayment' }
      & Pick<MissionContractPayment, 'id' | 'invoiceUrl' | 'name' | 'paymentType' | 'state'>
      & { amount: (
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      ) }
    )>> }
  )> }
);

export type UpdateMissionContractDeliverableMutationMutationVariables = {
  progress: Scalars['Int'];
  deliverableId: Scalars['BigInt'];
};


export type UpdateMissionContractDeliverableMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateMissionContractDeliverable: (
    { __typename?: 'UpdateMissionContractDeliverablePayload' }
    & { deliverable?: Maybe<(
      { __typename?: 'MissionContractDeliverable' }
      & Pick<MissionContractDeliverable, 'id' | 'state'>
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ClientMissionLifecyclesListQueryQueryVariables = {
  filter?: Maybe<ClientMissionsFilter>;
  sort?: Maybe<ClientMissionsSortInput>;
  page?: Maybe<Scalars['Int']>;
  per: Scalars['Int'];
};


export type ClientMissionLifecyclesListQueryQuery = (
  { __typename?: 'Query' }
  & { clientMissions?: Maybe<(
    { __typename?: 'PaginatedClientMissions' }
    & { data: Array<(
      { __typename?: 'MissionLifecycle' }
      & Pick<MissionLifecycle, 'id' | 'name' | 'completionProgress' | 'projectLocationCountryCode' | 'projectLocationType' | 'summary' | 'state' | 'createdAt'>
      & { missionLead?: Maybe<(
        { __typename?: 'MissionLead' }
        & Pick<MissionLead, 'id' | 'summary'>
        & { practiceGroup?: Maybe<(
          { __typename?: 'PracticeGroup' }
          & Pick<PracticeGroup, 'id' | 'name'>
        )> }
      )>, client: (
        { __typename?: 'Client' }
        & Pick<Client, 'id' | 'name'>
      ), missionScope?: Maybe<(
        { __typename?: 'MissionScope' }
        & Pick<MissionScope, 'id'>
        & { activities: Array<(
          { __typename?: 'MissionScopeActivity' }
          & Pick<MissionScopeActivity, 'id' | 'completed'>
        )> }
      )>, collaborators?: Maybe<Array<(
        { __typename?: 'UserAdvanced' }
        & UserAdvancedFragmentFragment
      )>>, scopeService?: Maybe<(
        { __typename?: 'ScopeService' }
        & Pick<ScopeService, 'id' | 'name'>
        & { serviceGroup: (
          { __typename?: 'ScopeServiceGroup' }
          & Pick<ScopeServiceGroup, 'id' | 'name'>
        ) }
      )>, missionTeamMemberships: Array<(
        { __typename?: 'MissionTeamMembership' }
        & { consultant?: Maybe<(
          { __typename?: 'Consultant' }
          & ConsultantFragmentFragment
        )>, user?: Maybe<(
          { __typename?: 'UserAdvanced' }
          & UserAdvancedFragmentFragment
        )> }
      )> }
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  )> }
);

export type ClientMissionLifecyclesDetailsQueryQueryVariables = {
  filter?: Maybe<ClientMissionsFilter>;
  sort?: Maybe<ClientMissionsSortInput>;
  page?: Maybe<Scalars['Int']>;
  per: Scalars['Int'];
};


export type ClientMissionLifecyclesDetailsQueryQuery = (
  { __typename?: 'Query' }
  & { clientMissions?: Maybe<(
    { __typename?: 'PaginatedClientMissions' }
    & { data: Array<(
      { __typename?: 'MissionLifecycle' }
      & MissionLifecycleFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  )> }
);

export type RequestMissionProposalApprovalMutationVariables = {
  missionLifecycleId: Scalars['ID'];
};


export type RequestMissionProposalApprovalMutation = (
  { __typename?: 'Mutation' }
  & { requestMissionProposalApproval: (
    { __typename?: 'RequestMissionProposalApprovalPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, missionLifecycle?: Maybe<(
      { __typename?: 'MissionLifecycle' }
      & MissionLifecycleFragmentFragment
    )> }
  ) }
);

export type StaffMissionUpdatesQueryQueryVariables = {
  filter?: Maybe<MissionLifecyclesFilter>;
  sort?: Maybe<MissionLifecyclesSortInput>;
  page?: Maybe<Scalars['Int']>;
  per: Scalars['Int'];
};


export type StaffMissionUpdatesQueryQuery = (
  { __typename?: 'Query' }
  & { missionLifecycles: (
    { __typename?: 'PaginatedMissionLifecycles' }
    & { data: Array<(
      { __typename?: 'MissionLifecycle' }
      & Pick<MissionLifecycle, 'id' | 'name' | 'summary' | 'projectLocationCountryCode' | 'projectLocationType' | 'state' | 'createdAt' | 'completionProgress'>
      & { client: (
        { __typename?: 'Client' }
        & Pick<Client, 'id' | 'name'>
      ), scopeService?: Maybe<(
        { __typename?: 'ScopeService' }
        & Pick<ScopeService, 'id' | 'name'>
        & { serviceGroup: (
          { __typename?: 'ScopeServiceGroup' }
          & Pick<ScopeServiceGroup, 'id' | 'name'>
        ) }
      )>, activityLogs: Array<(
        { __typename?: 'ActivityLog' }
        & ActivityLogFragmentFragment
      )>, completionProgressActivityLog?: Maybe<(
        { __typename?: 'ActivityLog' }
        & Pick<ActivityLog, 'id' | 'updatedAt'>
        & { updatedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'firstName'>
        )> }
      )> }
    ) | { __typename?: 'MissionLifecycleBasic' }>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type UpdateMissionLifecycleMutationMutationVariables = {
  missionLifecycleId: Scalars['ID'];
  projectTitle?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  tentativeEndDate?: Maybe<Scalars['ISO8601Date']>;
  tentativeStartDate?: Maybe<Scalars['ISO8601Date']>;
  projectLocationCountryCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type UpdateMissionLifecycleMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateMissionLifecycle: (
    { __typename?: 'UpdateMissionLifecyclePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, missionLifecycle?: Maybe<(
      { __typename?: 'MissionLifecycle' }
      & Pick<MissionLifecycle, 'id' | 'endDate' | 'startDate' | 'state' | 'updatedAt' | 'projectLocationCountryCode' | 'name' | 'summary'>
    )> }
  ) }
);

export type MissionStaffingPlacementMyMissionFragmentFragment = (
  { __typename?: 'MissionStaffingPlacement' }
  & Pick<MissionStaffingPlacement, 'id' | 'createdAt'>
  & { missionLifecycle: (
    { __typename?: 'MissionLifecycle' }
    & Pick<MissionLifecycle, 'id' | 'name' | 'state' | 'updatedAt'>
    & { missionLead?: Maybe<(
      { __typename?: 'MissionLead' }
      & Pick<MissionLead, 'id' | 'clientName' | 'projectLocationCountry' | 'projectLocationType' | 'tentativeStartDate' | 'tentativeEndDate'>
      & { client?: Maybe<(
        { __typename?: 'Client' }
        & Pick<Client, 'id' | 'name'>
      )> }
    )>, missionExecution?: Maybe<(
      { __typename?: 'MissionExecution' }
      & Pick<MissionExecution, 'id' | 'commencementDate' | 'completionDate'>
    )> }
  ), missionStaffingPosition?: Maybe<(
    { __typename?: 'MissionStaffingPosition' }
    & Pick<MissionStaffingPosition, 'id' | 'name' | 'description' | 'updatedAt'>
    & { jobTitle: (
      { __typename?: 'JobTitle' }
      & Pick<JobTitle, 'id' | 'name'>
    ), consultantContractPayments: Array<(
      { __typename?: 'ConsultantContractPayment' }
      & ConsultantContractPaymentFragmentFragment
    )> }
  )>, consultantInvoices: Array<(
    { __typename?: 'ConsultantInvoice' }
    & Pick<ConsultantInvoice, 'approvedAt' | 'createdAt' | 'dueAt' | 'id' | 'invoiceType' | 'paidAt' | 'paymentDescription' | 'state' | 'total' | 'updatedAt'>
    & { payment?: Maybe<(
      { __typename?: 'ConsultantContractPayment' }
      & Pick<ConsultantContractPayment, 'state' | 'description' | 'createdAt'>
      & { amount: (
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      ) }
    )> }
  )> }
);

export type MyMissionsQueryQueryVariables = {
  filter?: Maybe<MyMissionsFilter>;
  sort?: Maybe<MyMissionsSortInput>;
  page?: Maybe<Scalars['Int']>;
  per?: Maybe<Scalars['Int']>;
};


export type MyMissionsQueryQuery = (
  { __typename?: 'Query' }
  & { myMissions: (
    { __typename?: 'PaginatedMyMissions' }
    & { data: Array<(
      { __typename?: 'ConsultantMissionLifecycle' }
      & ConsultantMissionLifecycleFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type MyMissionsListsQueryQueryVariables = {
  filter?: Maybe<MyMissionsFilter>;
  sort?: Maybe<MyMissionsSortInput>;
  page?: Maybe<Scalars['Int']>;
  per?: Maybe<Scalars['Int']>;
};


export type MyMissionsListsQueryQuery = (
  { __typename?: 'Query' }
  & { myMissions: (
    { __typename?: 'PaginatedMyMissions' }
    & { data: Array<(
      { __typename?: 'ConsultantMissionLifecycle' }
      & ConsultantMissionLifecycleListFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type ConsultantMissionLifecycleListFragmentFragment = (
  { __typename?: 'ConsultantMissionLifecycle' }
  & Pick<ConsultantMissionLifecycle, 'id' | 'prn' | 'name' | 'state' | 'summary' | 'createdAt'>
  & { missionStaffingPlacement: (
    { __typename?: 'MissionStaffingPlacement' }
    & Pick<MissionStaffingPlacement, 'id'>
    & { missionStaffingPosition?: Maybe<(
      { __typename?: 'MissionStaffingPosition' }
      & Pick<MissionStaffingPosition, 'id' | 'name' | 'description'>
    )> }
  ) }
);

export type ConsultantMissionLifecycleFragmentFragment = (
  { __typename?: 'ConsultantMissionLifecycle' }
  & Pick<ConsultantMissionLifecycle, 'id' | 'prn' | 'name' | 'state' | 'summary' | 'tagList' | 'createdAt' | 'updatedAt' | 'completionProgress' | 'projectLocationCountryCode' | 'projectLocationType' | 'endDate' | 'startDate'>
  & { activityLogs: Array<(
    { __typename?: 'ActivityLog' }
    & ActivityLogFragmentFragment
  )>, termsOfReferences?: Maybe<Array<(
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  )>>, missionScope?: Maybe<(
    { __typename?: 'MissionScope' }
    & MissionScopeFragmentFragment
  )>, missionTeamMemberships: Array<(
    { __typename?: 'MissionTeamMembership' }
    & MissionTeamMembershipFragmentFragment
  )>, defaultConversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'prn' | 'id'>
    & { lastComment?: Maybe<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'prn' | 'text' | 'createdAt' | 'updatedAt'>
      & { user: (
        { __typename?: 'User' }
        & UserFragmentFragment
      ) }
    )> }
  )>, client: (
    { __typename?: 'ClientBasic' }
    & Pick<ClientBasic, 'id' | 'prn' | 'name' | 'companyLogoUrl' | 'verified'>
  ), scopeService?: Maybe<(
    { __typename?: 'ScopeService' }
    & Pick<ScopeService, 'id' | 'name' | 'prn'>
    & { serviceGroup: (
      { __typename?: 'ScopeServiceGroup' }
      & Pick<ScopeServiceGroup, 'id' | 'name' | 'prn'>
    ) }
  )>, collaborators?: Maybe<Array<(
    { __typename?: 'UserAdvanced' }
    & UserAdvancedFragmentFragment
  )>>, missionStaffingPlacement: (
    { __typename?: 'MissionStaffingPlacement' }
    & Pick<MissionStaffingPlacement, 'id'>
    & { missionLifecycle: (
      { __typename?: 'MissionLifecycle' }
      & Pick<MissionLifecycle, 'id' | 'prn'>
    ), missionStaffingPosition?: Maybe<(
      { __typename?: 'MissionStaffingPosition' }
      & Pick<MissionStaffingPosition, 'id' | 'name' | 'description' | 'positionType'>
      & { jobTitle: (
        { __typename?: 'JobTitle' }
        & Pick<JobTitle, 'id' | 'name'>
      ), staffingPositionDeliverables: Array<(
        { __typename?: 'StaffingPositionDeliverable' }
        & Pick<StaffingPositionDeliverable, 'id' | 'name'>
        & { missionStaffingPosition: (
          { __typename?: 'MissionStaffingPosition' }
          & Pick<MissionStaffingPosition, 'id' | 'name'>
          & { totalFee: (
            { __typename?: 'Money' }
            & MoneyFragmentFragment
          ) }
        ) }
      )> }
    )>, consultantInvoices: Array<(
      { __typename?: 'ConsultantInvoice' }
      & Pick<ConsultantInvoice, 'id' | 'createdAt' | 'updatedAt' | 'state' | 'dueAt' | 'paymentDescription'>
      & { payment?: Maybe<(
        { __typename?: 'ConsultantContractPayment' }
        & Pick<ConsultantContractPayment, 'description' | 'id' | 'paymentType'>
        & { amount: (
          { __typename?: 'Money' }
          & MoneyFragmentFragment
        ) }
      )> }
    )> }
  ) }
);

export type ConsultantPeralteeClaimFragmentFragment = (
  { __typename?: 'ConsultantPeralteeClaim' }
  & Pick<ConsultantPeralteeClaim, 'comment' | 'id' | 'state' | 'description' | 'createdAt'>
  & { consultant: (
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id' | 'firstName' | 'lastName' | 'name'>
    & { jobTitle?: Maybe<(
      { __typename?: 'JobTitle' }
      & Pick<JobTitle, 'id' | 'name'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { avatar?: Maybe<(
        { __typename?: 'UploadFile' }
        & Pick<UploadFile, 'id' | 'url'>
      )> }
    )> }
  ), supportService: (
    { __typename?: 'PeralteeSupportService' }
    & Pick<PeralteeSupportService, 'description' | 'id' | 'name' | 'points'>
  ) }
);

export type PerformanceAppraisalFragmentFragment = (
  { __typename?: 'MissionPerformanceAppraisal' }
  & Pick<MissionPerformanceAppraisal, 'id' | 'createdAt' | 'updatedAt'>
  & { appraiser: (
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id' | 'name'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { avatar?: Maybe<(
        { __typename?: 'UploadFile' }
        & Pick<UploadFile, 'id' | 'url'>
      )> }
    )> }
  ), appraisee: (
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id' | 'name'>
    & { jobTitle?: Maybe<(
      { __typename?: 'JobTitle' }
      & Pick<JobTitle, 'id' | 'name'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
      & { avatar?: Maybe<(
        { __typename?: 'UploadFile' }
        & Pick<UploadFile, 'id' | 'url'>
      )> }
    )> }
  ), missionLifecycle: (
    { __typename?: 'MissionLifecycle' }
    & Pick<MissionLifecycle, 'id' | 'name'>
  ), appraiseeRole?: Maybe<(
    { __typename?: 'JobTitle' }
    & Pick<JobTitle, 'id' | 'name'>
  )>, appraiserRole: (
    { __typename?: 'JobTitle' }
    & Pick<JobTitle, 'id' | 'name'>
  ), surveyAssignment: (
    { __typename?: 'SurveyAssignment' }
    & SurveyAssignmentFragmentFragment
  ) }
);

export type UserProfileQueryQueryVariables = {};


export type UserProfileQueryQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'User' }
    & UserProfileFragmentFragment
  ) }
);

export type UpdateAvatarMutationMutationVariables = {
  avatar: UploadFileInput;
};


export type UpdateAvatarMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile: (
    { __typename?: 'UpdateProfilePayload' }
    & { profile?: Maybe<(
      { __typename?: 'UserAdvanced' }
      & { avatar?: Maybe<(
        { __typename?: 'UploadFile' }
        & UploadFileFragmentFragment
      )> }
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UserProfileFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName'>
  & { avatar?: Maybe<(
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  )>, consultant?: Maybe<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id' | 'createdAt'>
    & { jobTitle?: Maybe<(
      { __typename?: 'JobTitle' }
      & Pick<JobTitle, 'id' | 'name'>
    )> }
  )> }
);

export type ConsultantProfileQueryQueryVariables = {
  missionsLimit?: Maybe<Scalars['Int']>;
};


export type ConsultantProfileQueryQuery = (
  { __typename?: 'Query' }
  & { consultantProfile?: Maybe<(
    { __typename?: 'ConsultantProfile' }
    & { nextPayment?: Maybe<(
      { __typename?: 'ConsultantInvoice' }
      & Pick<ConsultantInvoice, 'id' | 'dueAt' | 'total'>
      & { pendingMilestones?: Maybe<Array<(
        { __typename?: 'ConsultantContractMilestone' }
        & Pick<ConsultantContractMilestone, 'id'>
      )>> }
    )>, missionDeliverables: Array<(
      { __typename?: 'ConsultantMissionDeliverable' }
      & Pick<ConsultantMissionDeliverable, 'completed' | 'name'>
      & { missionStaffingPosition: (
        { __typename?: 'MissionStaffingPosition' }
        & Pick<MissionStaffingPosition, 'id'>
        & { missionLifecycle: (
          { __typename?: 'MissionLifecycleBasic' }
          & Pick<MissionLifecycleBasic, 'id' | 'name'>
          & { scopeService?: Maybe<(
            { __typename?: 'ScopeService' }
            & Pick<ScopeService, 'id' | 'name'>
            & { serviceGroup: (
              { __typename?: 'ScopeServiceGroup' }
              & Pick<ScopeServiceGroup, 'id' | 'name'>
            ) }
          )> }
        ) }
      ) }
    )>, opportunities?: Maybe<(
      { __typename?: 'PaginatedMissionStaffingPositions' }
      & { data: Array<(
        { __typename?: 'MissionStaffingPosition' }
        & ListMissionStaffingPositionFragmentFragment
      )> }
    )> }
    & ConsultantProfileFragmentFragment
  )> }
);

export type JobTitleFragmentFragment = (
  { __typename?: 'JobTitle' }
  & Pick<JobTitle, 'description' | 'id' | 'name' | 'questionsCount' | 'requirements'>
  & { questions: Array<(
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'identifier' | 'label' | 'metadata' | 'type'>
  )> }
);

export type CreateMissionProposalStaffingPositionMutationMutationVariables = {
  missionId: Scalars['BigInt'];
  jobTitleId: Scalars['BigInt'];
  description: Scalars['String'];
  milestones: Array<MissionProposalStaffingMilestoneInput>;
};


export type CreateMissionProposalStaffingPositionMutationMutation = (
  { __typename?: 'Mutation' }
  & { createMissionProposalStaffingPosition: (
    { __typename?: 'CreateMissionProposalStaffingPositionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type PublishMissionStaffingPositionMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type PublishMissionStaffingPositionMutationMutation = (
  { __typename?: 'Mutation' }
  & { publishMissionStaffingPosition: (
    { __typename?: 'PublishMissionStaffingPositionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UnpublishMissionStaffingPositionMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type UnpublishMissionStaffingPositionMutationMutation = (
  { __typename?: 'Mutation' }
  & { unpublishMissionStaffingPosition: (
    { __typename?: 'UnpublishMissionStaffingPositionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type FillMissionStaffingPositionMutationMutationVariables = {
  id: Scalars['BigInt'];
  force?: Maybe<Scalars['Boolean']>;
};


export type FillMissionStaffingPositionMutationMutation = (
  { __typename?: 'Mutation' }
  & { fillMissionStaffingPosition: (
    { __typename?: 'FillMissionStaffingPositionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type SetMissionTechnicalProposalMutationMutationVariables = {
  missionId: Scalars['BigInt'];
  uploadFile: UploadFileInput;
};


export type SetMissionTechnicalProposalMutationMutation = (
  { __typename?: 'Mutation' }
  & { setMissionTechnicalProposal: (
    { __typename?: 'SetMissionTechnicalProposalPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateMissionPricingProposalMutationMutationVariables = {
  id: Scalars['ID'];
  markup?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['BigInt']>;
  totalPrice?: Maybe<Scalars['BigInt']>;
  contractingEntity?: Maybe<ContractingEntity>;
};


export type UpdateMissionPricingProposalMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateMissionPricingProposal: (
    { __typename?: 'UpdateMissionPricingProposalPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, missionPricingProposal?: Maybe<(
      { __typename?: 'MissionPricingProposal' }
      & Pick<MissionPricingProposal, 'id'>
      & { totalPrice?: Maybe<(
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      )> }
    )> }
  ) }
);

export type AddPricingProposalActivityMutationMutationVariables = {
  missionPricingProposalId: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
  unitCost?: Maybe<Scalars['Float']>;
};


export type AddPricingProposalActivityMutationMutation = (
  { __typename?: 'Mutation' }
  & { addMissionPricingProposalActivity: (
    { __typename?: 'AddMissionPricingProposalActivityPayload' }
    & { activity?: Maybe<(
      { __typename?: 'MissionPricingProposalActivity' }
      & Pick<MissionPricingProposalActivity, 'id'>
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdatePricingProposalActivityMutationMutationVariables = {
  id: Scalars['BigInt'];
  name?: Maybe<Scalars['String']>;
  unitCost?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  distribution?: Maybe<Scalars['JSON']>;
};


export type UpdatePricingProposalActivityMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateMissionPricingProposalActivity: (
    { __typename?: 'UpdateMissionPricingProposalActivityPayload' }
    & { activity?: Maybe<(
      { __typename?: 'MissionPricingProposalActivity' }
      & Pick<MissionPricingProposalActivity, 'id'>
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DeletePricingProposalActivityMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type DeletePricingProposalActivityMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteMissionPricingProposalActivity: (
    { __typename?: 'DeleteMissionPricingProposalActivityPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RequestApprovalOnPricingProposalMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type RequestApprovalOnPricingProposalMutationMutation = (
  { __typename?: 'Mutation' }
  & { requestApprovalMissionPricingProposal: (
    { __typename?: 'RequestApprovalMissionPricingProposalPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type BeginMissionProposalMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type BeginMissionProposalMutationMutation = (
  { __typename?: 'Mutation' }
  & { beginMissionProposal: (
    { __typename?: 'BeginMissionProposalPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UpdateMissionProposalStaffingPositionMutationMutationVariables = {
  description: Scalars['String'];
  positionId: Scalars['BigInt'];
  milestones: Array<MissionProposalStaffingMilestoneInput>;
};


export type UpdateMissionProposalStaffingPositionMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateMissionProposalStaffingPosition?: Maybe<(
    { __typename?: 'UpdateMissionProposalStaffingPositionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, missionStaffingPosition?: Maybe<(
      { __typename?: 'MissionStaffingPosition' }
      & Pick<MissionStaffingPosition, 'id'>
    )> }
  )> }
);

export type ReworkPricingProposalMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type ReworkPricingProposalMutationMutation = (
  { __typename?: 'Mutation' }
  & { reworkMissionPricingProposal: (
    { __typename?: 'ReworkMissionPricingProposalPayload' }
    & { missionPricingProposal?: Maybe<(
      { __typename?: 'MissionPricingProposal' }
      & Pick<MissionPricingProposal, 'id' | 'state'>
    )> }
  ) }
);

export type CountriesQueryQueryVariables = {
  name?: Maybe<Scalars['String']>;
  per?: Maybe<Scalars['Int']>;
};


export type CountriesQueryQuery = (
  { __typename?: 'Query' }
  & { countries: (
    { __typename?: 'PaginatedCountries' }
    & { data: Array<(
      { __typename?: 'Country' }
      & Pick<Country, 'name' | 'id'>
    )> }
  ) }
);

export type LanguagesSearchQueryQueryVariables = {
  name?: Maybe<Scalars['String']>;
  per?: Maybe<Scalars['Int']>;
};


export type LanguagesSearchQueryQuery = (
  { __typename?: 'Query' }
  & { languages: (
    { __typename?: 'PaginatedLanguages' }
    & { data: Array<(
      { __typename?: 'Language' }
      & Pick<Language, 'id' | 'name'>
    )> }
  ) }
);

export type InstitutionsSearchQueryQueryVariables = {
  name?: Maybe<Scalars['String']>;
  per?: Maybe<Scalars['Int']>;
};


export type InstitutionsSearchQueryQuery = (
  { __typename?: 'Query' }
  & { institutions: (
    { __typename?: 'PaginatedInstitutions' }
    & { data: Array<(
      { __typename?: 'Institution' }
      & Pick<Institution, 'id' | 'name'>
    )> }
  ) }
);

export type PeralteeSupportServicesSearchQueryQueryVariables = {
  name?: Maybe<Scalars['String']>;
  per?: Maybe<Scalars['Int']>;
};


export type PeralteeSupportServicesSearchQueryQuery = (
  { __typename?: 'Query' }
  & { peralteeSupportServices: (
    { __typename?: 'PaginatedPeralteeSupportServices' }
    & { data: Array<(
      { __typename?: 'PeralteeSupportService' }
      & Pick<PeralteeSupportService, 'id' | 'name' | 'description'>
    )> }
  ) }
);

export type CompaniesSearchQueryQueryVariables = {
  name?: Maybe<Scalars['String']>;
  per?: Maybe<Scalars['Int']>;
};


export type CompaniesSearchQueryQuery = (
  { __typename?: 'Query' }
  & { companies: (
    { __typename?: 'PaginatedCompanies' }
    & { data: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )> }
  ) }
);

export type ClientsSearchQueryQueryVariables = {
  per?: Maybe<Scalars['Int']>;
  filter?: Maybe<ClientsFilter>;
};


export type ClientsSearchQueryQuery = (
  { __typename?: 'Query' }
  & { clients: (
    { __typename?: 'PaginatedClients' }
    & { data: Array<(
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'name'>
      & { primaryClientContact?: Maybe<(
        { __typename?: 'ClientContact' }
        & Pick<ClientContact, 'email' | 'name' | 'phone' | 'role'>
      )> }
    )> }
  ) }
);

export type LeadSourcesQueryQueryVariables = {};


export type LeadSourcesQueryQuery = (
  { __typename?: 'Query' }
  & { leadSources: Array<(
    { __typename?: 'LeadSource' }
    & Pick<LeadSource, 'id' | 'name'>
  )> }
);

export type BrandResourceLabelsQueryQueryVariables = {};


export type BrandResourceLabelsQueryQuery = (
  { __typename?: 'Query' }
  & { brandResourceLabels?: Maybe<(
    { __typename?: 'PaginatedBrandResourceLabels' }
    & { data: Array<(
      { __typename?: 'BrandResourceLabel' }
      & Pick<BrandResourceLabel, 'id' | 'name'>
    )> }
  )> }
);

export type ResourceCollectionsSearchQueryQueryVariables = {};


export type ResourceCollectionsSearchQueryQuery = (
  { __typename?: 'Query' }
  & { brandResourceCollections?: Maybe<(
    { __typename?: 'PaginatedBrandResourceCollections' }
    & { data: Array<(
      { __typename?: 'BrandResourceCollection' }
      & Pick<BrandResourceCollection, 'id' | 'name'>
    )> }
  )> }
);

export type LeadGradesQueryQueryVariables = {};


export type LeadGradesQueryQuery = (
  { __typename?: 'Query' }
  & { leadGrades: Array<(
    { __typename?: 'LeadGrade' }
    & Pick<LeadGrade, 'id' | 'name'>
  )> }
);

export type PracticeGroupsQueryQueryVariables = {};


export type PracticeGroupsQueryQuery = (
  { __typename?: 'Query' }
  & { practiceGroups: Array<(
    { __typename?: 'PracticeGroup' }
    & Pick<PracticeGroup, 'id' | 'name'>
    & { subGroups: Array<(
      { __typename?: 'SubPracticeGroup' }
      & Pick<SubPracticeGroup, 'id' | 'name'>
    )> }
  )> }
);

export type ConsultantsSearchQueryQueryVariables = {
  jobTitle?: Maybe<Scalars['String']>;
  per?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type ConsultantsSearchQueryQuery = (
  { __typename?: 'Query' }
  & { consultants: (
    { __typename?: 'PaginatedConsultants' }
    & { data: Array<(
      { __typename?: 'Consultant' }
      & Pick<Consultant, 'id' | 'name'>
      & { jobTitle?: Maybe<(
        { __typename?: 'JobTitle' }
        & Pick<JobTitle, 'id' | 'name'>
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
        & { avatar?: Maybe<(
          { __typename?: 'UploadFile' }
          & UploadFileFragmentFragment
        )> }
      )> }
    )> }
  ) }
);

export type SurveyFragmentFragment = (
  { __typename?: 'Survey' }
  & Pick<Survey, 'prn' | 'createdAt' | 'description' | 'id' | 'name' | 'state' | 'public' | 'scatterId' | 'surveyableId' | 'surveyType' | 'updatedAt'>
  & { questions: Array<(
    { __typename?: 'SurveyQuestion' }
    & SurveyQuestionFragmentFragment
  )>, responses?: Maybe<Array<(
    { __typename?: 'SurveyResponse' }
    & Pick<SurveyResponse, 'id' | 'updatedAt' | 'createdAt'>
    & { data: Array<(
      { __typename?: 'SurveyResponseData' }
      & SurveyResponseDataFragmentFragment
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & UserFragmentFragment
    )> }
  )>>, activityLogs: Array<(
    { __typename?: 'ActivityLog' }
    & ActivityLogFragmentFragment
  )> }
);

export type SurveyResponseDataFragmentFragment = (
  { __typename?: 'SurveyResponseData' }
  & Pick<SurveyResponseData, 'choice' | 'label' | 'questionId'>
);

export type SurveyAssignmentFragmentFragment = (
  { __typename?: 'SurveyAssignment' }
  & Pick<SurveyAssignment, 'assignmentType' | 'createdAt' | 'id' | 'surveyableId' | 'score' | 'state' | 'subtitle' | 'title' | 'updatedAt'>
  & { response?: Maybe<Array<(
    { __typename?: 'SurveyResponseData' }
    & SurveyResponseDataFragmentFragment
  )>>, survey: (
    { __typename?: 'Survey' }
    & Pick<Survey, 'description' | 'id' | 'name' | 'slug' | 'surveyType'>
    & { questions: Array<(
      { __typename?: 'SurveyQuestion' }
      & Pick<SurveyQuestion, 'id' | 'label' | 'options' | 'questionType' | 'slug' | 'description' | 'helpText' | 'ancestry' | 'position' | 'required'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name'>
      )> }
    )> }
  ), user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )> }
);

export type SurveyQuestionFragmentFragment = (
  { __typename?: 'SurveyQuestion' }
  & Pick<SurveyQuestion, 'id' | 'label' | 'options' | 'position' | 'questionType' | 'required' | 'ancestry' | 'prn'>
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>, surveySection?: Maybe<(
    { __typename?: 'SurveySection' }
    & Pick<SurveySection, 'id'>
  )> }
);

export type SurveyAssignmentQueryQueryVariables = {
  id: Scalars['BigInt'];
};


export type SurveyAssignmentQueryQuery = (
  { __typename?: 'Query' }
  & { surveyAssignment?: Maybe<(
    { __typename?: 'SurveyAssignment' }
    & SurveyAssignmentFragmentFragment
  )> }
);

export type SubmitSurveyResponseMutationMutationVariables = {
  id: Scalars['BigInt'];
  answer: Scalars['JSON'];
};


export type SubmitSurveyResponseMutationMutation = (
  { __typename?: 'Mutation' }
  & { submitSurveyResponse: (
    { __typename?: 'SubmitSurveyResponsePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type CreateSurveyMutationMutationVariables = {
  name: Scalars['String'];
  description: Scalars['String'];
  surveyType: SurveyTypeEnum;
  surveyable: SurveyableInput;
  public: Scalars['Boolean'];
};


export type CreateSurveyMutationMutation = (
  { __typename?: 'Mutation' }
  & { createSurvey: (
    { __typename?: 'CreateSurveyPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, survey?: Maybe<(
      { __typename?: 'Survey' }
      & { surveySections: Array<(
        { __typename?: 'SurveySection' }
        & Pick<SurveySection, 'id' | 'prn' | 'title' | 'description' | 'updatedAt' | 'createdAt' | 'surveyId'>
        & { questions: Array<(
          { __typename?: 'SurveyQuestion' }
          & SurveyQuestionFragmentFragment
        )> }
      )> }
      & SurveyFragmentFragment
    )> }
  ) }
);

export type UpdateSurveyMutationMutationVariables = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  surveyId: Scalars['ID'];
  public: Scalars['Boolean'];
};


export type UpdateSurveyMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateSurvey: (
    { __typename?: 'UpdateSurveyPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, survey?: Maybe<(
      { __typename?: 'Survey' }
      & SurveyFragmentFragment
    )> }
  ) }
);

export type ListSurveyQueryQueryVariables = {
  filter?: Maybe<SurveyFilterInput>;
  sortSurveys?: Maybe<SurveysSortInput>;
  paginateSurveys?: Maybe<PaginationInput>;
};


export type ListSurveyQueryQuery = (
  { __typename?: 'Query' }
  & { surveys?: Maybe<(
    { __typename?: 'PaginatedSurveys' }
    & { data: Array<(
      { __typename?: 'Survey' }
      & Pick<Survey, 'createdAt' | 'id' | 'prn' | 'name' | 'description' | 'scatterId' | 'public' | 'state' | 'surveyType' | 'updatedAt'>
      & { questions: Array<(
        { __typename?: 'SurveyQuestion' }
        & Pick<SurveyQuestion, 'id'>
      )>, responses?: Maybe<Array<(
        { __typename?: 'SurveyResponse' }
        & Pick<SurveyResponse, 'id'>
      )>> }
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  )> }
);

export type AddSurveyQuestionMutationMutationVariables = {
  label: Scalars['String'];
  surveyId: Scalars['ID'];
  questionType: SurveyQuestionTypeEnum;
  required?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  helpText?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  surveySectionId?: Maybe<Scalars['ID']>;
};


export type AddSurveyQuestionMutationMutation = (
  { __typename?: 'Mutation' }
  & { addSurveyQuestion: (
    { __typename?: 'AddSurveyQuestionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, question?: Maybe<(
      { __typename?: 'SurveyQuestion' }
      & Pick<SurveyQuestion, 'ancestry' | 'description' | 'helpText' | 'id' | 'label' | 'options' | 'position' | 'questionType' | 'required'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type ArchiveSurveyMutationMutationVariables = {
  surveyId: Scalars['ID'];
};


export type ArchiveSurveyMutationMutation = (
  { __typename?: 'Mutation' }
  & { archiveSurvey: (
    { __typename?: 'ArchiveSurveyPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type PublishSurveyMutationMutationVariables = {
  surveyId: Scalars['ID'];
};


export type PublishSurveyMutationMutation = (
  { __typename?: 'Mutation' }
  & { publishSurvey: (
    { __typename?: 'PublishSurveyPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, survey?: Maybe<(
      { __typename?: 'Survey' }
      & Pick<Survey, 'id' | 'state'>
    )> }
  ) }
);

export type UnpublishSurveyMutationMutationVariables = {
  surveyId: Scalars['ID'];
};


export type UnpublishSurveyMutationMutation = (
  { __typename?: 'Mutation' }
  & { unpublishSurvey: (
    { __typename?: 'UnpublishSurveyPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, survey?: Maybe<(
      { __typename?: 'Survey' }
      & Pick<Survey, 'id' | 'state'>
    )> }
  ) }
);

export type EditSurveyQuestionMutationMutationVariables = {
  questionId: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  required?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  helpText?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  questionType?: Maybe<SurveyQuestionTypeEnum>;
  surveySectionId?: Maybe<Scalars['ID']>;
};


export type EditSurveyQuestionMutationMutation = (
  { __typename?: 'Mutation' }
  & { editSurveyQuestion: (
    { __typename?: 'EditSurveyQuestionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, question?: Maybe<(
      { __typename?: 'SurveyQuestion' }
      & Pick<SurveyQuestion, 'ancestry' | 'description' | 'helpText' | 'id' | 'label' | 'options' | 'position' | 'questionType' | 'required'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type DeleteSurveyMutationMutationVariables = {
  surveyId: Scalars['ID'];
};


export type DeleteSurveyMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteSurvey: (
    { __typename?: 'DeleteSurveyPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DeleteSurveyQuestionMutationMutationVariables = {
  surveyQuestionId: Scalars['ID'];
};


export type DeleteSurveyQuestionMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteSurveyQuestion: (
    { __typename?: 'DeleteSurveyQuestionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RespondToSurveyMutationMutationVariables = {
  surveyId: Scalars['ID'];
  response: SurveyResponseInput;
  finalize?: Maybe<Scalars['Boolean']>;
};


export type RespondToSurveyMutationMutation = (
  { __typename?: 'Mutation' }
  & { respondToSurvey: (
    { __typename?: 'RespondToSurveyPayload' }
    & { publicResponse?: Maybe<(
      { __typename?: 'SurveyPublicResponse' }
      & Pick<SurveyPublicResponse, 'id' | 'averageScore'>
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type FetchSurveyQueryQueryVariables = {
  id: Scalars['BigInt'];
};


export type FetchSurveyQueryQuery = (
  { __typename?: 'Query' }
  & { publicSurveys?: Maybe<Array<(
    { __typename?: 'Survey' }
    & Pick<Survey, 'id' | 'name' | 'description' | 'slug'>
    & { questions: Array<(
      { __typename?: 'SurveyQuestion' }
      & Pick<SurveyQuestion, 'id' | 'label' | 'questionType' | 'options'>
      & { surveySection?: Maybe<(
        { __typename?: 'SurveySection' }
        & Pick<SurveySection, 'id'>
      )> }
    )>, surveySections: Array<(
      { __typename?: 'SurveySection' }
      & Pick<SurveySection, 'id' | 'title' | 'prn' | 'description'>
    )> }
  )>> }
);

export type SurveyQueryQueryVariables = {
  filter?: Maybe<SurveyFilterInput>;
};


export type SurveyQueryQuery = (
  { __typename?: 'Query' }
  & { surveys?: Maybe<(
    { __typename?: 'PaginatedSurveys' }
    & { data: Array<(
      { __typename?: 'Survey' }
      & { surveySections: Array<(
        { __typename?: 'SurveySection' }
        & Pick<SurveySection, 'id' | 'prn' | 'title' | 'description' | 'updatedAt' | 'createdAt' | 'surveyId'>
        & { questions: Array<(
          { __typename?: 'SurveyQuestion' }
          & SurveyQuestionFragmentFragment
        )> }
      )> }
      & SurveyFragmentFragment
    )> }
  )> }
);

export type AddSurveySectionMutationMutationVariables = {
  surveyId: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  questionIds?: Maybe<Array<Scalars['ID']>>;
};


export type AddSurveySectionMutationMutation = (
  { __typename?: 'Mutation' }
  & { addSurveySection: (
    { __typename?: 'AddSurveySectionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, section?: Maybe<(
      { __typename?: 'SurveySection' }
      & Pick<SurveySection, 'id' | 'updatedAt' | 'createdAt'>
    )> }
  ) }
);

export type UpdateSurveySectionMutationMutationVariables = {
  sectionId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type UpdateSurveySectionMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateSurveySection: (
    { __typename?: 'UpdateSurveySectionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, section?: Maybe<(
      { __typename?: 'SurveySection' }
      & Pick<SurveySection, 'createdAt' | 'description' | 'id' | 'title' | 'updatedAt'>
    )> }
  ) }
);

export type PerformanceAppraisalScheduleFragmentFragment = (
  { __typename?: 'MissionPerformanceAppraisalSchedule' }
  & Pick<MissionPerformanceAppraisalSchedule, 'createdAt'>
  & { appraiser?: Maybe<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id' | 'name'>
  )>, appraisee?: Maybe<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id' | 'name'>
  )>, missionLifecycle?: Maybe<(
    { __typename?: 'MissionLifecycle' }
    & Pick<MissionLifecycle, 'id' | 'name'>
  )>, appraiseeRole?: Maybe<(
    { __typename?: 'JobTitle' }
    & Pick<JobTitle, 'id' | 'name'>
  )>, appraiserRole?: Maybe<(
    { __typename?: 'JobTitle' }
    & Pick<JobTitle, 'id' | 'name'>
  )> }
);

export type TalentDashboardQueryQueryVariables = {};


export type TalentDashboardQueryQuery = (
  { __typename?: 'Query' }
  & { jobApplicationDeadlines: Array<(
    { __typename?: 'JobApplicationDeadline' }
    & Pick<JobApplicationDeadline, 'id' | 'date'>
    & { jobTitle: (
      { __typename?: 'JobTitle' }
      & Pick<JobTitle, 'name'>
    ) }
  )> }
);

export type CreateJobApplicationDeadlineMutationMutationVariables = {
  date: Scalars['ISO8601Date'];
  jobTitleId: Scalars['Int'];
};


export type CreateJobApplicationDeadlineMutationMutation = (
  { __typename?: 'Mutation' }
  & { createJobApplicationDeadline: (
    { __typename?: 'CreateJobApplicationDeadlinePayload' }
    & { jobApplicationDeadline?: Maybe<(
      { __typename?: 'JobApplicationDeadline' }
      & Pick<JobApplicationDeadline, 'id' | 'date'>
      & { jobTitle: (
        { __typename?: 'JobTitle' }
        & Pick<JobTitle, 'name'>
      ) }
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type DeleteJobApplicationDeadlineMutationMutationVariables = {
  id: Scalars['Int'];
};


export type DeleteJobApplicationDeadlineMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteJobApplicationDeadline: (
    { __typename?: 'DeleteJobApplicationDeadlinePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type FindJobTitleQueryQueryVariables = {
  id: Scalars['Int'];
};


export type FindJobTitleQueryQuery = (
  { __typename?: 'Query' }
  & { jobTitles: Array<(
    { __typename?: 'JobTitle' }
    & Pick<JobTitle, 'id' | 'name' | 'description' | 'questionsCount' | 'requirements'>
    & { questions: Array<(
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'identifier' | 'label' | 'type' | 'metadata'>
    )> }
  )> }
);

export type AssessmentsQueryQueryVariables = {};


export type AssessmentsQueryQuery = (
  { __typename?: 'Query' }
  & { assessments: Array<(
    { __typename?: 'Assessment' }
    & Pick<Assessment, 'id' | 'deadline' | 'duration'>
    & { jobTitle: (
      { __typename?: 'JobTitle' }
      & Pick<JobTitle, 'name'>
    ) }
  )> }
);

export type FindAssessmentQueryQueryVariables = {
  id?: Maybe<Scalars['Int']>;
};


export type FindAssessmentQueryQuery = (
  { __typename?: 'Query' }
  & { assessments: Array<(
    { __typename?: 'Assessment' }
    & Pick<Assessment, 'id' | 'deadline'>
    & { jobTitle: (
      { __typename?: 'JobTitle' }
      & Pick<JobTitle, 'name'>
    ), participants: Array<(
      { __typename?: 'Consultant' }
      & Pick<Consultant, 'id' | 'firstName' | 'lastName' | 'createdAt'>
    )>, submissions: Array<(
      { __typename?: 'ConsultantAssessment' }
      & Pick<ConsultantAssessment, 'id' | 'answer' | 'score' | 'updatedAt'>
      & { consultant: (
        { __typename?: 'Consultant' }
        & Pick<Consultant, 'id' | 'firstName' | 'lastName'>
      ) }
    )> }
  )> }
);

export type AddTrainingTagMutationMutationVariables = {
  name: Scalars['String'];
};


export type AddTrainingTagMutationMutation = (
  { __typename?: 'Mutation' }
  & { addTrainingTag: (
    { __typename?: 'AddTrainingTagPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type InviteConsultantMutationMutationVariables = {
  email: Scalars['String'];
  jobTitleId: Scalars['ID'];
};


export type InviteConsultantMutationMutation = (
  { __typename?: 'Mutation' }
  & { inviteConsultant: (
    { __typename?: 'InviteConsultantPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type MyPerformanceAppraisalsQueryQueryVariables = {
  page?: Maybe<Scalars['Int']>;
  per: Scalars['Int'];
  sort?: Maybe<MyPerformanceAppraisalsSortInput>;
  filter?: Maybe<MyPerformanceAppraisalsFilter>;
};


export type MyPerformanceAppraisalsQueryQuery = (
  { __typename?: 'Query' }
  & { myPerformanceAppraisals: (
    { __typename?: 'PaginatedMyPerformanceAppraisals' }
    & { data: Array<(
      { __typename?: 'MissionPerformanceAppraisal' }
      & PerformanceAppraisalFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type MissionPerformanceAppraisalSchedulesQueryQueryVariables = {
  date?: Maybe<Scalars['ISO8601DateTime']>;
};


export type MissionPerformanceAppraisalSchedulesQueryQuery = (
  { __typename?: 'Query' }
  & { missionPerformanceAppraisalSchedules?: Maybe<(
    { __typename?: 'MissionPerformanceAppraisalScheduleData' }
    & { data?: Maybe<Array<(
      { __typename?: 'MissionPerformanceAppraisalSchedule' }
      & PerformanceAppraisalScheduleFragmentFragment
    )>> }
  )> }
);

export type PerformanceAppraisalsQueryQueryVariables = {
  page?: Maybe<Scalars['Int']>;
  per: Scalars['Int'];
  sort?: Maybe<MissionPerformanceAppraisalsSortInput>;
  filter?: Maybe<MissionPerformanceAppraisalsFilter>;
};


export type PerformanceAppraisalsQueryQuery = (
  { __typename?: 'Query' }
  & { missionPerformanceAppraisals: (
    { __typename?: 'PaginatedMissionPerformanceAppraisals' }
    & { data: Array<(
      { __typename?: 'MissionPerformanceAppraisal' }
      & PerformanceAppraisalFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type TalentPoolListQueryQueryVariables = {
  filter?: Maybe<TalentPoolFilter>;
  sort?: Maybe<TalentPoolSortInput>;
  page?: Maybe<Scalars['Int']>;
  per: Scalars['Int'];
};


export type TalentPoolListQueryQuery = (
  { __typename?: 'Query' }
  & { talentPool: (
    { __typename?: 'PaginatedTalentPool' }
    & { data: Array<(
      { __typename?: 'ConsultantTalent' }
      & Pick<ConsultantTalent, 'id' | 'prn' | 'name' | 'email' | 'phone' | 'practiceArea' | 'jobFunctions' | 'yearJoined' | 'status'>
      & { user?: Maybe<(
        { __typename?: 'UserAdvanced' }
        & Pick<UserAdvanced, 'id'>
        & { avatar?: Maybe<(
          { __typename?: 'UploadFile' }
          & Pick<UploadFile, 'id' | 'url'>
        )> }
      )>, jobTitle?: Maybe<(
        { __typename?: 'JobTitle' }
        & Pick<JobTitle, 'id' | 'name'>
      )> }
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type TalentPoolQueryQueryVariables = {
  filter?: Maybe<TalentPoolFilter>;
  sort?: Maybe<TalentPoolSortInput>;
  page?: Maybe<Scalars['Int']>;
  per: Scalars['Int'];
};


export type TalentPoolQueryQuery = (
  { __typename?: 'Query' }
  & { talentPool: (
    { __typename?: 'PaginatedTalentPool' }
    & { data: Array<(
      { __typename?: 'ConsultantTalent' }
      & ConsultantTalentFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type UpdateConsultantTalentMutationMutationVariables = {
  consultantId: Scalars['BigInt'];
  talent: ConsultantTalentInput;
};


export type UpdateConsultantTalentMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantTalent: (
    { __typename?: 'UpdateConsultantTalentPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ConsultantTalentFragmentFragment = (
  { __typename?: 'ConsultantTalent' }
  & Pick<ConsultantTalent, 'convincedPeerAnswer' | 'applicationChannel' | 'availabilityDate' | 'applicationScore' | 'failedGoalAnswer' | 'leadershipRoleAnswer' | 'africaNeeds' | 'availability' | 'availabilityReason' | 'bio' | 'currentOccupation' | 'email' | 'employmentStatus' | 'firstName' | 'gender' | 'id' | 'jobFunctions' | 'lastName' | 'yearJoined' | 'yearStartedWork' | 'yearStartedFreelance' | 'linkedinUrl' | 'mbti' | 'name' | 'phone' | 'practiceArea' | 'prn' | 'postalAddress' | 'residentialAddress' | 'createdAt' | 'dateOfBirth' | 'rating' | 'status' | 'comments' | 'tagList' | 'trainingTagList' | 'field' | 'accountSetup' | 'state' | 'submittedAt'>
  & { consultantDocuments: Array<(
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  )>, consultantAssessments?: Maybe<Array<(
    { __typename?: 'ConsultantAssessment' }
    & Pick<ConsultantAssessment, 'id'>
  )>>, consultantEducations?: Maybe<Array<(
    { __typename?: 'ConsultantEducation' }
    & ConsultantEducationFragmentFragment
  )>>, consultantNationalities: Array<(
    { __typename?: 'ConsultantNationality' }
    & Pick<ConsultantNationality, 'countryId' | 'id'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    ) }
  )>, consultantSpokenLanguages: Array<(
    { __typename?: 'ConsultantSpokenLanguage' }
    & Pick<ConsultantSpokenLanguage, 'fluency' | 'id' | 'languageId'>
    & { language: (
      { __typename?: 'Language' }
      & Pick<Language, 'id' | 'name'>
    ) }
  )>, consultantWorkExperiences?: Maybe<Array<(
    { __typename?: 'ConsultantWorkExperience' }
    & ConsultantWorkExperienceFragmentFragment
  )>>, country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  )>, jobTitle?: Maybe<(
    { __typename?: 'JobTitle' }
    & JobTitleFragmentFragment
  )>, jobTitles?: Maybe<Array<(
    { __typename?: 'JobTitle' }
    & JobTitleFragmentFragment
  )>>, missionStaffingApplications?: Maybe<(
    { __typename?: 'PaginatedMissionStaffingApplications' }
    & { data: Array<(
      { __typename?: 'MissionStaffingApplication' }
      & MissionStaffingApplicationFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  )>, missionStaffingPlacements?: Maybe<(
    { __typename?: 'PaginatedMissionStaffingPlacements' }
    & { data: Array<(
      { __typename?: 'MissionStaffingPlacement' }
      & MissionStaffingPlacementFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  )>, nextStates: Array<(
    { __typename?: 'StateEventPair' }
    & Pick<StateEventPair, 'event' | 'state'>
  )>, profilePresentation?: Maybe<(
    { __typename?: 'UploadFile' }
    & UploadFileFragmentFragment
  )>, user?: Maybe<(
    { __typename?: 'UserAdvanced' }
    & Pick<UserAdvanced, 'id' | 'name' | 'email'>
    & { avatar?: Maybe<(
      { __typename?: 'UploadFile' }
      & UploadFileFragmentFragment
    )> }
  )>, missions: Array<(
    { __typename?: 'MissionLifecycleBasic' }
    & MissionLifecycleBasicFragmentFragment
  )> }
);

export type TalentRatingsFragmentFragment = (
  { __typename?: 'TalentRatings' }
  & { values: Array<(
    { __typename?: 'TalentRatingEntry' }
    & Pick<TalentRatingEntry, 'key'>
    & { bottom: Array<(
      { __typename?: 'ConsultantAdvanced' }
      & Pick<ConsultantAdvanced, 'createdAt' | 'firstName' | 'id' | 'lastName' | 'name' | 'rating' | 'submittedAt'>
      & { jobTitle?: Maybe<(
        { __typename?: 'JobTitle' }
        & Pick<JobTitle, 'name'>
      )>, profilePresentation?: Maybe<(
        { __typename?: 'UploadFile' }
        & Pick<UploadFile, 'url'>
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
        & { avatar?: Maybe<(
          { __typename?: 'UploadFile' }
          & Pick<UploadFile, 'url'>
        )>, consultant?: Maybe<(
          { __typename?: 'Consultant' }
          & Pick<Consultant, 'name'>
          & { jobTitle?: Maybe<(
            { __typename?: 'JobTitle' }
            & Pick<JobTitle, 'name'>
          )> }
        )> }
      )> }
    )>, top: Array<(
      { __typename?: 'ConsultantAdvanced' }
      & Pick<ConsultantAdvanced, 'createdAt' | 'firstName' | 'id' | 'lastName' | 'name' | 'rating' | 'submittedAt'>
      & { jobTitle?: Maybe<(
        { __typename?: 'JobTitle' }
        & Pick<JobTitle, 'name'>
      )>, profilePresentation?: Maybe<(
        { __typename?: 'UploadFile' }
        & Pick<UploadFile, 'url'>
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
        & { avatar?: Maybe<(
          { __typename?: 'UploadFile' }
          & Pick<UploadFile, 'url'>
        )>, consultant?: Maybe<(
          { __typename?: 'Consultant' }
          & Pick<Consultant, 'name'>
          & { jobTitle?: Maybe<(
            { __typename?: 'JobTitle' }
            & Pick<JobTitle, 'name'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type TalentRatingsQueryQueryVariables = {
  filter?: Maybe<TalentRatingsFilter>;
};


export type TalentRatingsQueryQuery = (
  { __typename?: 'Query' }
  & { talentDashboardData: (
    { __typename?: 'TalentDashboardStatistics' }
    & { talentRatings: (
      { __typename?: 'TalentRatings' }
      & TalentRatingsFragmentFragment
    ) }
  ) }
);

export type TalentDistributionQueryQueryVariables = {
  filter?: Maybe<TalentDistributionFilter>;
};


export type TalentDistributionQueryQuery = (
  { __typename?: 'Query' }
  & { talentDashboardData: (
    { __typename?: 'TalentDashboardStatistics' }
    & { talentDistribution: (
      { __typename?: 'TalentDistribution' }
      & Pick<TalentDistribution, 'by'>
      & { values: Array<(
        { __typename?: 'TalentDistributionTypeEntry' }
        & Pick<TalentDistributionTypeEntry, 'count' | 'keys'>
      )> }
    ) }
  ) }
);

export type TalentApplicationsDistributionQueryQueryVariables = {
  filter?: Maybe<TalentApplicationsDistributionFilter>;
};


export type TalentApplicationsDistributionQueryQuery = (
  { __typename?: 'Query' }
  & { talentDashboardData: (
    { __typename?: 'TalentDashboardStatistics' }
    & { talentApplicationsDistribution: (
      { __typename?: 'TalentApplicationsDistribution' }
      & Pick<TalentApplicationsDistribution, 'by'>
      & { values: Array<(
        { __typename?: 'TalentDistributionTypeEntry' }
        & Pick<TalentDistributionTypeEntry, 'count' | 'keys'>
      )> }
    ) }
  ) }
);

export type TalentUtilizationQueryQueryVariables = {
  filter?: Maybe<TalentUtilizationFilter>;
};


export type TalentUtilizationQueryQuery = (
  { __typename?: 'Query' }
  & { talentDashboardData: (
    { __typename?: 'TalentDashboardStatistics' }
    & { talentUtilization: (
      { __typename?: 'TalentUtilization' }
      & Pick<TalentUtilization, 'by'>
      & { values: Array<(
        { __typename?: 'TalentUtilizationEntry' }
        & Pick<TalentUtilizationEntry, 'count' | 'total' | 'percent'>
        & { by: (
          { __typename?: 'ByAggregationResult' }
          & Pick<ByAggregationResult, 'role'>
        ) }
      )> }
    ) }
  ) }
);

export type ConsultantPeralteeClaimsQueryQueryVariables = {
  filter?: Maybe<ConsultantPeralteeClaimsFilter>;
  sort?: Maybe<ConsultantPeralteeClaimsSortInput>;
  page?: Maybe<Scalars['Int']>;
  per?: Maybe<Scalars['Int']>;
};


export type ConsultantPeralteeClaimsQueryQuery = (
  { __typename?: 'Query' }
  & { consultantPeralteeClaims: (
    { __typename?: 'PaginatedConsultantPeralteeClaims' }
    & { data: Array<(
      { __typename?: 'ConsultantPeralteeClaim' }
      & ConsultantPeralteeClaimFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type ApproveConsultantPeralteeClaimMutationMutationVariables = {
  id: Scalars['BigInt'];
  comment?: Maybe<Scalars['String']>;
};


export type ApproveConsultantPeralteeClaimMutationMutation = (
  { __typename?: 'Mutation' }
  & { approveConsultantPeralteeClaim: (
    { __typename?: 'ApproveConsultantPeralteeClaimPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RejectConsultantPeralteeClaimMutationMutationVariables = {
  id: Scalars['BigInt'];
  comment: Scalars['String'];
};


export type RejectConsultantPeralteeClaimMutationMutation = (
  { __typename?: 'Mutation' }
  & { rejectConsultantPeralteeClaim: (
    { __typename?: 'RejectConsultantPeralteeClaimPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ConsultantPeralteeStatisticsQueryQueryVariables = {
  dateRange?: Maybe<DateFilterInput>;
  consultantId?: Maybe<Scalars['BigInt']>;
};


export type ConsultantPeralteeStatisticsQueryQuery = (
  { __typename?: 'Query' }
  & { consultantPeralteeClaimsStatistics: (
    { __typename?: 'ConsultantPeralteeClaimsStatistics' }
    & Pick<ConsultantPeralteeClaimsStatistics, 'total'>
    & { approved: (
      { __typename?: 'ClaimStateStatistics' }
      & PeralteeClaimsStatsFragment
    ), pending: (
      { __typename?: 'ClaimStateStatistics' }
      & PeralteeClaimsStatsFragment
    ), rejected: (
      { __typename?: 'ClaimStateStatistics' }
      & PeralteeClaimsStatsFragment
    ) }
  ) }
);

export type PeralteeClaimsStatsFragment = (
  { __typename?: 'ClaimStateStatistics' }
  & Pick<ClaimStateStatistics, 'count' | 'points'>
  & { change: (
    { __typename?: 'ClaimPeriodChangeStatistics' }
    & Pick<ClaimPeriodChangeStatistics, 'count' | 'points'>
  ) }
);

export type AllApplicantsQueryQueryVariables = {
  page?: Maybe<Scalars['Int']>;
  per?: Maybe<Scalars['Int']>;
  nameContains?: Maybe<Scalars['String']>;
};


export type AllApplicantsQueryQuery = (
  { __typename?: 'Query' }
  & { pending: (
    { __typename?: 'PaginatedApplicants' }
    & { data: Array<(
      { __typename?: 'Applicant' }
      & Pick<Applicant, 'id'>
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ), intro_call: (
    { __typename?: 'PaginatedApplicants' }
    & { data: Array<(
      { __typename?: 'Applicant' }
      & Pick<Applicant, 'id'>
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ), shortlisted: (
    { __typename?: 'PaginatedApplicants' }
    & { data: Array<(
      { __typename?: 'Applicant' }
      & Pick<Applicant, 'id'>
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ), first_case_study: (
    { __typename?: 'PaginatedApplicants' }
    & { data: Array<(
      { __typename?: 'Applicant' }
      & Pick<Applicant, 'id'>
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ), second_case_study: (
    { __typename?: 'PaginatedApplicants' }
    & { data: Array<(
      { __typename?: 'Applicant' }
      & Pick<Applicant, 'id'>
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ), first_final_interview: (
    { __typename?: 'PaginatedApplicants' }
    & { data: Array<(
      { __typename?: 'Applicant' }
      & Pick<Applicant, 'id'>
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ), second_final_interview: (
    { __typename?: 'PaginatedApplicants' }
    & { data: Array<(
      { __typename?: 'Applicant' }
      & Pick<Applicant, 'id'>
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ), rejected: (
    { __typename?: 'PaginatedApplicants' }
    & { data: Array<(
      { __typename?: 'Applicant' }
      & Pick<Applicant, 'id'>
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type ConsultantTalentBasicFragmentFragment = (
  { __typename?: 'ConsultantTalent' }
  & Pick<ConsultantTalent, 'id' | 'firstName' | 'lastName' | 'name' | 'state' | 'submittedAt'>
  & { jobTitle?: Maybe<(
    { __typename?: 'JobTitle' }
    & Pick<JobTitle, 'id' | 'name'>
  )>, nextStates: Array<(
    { __typename?: 'StateEventPair' }
    & Pick<StateEventPair, 'event' | 'state'>
  )>, user?: Maybe<(
    { __typename?: 'UserAdvanced' }
    & Pick<UserAdvanced, 'id'>
    & { avatar?: Maybe<(
      { __typename?: 'UploadFile' }
      & Pick<UploadFile, 'id' | 'url'>
    )> }
  )> }
);

export type ProceedApplicationMutationMutationVariables = {
  id: Scalars['BigInt'];
  nextEvent?: Maybe<Scalars['String']>;
};


export type ProceedApplicationMutationMutation = (
  { __typename?: 'Mutation' }
  & { proceedApplication: (
    { __typename?: 'ProceedApplicationPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type TasksQueryQueryVariables = {
  filter?: Maybe<TasksFilter>;
};


export type TasksQueryQuery = (
  { __typename?: 'Query' }
  & { tasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'completedAt' | 'createdAt' | 'deadline' | 'description' | 'id' | 'metadata' | 'state' | 'title' | 'type' | 'taskableId' | 'taskableType' | 'taskableName' | 'priority'>
  )> }
);

export type ResolveTaskMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type ResolveTaskMutationMutation = (
  { __typename?: 'Mutation' }
  & { resolveTask: (
    { __typename?: 'ResolveTaskPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RejectTaskMutationMutationVariables = {
  id: Scalars['BigInt'];
  data: Scalars['JSON'];
};


export type RejectTaskMutationMutation = (
  { __typename?: 'Mutation' }
  & { rejectTask: (
    { __typename?: 'RejectTaskPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type GeneratePresignedUrlMutationMutationVariables = {
  folder: Scalars['String'];
  filename: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
};


export type GeneratePresignedUrlMutationMutation = (
  { __typename?: 'Mutation' }
  & { generatePresignedUrl: (
    { __typename?: 'GeneratePresignedUrlPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, presignedUrl?: Maybe<(
      { __typename?: 'PresignedUrl' }
      & Pick<PresignedUrl, 'key' | 'url'>
    )> }
  ) }
);

export type UploadFileFragmentFragment = (
  { __typename?: 'UploadFile' }
  & Pick<UploadFile, 'id' | 'name' | 'url' | 'extension' | 'createdAt' | 'category' | 'public'>
);

export type DeleteUploadFileMutationMutationVariables = {
  id: Scalars['BigInt'];
};


export type DeleteUploadFileMutationMutation = (
  { __typename?: 'Mutation' }
  & { deleteUploadFile: (
    { __typename?: 'DeleteUploadFilePayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type UserSignaturesFragmentFragment = (
  { __typename?: 'UserSignatures' }
  & { files: Array<(
    { __typename?: 'SignatureSource' }
    & SignatureSourceFragmentFragment
  )>, scripts: Array<(
    { __typename?: 'SignatureSource' }
    & SignatureSourceFragmentFragment
  )> }
);

export type SignatureSourceFragmentFragment = (
  { __typename?: 'SignatureSource' }
  & Pick<SignatureSource, 'createdAt' | 'id' | 'imageUrl' | 'key' | 'type' | 'updatedAt'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'firstName' | 'id' | 'lastName' | 'name'>
  )> }
);

export type UserSignaturesQueryQueryVariables = {
  filter?: Maybe<SignatureFilter>;
};


export type UserSignaturesQueryQuery = (
  { __typename?: 'Query' }
  & { userSignatures?: Maybe<(
    { __typename?: 'UserSignatures' }
    & UserSignaturesFragmentFragment
  )> }
);

export type GenerateConsultantInvoicesMutationMutationVariables = {
  missionLifecycleId: Scalars['ID'];
};


export type GenerateConsultantInvoicesMutationMutation = (
  { __typename?: 'Mutation' }
  & { generateConsultantInvoices: (
    { __typename?: 'GenerateConsultantInvoicesPayload' }
    & { consultantInvoices?: Maybe<Array<(
      { __typename?: 'ConsultantInvoice' }
      & Pick<ConsultantInvoice, 'id' | 'updatedAt'>
    )>>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ArchiveMissionMutationMutationVariables = {
  id: Scalars['ID'];
  archivalReason: Scalars['String'];
};


export type ArchiveMissionMutationMutation = (
  { __typename?: 'Mutation' }
  & { archiveMission: (
    { __typename?: 'ArchiveMissionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type MissionStaffingApplicationsQueryQueryVariables = {
  filter?: Maybe<MissionStaffingPositionsFilter>;
  sort?: Maybe<MissionStaffingPositionsSortInput>;
  page?: Maybe<Scalars['Int']>;
  per: Scalars['Int'];
};


export type MissionStaffingApplicationsQueryQuery = (
  { __typename?: 'Query' }
  & { missionStaffingPositions: (
    { __typename?: 'PaginatedMissionStaffingPositions' }
    & { data: Array<(
      { __typename?: 'MissionStaffingPosition' }
      & Pick<MissionStaffingPosition, 'id' | 'prn'>
      & { missionStaffingApplications: Array<(
        { __typename?: 'MissionStaffingApplication' }
        & PendingMissionStaffingApplicationFragmentFragment
      )> }
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type ApproveMissionStaffingApplicationMutationMutationVariables = {
  id: Scalars['ID'];
};


export type ApproveMissionStaffingApplicationMutationMutation = (
  { __typename?: 'Mutation' }
  & { approveMissionStaffingApplication: (
    { __typename?: 'ApproveMissionStaffingApplicationPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type RejectMissionStaffingApplicationMutationMutationVariables = {
  id: Scalars['ID'];
  rejectionReason: Scalars['String'];
};


export type RejectMissionStaffingApplicationMutationMutation = (
  { __typename?: 'Mutation' }
  & { rejectMissionStaffingApplication: (
    { __typename?: 'RejectMissionStaffingApplicationPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  ) }
);

export type ShortlistMissionStaffingApplicationMutationMutationVariables = {
  id: Scalars['ID'];
};


export type ShortlistMissionStaffingApplicationMutationMutation = (
  { __typename?: 'Mutation' }
  & { shortlistMissionStaffingApplication: (
    { __typename?: 'ShortlistMissionStaffingApplicationPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, missionStaffingApplication?: Maybe<(
      { __typename?: 'MissionStaffingApplication' }
      & PendingMissionStaffingApplicationFragmentFragment
    )> }
  ) }
);

export type MissionStaffingPositionDetailFragmentFragment = (
  { __typename?: 'MissionStaffingPosition' }
  & Pick<MissionStaffingPosition, 'id' | 'prn' | 'description' | 'invoiceable' | 'updatedAt' | 'name' | 'state' | 'positionType'>
  & { jobTitle: (
    { __typename?: 'JobTitle' }
    & Pick<JobTitle, 'id' | 'name'>
  ), requiredExpertise: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>, missionLifecycle: (
    { __typename?: 'MissionLifecycleBasic' }
    & Pick<MissionLifecycleBasic, 'id' | 'name' | 'projectLocationCountryCode' | 'projectLocationType' | 'summary' | 'endDate' | 'startDate' | 'state'>
    & { scopeService?: Maybe<(
      { __typename?: 'ScopeService' }
      & Pick<ScopeService, 'id' | 'name'>
      & { serviceGroup: (
        { __typename?: 'ScopeServiceGroup' }
        & Pick<ScopeServiceGroup, 'id' | 'name'>
      ) }
    )> }
  ), consultantContractPayments: Array<(
    { __typename?: 'ConsultantContractPayment' }
    & ConsultantContractPaymentFragmentFragment
  )>, staffingPositionDeliverables: Array<(
    { __typename?: 'StaffingPositionDeliverable' }
    & Pick<StaffingPositionDeliverable, 'id' | 'name'>
    & { missionStaffingPosition: (
      { __typename?: 'MissionStaffingPosition' }
      & Pick<MissionStaffingPosition, 'id' | 'description'>
      & { totalFee: (
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      ) }
    ) }
  )>, pendingMissionStaffingApplications: Array<(
    { __typename?: 'MissionStaffingApplication' }
    & PendingMissionStaffingApplicationFragmentFragment
  )>, totalFee: (
    { __typename?: 'Money' }
    & MoneyFragmentFragment
  ), activityLogs: Array<(
    { __typename?: 'ActivityLog' }
    & ActivityLogFragmentFragment
  )> }
);

export type PendingMissionStaffingApplicationFragmentFragment = (
  { __typename?: 'MissionStaffingApplication' }
  & Pick<MissionStaffingApplication, 'id' | 'prn' | 'state' | 'expressionOfInterest' | 'rejectReason'>
  & { consultant: (
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id' | 'firstName' | 'lastName' | 'name'>
    & { jobTitle?: Maybe<(
      { __typename?: 'JobTitle' }
      & Pick<JobTitle, 'id' | 'name'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
      & { avatar?: Maybe<(
        { __typename?: 'UploadFile' }
        & UploadFileFragmentFragment
      )> }
    )>, nextStates: Array<(
      { __typename?: 'StateEventPair' }
      & Pick<StateEventPair, 'event' | 'state'>
    )> }
  ), missionStaffingPosition: (
    { __typename?: 'MissionStaffingPosition' }
    & Pick<MissionStaffingPosition, 'id' | 'name'>
  ) }
);

export type UpdateMissionExecutionStaffingPositionMutationMutationVariables = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  milestones?: Maybe<Array<Scalars['ID']>>;
  contractPayments?: Maybe<Array<ConsultantContractPaymentInput>>;
  requiredExpertiseTags?: Maybe<Array<Scalars['String']>>;
  totalFee?: Maybe<MoneyInput>;
};


export type UpdateMissionExecutionStaffingPositionMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateMissionExecutionStaffingPosition: (
    { __typename?: 'UpdateMissionExecutionStaffingPositionPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, missionStaffingPosition?: Maybe<(
      { __typename?: 'MissionStaffingPosition' }
      & Pick<MissionStaffingPosition, 'id' | 'prn' | 'name' | 'description'>
      & { totalFee: (
        { __typename?: 'Money' }
        & MoneyFragmentFragment
      ) }
    )> }
  ) }
);

export type MissionStaffingPositionsQueryQueryVariables = {
  filter?: Maybe<MissionStaffingPositionsFilter>;
  sort?: Maybe<MissionStaffingPositionsSortInput>;
  paginate?: Maybe<PaginationInput>;
};


export type MissionStaffingPositionsQueryQuery = (
  { __typename?: 'Query' }
  & { missionStaffingPositions: (
    { __typename?: 'PaginatedMissionStaffingPositions' }
    & { data: Array<(
      { __typename?: 'MissionStaffingPosition' }
      & MissionStaffingPositionDetailFragmentFragment
    )>, pagination: (
      { __typename?: 'PaginationV2' }
      & PaginationFragmentFragment
    ) }
  ) }
);

export type CreateConsultantContractPaymentMutationMutationVariables = {
  consultantContractPaymentInput: ConsultantContractPaymentInput;
  missionContractPaymentId: Scalars['ID'];
  missionStaffingPositionId: Scalars['ID'];
};


export type CreateConsultantContractPaymentMutationMutation = (
  { __typename?: 'Mutation' }
  & { createConsultantContractPayment: (
    { __typename?: 'CreateConsultantContractPaymentPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, payment?: Maybe<(
      { __typename?: 'ConsultantContractPayment' }
      & ConsultantContractPaymentFragmentFragment
    )> }
  ) }
);

export type UpdateConsultantContractPaymentMutationMutationVariables = {
  consultantContractPaymentId: Scalars['ID'];
  consultantContractPaymentInput: ConsultantContractPaymentInput;
};


export type UpdateConsultantContractPaymentMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantContractPayment: (
    { __typename?: 'UpdateConsultantContractPaymentPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, payment?: Maybe<(
      { __typename?: 'ConsultantContractPayment' }
      & ConsultantContractPaymentFragmentFragment
    )> }
  ) }
);

export type InitiateClientPaymentProcessingMutationMutationVariables = {
  paymentId: Scalars['ID'];
};


export type InitiateClientPaymentProcessingMutationMutation = (
  { __typename?: 'Mutation' }
  & { initiateClientPaymentProcessing?: Maybe<(
    { __typename?: 'InitiateClientPaymentProcessingPayload' }
    & { payment?: Maybe<(
      { __typename?: 'MissionContractPayment' }
      & Pick<MissionContractPayment, 'id' | 'state'>
    )>, error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )> }
  )> }
);

export type AcceptInvitationMutationMutationVariables = {
  invitationId: Scalars['ID'];
  token: Scalars['String'];
  newUserInput?: Maybe<UserInput>;
  newConsultantInput?: Maybe<ConsultantOnboardingInput>;
  newClientInput?: Maybe<ClientInput>;
  newSubscriptionUserInput?: Maybe<SubscriptionUserInput>;
};


export type AcceptInvitationMutationMutation = (
  { __typename?: 'Mutation' }
  & { acceptInvitation: (
    { __typename?: 'AcceptInvitationPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, invitation?: Maybe<(
      { __typename?: 'Invitation' }
      & Pick<Invitation, 'id'>
    )> }
  ) }
);

export type FindInvitationQueryQueryVariables = {
  token: Scalars['String'];
};


export type FindInvitationQueryQuery = (
  { __typename?: 'Query' }
  & { findInvitation?: Maybe<(
    { __typename?: 'Invitation' }
    & Pick<Invitation, 'createdAt' | 'email' | 'id' | 'prn' | 'state' | 'type' | 'updatedAt'>
    & { invitationForProduct: (
      { __typename?: 'Product' }
      & Pick<Product, 'slug'>
    ) }
  )> }
);

export type CreateClientWithUserMutationMutationVariables = {
  newUserInput: UserInput;
  newClientInput: ClientInput;
  newSubscriptionInput?: Maybe<SubscriptionInput>;
  newCardInput?: Maybe<CardInput>;
  missionRequestInput?: Maybe<MissionRequestInput>;
  promoCodeInput?: Maybe<PromoCodeInput>;
};


export type CreateClientWithUserMutationMutation = (
  { __typename?: 'Mutation' }
  & { createClientWithUser: (
    { __typename?: 'CreateClientWithUserPayload' }
    & { error?: Maybe<(
      { __typename?: 'ErrorV2' }
      & ErrorFragmentFragment
    )>, clientPayload?: Maybe<(
      { __typename?: 'ClientPayload' }
      & Pick<ClientPayload, 'subscriptionId' | 'payInvoiceUrl'>
    )> }
  ) }
);

export type ProductPlanFragmentFragment = (
  { __typename?: 'ProductPlan' }
  & Pick<ProductPlan, 'feesCurrency' | 'id' | 'name' | 'prn' | 'feesSubunit' | 'subscriptionPeriod' | 'slug' | 'available' | 'description' | 'allowedSeats' | 'maximumSeats' | 'minimumSeats'>
);

export type FindProductPlansQueryQueryVariables = {
  productSlug?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};


export type FindProductPlansQueryQuery = (
  { __typename?: 'Query' }
  & { findProductPlans?: Maybe<Array<(
    { __typename?: 'ProductPlan' }
    & ProductPlanFragmentFragment
  )>> }
);

export type FindPromoCodeQueryQueryVariables = {
  code: Scalars['String'];
};


export type FindPromoCodeQueryQuery = (
  { __typename?: 'Query' }
  & { findPromoCode?: Maybe<(
    { __typename?: 'PromoCode' }
    & Pick<PromoCode, 'code' | 'createdAt' | 'id' | 'offers' | 'prn' | 'updatedAt' | 'description' | 'freeTrial'>
    & { plan?: Maybe<(
      { __typename?: 'ProductPlan' }
      & ProductPlanFragmentFragment
    )> }
  )> }
);

export const UploadFileFragment = gql`
    fragment UploadFileFragment on UploadFile {
  id
  name
  url
  extension
  createdAt
  extension
  category
  public
}
    `;
export const ApplicantFragment = gql`
    fragment ApplicantFragment on Applicant {
  id
  city
  country {
    id
    name
  }
  phoneCountryCode
  phoneNumber
  dateOfBirth
  emailAddress
  gender
  jobRole
  name
  phone
  prn
  firstName
  lastName
  scatterId
  state
  coverLetter {
    ...UploadFileFragment
  }
  cv {
    ...UploadFileFragment
  }
  certificate {
    ...UploadFileFragment
  }
  documents {
    ...UploadFileFragment
  }
  allowedEvents
}
    ${UploadFileFragment}`;
export const DocumentFragment = gql`
    fragment DocumentFragment on MissionDocument {
  createdAt
  documentType
  id
  name
  note
  prn
  updatedAt
  uploadFile {
    ...UploadFileFragment
  }
  uploadedBy {
    id
    name
    lastName
    firstName
    avatar {
      id
      url
    }
  }
}
    ${UploadFileFragment}`;
export const ApprovalFragment = gql`
    fragment ApprovalFragment on Approval {
  approvedAt
  createdAt
  id
  rejectedAt
  rejectionReason
  state
  updatedAt
  user {
    id
    name
  }
}
    `;
export const ClientDetailFragment = gql`
    fragment ClientDetailFragment on Client {
  id
  name
  address
  email
  companyLogoUrl
  companySizeRange
  website
  verified
  description
  proposalsCount
  taxExempt
  prn
  industry
  ownershipStructure
  productSlug
  approval {
    ...ApprovalFragment
  }
  product {
    id
    name
    logoUrl
  }
  subscriptions {
    id
    expiredAt
    state
    seatsUsageCount
    product {
      id
      name
      logoUrl
    }
    createdAt
  }
  clientType {
    id
    name
  }
  primaryClientContact {
    email
    name
    phone
    role
  }
  clientContacts {
    id
    email
    name
    phone
    role
  }
  missionsCount
  country {
    id
    name
  }
  clientType {
    id
    name
  }
  missionLifecycles {
    id
    name
    state
    missionProposal {
      id
    }
  }
  createdAt
}
    ${ApprovalFragment}`;
export const SubscriptionUserFragment = gql`
    fragment SubscriptionUserFragment on SubscriptionUser {
  active
  createdAt
  email
  id
  name
  prn
  updatedAt
}
    `;
export const MoneyFragment = gql`
    fragment MoneyFragment on Money {
  amount
  currency
  format
}
    `;
export const ScopeServiceFragment = gql`
    fragment ScopeServiceFragment on ScopeService {
  createdAt
  description
  id
  name
  prn
  averageDuration
  serviceGroup {
    createdAt
    id
    name
    prn
    updatedAt
  }
  priceMax {
    ...MoneyFragment
  }
  priceMin {
    ...MoneyFragment
  }
  teamRoles {
    id
    prn
    quantity
    role {
      id
      name
    }
  }
  phases {
    id
    activities {
      id
      name
    }
    name
  }
  updatedAt
  availableForMissions
}
    ${MoneyFragment}`;
export const ScopePhaseSubscriptionFragment = gql`
    fragment ScopePhaseSubscriptionFragment on CreateScopeLibraryPhasePayload {
  scopePhaseSubscription {
    activities {
      createdAt
      id
      name
      position
      updatedAt
    }
    createdAt
    id
    position
    phase {
      createdAt
      id
      name
      subscriptionCount
      updatedAt
    }
    updatedAt
  }
}
    `;
export const PhaseSubscriptionsFragment = gql`
    fragment PhaseSubscriptionsFragment on ScopeService {
  phaseSubscriptions {
    activities {
      createdAt
      id
      name
      position
      updatedAt
    }
    createdAt
    id
    position
    phase {
      createdAt
      id
      name
      subscriptionCount
      updatedAt
    }
    updatedAt
  }
}
    `;
export const RoleFragment = gql`
    fragment RoleFragment on Role {
  id
  name
  description
  active
  permissions {
    slug
    description
    name
  }
}
    `;
export const ErrorFragment = gql`
    fragment ErrorFragment on ErrorV2 {
  fields {
    field
    message
  }
  message
  code
}
    `;
export const ConsultantEducationFragment = gql`
    fragment consultantEducationFragment on ConsultantEducation {
  degree
  startDate
  endDate
  grade
  id
  institution {
    id
    name
  }
  institutionId
  institutionName
  name
}
    `;
export const ConsultantWorkExperienceFragment = gql`
    fragment consultantWorkExperienceFragment on ConsultantWorkExperience {
  company {
    id
    name
  }
  companyId
  consultantWorkExperienceLocations {
    country {
      id
      name
    }
    countryId
    id
  }
  description
  endDate
  id
  jobTitle
  otherCompany
  startDate
}
    `;
export const JobTitleFragment = gql`
    fragment jobTitleFragment on JobTitle {
  description
  id
  name
  questions {
    id
    identifier
    label
    metadata
    type
  }
  questionsCount
  requirements
}
    `;
export const ConsultantApplicationFragment = gql`
    fragment ConsultantApplicationFragment on ConsultantApplication {
  convincedPeerAnswer
  applicationChannel
  availabilityDate
  consultantDocuments {
    ...UploadFileFragment
  }
  failedGoalAnswer
  leadershipRoleAnswer
  africaNeeds
  bio
  dateOfBirth
  yearStartedFreelance
  yearStartedWork
  availability
  consultantEducations {
    ...consultantEducationFragment
  }
  consultantNationalities {
    country {
      id
      name
    }
    countryId
    id
  }
  consultantSpokenLanguages {
    fluency
    id
    language {
      id
      name
    }
    languageId
  }
  consultantWorkExperiences {
    ...consultantWorkExperienceFragment
  }
  country {
    id
    name
  }
  currentOccupation
  email
  firstName
  gender
  id
  jobTitle {
    ...jobTitleFragment
  }
  lastName
  linkedinUrl
  mbti
  name
  phone
  postalAddress
  residentialAddress
  user {
    id
    name
  }
  createdAt
  employmentStatus
  jobFunctions
  practiceArea
  yearStartedFreelance
  yearStartedWork
}
    ${UploadFileFragment}
${ConsultantEducationFragment}
${ConsultantWorkExperienceFragment}
${JobTitleFragment}`;
export const ActivityLogFragment = gql`
    fragment ActivityLogFragment on ActivityLog {
  action
  actionComposition
  actionables
  createdAt
  id
  updatedAt
  initiatorType
  initiator {
    name
    avatar {
      url
    }
  }
}
    `;
export const ConsultantContractPaymentFragment = gql`
    fragment ConsultantContractPaymentFragment on ConsultantContractPayment {
  id
  prn
  createdAt
  updatedAt
  description
  name
  amount {
    ...MoneyFragment
  }
  percentage
  amountCents
  dueDate
  missionContractPayment {
    id
    name
  }
  staffingPositionDeliverables {
    id
    name
    missionScopeActivity {
      id
      name
      prn
    }
    missionStaffingPosition {
      id
    }
  }
  paymentType
  state
}
    ${MoneyFragment}`;
export const CurrencyFragment = gql`
    fragment CurrencyFragment on Currency {
  id
  code
  name
  symbol
  factor
}
    `;
export const EsignatureFragment = gql`
    fragment EsignatureFragment on Esignature {
  id
  signatureSource {
    id
    type
    key
    imageUrl
  }
  user {
    id
    name
  }
  signedAt
}
    `;
export const ConsultantContractFragment = gql`
    fragment ConsultantContractFragment on ConsultantContract {
  id
  prn
  activityLogs {
    ...ActivityLogFragment
  }
  application {
    id
    missionStaffingPosition {
      id
      jobTitle {
        id
        name
      }
      description
      name
      totalFee {
        ...MoneyFragment
      }
      consultantContractPayments {
        ...ConsultantContractPaymentFragment
      }
    }
    consultant {
      id
      name
      jobTitle {
        id
        name
      }
      user {
        id
        avatar {
          ...UploadFileFragment
        }
      }
    }
  }
  missionLifecycle {
    id
    name
    projectLocationType
    projectLocationCountryCode
    scopeService {
      id
      name
      serviceGroup {
        id
        name
      }
    }
    contractingEntity
    scopeService {
      id
      name
      serviceGroup {
        id
        name
      }
    }
  }
  consultantContractMilestones {
    id
    description
    feesCents
    fees
  }
  updatedAt
  acknowledgedAt
  state
  totalFees
  objective
  contractDate
  terminatedAt
  address
  companyName
  approvals {
    ...ApprovalFragment
  }
  currency {
    ...CurrencyFragment
  }
  createdAt
  consultant {
    id
    user {
      id
    }
    name
  }
  contractingEntity
  commencementDate
  conclusionDate
  declineReason
  signatures {
    ...EsignatureFragment
  }
}
    ${ActivityLogFragment}
${MoneyFragment}
${ConsultantContractPaymentFragment}
${UploadFileFragment}
${ApprovalFragment}
${CurrencyFragment}
${EsignatureFragment}`;
export const ListMissionStaffingPositionFragment = gql`
    fragment ListMissionStaffingPositionFragment on MissionStaffingPosition {
  id
  prn
  description
  jobTitle {
    id
    name
  }
  name
  state
  staffingPositionDeliverables {
    id
    completed
    name
    missionStaffingPosition {
      id
      prn
      name
      totalFee {
        ...MoneyFragment
      }
    }
  }
  missionLifecycle {
    id
    name
    projectLocationCountryCode
    projectLocationType
    startDate
    tagList
    scopeService {
      id
      name
      serviceGroup {
        id
        name
      }
    }
  }
  requiredExpertise {
    id
    name
  }
  updatedAt
  createdAt
}
    ${MoneyFragment}`;
export const MissionStaffingApplicationFragment = gql`
    fragment missionStaffingApplicationFragment on MissionStaffingApplication {
  id
  prn
  state
  missionStaffingPosition {
    ...ListMissionStaffingPositionFragment
  }
  expressionOfInterest
  rejectReason
  createdAt
  updatedAt
}
    ${ListMissionStaffingPositionFragment}`;
export const PaginationFragment = gql`
    fragment PaginationFragment on PaginationV2 {
  currentPage
  firstPage
  lastPage
  nextPage
  prevPage
  pageCount
  totalSize
}
    `;
export const MissionStaffingPlacementFragment = gql`
    fragment missionStaffingPlacementFragment on MissionStaffingPlacement {
  id
  missionLifecycle {
    id
    name
    state
    missionLead {
      id
      clientName
      client {
        id
        name
      }
      projectLocationCountry
      projectLocationType
      tentativeStartDate
      tentativeEndDate
    }
    missionExecution {
      id
      commencementDate
      completionDate
    }
    updatedAt
  }
  missionStaffingPosition {
    id
    name
    description
    jobTitle {
      id
      name
    }
    updatedAt
    consultantContractPayments {
      ...ConsultantContractPaymentFragment
    }
  }
  createdAt
}
    ${ConsultantContractPaymentFragment}`;
export const ConsultantBankAccountFragment = gql`
    fragment ConsultantBankAccountFragment on ConsultantBankAccount {
  accountName
  accountNumber
  bankName
  createdAt
  id
  routingNumber
  sortCode
  state
  swiftCode
  bankAddress
  updatedAt
  country {
    id
    name
  }
}
    `;
export const MissionLifecycleBasicFragment = gql`
    fragment MissionLifecycleBasicFragment on MissionLifecycleBasic {
  id
  createdAt
  updatedAt
  name
  prn
  scopeService {
    id
    name
    serviceGroup {
      id
      name
    }
  }
  completionProgress
  completionProgressActivityLog {
    id
    updatedAt
    updatedBy {
      firstName
    }
  }
  summary
  projectLocationCountryCode
  projectLocationType
  endDate
  startDate
  activityLogs {
    id
    createdAt
    updatedAt
    action
    initiator {
      name
      avatar {
        url
      }
    }
    initiatorType
    actionComposition
    actionables
  }
  state
  tagList
}
    `;
export const ConsultantProfileFragment = gql`
    fragment ConsultantProfileFragment on ConsultantProfile {
  wallet {
    id
    totalEarned
    totalOutstanding
  }
  africaNeeds
  availability
  availabilityReason
  bio
  consultantEducations {
    ...consultantEducationFragment
  }
  consultantNationalities {
    country {
      id
      name
    }
    id
  }
  employmentStatus
  consultantSpokenLanguages {
    fluency
    id
    language {
      id
      name
    }
    languageId
  }
  consultantWorkExperiences {
    ...consultantWorkExperienceFragment
  }
  country {
    id
    name
  }
  currentOccupation
  email
  firstName
  gender
  id
  jobFunctions
  jobTitle {
    ...jobTitleFragment
  }
  jobTitles {
    ...jobTitleFragment
  }
  lastName
  linkedinUrl
  mbti
  missionStaffingApplications {
    data {
      ...missionStaffingApplicationFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
  missionStaffingPlacements {
    data {
      ...missionStaffingPlacementFragment
      consultantInvoices {
        total
        id
        paymentDescription
        payment {
          amount {
            ...MoneyFragment
          }
          description
          id
          paymentType
        }
        state
        paidAt
      }
    }
    pagination {
      ...PaginationFragment
    }
  }
  name
  phone
  profilePresentation {
    ...UploadFileFragment
  }
  practiceArea
  prn
  postalAddress
  rating
  residentialAddress
  trainingTagList
  user {
    id
    name
    avatar {
      ...UploadFileFragment
    }
  }
  yearJoined
  yearStartedWork
  yearStartedFreelance
  dateOfBirth
  createdAt
  consultantPayoneerAccounts {
    createdAt
    customerId
    emailAddress
    id
    state
    updatedAt
  }
  consultantBankAccounts {
    ...ConsultantBankAccountFragment
  }
  consultantDocuments {
    ...UploadFileFragment
  }
  missions(limit: $missionsLimit) {
    ...MissionLifecycleBasicFragment
  }
  state
}
    ${ConsultantEducationFragment}
${ConsultantWorkExperienceFragment}
${JobTitleFragment}
${MissionStaffingApplicationFragment}
${PaginationFragment}
${MissionStaffingPlacementFragment}
${MoneyFragment}
${UploadFileFragment}
${ConsultantBankAccountFragment}
${MissionLifecycleBasicFragment}`;
export const ClientFragment = gql`
    fragment ClientFragment on Client {
  address
  approval {
    ...ApprovalFragment
  }
  clientType {
    id
    name
  }
  country {
    id
    name
  }
  id
  missionsCount
  name
  proposalsCount
  taxExempt
}
    ${ApprovalFragment}`;
export const UserFragment = gql`
    fragment UserFragment on User {
  id
  firstName
  lastName
  consultant {
    id
  }
  avatar {
    ...UploadFileFragment
  }
}
    ${UploadFileFragment}`;
export const CommentFragment = gql`
    fragment CommentFragment on Comment {
  createdAt
  edited
  id
  prn
  text
  updatedAt
  user {
    ...UserFragment
  }
}
    ${UserFragment}`;
export const UserAdvancedFragment = gql`
    fragment UserAdvancedFragment on UserAdvanced {
  id
  prn
  firstName
  lastName
  name
  consultant {
    id
  }
  avatar {
    ...UploadFileFragment
  }
  roles {
    id
    name
    active
  }
  email
  permissions {
    id
    name
    slug
  }
  clientsAsAdmin {
    id
    name
    prn
    acceptedServiceAgreement
    product {
      id
    }
    productSlug
  }
  verified
}
    ${UploadFileFragment}`;
export const ConsultantFragment = gql`
    fragment ConsultantFragment on Consultant {
  id
  jobTitle {
    id
    name
  }
  firstName
  lastName
  createdAt
  user {
    id
    avatar {
      ...UploadFileFragment
    }
    firstName
    lastName
  }
  jobTitle {
    id
    name
  }
  name
}
    ${UploadFileFragment}`;
export const MissionTeamMembershipFragment = gql`
    fragment MissionTeamMembershipFragment on MissionTeamMembership {
  consultant {
    ...ConsultantFragment
  }
  isManager
  user {
    ...UserAdvancedFragment
  }
  state
  position {
    id
    name
    state
  }
  role
  placement {
    id
    state
    placementType
    consultantContract {
      id
    }
  }
}
    ${ConsultantFragment}
${UserAdvancedFragment}`;
export const MissionScopeFragment = gql`
    fragment MissionScopeFragment on MissionScope {
  id
  targetMissionDuration
  capacityAllocation
  scopeDocument {
    ...UploadFileFragment
  }
  activityLogs {
    ...ActivityLogFragment
  }
  phases {
    id
    name
    position
    activities {
      capacities
      id
      prn
      name
      position
      deliverable
      capacityDistribution
      missionScopePhase {
        id
      }
      completed
    }
  }
  activities {
    capacities
    id
    prn
    name
    position
    deliverable
    missionScopePhase {
      id
    }
    capacityDistribution
    completed
  }
  staffs {
    id
    jobTitle {
      id
      name
      abbreviation
      dailyRate
      capacityFactor
    }
  }
  collaborators {
    ...UserFragment
  }
  version
  updatedAt
}
    ${UploadFileFragment}
${ActivityLogFragment}
${UserFragment}`;
export const MissionPricingProposalFragment = gql`
    fragment MissionPricingProposalFragment on MissionPricingProposal {
  id
  totalPrice {
    ...MoneyFragment
  }
  activities {
    id
    quantity
    name
    unitCost
    distribution
  }
  markup
  currency {
    ...CurrencyFragment
  }
  currencyCode
  contractingEntity
  updatedAt
  approvals {
    ...ApprovalFragment
  }
  state
}
    ${MoneyFragment}
${CurrencyFragment}
${ApprovalFragment}`;
export const ApprovedAttachmentFragment = gql`
    fragment ApprovedAttachmentFragment on ApprovedAttachment {
  id
  approvals {
    ...ApprovalFragment
  }
  createdAt
  state
  updatedAt
  uploadFile {
    ...UploadFileFragment
  }
}
    ${ApprovalFragment}
${UploadFileFragment}`;
export const MissionStaffingPositionFragment = gql`
    fragment MissionStaffingPositionFragment on MissionStaffingPosition {
  description
  id
  prn
  jobTitle {
    id
    name
  }
  requiredExpertise {
    id
    name
  }
  consultantContractPayments {
    ...ConsultantContractPaymentFragment
  }
  name
  pendingMissionStaffingApplications {
    consultant {
      id
      name
      jobTitle {
        id
        name
      }
      user {
        id
        avatar {
          ...UploadFileFragment
        }
      }
    }
    missionStaffingPosition {
      id
      name
    }
    id
    state
    expressionOfInterest
    rejectReason
  }
  state
}
    ${ConsultantContractPaymentFragment}
${UploadFileFragment}`;
export const EsignatureRequestFragment = gql`
    fragment EsignatureRequestFragment on EsignatureRequest {
  id
  mandateSecret
  signer {
    id
    name
  }
  state
}
    `;
export const SurveyResponseDataFragment = gql`
    fragment SurveyResponseDataFragment on SurveyResponseData {
  choice
  label
  questionId
}
    `;
export const SurveyAssignmentFragment = gql`
    fragment SurveyAssignmentFragment on SurveyAssignment {
  assignmentType
  createdAt
  id
  surveyableId
  response {
    ...SurveyResponseDataFragment
  }
  score
  state
  subtitle
  survey {
    description
    id
    name
    questions {
      id
      label
      options
      questionType
      slug
      description
      helpText
      ancestry
      category {
        id
        name
      }
      position
      required
    }
    slug
    surveyType
  }
  title
  updatedAt
  user {
    id
    name
  }
}
    ${SurveyResponseDataFragment}`;
export const MissionLifecycleFragment = gql`
    fragment missionLifecycleFragment on MissionLifecycle {
  createdAt
  inProgress
  archivalNotes
  archiveable
  inProgress
  missionExecution {
    commencementDate
    completionDate
    id
  }
  scopeService {
    id
    name
    serviceGroup {
      id
      name
    }
  }
  endDate
  startDate
  id
  prn
  completionProgress
  completionProgressActivityLog {
    ...ActivityLogFragment
    updatedBy {
      ...UserFragment
    }
  }
  collaborators {
    ...UserAdvancedFragment
  }
  defaultConversation {
    prn
    id
    lastComment {
      id
      prn
      text
      createdAt
      updatedAt
      user {
        ...UserFragment
      }
    }
  }
  termsOfReferences {
    ...UploadFileFragment
  }
  missionLead {
    approver {
      id
    }
    client {
      id
      name
      verified
      companyLogoUrl
      email
      clientType {
        name
        id
      }
      clientContacts {
        id
        email
        name
        phone
      }
    }
    missionLifecycle {
      id
    }
    region {
      id
      name
    }
    clientContactEmail
    clientContactName
    clientContactPhone
    clientContactRole
    clientDescription
    clientName
    eoiDeadline
    rfpDeadline
    clientWebsite
    leadSource {
      id
      name
    }
    createdAt
    leadGrade {
      id
      name
    }
    id
    practiceGroup {
      id
      name
      subGroups {
        id
        name
      }
    }
    subPracticeGroup {
      id
      name
    }
    projectLocationCountry
    projectLocationType
    summary
    state
    tentativeEndDate
    tentativeStartDate
    termsOfReferences {
      ...UploadFileFragment
    }
    user {
      ...UserFragment
    }
  }
  projectLocationCountryCode
  projectLocationType
  summary
  client {
    id
    name
    email
    companyLogoUrl
    verified
  }
  missionTeamMemberships {
    ...MissionTeamMembershipFragment
  }
  executive {
    id
    firstName
    lastName
    name
    jobTitle {
      id
      name
    }
    user {
      ...UserFragment
    }
  }
  teamLeads {
    id
    firstName
    lastName
    name
    jobTitle {
      id
      name
    }
    user {
      ...UserFragment
    }
  }
  principals {
    id
    firstName
    lastName
    name
    jobTitle {
      id
      name
    }
    user {
      ...UserFragment
    }
  }
  staffingRequests {
    id
    consultant {
      id
      name
      jobTitle {
        id
        name
      }
      user {
        id
        avatar {
          ...UploadFileFragment
        }
      }
    }
    approval {
      ...ApprovalFragment
    }
    missionStaffingPosition {
      name
    }
  }
  missionScope {
    ...MissionScopeFragment
  }
  missionPricingProposal {
    ...MissionPricingProposalFragment
  }
  missionProposal {
    createdAt
    id
    clientApproval {
      ...ApprovalFragment
    }
    clientApprovals {
      ...ApprovalFragment
    }
    pricingProposal {
      ...ApprovedAttachmentFragment
    }
    missionPricingProposal {
      totalPrice {
        ...MoneyFragment
      }
      activities {
        id
        quantity
        name
        unitCost
        distribution
      }
      id
      markup
      currency {
        ...CurrencyFragment
      }
      currencyCode
      contractingEntity
      updatedAt
      approvals {
        ...ApprovalFragment
      }
      state
    }
    missionProposalStaffingPlacements {
      consultant {
        id
        name
        jobTitle {
          id
          name
        }
        user {
          id
          avatar {
            ...UploadFileFragment
          }
        }
      }
      id
      placementType
      state
    }
    missionProposalStaffingPositions {
      ...MissionStaffingPositionFragment
    }
    state
    technicalProposal {
      ...ApprovedAttachmentFragment
    }
    updatedAt
  }
  missionExecutionStaffingPositions {
    ...MissionStaffingPositionFragment
  }
  missionExecutionStaffingPlacements {
    consultant {
      id
      name
      jobTitle {
        id
        name
      }
      user {
        id
        avatar {
          ...UploadFileFragment
        }
      }
    }
    consultantContract {
      id
    }
    id
    placementType
    state
  }
  missionContract {
    createdAt
    engagementLetter {
      ...ApprovedAttachmentFragment
    }
    id
    signedContract {
      ...UploadFileFragment
    }
    signatures {
      ...EsignatureFragment
    }
    signatureRequest {
      ...EsignatureRequestFragment
    }
    updatedAt
    draftContract {
      bankAccount {
        accountAlias
        accountName
        accountNumber
        bankAddress
        bankName
        createdAt
        iban
        id
        sortCode
        swiftCode
      }
      clientLegalName
      clientStakeholders
      commencementDate
      conclusionDate
      contractDate
      createdAt
      currency {
        ...CurrencyFragment
      }
      deliverableSubmissionDate
      id
      logisticsCostBearer
      maximumLogisticsCost
      missionPartner {
        id
        name
        email
      }
      afgSignatoryTitle
      projectObjective
      providerObjective
      updatedAt
    }
    missionContractPayments {
      amount {
        ...MoneyFragment
      }
      invoiceUrl
      checkoutUrl
      createdAt
      updatedAt
      amountCents
      description
      dueDate
      id
      state
      prn
      missionContractDeliverables {
        dueDate
        id
        name
        state
        missionScopeActivity {
          id
          name
          prn
        }
      }
      name
      paymentType
      state
    }
    deliverables {
      dueDate
      id
      name
      state
      missionScopeActivity {
        id
        name
      }
    }
  }
  asanteSurveys {
    ...SurveyAssignmentFragment
  }
  clientSatisfactionSurvey {
    ...SurveyAssignmentFragment
  }
  name
  rejectionReason
  state
  tagList
  activityLogs {
    ...ActivityLogFragment
  }
  operational
  staffable
}
    ${ActivityLogFragment}
${UserFragment}
${UserAdvancedFragment}
${UploadFileFragment}
${MissionTeamMembershipFragment}
${ApprovalFragment}
${MissionScopeFragment}
${MissionPricingProposalFragment}
${ApprovedAttachmentFragment}
${MoneyFragment}
${CurrencyFragment}
${MissionStaffingPositionFragment}
${EsignatureFragment}
${EsignatureRequestFragment}
${SurveyAssignmentFragment}`;
export const MissionStaffingPlacementMyMissionFragment = gql`
    fragment missionStaffingPlacementMyMissionFragment on MissionStaffingPlacement {
  id
  missionLifecycle {
    id
    name
    state
    missionLead {
      id
      clientName
      client {
        id
        name
      }
      projectLocationCountry
      projectLocationType
      tentativeStartDate
      tentativeEndDate
    }
    missionExecution {
      id
      commencementDate
      completionDate
    }
    updatedAt
  }
  missionStaffingPosition {
    id
    name
    description
    jobTitle {
      id
      name
    }
    updatedAt
    consultantContractPayments {
      ...ConsultantContractPaymentFragment
    }
  }
  consultantInvoices {
    approvedAt
    createdAt
    dueAt
    id
    invoiceType
    paidAt
    paymentDescription
    payment {
      amount {
        ...MoneyFragment
      }
      state
      description
      createdAt
    }
    state
    total
    updatedAt
  }
  createdAt
}
    ${ConsultantContractPaymentFragment}
${MoneyFragment}`;
export const ConsultantMissionLifecycleListFragment = gql`
    fragment ConsultantMissionLifecycleListFragment on ConsultantMissionLifecycle {
  id
  prn
  name
  state
  summary
  createdAt
  missionStaffingPlacement {
    id
    missionStaffingPosition {
      id
      name
      description
    }
  }
}
    `;
export const ConsultantMissionLifecycleFragment = gql`
    fragment ConsultantMissionLifecycleFragment on ConsultantMissionLifecycle {
  id
  prn
  name
  state
  summary
  tagList
  createdAt
  updatedAt
  activityLogs {
    ...ActivityLogFragment
  }
  termsOfReferences {
    ...UploadFileFragment
  }
  missionScope {
    ...MissionScopeFragment
  }
  missionTeamMemberships {
    ...MissionTeamMembershipFragment
  }
  defaultConversation {
    prn
    id
    lastComment {
      id
      prn
      text
      createdAt
      updatedAt
      user {
        ...UserFragment
      }
    }
  }
  client {
    id
    prn
    name
    companyLogoUrl
    verified
  }
  scopeService {
    id
    name
    prn
    serviceGroup {
      id
      name
      prn
    }
  }
  completionProgress
  projectLocationCountryCode
  projectLocationType
  endDate
  startDate
  collaborators {
    ...UserAdvancedFragment
  }
  missionStaffingPlacement {
    id
    missionLifecycle {
      id
      prn
    }
    missionStaffingPosition {
      id
      name
      description
      positionType
      jobTitle {
        id
        name
      }
      staffingPositionDeliverables {
        id
        name
        missionStaffingPosition {
          id
          name
          totalFee {
            ...MoneyFragment
          }
        }
      }
    }
    consultantInvoices {
      id
      createdAt
      updatedAt
      state
      dueAt
      paymentDescription
      payment {
        amount {
          ...MoneyFragment
        }
        description
        id
        paymentType
      }
    }
  }
}
    ${ActivityLogFragment}
${UploadFileFragment}
${MissionScopeFragment}
${MissionTeamMembershipFragment}
${UserFragment}
${UserAdvancedFragment}
${MoneyFragment}`;
export const ConsultantPeralteeClaimFragment = gql`
    fragment ConsultantPeralteeClaimFragment on ConsultantPeralteeClaim {
  comment
  consultant {
    id
    firstName
    lastName
    jobTitle {
      id
      name
    }
    name
    user {
      id
      avatar {
        id
        url
      }
    }
  }
  id
  state
  description
  createdAt
  supportService {
    description
    id
    name
    points
  }
}
    `;
export const PerformanceAppraisalFragment = gql`
    fragment performanceAppraisalFragment on MissionPerformanceAppraisal {
  id
  appraiser {
    id
    name
    user {
      id
      avatar {
        id
        url
      }
    }
  }
  appraisee {
    id
    name
    jobTitle {
      id
      name
    }
    user {
      id
      avatar {
        id
        url
      }
      firstName
      lastName
    }
  }
  missionLifecycle {
    id
    name
  }
  appraiseeRole {
    id
    name
  }
  appraiserRole {
    id
    name
  }
  surveyAssignment {
    ...SurveyAssignmentFragment
  }
  createdAt
  updatedAt
}
    ${SurveyAssignmentFragment}`;
export const UserProfileFragment = gql`
    fragment UserProfileFragment on User {
  id
  avatar {
    ...UploadFileFragment
  }
  consultant {
    id
    createdAt
    jobTitle {
      id
      name
    }
  }
  firstName
  lastName
}
    ${UploadFileFragment}`;
export const SurveyQuestionFragment = gql`
    fragment SurveyQuestionFragment on SurveyQuestion {
  id
  label
  options
  position
  questionType
  required
  ancestry
  prn
  category {
    id
    name
  }
  surveySection {
    id
  }
}
    `;
export const SurveyFragment = gql`
    fragment SurveyFragment on Survey {
  prn
  createdAt
  description
  id
  name
  state
  public
  scatterId
  questions {
    ...SurveyQuestionFragment
  }
  surveyableId
  responses {
    id
    updatedAt
    createdAt
    data {
      ...SurveyResponseDataFragment
    }
    user {
      ...UserFragment
    }
  }
  activityLogs {
    ...ActivityLogFragment
  }
  surveyType
  updatedAt
}
    ${SurveyQuestionFragment}
${SurveyResponseDataFragment}
${UserFragment}
${ActivityLogFragment}`;
export const PerformanceAppraisalScheduleFragment = gql`
    fragment performanceAppraisalScheduleFragment on MissionPerformanceAppraisalSchedule {
  appraiser {
    id
    name
  }
  appraisee {
    id
    name
  }
  missionLifecycle {
    id
    name
  }
  appraiseeRole {
    id
    name
  }
  appraiserRole {
    id
    name
  }
  createdAt
}
    `;
export const ConsultantTalentFragment = gql`
    fragment ConsultantTalentFragment on ConsultantTalent {
  convincedPeerAnswer
  applicationChannel
  availabilityDate
  applicationScore
  consultantDocuments {
    ...UploadFileFragment
  }
  failedGoalAnswer
  leadershipRoleAnswer
  africaNeeds
  availability
  availabilityReason
  bio
  consultantAssessments {
    id
  }
  consultantEducations {
    ...consultantEducationFragment
  }
  consultantNationalities {
    country {
      id
      name
    }
    countryId
    id
  }
  consultantSpokenLanguages {
    fluency
    id
    language {
      id
      name
    }
    languageId
  }
  consultantWorkExperiences {
    ...consultantWorkExperienceFragment
  }
  country {
    id
    name
  }
  currentOccupation
  email
  employmentStatus
  firstName
  gender
  id
  jobFunctions
  jobTitle {
    ...jobTitleFragment
  }
  jobTitles {
    ...jobTitleFragment
  }
  lastName
  yearJoined
  yearStartedWork
  yearStartedFreelance
  linkedinUrl
  mbti
  missionStaffingApplications {
    data {
      ...missionStaffingApplicationFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
  missionStaffingPlacements {
    data {
      ...missionStaffingPlacementFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
  nextStates {
    event
    state
  }
  name
  phone
  practiceArea
  profilePresentation {
    ...UploadFileFragment
  }
  prn
  postalAddress
  residentialAddress
  user {
    id
    name
    avatar {
      ...UploadFileFragment
    }
    email
  }
  createdAt
  dateOfBirth
  rating
  status
  comments
  tagList
  trainingTagList
  field
  accountSetup
  state
  submittedAt
  missions(limit: 5) {
    ...MissionLifecycleBasicFragment
  }
}
    ${UploadFileFragment}
${ConsultantEducationFragment}
${ConsultantWorkExperienceFragment}
${JobTitleFragment}
${MissionStaffingApplicationFragment}
${PaginationFragment}
${MissionStaffingPlacementFragment}
${MissionLifecycleBasicFragment}`;
export const TalentRatingsFragment = gql`
    fragment TalentRatingsFragment on TalentRatings {
  values {
    bottom {
      createdAt
      firstName
      id
      jobTitle {
        name
      }
      lastName
      name
      profilePresentation {
        url
      }
      rating
      submittedAt
      user {
        avatar {
          url
        }
        name
        consultant {
          name
          jobTitle {
            name
          }
        }
      }
    }
    key
    top {
      createdAt
      firstName
      id
      jobTitle {
        name
      }
      lastName
      name
      profilePresentation {
        url
      }
      rating
      submittedAt
      user {
        avatar {
          url
        }
        name
        consultant {
          name
          jobTitle {
            name
          }
        }
      }
    }
  }
}
    `;
export const PeralteeClaimsStats = gql`
    fragment PeralteeClaimsStats on ClaimStateStatistics {
  count
  points
  change {
    count
    points
  }
}
    `;
export const ConsultantTalentBasicFragment = gql`
    fragment ConsultantTalentBasicFragment on ConsultantTalent {
  id
  jobTitle {
    id
    name
  }
  firstName
  lastName
  id
  nextStates {
    event
    state
  }
  user {
    id
    avatar {
      id
      url
    }
  }
  name
  state
  submittedAt
}
    `;
export const SignatureSourceFragment = gql`
    fragment SignatureSourceFragment on SignatureSource {
  createdAt
  id
  imageUrl
  key
  type
  updatedAt
  user {
    active
    firstName
    id
    lastName
    name
  }
}
    `;
export const UserSignaturesFragment = gql`
    fragment userSignaturesFragment on UserSignatures {
  files {
    ...SignatureSourceFragment
  }
  scripts {
    ...SignatureSourceFragment
  }
}
    ${SignatureSourceFragment}`;
export const PendingMissionStaffingApplicationFragment = gql`
    fragment PendingMissionStaffingApplicationFragment on MissionStaffingApplication {
  consultant {
    id
    firstName
    lastName
    name
    jobTitle {
      id
      name
    }
    user {
      id
      firstName
      lastName
      avatar {
        ...UploadFileFragment
      }
    }
    nextStates {
      event
      state
    }
  }
  missionStaffingPosition {
    id
    name
  }
  id
  prn
  state
  expressionOfInterest
  rejectReason
}
    ${UploadFileFragment}`;
export const MissionStaffingPositionDetailFragment = gql`
    fragment MissionStaffingPositionDetailFragment on MissionStaffingPosition {
  id
  prn
  description
  invoiceable
  updatedAt
  name
  state
  positionType
  jobTitle {
    id
    name
  }
  requiredExpertise {
    id
    name
  }
  missionLifecycle {
    id
    name
    projectLocationCountryCode
    projectLocationType
    summary
    scopeService {
      id
      name
      serviceGroup {
        id
        name
      }
    }
    endDate
    startDate
    state
  }
  consultantContractPayments {
    ...ConsultantContractPaymentFragment
  }
  staffingPositionDeliverables {
    id
    name
    missionStaffingPosition {
      id
      description
      totalFee {
        ...MoneyFragment
      }
    }
  }
  pendingMissionStaffingApplications {
    ...PendingMissionStaffingApplicationFragment
  }
  totalFee {
    ...MoneyFragment
  }
  activityLogs {
    ...ActivityLogFragment
  }
}
    ${ConsultantContractPaymentFragment}
${MoneyFragment}
${PendingMissionStaffingApplicationFragment}
${ActivityLogFragment}`;
export const ProductPlanFragment = gql`
    fragment ProductPlanFragment on ProductPlan {
  feesCurrency
  id
  name
  prn
  feesCurrency
  feesSubunit
  subscriptionPeriod
  slug
  available
  description
  allowedSeats
  maximumSeats
  minimumSeats
}
    `;
export const AppendSignatureMutation = gql`
    mutation appendSignatureMutation($mandateSecret: String!, $requestId: BigInt!, $signatureSource: SignatureSourceInput!) {
  appendSignature(
    mandateSecret: $mandateSecret
    requestId: $requestId
    signatureSource: $signatureSource
  ) {
    error {
      ...ErrorFragment
    }
    signature {
      ...EsignatureFragment
    }
  }
}
    ${ErrorFragment}
${EsignatureFragment}`;
export const MeApplicantQuery = gql`
    query meApplicantQuery($applicantId: String!) {
  meApplicant(applicantId: $applicantId) {
    ...ApplicantFragment
  }
}
    ${ApplicantFragment}`;
export const FindApplicantQuery = gql`
    query findApplicantQuery($emailAddress: String!) {
  findApplicant(emailAddress: $emailAddress) {
    emailAddress
  }
}
    `;
export const ClientDetailsQuery = gql`
    query clientDetailsQuery($filter: ClientsFilter) {
  clients(filter: $filter) {
    data {
      createdAt
      description
      id
      name
      tasks {
        id
        title
        type
        taskableId
        description
      }
    }
  }
}
    `;
export const MyClientQuery = gql`
    query myClientQuery($id: ID!) {
  myClient(id: $id) {
    id
    name
    ongoingMissions {
      ...MissionLifecycleBasicFragment
    }
    priorityPayments {
      amount {
        ...MoneyFragment
      }
      amountCents
      description
      dueDate
      id
      name
      missionLifecycle {
        id
        name
      }
    }
    tasks {
      completedAt
      createdAt
      deadline
      description
      id
      metadata
      state
      taskableId
      taskableType
      title
      type
      taskableName
      priority
    }
    taxExempt
    website
  }
}
    ${MissionLifecycleBasicFragment}
${MoneyFragment}`;
export const UnreadConversationsQuery = gql`
    query unreadConversationsQuery($clientId: ID) {
  unreadConversations(clientId: $clientId) {
    comments {
      ...CommentFragment
    }
    lastComment {
      ...CommentFragment
    }
    scope
    createdAt
    id
    prn
    updatedAt
  }
}
    ${CommentFragment}`;
export const DocumentsQuery = gql`
    query documentsQuery($filter: MissionDocumentsFilter) {
  missionDocuments(paginate: {page: 1, per: 20}, filter: $filter) {
    data {
      createdAt
      documentType
      id
      note
      name
      prn
      updatedAt
      uploadFile {
        ...UploadFileFragment
      }
      uploadedBy {
        id
        name
        lastName
        firstName
        avatar {
          id
          url
        }
      }
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${UploadFileFragment}
${PaginationFragment}`;
export const ClientAdminsQuery = gql`
    query clientAdminsQuery($id: ID!) {
  findClient(id: $id) {
    id
    name
    prn
    acceptedServiceAgreement
    clientAdminGrants {
      id
      prn
      client {
        email
        id
        prn
      }
      clientAdminPosition
      user {
        id
        active
        firstName
        lastName
        prn
        avatar {
          id
          url
        }
        email
      }
    }
    pendingClientAdminInvitations {
      id
      prn
      email
      state
      roles
    }
    industry
    description
    address
    country {
      id
      name
    }
    companyLogoUrl
    tagline
    website
  }
}
    `;
export const InviteClientAdminMutation = gql`
    mutation inviteClientAdminMutation($clientId: ID!, $roles: [String!], $userEmail: String!) {
  inviteClientAdmin(clientId: $clientId, roles: $roles, userEmail: $userEmail) {
    error {
      ...ErrorFragment
    }
    invitation {
      createdAt
      email
      id
      prn
      updatedAt
    }
  }
}
    ${ErrorFragment}`;
export const RemoveClientAdminAccessMutation = gql`
    mutation removeClientAdminAccessMutation($clientId: ID!, $userId: ID!) {
  removeClientAdminAccess(clientId: $clientId, userId: $userId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CreateMissionDocumentMutation = gql`
    mutation createMissionDocumentMutation($missionLifecycleId: ID!, $missionDocumentInput: MissionDocumentInput!) {
  createMissionDocument(
    missionLifecycleId: $missionLifecycleId
    missionDocumentInput: $missionDocumentInput
  ) {
    document {
      ...DocumentFragment
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${DocumentFragment}
${ErrorFragment}`;
export const UpdateMissionDocumentMutation = gql`
    mutation updateMissionDocumentMutation($missionDocumentId: ID!, $missionDocumentInput: MissionDocumentInput!) {
  updateMissionDocument(
    missionDocumentId: $missionDocumentId
    missionDocumentInput: $missionDocumentInput
  ) {
    document {
      ...DocumentFragment
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${DocumentFragment}
${ErrorFragment}`;
export const UpdateProfileMutation = gql`
    mutation updateProfileMutation($avatar: UploadFileInput, $firstName: String, $lastName: String, $phone: PhoneInput, $email: String) {
  updateProfile(
    avatar: $avatar
    firstName: $firstName
    lastName: $lastName
    phone: $phone
    email: $email
  ) {
    error {
      ...ErrorFragment
    }
    profile {
      active
      avatar {
        id
        url
      }
      firstName
      id
      lastName
      name
    }
  }
}
    ${ErrorFragment}`;
export const UpdatePasswordMutation = gql`
    mutation updatePasswordMutation($currentPassword: String!, $newPassword: String!) {
  updatePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UsersDetailsQuery = gql`
    query usersDetailsQuery($page: Int, $per: Int, $filter: UsersFilter, $sort: UsersSortInput = {createdAt: desc}) {
  users(filter: $filter, paginate: {page: $page, per: $per}, sort: $sort) {
    data {
      firstName
      lastName
      email
      avatar {
        ...UploadFileFragment
      }
      clientsAsAdmin {
        name
        companyLogoUrl
        description
        address
        country {
          id
          name
        }
        prn
        tagline
        website
        industry
      }
      phoneCountryCode
      phoneNumber
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${UploadFileFragment}
${PaginationFragment}`;
export const ClientUserDetailsQuery = gql`
    query clientUserDetailsQuery {
  meUser {
    active
    avatar {
      ...UploadFileFragment
    }
    clientsAsAdmin {
      id
    }
    consultant {
      createdAt
      firstName
      id
    }
    createdAt
    email
    firstName
    id
    lastName
    name
    permissions {
      description
      id
      name
      slug
    }
    phoneCountryCode
    phoneNumber
    prn
    roles {
      active
      description
      id
      name
    }
    scatterId
    updatedAt
  }
}
    ${UploadFileFragment}`;
export const UpdateCompanyProfileMutation = gql`
    mutation updateCompanyProfileMutation($clientId: ID!, $name: String, $address: String, $description: String, $countryId: ID, $tagline: String, $companyLogo: UploadFileInput, $industry: String, $website: String) {
  updateCompanyProfile(
    clientId: $clientId
    name: $name
    address: $address
    description: $description
    countryId: $countryId
    tagline: $tagline
    companyLogo: $companyLogo
    industry: $industry
    website: $website
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ClientsQuery = gql`
    query clientsQuery($filter: ClientsFilter) {
  clients(paginate: {page: 1, per: 30}, filter: $filter) {
    data {
      ...ClientDetailFragment
    }
  }
}
    ${ClientDetailFragment}`;
export const ClientSubscriptionsQuery = gql`
    query clientSubscriptionsQuery($id: ID!) {
  myClient(id: $id) {
    id
    subscriptions {
      createdAt
      expiredAt
      id
      lastBillingAt
      nextBillingAt
      prn
      productPlan {
        ...ProductPlanFragment
      }
      paymentMethod {
        ... on Card {
          id
        }
      }
      seatsUsageCount
      remainingSeats
      state
      subscriptionPeriod
      updatedAt
      subscriptionUsers {
        ...SubscriptionUserFragment
      }
      invitations {
        id
        prn
        state
        email
      }
    }
  }
}
    ${ProductPlanFragment}
${SubscriptionUserFragment}`;
export const UpdatePaymentMethodMutation = gql`
    mutation updatePaymentMethodMutation($paymentMethodId: ID!, $paymentMethodInput: CardInput!) {
  updatePaymentMethod(
    paymentMethodId: $paymentMethodId
    paymentMethodInput: $paymentMethodInput
  ) {
    error {
      ...ErrorFragment
    }
    paymentMethod {
      id
      prn
    }
  }
}
    ${ErrorFragment}`;
export const InviteSubscriptionUserMutation = gql`
    mutation inviteSubscriptionUserMutation($email: String!, $name: String!, $subscriptionId: ID!) {
  inviteSubscriptionUser(
    email: $email
    name: $name
    subscriptionId: $subscriptionId
  ) {
    error {
      ...ErrorFragment
    }
    subscriptionUser {
      email
      id
    }
  }
}
    ${ErrorFragment}`;
export const RevokeInvitationMutation = gql`
    mutation revokeInvitationMutation($id: ID!) {
  revokeInvitation(invitationId: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ResendInvitationMutation = gql`
    mutation resendInvitationMutation($id: ID!) {
  resendInvitation(invitationId: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const AvailableMissionScopeServicesQuery = gql`
    query availableMissionScopeServicesQuery {
  availableMissionScopeServices {
    ...ScopeServiceFragment
  }
}
    ${ScopeServiceFragment}`;
export const PublicScopeServicesQuery = gql`
    query publicScopeServicesQuery {
  publicScopeServices(filter: {}) {
    createdAt
    description
    id
    name
    prn
    updatedAt
    serviceGroup {
      createdAt
      id
      name
      prn
      updatedAt
    }
  }
}
    `;
export const MissionProblemStatementsQuery = gql`
    query missionProblemStatementsQuery {
  missionProblemStatements {
    options
    problemStatement
    serviceCategories
    serviceGroups
    key
  }
}
    `;
export const CreateMissionRequestMutation = gql`
    mutation createMissionRequestMutation($missionRequestInput: MissionRequestInput!) {
  createMissionRequest(missionRequestInput: $missionRequestInput) {
    error {
      ...ErrorFragment
    }
    missionRequest {
      id
    }
  }
}
    ${ErrorFragment}`;
export const ClientsTaskQuery = gql`
    query clientsTaskQuery($id: ID!) {
  myClient(id: $id) {
    tasks {
      completedAt
      createdAt
      deadline
      description
      id
      metadata
      state
      taskableId
      taskableName
      taskableType
      title
      type
      priority
    }
  }
}
    `;
export const AllScopeServicesQuery = gql`
    query allScopeServicesQuery($filter: ScopeServicesFilter) {
  scopeServices(filter: $filter) {
    id
    name
  }
}
    `;
export const CreateMissionScopeMutation = gql`
    mutation createMissionScopeMutation($missionLifecycleId: ID!, $missionScopePhases: [MissionScopePhaseInput!]) {
  createMissionScope(
    missionLifecycleId: $missionLifecycleId
    missionScopePhases: $missionScopePhases
  ) {
    error {
      ...ErrorFragment
    }
    missionScope {
      id
    }
  }
}
    ${ErrorFragment}`;
export const ScopeTagsQuery = gql`
    query scopeTagsQuery($filter: ScopeTagsFilter) {
  scopeTags(filter: $filter) {
    id
    name
    prn
  }
}
    `;
export const ScopeServiceGroupsQuery = gql`
    query scopeServiceGroupsQuery {
  scopeServiceGroups {
    createdAt
    id
    name
    updatedAt
  }
}
    `;
export const CreateScopeServiceMutation = gql`
    mutation createScopeServiceMutation($scopeServiceInput: ScopeServiceInput!) {
  createScopeService(scopeServiceInput: $scopeServiceInput) {
    error {
      ...ErrorFragment
    }
    scopeService {
      availableForMissions
      createdAt
      description
      id
      name
      serviceGroup {
        id
        name
      }
      updatedAt
    }
  }
}
    ${ErrorFragment}`;
export const CreateScopeLibraryPhaseMutation = gql`
    mutation createScopeLibraryPhaseMutation($name: String!, $scopeServiceId: ID!) {
  createScopeLibraryPhase(name: $name, scopeServiceId: $scopeServiceId) {
    error {
      ...ErrorFragment
    }
    ...ScopePhaseSubscriptionFragment
  }
}
    ${ErrorFragment}
${ScopePhaseSubscriptionFragment}`;
export const ScopeServicesListQuery = gql`
    query scopeServicesListQuery {
  scopeServiceGroups {
    createdAt
    id
    name
    services {
      id
      name
      description
      createdAt
      updatedAt
    }
    updatedAt
  }
}
    `;
export const ScopeServiceQuery = gql`
    query scopeServiceQuery($id: ID) {
  scopeServices(filter: {id: $id}) {
    createdAt
    description
    id
    name
    ...PhaseSubscriptionsFragment
    serviceGroup {
      id
      name
    }
    tags {
      createdAt
      id
      name
      updatedAt
    }
    prn
    teamRoles {
      id
      prn
      quantity
      role {
        id
        name
        abbreviation
      }
    }
    priceMax {
      amount
      currency
    }
    priceMin {
      amount
      currency
    }
    averageDuration
    updatedAt
    availableForMissions
  }
}
    ${PhaseSubscriptionsFragment}`;
export const AllScopeLibraryPhasesQuery = gql`
    query allScopeLibraryPhasesQuery($filter: ScopeLibraryPhasesFilter) {
  scopeLibraryPhases(filter: $filter) {
    createdAt
    id
    name
    updatedAt
  }
}
    `;
export const CreateScopePhaseSubscriptionMutation = gql`
    mutation createScopePhaseSubscriptionMutation($scopeLibraryPhaseId: BigInt!, $scopeServiceId: BigInt!) {
  createScopePhaseSubscription(
    scopeLibraryPhaseId: $scopeLibraryPhaseId
    scopeServiceId: $scopeServiceId
  ) {
    error {
      ...ErrorFragment
    }
    scopePhaseSubscription {
      createdAt
      id
      updatedAt
    }
  }
}
    ${ErrorFragment}`;
export const UpdateScopeLibraryPhaseMutation = gql`
    mutation updateScopeLibraryPhaseMutation($id: BigInt!, $name: String!) {
  updateScopeLibraryPhase(id: $id, name: $name) {
    error {
      ...ErrorFragment
    }
    scopeLibraryPhase {
      activities {
        id
      }
      createdAt
      id
      name
      updatedAt
    }
  }
}
    ${ErrorFragment}`;
export const CreateScopeLibraryActivityMutation = gql`
    mutation createScopeLibraryActivityMutation($scopePhaseSubscriptionId: BigInt!, $name: String!) {
  createScopeLibraryActivity(
    scopePhaseSubscriptionId: $scopePhaseSubscriptionId
    name: $name
  ) {
    error {
      ...ErrorFragment
    }
    scopeLibraryActivity {
      createdAt
      id
      name
      updatedAt
    }
  }
}
    ${ErrorFragment}`;
export const UpdateScopeLibraryActivityMutation = gql`
    mutation updateScopeLibraryActivityMutation($id: BigInt!, $position: BigInt, $name: String) {
  updateScopeLibraryActivity(id: $id, position: $position, name: $name) {
    error {
      ...ErrorFragment
    }
    scopeLibraryActivity {
      id
      name
      updatedAt
      position
    }
  }
}
    ${ErrorFragment}`;
export const DeleteScopeLibraryActivityMutation = gql`
    mutation deleteScopeLibraryActivityMutation($id: BigInt!) {
  deleteScopeLibraryActivity(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateScopeServiceTagsMutation = gql`
    mutation updateScopeServiceTagsMutation($serviceId: BigInt!, $tags: [String!]) {
  updateScopeServiceTags(serviceId: $serviceId, tags: $tags) {
    error {
      ...ErrorFragment
    }
    scopeService {
      id
      tags {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
}
    ${ErrorFragment}`;
export const UpdateScopePhaseSubscriptionMutation = gql`
    mutation updateScopePhaseSubscriptionMutation($id: BigInt!, $libraryPhaseName: String!) {
  updateScopePhaseSubscription(id: $id, libraryPhaseName: $libraryPhaseName) {
    error {
      ...ErrorFragment
    }
    scopePhaseSubscription {
      createdAt
      id
      phase {
        id
        name
        subscriptionCount
        updatedAt
        createdAt
      }
      updatedAt
    }
  }
}
    ${ErrorFragment}`;
export const DeleteScopePhaseSubscriptionMutation = gql`
    mutation deleteScopePhaseSubscriptionMutation($id: BigInt!) {
  deleteScopePhaseSubscription(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateScopeServiceMutation = gql`
    mutation updateScopeServiceMutation($scopeServiceId: ID!, $scopeServiceInput: ScopeServiceInput!) {
  updateScopeService(
    scopeServiceId: $scopeServiceId
    scopeServiceInput: $scopeServiceInput
  ) {
    error {
      ...ErrorFragment
    }
    scopeService {
      description
      id
      name
      updatedAt
      averageDuration
      priceMax {
        amount
        currency
      }
      priceMin {
        amount
        currency
      }
    }
  }
}
    ${ErrorFragment}`;
export const ScopeServiceTagsQuery = gql`
    query scopeServiceTagsQuery {
  scopeServices {
    createdAt
    id
    name
    tags {
      createdAt
      id
      name
      updatedAt
    }
    updatedAt
  }
}
    `;
export const UpdateActivityPositionMutation = gql`
    mutation updateActivityPositionMutation($id: BigInt!, $position: BigInt) {
  updateScopeLibraryActivity(id: $id, position: $position) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ScopeLibraryTreeDataQuery = gql`
    query ScopeLibraryTreeDataQuery {
  scopeServiceGroups {
    id
    name
    services {
      id
      name
      phases {
        id
        name
        activities {
          id
          name
        }
      }
    }
  }
}
    `;
export const UpdatePhaseSubscriptionPositionMutation = gql`
    mutation updatePhaseSubscriptionPositionMutation($id: BigInt!, $position: BigInt) {
  updateScopePhaseSubscription(id: $id, position: $position) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CreateScopeServiceTeamRoleMutation = gql`
    mutation createScopeServiceTeamRoleMutation($scopeServiceId: ID!, $scopeServiceTeamRoleInput: ScopeServiceTeamRoleInput!) {
  createScopeServiceTeamRole(
    scopeServiceId: $scopeServiceId
    scopeServiceTeamRoleInput: $scopeServiceTeamRoleInput
  ) {
    error {
      ...ErrorFragment
    }
    scopeServiceTeamRole {
      createdAt
      id
      prn
      quantity
      role {
        id
      }
      updatedAt
    }
  }
}
    ${ErrorFragment}`;
export const UpdateScopeServiceTeamRoleMutation = gql`
    mutation updateScopeServiceTeamRoleMutation($scopeServiceTeamRoleId: ID!, $scopeServiceTeamRoleInput: ScopeServiceTeamRoleInput!) {
  updateScopeServiceTeamRole(
    scopeServiceTeamRoleId: $scopeServiceTeamRoleId
    scopeServiceTeamRoleInput: $scopeServiceTeamRoleInput
  ) {
    error {
      ...ErrorFragment
    }
    scopeServiceTeamRole {
      createdAt
      id
      prn
      quantity
      role {
        id
        name
      }
      updatedAt
    }
  }
}
    ${ErrorFragment}`;
export const DeleteScopeServiceTeamRoleMutation = gql`
    mutation deleteScopeServiceTeamRoleMutation($scopeServiceTeamRoleId: ID!) {
  deleteScopeServiceTeamRole(scopeServiceTeamRoleId: $scopeServiceTeamRoleId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const AddUserMutation = gql`
    mutation addUserMutation($email: String!, $roleIds: [String!]!) {
  addUser(email: $email, roleIds: $roleIds) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateUserMutation = gql`
    mutation updateUserMutation($id: ID!, $firstName: String!, $lastName: String!, $email: String!, $active: Boolean!, $forceUpdate: Boolean!, $roleIds: [BigInt!]!) {
  updateUser(
    id: $id
    firstName: $firstName
    lastName: $lastName
    email: $email
    active: $active
    forceUpdate: $forceUpdate
    roleIds: $roleIds
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const AddUserRoleMutation = gql`
    mutation addUserRoleMutation($name: String!, $description: String!, $permissionSlugs: [String!]!, $active: Boolean!) {
  addRole(
    name: $name
    description: $description
    permissionSlugs: $permissionSlugs
    active: $active
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateUserRoleMutation = gql`
    mutation updateUserRoleMutation($id: BigInt!, $name: String!, $description: String!, $permissionSlugs: [String!]!, $active: Boolean!) {
  updateRole(
    id: $id
    name: $name
    description: $description
    permissionSlugs: $permissionSlugs
    active: $active
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UsersQuery = gql`
    query usersQuery($page: Int, $per: Int, $filter: UsersFilter, $sort: UsersSortInput = {createdAt: desc}) {
  users(filter: $filter, paginate: {page: $page, per: $per}, sort: $sort) {
    data {
      id
      firstName
      lastName
      email
      active
      avatar {
        ...UploadFileFragment
      }
      roles {
        id
        name
        description
        active
      }
      permissions {
        id
        name
        slug
        description
      }
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${UploadFileFragment}
${PaginationFragment}`;
export const PermissionsQuery = gql`
    query permissionsQuery($page: Int, $per: Int = 200, $sort: PermissionsSortInput = {createdAt: desc}) {
  permissions(paginate: {page: $page, per: $per}, sort: $sort) {
    data {
      id
      slug
      description
      name
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${PaginationFragment}`;
export const UserRolesQuery = gql`
    query userRolesQuery($filter: RolesFilter, $sort: RolesSortInput, $page: Int, $per: Int) {
  roles(filter: $filter, sort: $sort, paginate: {page: $page, per: $per}) {
    data {
      ...RoleFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${RoleFragment}
${PaginationFragment}`;
export const ShortlistBulkApplicantionsMutation = gql`
    mutation shortlistBulkApplicantionsMutation($ids: [ID!]!) {
  bulkShortlistApplicant(applicantsId: $ids) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ArchiveBulkApplicantionsMutation = gql`
    mutation archiveBulkApplicantionsMutation($ids: [ID!]!) {
  bulkArchiveApplication(applicantsId: $ids) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const RejectBulkApplicantionsMutation = gql`
    mutation rejectBulkApplicantionsMutation($ids: [ID!]!, $rejectionReason: CandidateRejectionTypeEnum!) {
  bulkRejectApplication(applicantsId: $ids, rejectionReason: $rejectionReason) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ArchiveApplicationsMutation = gql`
    mutation archiveApplicationsMutation {
  archiveApplications {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const StartApplicationMutation = gql`
    mutation startApplicationMutation($applicant: ApplicantInput!) {
  startApplication(applicant: $applicant) {
    application {
      ...ConsultantApplicationFragment
    }
    completed
    error {
      ...ErrorFragment
    }
  }
}
    ${ConsultantApplicationFragment}
${ErrorFragment}`;
export const SaveApplicationMutation = gql`
    mutation saveApplicationMutation($applicant: ApplicantInput!, $application: ConsultantApplicationInput!) {
  saveApplication(applicant: $applicant, application: $application) {
    application {
      ...ConsultantApplicationFragment
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ConsultantApplicationFragment}
${ErrorFragment}`;
export const CompleteApplicationMutation = gql`
    mutation completeApplicationMutation($applicant: ApplicantInput!) {
  completeApplication(applicant: $applicant) {
    application {
      ...ConsultantApplicationFragment
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ConsultantApplicationFragment}
${ErrorFragment}`;
export const OpenJobTitlesQuery = gql`
    query openJobTitlesQuery {
  jobTitles(filter: {unelapsed: true}, sort: {name: asc}) {
    id
    name
    description
    requirements
    questionsCount
    questions {
      id
      type
      identifier
      label
      metadata
    }
  }
}
    `;
export const AllJobTitlesQuery = gql`
    query allJobTitlesQuery($filter: JobTitlesFilter) {
  jobTitles(filter: $filter) {
    id
    name
    prn
    questionsCount
    questions {
      id
      type
      identifier
      label
      metadata
    }
    abbreviation
    dailyRate
    capacityFactor
  }
}
    `;
export const ApplicationTrackQuery = gql`
    query applicationTrackQuery($id: String!) {
  applicationTrack(id: $id) {
    client {
      id
    }
    createdAt
    description
    id
    name
    overviewContent
    prn
    scatterId
    applicationFormSections {
      createdAt
      description
      id
      sectionType
      timer
      title
      updatedAt
      slug
      externalFormUrl
      stepable
    }
    updatedAt
    url
  }
}
    `;
export const BeginApplicationMutation = gql`
    mutation beginApplicationMutation($applicationTrackId: ID!, $applicantInput: ApplicantInputV2!, $newApplicant: Boolean!) {
  beginApplication(
    applicationTrackId: $applicationTrackId
    applicantInput: $applicantInput
    newApplicant: $newApplicant
  ) {
    applicant {
      id
      prn
      token
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateApplicantProfileMutation = gql`
    mutation updateApplicantProfileMutation($applicantId: ID!, $profile: ApplicantProfileInput!, $applicantDocuments: [UploadFileInput!]!) {
  updateApplicantProfile(
    applicantId: $applicantId
    profile: $profile
    applicantDocuments: $applicantDocuments
  ) {
    applicant {
      id
      prn
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const SubmitApplicationMutation = gql`
    mutation submitApplicationMutation($applicantId: ID!, $applicationTrackId: ID!) {
  submitApplication(
    applicantId: $applicantId
    applicationTrackId: $applicationTrackId
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const LoginMutation = gql`
    mutation loginMutation($email: String!, $password: String!, $mode: LoginMode!) {
  login(email: $email, password: $password, mode: $mode) {
    user {
      ...UserAdvancedFragment
    }
    token
    error {
      message
    }
  }
}
    ${UserAdvancedFragment}`;
export const RequestPasswordResetMutation = gql`
    mutation requestPasswordResetMutation($email: String!) {
  requestPasswordReset(email: $email) {
    error {
      message
    }
  }
}
    `;
export const ResetPasswordMutation = gql`
    mutation resetPasswordMutation($token: String!, $password: String!) {
  resetPassword(token: $token, password: $password) {
    error {
      message
    }
  }
}
    `;
export const RequestApplicantPasswordResetMutation = gql`
    mutation requestApplicantPasswordResetMutation($emailAddress: String!, $trackId: ID!) {
  requestApplicantPasswordReset(emailAddress: $emailAddress, trackId: $trackId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ResetApplicantPasswordMutation = gql`
    mutation resetApplicantPasswordMutation($token: String!, $password: String!) {
  resetApplicantPassword(token: $token, password: $password) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ConfirmEmailMutation = gql`
    mutation confirmEmailMutation($confirmationToken: String!) {
  confirmEmail(confirmationToken: $confirmationToken) {
    error {
      ...ErrorFragment
    }
    response {
      message
      success
    }
  }
}
    ${ErrorFragment}`;
export const ResendEmailConfirmationMutation = gql`
    mutation resendEmailConfirmationMutation {
  resendEmailConfirmation {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const MeUserQuery = gql`
    query meUserQuery {
  meUser {
    ...UserAdvancedFragment
  }
}
    ${UserAdvancedFragment}`;
export const BrandResourceCollectionsQuery = gql`
    query brandResourceCollectionsQuery($filter: BrandResourceCollectionsFilter, $sort: BrandResourceCollectionsSortInput, $paginate: PaginationInput) {
  brandResourceCollections(filter: $filter, sort: $sort, paginate: $paginate) {
    data {
      files {
        id
        name
        embedFileUrl
        embedFileThumbnail
        createdAt
        updatedAt
        brandResourceCollection {
          id
          name
        }
        uploadFile {
          name
          id
          extension
          public
          url
        }
      }
      id
      label {
        id
        name
      }
      name
      updatedAt
      createdAt
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${PaginationFragment}`;
export const CreateBrandResourceCollectionMutation = gql`
    mutation createBrandResourceCollectionMutation($labelId: BigInt!, $name: String!) {
  createBrandResourceCollection(labelId: $labelId, name: $name) {
    collection {
      files {
        id
        name
        embedFileUrl
        embedFileThumbnail
        createdAt
        updatedAt
        brandResourceCollection {
          id
          name
        }
        uploadFile {
          name
          id
          extension
          public
          url
        }
      }
      id
      label {
        id
        name
      }
      name
      updatedAt
      createdAt
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateBrandResourceCollectionMutation = gql`
    mutation updateBrandResourceCollectionMutation($id: BigInt!, $labelId: BigInt!, $name: String!) {
  updateBrandResourceCollection(id: $id, labelId: $labelId, name: $name) {
    collection {
      files {
        id
        name
        embedFileUrl
        embedFileThumbnail
        createdAt
        updatedAt
        brandResourceCollection {
          id
          name
        }
        uploadFile {
          name
          id
          extension
          public
          url
        }
      }
      id
      label {
        id
        name
      }
      name
      updatedAt
      createdAt
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const DeleteBrandResourceCollectionMutation = gql`
    mutation deleteBrandResourceCollectionMutation($id: BigInt!, $reassignCollectionId: BigInt) {
  deleteBrandResourceCollection(
    id: $id
    reassignCollectionId: $reassignCollectionId
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CreateBrandResourceDocumentMutation = gql`
    mutation createBrandResourceDocumentMutation($collectionId: BigInt!, $name: String!, $uploadFile: UploadFileInput!) {
  createBrandResourceDocument(
    collectionId: $collectionId
    name: $name
    uploadFile: $uploadFile
  ) {
    document {
      brandResourceCollection {
        name
        id
        label {
          id
          name
        }
      }
      createdAt
      embedFileThumbnail
      embedFileUrl
      id
      name
      type
      updatedAt
      uploadFile {
        name
        id
        extension
        public
        url
      }
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateBrandResourceDocumentMutation = gql`
    mutation updateBrandResourceDocumentMutation($id: BigInt!, $name: String, $uploadFile: UploadFileInput, $collectionId: BigInt) {
  updateBrandResourceDocument(
    id: $id
    name: $name
    uploadFile: $uploadFile
    collectionId: $collectionId
  ) {
    document {
      brandResourceCollection {
        name
        id
        label {
          id
          name
        }
      }
      createdAt
      embedFileThumbnail
      embedFileUrl
      id
      name
      type
      updatedAt
      uploadFile {
        name
        id
        extension
        public
        url
      }
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const DeleteBrandResourceDocumentMutation = gql`
    mutation deleteBrandResourceDocumentMutation($id: BigInt!) {
  deleteBrandResourceDocument(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CreateBrandResourceVideoMutation = gql`
    mutation createBrandResourceVideoMutation($collectionId: BigInt!, $name: String!, $videoUrl: String!, $videoThumbnail: String!) {
  createBrandResourceVideo(
    collectionId: $collectionId
    name: $name
    videoUrl: $videoUrl
    videoThumbnail: $videoThumbnail
  ) {
    video {
      brandResourceCollection {
        name
        id
        label {
          id
          name
        }
      }
      createdAt
      embedFileThumbnail
      embedFileUrl
      id
      name
      type
      updatedAt
      uploadFile {
        name
        id
        extension
        public
        url
      }
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateBrandResourceVideoMutation = gql`
    mutation updateBrandResourceVideoMutation($id: BigInt!, $collectionId: BigInt!, $name: String!, $videoUrl: String!, $videoThumbnail: String!) {
  updateBrandResourceVideo(
    id: $id
    collectionId: $collectionId
    name: $name
    videoUrl: $videoUrl
    videoThumbnail: $videoThumbnail
  ) {
    video {
      brandResourceCollection {
        name
        id
        label {
          id
          name
        }
      }
      createdAt
      embedFileThumbnail
      embedFileUrl
      id
      name
      type
      updatedAt
      uploadFile {
        name
        id
        extension
        public
        url
      }
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const DeleteBrandResourceVideoMutation = gql`
    mutation deleteBrandResourceVideoMutation($id: BigInt!) {
  deleteBrandResourceVideo(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ListClientsQuery = gql`
    query listClientsQuery($page: Int, $per: Int!, $sort: ClientsSortInput, $filter: ClientsFilter) {
  clients(paginate: {page: $page, per: $per}, sort: $sort, filter: $filter) {
    data {
      id
      name
      proposalsCount
      missionsCount
      product {
        id
        name
      }
      subscriptions {
        id
      }
      country {
        id
        name
      }
      clientType {
        id
        name
      }
      createdAt
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${PaginationFragment}`;
export const AcceptServiceAgreementMutation = gql`
    mutation acceptServiceAgreementMutation {
  acceptServiceAgreement {
    client {
      id
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CreateClientMutation = gql`
    mutation createClientMutation($clientInput: ClientInput!) {
  createClient(clientInput: $clientInput) {
    client {
      id
      name
      proposalsCount
      missionsCount
      country {
        id
        name
      }
      clientType {
        id
        name
      }
      createdAt
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateClientMutation = gql`
    mutation updateClientMutation($id: ID!, $clientInput: ClientInput!) {
  updateClient(clientId: $id, clientInput: $clientInput) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const DeleteClientMutation = gql`
    mutation deleteClientMutation($clientId: BigInt!) {
  deleteClient(clientId: $clientId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ClientTypesQuery = gql`
    query clientTypesQuery {
  clientTypes {
    id
    name
  }
}
    `;
export const CurrenciesQuery = gql`
    query currenciesQuery {
  currencies {
    ...CurrencyFragment
  }
}
    ${CurrencyFragment}`;
export const QuartersQuery = gql`
    query quartersQuery {
  quarters {
    id
    name
  }
}
    `;
export const MissionNaturesQuery = gql`
    query missionNaturesQuery {
  missionNatures {
    id
    name
  }
}
    `;
export const FindOnboardingUserQuery = gql`
    query findOnboardingUserQuery($onboardingIdentifier: String) {
  findUser(onboardingIdentifier: $onboardingIdentifier) {
    id
    firstName
    lastName
  }
}
    `;
export const GrantApprovalMutation = gql`
    mutation grantApprovalMutation($id: BigInt!) {
  grantApproval(id: $id) {
    approval {
      ...ApprovalFragment
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ApprovalFragment}
${ErrorFragment}`;
export const RejectApprovalMutation = gql`
    mutation rejectApprovalMutation($id: BigInt!, $reason: String!, $metadata: JSON) {
  rejectApproval(id: $id, reason: $reason, metadata: $metadata) {
    approval {
      ...ApprovalFragment
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ApprovalFragment}
${ErrorFragment}`;
export const AppConfigsQuery = gql`
    query appConfigsQuery($filter: AppConfigsFilter) {
  appConfigs(paginate: {per: 20, page: 1}, filter: $filter) {
    data {
      id
      group
      key
      name
      value
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${PaginationFragment}`;
export const UpdateAppConfigMutation = gql`
    mutation updateAppConfigMutation($key: String!, $group: String!, $value: String!) {
  updateAppConfig(key: $key, group: $group, value: $value) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const RegionsQuery = gql`
    query regionsQuery {
  regions {
    id
    name
  }
}
    `;
export const SignConsultantContractMutation = gql`
    mutation SignConsultantContractMutation($contractId: BigInt!, $adoptedSignature: String) {
  signConsultantContractDocument(
    id: $contractId
    adoptedSignature: $adoptedSignature
  ) {
    error {
      ...ErrorFragment
    }
    consultantContract {
      ...ConsultantContractFragment
    }
  }
}
    ${ErrorFragment}
${ConsultantContractFragment}`;
export const FindConsultantQuery = gql`
    query findConsultantQuery($id: BigInt!) {
  findConsultant(id: $id) {
    ...ConsultantFragment
  }
}
    ${ConsultantFragment}`;
export const ArchiveApplicationMutation = gql`
    mutation archiveApplicationMutation($applicantId: ID!) {
  archiveApplication(applicantId: $applicantId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ApproveApplicationMutation = gql`
    mutation approveApplicationMutation($id: BigInt!) {
  approveApplication(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ShortlistApplicantForAssessmentMutation = gql`
    mutation shortlistApplicantForAssessmentMutation($id: ID!) {
  shortlistApplicant(applicantId: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ActivatePeraltaAccountMutation = gql`
    mutation activatePeraltaAccountMutation($id: BigInt, $email: String, $jobTitleId: BigInt) {
  activatePeraltaAccount(id: $id, email: $email, jobTitleId: $jobTitleId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const RejectApplicationMutation = gql`
    mutation rejectApplicationMutation($applicantId: ID!, $rejectionReason: CandidateRejectionTypeEnum!) {
  rejectApplication(applicantId: $applicantId, rejectionReason: $rejectionReason) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ApplyForMissionStaffingPositionMutation = gql`
    mutation applyForMissionStaffingPositionMutation($id: ID!, $expressionOfInterest: String!) {
  applyForMissionStaffingPosition(
    missionStaffingPositionId: $id
    expressionOfInterest: $expressionOfInterest
  ) {
    application {
      id
      state
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CancelMissionStaffingApplicationMutation = gql`
    mutation cancelMissionStaffingApplicationMutation($id: BigInt!) {
  cancelMissionStaffingApplication(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const OpenMissionStaffingPositionsQuery = gql`
    query openMissionStaffingPositionsQuery($sort: OpenMissionStaffingPositionsSortInput, $paginate: PaginationInput, $filter: OpenMissionStaffingPositionsFilter) {
  openMissionStaffingPositions(sort: $sort, paginate: $paginate, filter: $filter) {
    data {
      ...ListMissionStaffingPositionFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${ListMissionStaffingPositionFragment}
${PaginationFragment}`;
export const UpdateConsultantProfileMutation = gql`
    mutation updateConsultantProfileMutation($applicant: ApplicantInput, $profile: ConsultantProfileInput!, $profilePresentation: UploadFileInput, $consultantId: BigInt) {
  updateConsultantProfile(
    applicant: $applicant
    profile: $profile
    profilePresentation: $profilePresentation
    consultantId: $consultantId
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateConsultantJobTitlesMutation = gql`
    mutation updateConsultantJobTitlesMutation($consultantId: ID!, $jobTitles: [BigInt!]!) {
  updateConsultantJobTitles(consultantId: $consultantId, jobTitles: $jobTitles) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateConsultantAvailabilityMutation = gql`
    mutation updateConsultantAvailabilityMutation($availability: ConsultantAvailability!, $availabilityReason: String, $consultantId: BigInt, $missionsLimit: Int = 5) {
  updateConsultantAvailability(
    consultantId: $consultantId
    availability: $availability
    availabilityReason: $availabilityReason
  ) {
    consultant {
      ...ConsultantProfileFragment
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ConsultantProfileFragment}
${ErrorFragment}`;
export const AddConsultantWorkExperienceMutation = gql`
    mutation addConsultantWorkExperienceMutation($applicant: ApplicantInput, $consultantId: BigInt, $workExperience: ConsultantWorkExperienceInput!) {
  addConsultantWorkExperience(
    applicant: $applicant
    consultantId: $consultantId
    workExperience: $workExperience
  ) {
    error {
      ...ErrorFragment
    }
    workExperience {
      ...consultantWorkExperienceFragment
    }
  }
}
    ${ErrorFragment}
${ConsultantWorkExperienceFragment}`;
export const UpdateConsultantWorkExperienceMutation = gql`
    mutation updateConsultantWorkExperienceMutation($applicant: ApplicantInput, $workExperienceId: BigInt!, $consultantId: BigInt, $workExperience: ConsultantWorkExperienceInput!) {
  updateConsultantWorkExperience(
    applicant: $applicant
    consultantId: $consultantId
    workExperienceId: $workExperienceId
    workExperience: $workExperience
  ) {
    error {
      ...ErrorFragment
    }
    workExperience {
      ...consultantWorkExperienceFragment
    }
  }
}
    ${ErrorFragment}
${ConsultantWorkExperienceFragment}`;
export const DeleteConsultantWorkExperienceMutation = gql`
    mutation deleteConsultantWorkExperienceMutation($applicant: ApplicantInput, $consultantId: BigInt, $workExperienceId: BigInt!) {
  deleteConsultantWorkExperience(
    applicant: $applicant
    consultantId: $consultantId
    workExperienceId: $workExperienceId
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const AddConsultantEducationMutation = gql`
    mutation addConsultantEducationMutation($applicant: ApplicantInput, $consultantId: BigInt, $education: ConsultantEducationInput!) {
  addConsultantEducation(
    applicant: $applicant
    consultantId: $consultantId
    education: $education
  ) {
    education {
      ...consultantEducationFragment
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ConsultantEducationFragment}
${ErrorFragment}`;
export const UpdateConsultantEducationMutation = gql`
    mutation updateConsultantEducationMutation($applicant: ApplicantInput, $educationId: BigInt!, $education: ConsultantEducationInput!, $consultantId: BigInt) {
  updateConsultantEducation(
    applicant: $applicant
    educationId: $educationId
    education: $education
    consultantId: $consultantId
  ) {
    education {
      ...consultantEducationFragment
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ConsultantEducationFragment}
${ErrorFragment}`;
export const DeleteConsultantEducationMutation = gql`
    mutation deleteConsultantEducationMutation($applicant: ApplicantInput, $consultantId: BigInt, $educationId: BigInt!) {
  deleteConsultantEducation(
    applicant: $applicant
    consultantId: $consultantId
    educationId: $educationId
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const AddConsultantNationalityMutation = gql`
    mutation addConsultantNationalityMutation($applicant: ApplicantInput, $countryId: BigInt!, $consultantId: BigInt) {
  addConsultantNationality(
    applicant: $applicant
    countryId: $countryId
    consultantId: $consultantId
  ) {
    error {
      ...ErrorFragment
    }
    nationality {
      country {
        id
        name
      }
      countryId
      id
    }
  }
}
    ${ErrorFragment}`;
export const DeleteConsultantNationalityMutation = gql`
    mutation deleteConsultantNationalityMutation($applicant: ApplicantInput, $nationalityId: BigInt!, $consultantId: BigInt) {
  deleteConsultantNationality(
    applicant: $applicant
    nationalityId: $nationalityId
    consultantId: $consultantId
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const AddConsultantDocumentMutation = gql`
    mutation addConsultantDocumentMutation($applicant: ApplicantInput, $uploadFile: UploadFileInput!) {
  addConsultantDocument(applicant: $applicant, uploadFile: $uploadFile) {
    consultantDocument {
      id
      uploadFile {
        ...UploadFileFragment
      }
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${UploadFileFragment}
${ErrorFragment}`;
export const DeleteConsultantDocumentMutation = gql`
    mutation deleteConsultantDocumentMutation($applicant: ApplicantInput, $uploadFileId: BigInt!) {
  deleteConsultantDocument(applicant: $applicant, uploadFileId: $uploadFileId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const AddConsultantSpokenLanguageMutation = gql`
    mutation addConsultantSpokenLanguageMutation($applicant: ApplicantInput, $consultantLanguage: ConsultantSpokenLanguageInput!, $consultantId: BigInt) {
  addConsultantSpokenLanguage(
    applicant: $applicant
    consultantLanguage: $consultantLanguage
    consultantId: $consultantId
  ) {
    error {
      ...ErrorFragment
    }
    spokenLanguage {
      fluency
      id
      language {
        id
        name
      }
      languageId
    }
  }
}
    ${ErrorFragment}`;
export const DeleteConsultantSpokenLanguageMutation = gql`
    mutation deleteConsultantSpokenLanguageMutation($applicant: ApplicantInput, $languageId: BigInt!, $consultantId: BigInt) {
  deleteConsultantSpokenLanguage(
    applicant: $applicant
    consultantSpokenLanguageId: $languageId
    consultantId: $consultantId
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ConsultantMissionRolesQuery = gql`
    query consultantMissionRolesQuery($openPositionsSort: OpenMissionStaffingPositionsSortInput = {updatedAt: desc}, $applicationsSort: MissionStaffingApplicationsSortInput = {createdAt: desc}, $openPositionsFilter: OpenMissionStaffingPositionsFilter, $applicationsFilter: MissionStaffingApplicationsFilter, $paginateOpen: PaginationInput, $paginateApplications: PaginationInput) {
  openMissionStaffingPositions(
    sort: $openPositionsSort
    paginate: $paginateOpen
    filter: $openPositionsFilter
  ) {
    data {
      ...ListMissionStaffingPositionFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
  consultantProfile {
    id
    missionStaffingApplications(
      sort: $applicationsSort
      paginate: $paginateApplications
      filter: $applicationsFilter
    ) {
      data {
        ...missionStaffingApplicationFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
}
    ${ListMissionStaffingPositionFragment}
${PaginationFragment}
${MissionStaffingApplicationFragment}`;
export const ConsultantOpenOpportunitiesQuery = gql`
    query consultantOpenOpportunitiesQuery($sort: OpenMissionStaffingPositionsSortInput = {updatedAt: desc}, $filter: OpenMissionStaffingPositionsFilter, $paginate: PaginationInput) {
  openMissionStaffingPositions(sort: $sort, paginate: $paginate, filter: $filter) {
    data {
      ...ListMissionStaffingPositionFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${ListMissionStaffingPositionFragment}
${PaginationFragment}`;
export const ConsultantApplicationsQuery = gql`
    query consultantApplicationsQuery($sort: MissionStaffingApplicationsSortInput = {createdAt: desc}, $filter: MissionStaffingApplicationsFilter, $paginate: PaginationInput) {
  consultantProfile {
    id
    missionStaffingApplications(sort: $sort, paginate: $paginate, filter: $filter) {
      data {
        ...missionStaffingApplicationFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
}
    ${MissionStaffingApplicationFragment}
${PaginationFragment}`;
export const ListApplicantsQuery = gql`
    query listApplicantsQuery($filter: ApplicantsFilter, $sort: ApplicantsSortInput, $page: Int, $per: Int!) {
  applicants(filter: $filter, sort: $sort, paginate: {page: $page, per: $per}) {
    data {
      id
      phone
      createdAt
      emailAddress
      firstName
      lastName
      jobRole
      phoneNumber
      state
      referrer
      gender
      referrer
      jobRole
      city
      dateOfBirth
      country {
        id
        name
      }
      scatterId
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${PaginationFragment}`;
export const ApplicantDetailQuery = gql`
    query applicantDetailQuery($filter: ApplicantsFilter) {
  applicants(filter: $filter) {
    data {
      ...ApplicantFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${ApplicantFragment}
${PaginationFragment}`;
export const MyContractsQuery = gql`
    query myContractsQuery($filter: ConsultantContractsFilter, $sort: ConsultantContractsSortInput, $paginate: PaginationInput, $scope: ConsultantContractScope) {
  consultantProfile {
    id
    consultantContracts(
      sort: $sort
      paginate: $paginate
      filter: $filter
      scope: $scope
    ) {
      data {
        ...ConsultantContractFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
}
    ${ConsultantContractFragment}
${PaginationFragment}`;
export const ConsultantContractsQuery = gql`
    query consultantContractsQuery($filter: ConsultantContractsFilter, $sort: ConsultantContractsSortInput, $paginate: PaginationInput, $scope: ConsultantContractScope) {
  consultantContracts(
    sort: $sort
    paginate: $paginate
    filter: $filter
    scope: $scope
  ) {
    data {
      ...ConsultantContractFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${ConsultantContractFragment}
${PaginationFragment}`;
export const CreateConsultantPayoneerAccountMutation = gql`
    mutation createConsultantPayoneerAccountMutation($customerId: String!, $emailAddress: String!) {
  createConsultantPayoneerAccount(
    customerId: $customerId
    emailAddress: $emailAddress
  ) {
    error {
      ...ErrorFragment
    }
    payoneerAccount {
      createdAt
      customerId
      emailAddress
      id
      state
      updatedAt
    }
  }
}
    ${ErrorFragment}`;
export const CreateConsultantBankAccountMutation = gql`
    mutation createConsultantBankAccountMutation($bankDetails: ConsultantBankAccountInput!) {
  createConsultantBankAccount(bankDetails: $bankDetails) {
    bankAccount {
      ...ConsultantBankAccountFragment
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ConsultantBankAccountFragment}
${ErrorFragment}`;
export const ArchiveConsultantPayoneerAccountMutation = gql`
    mutation archiveConsultantPayoneerAccountMutation($accountId: ID!) {
  archiveConsultantPayoneerAccount(accountId: $accountId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ArchiveConsultantBankAccountMutation = gql`
    mutation archiveConsultantBankAccountMutation($accountId: ID!) {
  archiveConsultantBankAccount(accountId: $accountId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateConsultantPayoneerAccountMutation = gql`
    mutation updateConsultantPayoneerAccountMutation($payoneerAccountId: ID!, $customerId: String!, $emailAddress: String!) {
  updateConsultantPayoneerAccount(
    payoneerAccountId: $payoneerAccountId
    customerId: $customerId
    emailAddress: $emailAddress
  ) {
    error {
      ...ErrorFragment
    }
    payoneerAccount {
      id
      customerId
      emailAddress
    }
  }
}
    ${ErrorFragment}`;
export const UpdateConsultantBankAccountMutation = gql`
    mutation updateConsultantBankAccountMutation($accountId: BigInt!, $bankDetails: ConsultantBankAccountInput!) {
  updateConsultantBankAccount(accountId: $accountId, bankDetails: $bankDetails) {
    bankAccount {
      ...ConsultantBankAccountFragment
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ConsultantBankAccountFragment}
${ErrorFragment}`;
export const DeleteConsultantContractMilestoneMutation = gql`
    mutation deleteConsultantContractMilestoneMutation($id: BigInt!) {
  deleteConsultantContractMilestone(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateConsultantContractMilestoneMutation = gql`
    mutation updateConsultantContractMilestoneMutation($consultantContractMilestoneId: BigInt!, $consultantContractMilestone: ConsultantContractMilestoneInput!) {
  updateConsultantContractMilestone(
    consultantContractMilestoneId: $consultantContractMilestoneId
    consultantContractMilestone: $consultantContractMilestone
  ) {
    consultantContract {
      id
      consultantContractMilestones {
        description
        fees
        state
      }
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const MyPeralteesQuery = gql`
    query myPeralteesQuery($filter: ConsultantPeralteeClaimsFilter) {
  consultantProfile {
    id
    peralteeClaims(filter: $filter) {
      data {
        id
        comment
        state
        description
        createdAt
        supportService {
          id
          points
          description
          name
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
}
    ${PaginationFragment}`;
export const RequestConsultantPeralteeClaimMutation = gql`
    mutation requestConsultantPeralteeClaimMutation($supportServiceId: BigInt!, $description: String!) {
  requestConsultantPeralteeClaim(
    supportServiceId: $supportServiceId
    description: $description
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CancelConsultantPeralteeClaimRequestMutation = gql`
    mutation cancelConsultantPeralteeClaimRequestMutation($id: BigInt!) {
  cancelConsultantPeralteeClaimRequest(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const TrainingTagsQuery = gql`
    query trainingTagsQuery {
  trainingTags {
    data {
      id
      name
    }
  }
}
    `;
export const SetConsultantTrainingTagsMutation = gql`
    mutation setConsultantTrainingTagsMutation($consultantId: BigInt!, $trainingTags: [String!]!) {
  setConsultantTrainingTags(
    consultantId: $consultantId
    trainingTags: $trainingTags
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ConsultantBillingAccountsQuery = gql`
    query consultantBillingAccountsQuery {
  consultantProfile {
    id
    consultantBankAccounts {
      ...ConsultantBankAccountFragment
    }
    consultantPayoneerAccounts {
      createdAt
      customerId
      emailAddress
      id
      state
      updatedAt
    }
  }
}
    ${ConsultantBankAccountFragment}`;
export const SetMissionEngagementLetterMutation = gql`
    mutation setMissionEngagementLetterMutation($missionId: BigInt!, $uploadFile: UploadFileInput!) {
  setMissionEngagementLetter(missionId: $missionId, uploadFile: $uploadFile) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const SetMissionSignedContractMutation = gql`
    mutation setMissionSignedContractMutation($missionId: BigInt!, $uploadFile: UploadFileInput!) {
  setMissionSignedContract(missionId: $missionId, uploadFile: $uploadFile) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CreateMissionContractPaymentMutation = gql`
    mutation createMissionContractPaymentMutation($missionLifecycleId: ID!, $description: String!, $amount: Int!, $paymentType: ContractPayment!, $dueDate: ISO8601Date!, $deliverables: [ID!], $name: String) {
  createMissionContractPayment(
    missionLifecycleId: $missionLifecycleId
    description: $description
    amount: $amount
    paymentType: $paymentType
    dueDate: $dueDate
    deliverables: $deliverables
    name: $name
  ) {
    error {
      ...ErrorFragment
    }
    missionContractPayment {
      id
    }
  }
}
    ${ErrorFragment}`;
export const UpdateMissionContractPaymentMutation = gql`
    mutation updateMissionContractPaymentMutation($missionContractPaymentId: ID!, $description: String!, $amount: Float!, $dueDate: ISO8601Date, $deliverables: [ID!], $name: String, $state: MissionContractPaymentState) {
  updateMissionContractPayment(
    missionContractPaymentId: $missionContractPaymentId
    description: $description
    amount: $amount
    dueDate: $dueDate
    deliverables: $deliverables
    name: $name
    state: $state
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CreateMissionContractDeliverableMutation = gql`
    mutation createMissionContractDeliverableMutation($contractPaymentId: BigInt!, $name: String!, $description: String!, $dueDate: ISO8601Date!) {
  createMissionContractDeliverable(
    contractPaymentId: $contractPaymentId
    name: $name
    description: $description
    dueDate: $dueDate
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const DeleteMissionContractDeliverableMutation = gql`
    mutation deleteMissionContractDeliverableMutation($id: BigInt!) {
  deleteMissionContractDeliverable(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CreateClientContractMutation = gql`
    mutation createClientContractMutation($contractTerms: MissionContractTermsInput!) {
  createClientContract(contractTerms: $contractTerms) {
    error {
      ...ErrorFragment
    }
    missionContract {
      id
    }
  }
}
    ${ErrorFragment}`;
export const UpdateClientContractMutation = gql`
    mutation updateClientContractMutation($contractTermsId: BigInt!, $contractTerms: MissionContractTermsInput!) {
  updateClientContract(
    contractTermsId: $contractTermsId
    contractTerms: $contractTerms
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const BankAccountsListQuery = gql`
    query bankAccountsListQuery($filter: BankAccountsFilter, $sort: BankAccountsSortInput, $per: Int = 10) {
  bankAccounts(filter: $filter, sort: $sort, paginate: {per: $per}) {
    data {
      accountAlias
      accountName
      accountNumber
      bankAddress
      bankName
      createdAt
      iban
      id
      sortCode
      swiftCode
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${PaginationFragment}`;
export const DeleteMissionContractPaymentMutation = gql`
    mutation deleteMissionContractPaymentMutation($id: BigInt!) {
  deleteMissionContractPayment(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CreateConsultantContractMutation = gql`
    mutation createConsultantContractMutation($consultantContract: ConsultantContractInput!) {
  createConsultantContract(consultantContract: $consultantContract) {
    consultantContract {
      ...ConsultantContractFragment
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ConsultantContractFragment}
${ErrorFragment}`;
export const RequestConsultantContractApprovalMutation = gql`
    mutation requestConsultantContractApprovalMutation($id: BigInt!) {
  requestConsultantContractApproval(id: $id) {
    error {
      ...ErrorFragment
    }
    consultantContract {
      id
      state
    }
  }
}
    ${ErrorFragment}`;
export const UpdateConsultantContractMutation = gql`
    mutation updateConsultantContractMutation($id: BigInt!, $consultantContract: ConsultantContractInput!) {
  updateConsultantContract(id: $id, consultantContract: $consultantContract) {
    consultantContract {
      id
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const AcceptConsultantContractMutation = gql`
    mutation acceptConsultantContractMutation($id: ID!) {
  acceptConsultantContract(consultantContractId: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const RequestTerminationConsultantContractMutation = gql`
    mutation requestTerminationConsultantContractMutation($id: BigInt!, $terminationReason: String!) {
  requestTerminationConsultantContract(
    id: $id
    terminationReason: $terminationReason
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const DeclineConsultantContractMutation = gql`
    mutation declineConsultantContractMutation($id: BigInt!, $declineReason: String!) {
  declineConsultantContract(id: $id, declineReason: $declineReason) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const DeleteConsultantContractPaymentMutation = gql`
    mutation deleteConsultantContractPaymentMutation($id: BigInt!) {
  deleteConsultantContractPayment(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const DeleteStaffingMilestoneMutation = gql`
    mutation deleteStaffingMilestoneMutation($id: BigInt!, $forceDelete: Boolean!) {
  deleteStaffingMilestone(id: $id, forceDelete: $forceDelete) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const RequestEsignatureMutation = gql`
    mutation requestEsignatureMutation($signableId: BigInt!, $signableType: String!, $signers: [EsignatureSignerInput!]!) {
  requestEsignature(
    signableId: $signableId
    signableType: $signableType
    signers: $signers
  ) {
    error {
      ...ErrorFragment
    }
    esignatureRequest {
      createdAt
      email
      id
      requester {
        id
        name
      }
      signable {
        id
      }
      signer {
        id
        name
      }
      state
      updatedAt
    }
  }
}
    ${ErrorFragment}`;
export const CommentsQuery = gql`
    query commentsQuery($filter: ClientMissionsFilter, $sort: ClientMissionsSortInput, $page: Int, $per: Int!) {
  clientMissions(filter: $filter, sort: $sort, paginate: {page: $page, per: $per}) {
    data {
      defaultConversation {
        prn
        comments {
          id
          prn
          text
          createdAt
          edited
          updatedAt
          file {
            ...UploadFileFragment
          }
          conversation {
            id
            prn
          }
          user {
            ...UserFragment
          }
        }
      }
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${UploadFileFragment}
${UserFragment}
${PaginationFragment}`;
export const FindConversationQuery = gql`
    query findConversationQuery($id: ID!) {
  findConversation(id: $id) {
    comments {
      id
      prn
      text
      createdAt
      edited
      updatedAt
      file {
        ...UploadFileFragment
      }
      conversation {
        id
        prn
      }
      user {
        ...UserFragment
      }
    }
    conversableId
    conversableType
    createdAt
    id
    prn
    updatedAt
  }
}
    ${UploadFileFragment}
${UserFragment}`;
export const AddCommentMutation = gql`
    mutation addCommentMutation($conversationId: ID!, $fileInput: UploadFileInput, $text: String!) {
  addComment(conversationId: $conversationId, fileInput: $fileInput, text: $text) {
    comment {
      id
      prn
      text
      createdAt
      edited
      updatedAt
      file {
        ...UploadFileFragment
      }
      conversation {
        id
        prn
      }
      user {
        ...UserFragment
      }
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${UploadFileFragment}
${UserFragment}
${ErrorFragment}`;
export const InvoiceDetailQuery = gql`
    query invoiceDetailQuery($filter: ConsultantInvoicesFilter) {
  consultantInvoices(filter: $filter) {
    data {
      id
      createdAt
      dueAt
      paidAt
      paymentDescription
      payment {
        id
        missionStaffingPosition {
          id
          name
        }
        amount {
          ...MoneyFragment
        }
        description
      }
      state
      updatedAt
      missionLifecycle {
        id
        name
      }
      total
      activityLogs {
        ...ActivityLogFragment
      }
      invoiceItems {
        id
        description
        quantity
        unitCost
      }
      consultant {
        id
        name
        postalAddress
        email
        phone
        user {
          id
        }
      }
      billingAccount {
        ... on ConsultantPayoneerAccount {
          emailAddress
          customerId
          state
        }
        ... on ConsultantBankAccount {
          ...ConsultantBankAccountFragment
        }
      }
      missionStaffingPlacement {
        id
        missionStaffingPosition {
          id
          name
        }
      }
      approvals {
        ...ApprovalFragment
      }
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${MoneyFragment}
${ActivityLogFragment}
${ConsultantBankAccountFragment}
${ApprovalFragment}
${PaginationFragment}`;
export const MyInvoiceQuery = gql`
    query myInvoiceQuery($filter: ConsultantInvoicesFilter) {
  consultantProfile {
    id
    consultantInvoices(filter: $filter) {
      data {
        id
        createdAt
        dueAt
        invoiceType
        paidAt
        paymentDescription
        payment {
          id
          description
          missionStaffingPosition {
            id
            name
          }
        }
        state
        updatedAt
        missionLifecycle {
          id
          name
        }
        consultant {
          id
          name
          postalAddress
          email
          phone
          user {
            id
          }
        }
        total
        invoiceItems {
          id
          description
          quantity
          unitCost
          type
          uploadFile {
            ...UploadFileFragment
          }
        }
        billingAccount {
          ... on ConsultantPayoneerAccount {
            id
            prn
            emailAddress
            customerId
            state
          }
          ... on ConsultantBankAccount {
            ...ConsultantBankAccountFragment
          }
        }
        missionStaffingPlacement {
          id
          missionStaffingPosition {
            id
            name
          }
        }
      }
    }
  }
}
    ${UploadFileFragment}
${ConsultantBankAccountFragment}`;
export const SubmitConsultantInvoiceMutation = gql`
    mutation submitConsultantInvoiceMutation($invoiceId: BigInt!, $billingAccountType: BillingAccountEnum!, $billingAccountId: BigInt!, $expenses: [ConsultantMissionExpenseInput!]) {
  submitConsultantInvoice(
    invoiceId: $invoiceId
    billingAccountType: $billingAccountType
    billingAccountId: $billingAccountId
    expenses: $expenses
  ) {
    consultantInvoice {
      id
      state
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CreateConsultantInvoiceMutation = gql`
    mutation createConsultantInvoiceMutation($consultantId: BigInt!, $missionLifecycleId: BigInt!, $missionStaffingPlacementId: BigInt!, $billingAccountType: BillingAccountEnum!, $billingAccountId: BigInt!, $expenses: [ConsultantMissionExpenseInput!]!, $paymentDescription: String) {
  createConsultantInvoice(
    consultantId: $consultantId
    missionLifecycleId: $missionLifecycleId
    missionStaffingPlacementId: $missionStaffingPlacementId
    billingAccountType: $billingAccountType
    billingAccountId: $billingAccountId
    expenses: $expenses
    paymentDescription: $paymentDescription
  ) {
    consultantInvoice {
      id
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const QueueInvoiceForPaymentMutation = gql`
    mutation queueInvoiceForPaymentMutation($invoiceId: BigInt!) {
  queueInvoiceForPayment(invoiceId: $invoiceId) {
    consultantInvoice {
      id
      state
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const MarkInvoiceAsPaidMutation = gql`
    mutation markInvoiceAsPaidMutation($invoiceId: BigInt!) {
  markInvoiceAsPaid(invoiceId: $invoiceId) {
    consultantInvoice {
      id
      state
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const InvoicesListQuery = gql`
    query invoicesListQuery($filter: ConsultantInvoicesFilter, $sort: ConsultantInvoicesSortInput, $page: Int, $per: Int!) {
  consultantInvoices(
    filter: $filter
    sort: $sort
    paginate: {per: $per, page: $page}
  ) {
    data {
      id
      consultant {
        id
        name
      }
      missionLifecycle {
        id
        name
      }
      createdAt
      total
      state
      dueAt
      missionStaffingPlacement {
        id
        missionStaffingPosition {
          id
          name
        }
      }
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${PaginationFragment}`;
export const ClientInvoicesListQuery = gql`
    query clientInvoicesListQuery($filter: ClientInvoicesFilter, $sort: ClientInvoicesSortInput, $page: Int, $per: Int!) {
  clientInvoices(filter: $filter, sort: $sort, paginate: {per: $per, page: $page}) {
    data {
      amount {
        ...MoneyFragment
      }
      amountCents
      createdAt
      dueDate
      id
      invoiceUrl
      checkoutUrl
      missionLifecycle {
        id
        name
      }
      state
      updatedAt
    }
    pagination {
      currentPage
      firstPage
      lastPage
      nextPage
      pageCount
      prevPage
      totalSize
    }
  }
}
    ${MoneyFragment}`;
export const FinanceStatisticsQuery = gql`
    query financeStatisticsQuery {
  financeStatistics {
    key
    label
    value
    money
  }
}
    `;
export const RequestMissionPartnerMutation = gql`
    mutation requestMissionPartnerMutation($missionLifecycleId: BigInt!, $consultantId: BigInt!) {
  requestMissionPartner(
    missionId: $missionLifecycleId
    consultantId: $consultantId
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CreateMissionLeadMutation = gql`
    mutation createMissionLeadMutation($missionLead: MissionLeadInput!) {
  createMissionLead(missionLead: $missionLead) {
    error {
      ...ErrorFragment
    }
    missionLifecycle {
      ...missionLifecycleFragment
    }
  }
}
    ${ErrorFragment}
${MissionLifecycleFragment}`;
export const UpdateMissionLeadMutation = gql`
    mutation updateMissionLeadMutation($missionLifecycleId: BigInt!, $missionLead: MissionLeadInput!) {
  updateMissionLead(
    missionLifecycleId: $missionLifecycleId
    missionLead: $missionLead
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const SetMissionNameMutation = gql`
    mutation setMissionNameMutation($missionLifecycleId: ID!, $name: String!) {
  setMissionName(missionLifecycleId: $missionLifecycleId, name: $name) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const SetMissionClientMutation = gql`
    mutation setMissionClientMutation($missionLifecycleId: BigInt!, $clientId: BigInt!) {
  setMissionClient(missionId: $missionLifecycleId, clientId: $clientId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ApproveMissionLeadMutation = gql`
    mutation approveMissionLeadMutation($missionLifecycleId: BigInt!) {
  approveMissionLead(missionId: $missionLifecycleId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const RejectMissionLeadMutation = gql`
    mutation rejectMissionLeadMutation($missionLifecycleId: BigInt!) {
  rejectMissionLead(missionId: $missionLifecycleId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const SetTermsOfReferenceMutation = gql`
    mutation setTermsOfReferenceMutation($missionId: ID!, $uploadFiles: [UploadFileInput!]!) {
  setTermsOfReference(missionLifecycleId: $missionId, uploadFiles: $uploadFiles) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const StartRfpDevelopmentMutation = gql`
    mutation startRfpDevelopmentMutation($rfpDeadline: ISO8601Date!, $missionId: BigInt!) {
  startRfpDevelopment(rfpDeadline: $rfpDeadline, missionId: $missionId) {
    error {
      ...ErrorFragment
    }
    mission {
      id
      state
    }
  }
}
    ${ErrorFragment}`;
export const CancelMissionStaffingRequestMutation = gql`
    mutation cancelMissionStaffingRequestMutation($id: BigInt!) {
  cancelMissionStaffingRequest(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateMissionScopeActivityMutation = gql`
    mutation updateMissionScopeActivityMutation($missionScopeActivityId: ID!, $name: String, $position: Int, $capacities: JSON, $deliverable: Boolean, $capacityDistribution: String, $completed: Boolean) {
  updateMissionScopeActivity(
    missionScopeActivityId: $missionScopeActivityId
    name: $name
    positionPosition: $position
    capacities: $capacities
    deliverable: $deliverable
    capacityDistribution: $capacityDistribution
    completed: $completed
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const AddMissionScopeStaffMutation = gql`
    mutation addMissionScopeStaffMutation($missionScopeId: BigInt!, $jobTitleId: BigInt!) {
  addMissionScopeStaff(missionScopeId: $missionScopeId, jobTitleId: $jobTitleId) {
    error {
      ...ErrorFragment
    }
    staff {
      id
    }
  }
}
    ${ErrorFragment}`;
export const DeleteMissionScopeStaffMutation = gql`
    mutation deleteMissionScopeStaffMutation($missionScopeStaffId: BigInt!) {
  deleteMissionScopeStaff(missionScopeStaffId: $missionScopeStaffId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const DeleteMissionScopeActivityMutation = gql`
    mutation deleteMissionScopeActivityMutation($activityId: BigInt!) {
  deleteMissionScopeActivity(activityId: $activityId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const AddMissionScopePhaseMutation = gql`
    mutation addMissionScopePhaseMutation($missionScopeId: BigInt!, $name: String!) {
  addMissionScopePhase(missionScopeId: $missionScopeId, name: $name) {
    error {
      ...ErrorFragment
    }
    missionScopePhase {
      id
      name
    }
  }
}
    ${ErrorFragment}`;
export const UpdateMissionScopePhaseMutation = gql`
    mutation updateMissionScopePhaseMutation($phaseId: BigInt!, $name: String, $position: Int) {
  updateMissionScopePhase(id: $phaseId, name: $name, positionPosition: $position) {
    error {
      ...ErrorFragment
    }
    missionScopePhase {
      id
    }
  }
}
    ${ErrorFragment}`;
export const DeleteMissionScopePhaseMutation = gql`
    mutation deleteMissionScopePhaseMutation($id: BigInt!, $forceDelete: Boolean!) {
  deleteMissionScopePhase(phaseId: $id, forceDelete: $forceDelete) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateMissionScopeMutation = gql`
    mutation updateMissionScopeMutation($id: BigInt!, $targetMissionDuration: Float, $capacityAllocation: Int) {
  updateMissionScope(
    id: $id
    targetMissionDuration: $targetMissionDuration
    capacityAllocation: $capacityAllocation
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const DeleteMissionMutation = gql`
    mutation deleteMissionMutation($id: BigInt!) {
  deleteMission(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const GenerateMissionStaffingPositionsMutation = gql`
    mutation generateMissionStaffingPositionsMutation($id: ID!) {
  generateMissionStaffingPositions(missionLifecycleId: $id) {
    error {
      ...ErrorFragment
    }
    staffingPositions {
      createdAt
      description
      id
    }
  }
}
    ${ErrorFragment}`;
export const GenerateConsultantPaymentStructureMutation = gql`
    mutation generateConsultantPaymentStructureMutation($missionStaffingPositionId: ID!, $paymentsInputs: [ConsultantPaymentStructureInput!]!) {
  generateConsultantPaymentStructure(
    missionStaffingPositionId: $missionStaffingPositionId
    paymentsInputs: $paymentsInputs
  ) {
    error {
      ...ErrorFragment
    }
    payments {
      amount {
        ...MoneyFragment
      }
      amountCents
      createdAt
      description
      dueDate
      id
      missionStaffingPosition {
        ...MissionStaffingPositionDetailFragment
      }
      paymentType
      percentage
      state
      updatedAt
    }
  }
}
    ${ErrorFragment}
${MoneyFragment}
${MissionStaffingPositionDetailFragment}`;
export const CreateConsultantMissionExpenseMutation = gql`
    mutation createConsultantMissionExpenseMutation($invoiceId: BigInt!, $expense: ConsultantMissionExpenseInput!) {
  addConsultantMissionExpense(invoiceId: $invoiceId, expense: $expense) {
    missionExpense {
      id
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateConsultantMissionExpenseMutation = gql`
    mutation updateConsultantMissionExpenseMutation($expenseId: BigInt!, $expense: ConsultantMissionExpenseInput!) {
  updateConsultantMissionExpense(expenseId: $expenseId, expensePayload: $expense) {
    missionExpense {
      id
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const MissionLifecyclesListQuery = gql`
    query missionLifecyclesListQuery($filter: MissionLifecyclesFilter, $sort: MissionLifecyclesSortInput, $page: Int, $per: Int!) {
  missionLifecycles(
    filter: $filter
    sort: $sort
    paginate: {page: $page, per: $per}
  ) {
    data {
      ... on MissionLifecycle {
        id
        name
        client {
          id
          name
        }
        primaryManager {
          ...UserFragment
        }
        missionLead {
          id
          summary
          clientName
          practiceGroup {
            id
            name
            subGroups {
              id
              name
            }
          }
        }
        projectLocationCountryCode
        projectLocationType
        summary
        client {
          id
          name
        }
        collaborators {
          ...UserAdvancedFragment
        }
        scopeService {
          id
          name
          serviceGroup {
            id
            name
          }
        }
        executive {
          id
          firstName
          lastName
          name
          jobTitle {
            id
            name
          }
          user {
            ...UserFragment
            ...UserFragment
          }
        }
        executive {
          id
          firstName
          lastName
          name
          jobTitle {
            id
            name
          }
          user {
            ...UserFragment
          }
        }
        state
        createdAt
      }
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${UserFragment}
${UserAdvancedFragment}
${PaginationFragment}`;
export const MissionLifecyclesQuery = gql`
    query missionLifecyclesQuery($filter: MissionLifecyclesFilter, $sort: MissionLifecyclesSortInput, $page: Int, $per: Int!) {
  missionLifecycles(
    filter: $filter
    sort: $sort
    paginate: {page: $page, per: $per}
  ) {
    data {
      ... on MissionLifecycle {
        ...missionLifecycleFragment
      }
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${MissionLifecycleFragment}
${PaginationFragment}`;
export const MissionLifecycleSelectQuery = gql`
    query missionLifecycleSelectQuery($filter: MissionLifecyclesFilter, $sort: MissionLifecyclesSortInput, $page: Int, $per: Int!) {
  missionLifecycles(
    filter: $filter
    sort: $sort
    paginate: {page: $page, per: $per}
  ) {
    data {
      ... on MissionLifecycle {
        id
        name
      }
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${PaginationFragment}`;
export const MissionDashboardStatisticsQuery = gql`
    query missionDashboardStatisticsQuery {
  missionDashboardStatistics {
    name
    value
    slug
  }
}
    `;
export const TerminateMissionStaffingPlacementMutation = gql`
    mutation terminateMissionStaffingPlacementMutation($id: BigInt!, $terminationReason: String!) {
  terminateMissionStaffingPlacement(
    id: $id
    terminationReason: $terminationReason
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const RestoreMissionStaffingPlacementMutation = gql`
    mutation restoreMissionStaffingPlacementMutation($id: BigInt!) {
  restoreMissionStaffingPlacement(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CommenceStaffingMutation = gql`
    mutation commenceStaffingMutation($missionId: BigInt!) {
  commenceStaffing(missionId: $missionId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CommenceContractingMutation = gql`
    mutation commenceContractingMutation($missionId: BigInt!) {
  commenceContracting(missionId: $missionId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CommenceExecutionMutation = gql`
    mutation commenceExecutionMutation($missionId: BigInt!, $commencementDate: ISO8601Date!) {
  commenceExecution(missionId: $missionId, commencementDate: $commencementDate) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CreateMissionExecutionStaffingPositionMutation = gql`
    mutation createMissionExecutionStaffingPositionMutation($missionId: BigInt!, $jobTitleId: BigInt!, $name: String!, $description: String!, $milestones: [BigInt!]!, $payments: [ConsultantContractPaymentInput!], $requiredExpertiseTags: [String!]) {
  createMissionExecutionStaffingPosition(
    missionId: $missionId
    jobTitleId: $jobTitleId
    name: $name
    description: $description
    milestones: $milestones
    contractPayments: $payments
    requiredExpertiseTags: $requiredExpertiseTags
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateMissionStaffingPositionMutation = gql`
    mutation updateMissionStaffingPositionMutation($staffingPositionId: ID!, $name: String!, $description: String!) {
  updateMissionStaffingPosition(
    staffingPositionId: $staffingPositionId
    name: $name
    description: $description
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const SetMissionTagsMutation = gql`
    mutation setMissionTagsMutation($missionId: BigInt!, $tags: [String!]!) {
  setMissionTags(missionId: $missionId, tags: $tags) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CompleteExecutionMutation = gql`
    mutation completeExecutionMutation($missionId: BigInt!, $completionDate: ISO8601Date!) {
  completeExecution(missionId: $missionId, completionDate: $completionDate) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const RequestMissionExecutiveMutation = gql`
    mutation requestMissionExecutiveMutation($missionLifecycleId: BigInt!, $consultantId: BigInt!) {
  requestMissionExecutive(
    missionId: $missionLifecycleId
    consultantId: $consultantId
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const AssignMissionTeamLeadMutation = gql`
    mutation assignMissionTeamLeadMutation($missionLifecycleId: BigInt!, $consultantId: BigInt!) {
  assignMissionTeamLead(
    missionId: $missionLifecycleId
    consultantId: $consultantId
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const AssignMissionPrincipalMutation = gql`
    mutation assignMissionPrincipalMutation($missionLifecycleId: BigInt!, $consultantId: BigInt!) {
  assignMissionPrincipal(
    missionId: $missionLifecycleId
    consultantId: $consultantId
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const RemoveMissionPartnerMutation = gql`
    mutation removeMissionPartnerMutation($missionLifecycleId: BigInt!, $consultantId: BigInt!) {
  removeMissionPartner(
    missionId: $missionLifecycleId
    consultantId: $consultantId
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const RemoveMissionPrincipalMutation = gql`
    mutation removeMissionPrincipalMutation($missionLifecycleId: BigInt!, $consultantId: BigInt!) {
  removeMissionPrincipal(
    missionId: $missionLifecycleId
    consultantId: $consultantId
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const RemoveMissionTeamLeadMutation = gql`
    mutation removeMissionTeamLeadMutation($missionLifecycleId: BigInt!, $consultantId: BigInt!) {
  removeMissionTeamLead(
    missionId: $missionLifecycleId
    consultantId: $consultantId
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ResumeMissionMutation = gql`
    mutation resumeMissionMutation($id: BigInt!) {
  resumeMission(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const PauseMissionMutation = gql`
    mutation pauseMissionMutation($id: BigInt!) {
  pauseMission(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const AddMissionScopeActivityMutation = gql`
    mutation addMissionScopeActivityMutation($missionScopeId: BigInt!, $name: String!, $capacities: JSON, $missionScopePhaseId: BigInt!) {
  addMissionScopeActivity(
    missionScopeId: $missionScopeId
    name: $name
    capacities: $capacities
    missionScopePhaseId: $missionScopePhaseId
  ) {
    error {
      ...ErrorFragment
    }
    activity {
      id
      prn
      name
    }
  }
}
    ${ErrorFragment}`;
export const CreateStaffingMilestoneMutation = gql`
    mutation createStaffingMilestoneMutation($missionContractDeliverableId: BigInt, $name: String!, $description: String!, $feesCents: Int) {
  createStaffingMilestone(
    missionContractDeliverableId: $missionContractDeliverableId
    name: $name
    description: $description
    feesCents: $feesCents
  ) {
    error {
      ...ErrorFragment
    }
    milestone {
      id
      fees
    }
  }
}
    ${ErrorFragment}`;
export const StartMissionScopeDevelopmentMutation = gql`
    mutation startMissionScopeDevelopmentMutation($missionId: BigInt!) {
  startMissionScopeDevelopment(missionId: $missionId) {
    error {
      ...ErrorFragment
    }
    missionScope {
      id
    }
  }
}
    ${ErrorFragment}`;
export const StartPricingProposalDevelopmentMutation = gql`
    mutation startPricingProposalDevelopmentMutation($id: ID!) {
  startPricingProposalDevelopment(missionLifecycleId: $id) {
    error {
      ...ErrorFragment
    }
    missionPricingProposal {
      id
    }
  }
}
    ${ErrorFragment}`;
export const GenerateClientPaymentStructureMutation = gql`
    mutation generateClientPaymentStructureMutation($missionLifecycleId: ID!, $paymentsInput: [ClientPaymentStructureInput!]!) {
  generateClientPaymentStructure(
    missionLifecycleId: $missionLifecycleId
    paymentsInput: $paymentsInput
  ) {
    error {
      ...ErrorFragment
    }
    payments {
      amount {
        ...MoneyFragment
      }
      id
      invoiceUrl
      name
      paymentType
      state
    }
  }
}
    ${ErrorFragment}
${MoneyFragment}`;
export const UpdateMissionContractDeliverableMutation = gql`
    mutation updateMissionContractDeliverableMutation($progress: Int!, $deliverableId: BigInt!) {
  updateMissionContractDeliverable(
    progress: $progress
    deliverableId: $deliverableId
  ) {
    deliverable {
      id
      state
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ClientMissionLifecyclesListQuery = gql`
    query clientMissionLifecyclesListQuery($filter: ClientMissionsFilter, $sort: ClientMissionsSortInput, $page: Int, $per: Int!) {
  clientMissions(filter: $filter, sort: $sort, paginate: {page: $page, per: $per}) {
    data {
      ... on MissionLifecycle {
        id
        name
        missionLead {
          id
          summary
          practiceGroup {
            id
            name
          }
        }
        completionProgress
        projectLocationCountryCode
        projectLocationType
        summary
        client {
          id
          name
        }
        missionScope {
          id
          activities {
            id
            completed
          }
        }
        collaborators {
          ...UserAdvancedFragment
        }
        scopeService {
          id
          name
          serviceGroup {
            id
            name
          }
        }
        missionTeamMemberships {
          consultant {
            ...ConsultantFragment
          }
          user {
            ...UserAdvancedFragment
          }
        }
        state
        createdAt
      }
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${UserAdvancedFragment}
${ConsultantFragment}
${PaginationFragment}`;
export const ClientMissionLifecyclesDetailsQuery = gql`
    query clientMissionLifecyclesDetailsQuery($filter: ClientMissionsFilter, $sort: ClientMissionsSortInput, $page: Int, $per: Int!) {
  clientMissions(filter: $filter, sort: $sort, paginate: {page: $page, per: $per}) {
    data {
      ...missionLifecycleFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${MissionLifecycleFragment}
${PaginationFragment}`;
export const RequestMissionProposalApproval = gql`
    mutation requestMissionProposalApproval($missionLifecycleId: ID!) {
  requestMissionProposalApproval(missionLifecycleId: $missionLifecycleId) {
    error {
      ...ErrorFragment
    }
    missionLifecycle {
      ...missionLifecycleFragment
    }
  }
}
    ${ErrorFragment}
${MissionLifecycleFragment}`;
export const StaffMissionUpdatesQuery = gql`
    query staffMissionUpdatesQuery($filter: MissionLifecyclesFilter, $sort: MissionLifecyclesSortInput, $page: Int, $per: Int!) {
  missionLifecycles(
    filter: $filter
    sort: $sort
    paginate: {page: $page, per: $per}
  ) {
    data {
      ... on MissionLifecycle {
        id
        name
        client {
          id
          name
        }
        summary
        projectLocationCountryCode
        projectLocationType
        scopeService {
          id
          name
          serviceGroup {
            id
            name
          }
        }
        state
        createdAt
        activityLogs {
          ...ActivityLogFragment
        }
        completionProgress
        completionProgressActivityLog {
          id
          updatedAt
          updatedBy {
            firstName
          }
        }
      }
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${ActivityLogFragment}
${PaginationFragment}`;
export const UpdateMissionLifecycleMutation = gql`
    mutation updateMissionLifecycleMutation($missionLifecycleId: ID!, $projectTitle: String, $summary: String, $tentativeEndDate: ISO8601Date, $tentativeStartDate: ISO8601Date, $projectLocationCountryCode: String, $name: String) {
  updateMissionLifecycle(
    missionLifecycleId: $missionLifecycleId
    projectTitle: $projectTitle
    summary: $summary
    tentativeEndDate: $tentativeEndDate
    tentativeStartDate: $tentativeStartDate
    projectLocationCountryCode: $projectLocationCountryCode
    name: $name
  ) {
    error {
      ...ErrorFragment
    }
    missionLifecycle {
      id
      endDate
      startDate
      state
      updatedAt
      projectLocationCountryCode
      name
      summary
    }
  }
}
    ${ErrorFragment}`;
export const MyMissionsQuery = gql`
    query myMissionsQuery($filter: MyMissionsFilter, $sort: MyMissionsSortInput = {createdAt: desc}, $page: Int, $per: Int) {
  myMissions(filter: $filter, sort: $sort, paginate: {page: $page, per: $per}) {
    data {
      ...ConsultantMissionLifecycleFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${ConsultantMissionLifecycleFragment}
${PaginationFragment}`;
export const MyMissionsListsQuery = gql`
    query myMissionsListsQuery($filter: MyMissionsFilter, $sort: MyMissionsSortInput = {createdAt: desc}, $page: Int, $per: Int) {
  myMissions(filter: $filter, sort: $sort, paginate: {page: $page, per: $per}) {
    data {
      ...ConsultantMissionLifecycleListFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${ConsultantMissionLifecycleListFragment}
${PaginationFragment}`;
export const UserProfileQuery = gql`
    query userProfileQuery {
  profile {
    ...UserProfileFragment
  }
}
    ${UserProfileFragment}`;
export const UpdateAvatarMutation = gql`
    mutation updateAvatarMutation($avatar: UploadFileInput!) {
  updateProfile(avatar: $avatar) {
    profile {
      avatar {
        ...UploadFileFragment
      }
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${UploadFileFragment}
${ErrorFragment}`;
export const ConsultantProfileQuery = gql`
    query consultantProfileQuery($missionsLimit: Int) {
  consultantProfile {
    ...ConsultantProfileFragment
    nextPayment {
      id
      dueAt
      total
      pendingMilestones {
        id
      }
    }
    missionDeliverables(completed: false) {
      completed
      name
      missionStaffingPosition {
        id
        missionLifecycle {
          id
          name
          scopeService {
            id
            name
            serviceGroup {
              id
              name
            }
          }
        }
      }
    }
    opportunities {
      data {
        ...ListMissionStaffingPositionFragment
      }
    }
  }
}
    ${ConsultantProfileFragment}
${ListMissionStaffingPositionFragment}`;
export const CreateMissionProposalStaffingPositionMutation = gql`
    mutation createMissionProposalStaffingPositionMutation($missionId: BigInt!, $jobTitleId: BigInt!, $description: String!, $milestones: [MissionProposalStaffingMilestoneInput!]!) {
  createMissionProposalStaffingPosition(
    missionId: $missionId
    jobTitleId: $jobTitleId
    description: $description
    milestones: $milestones
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const PublishMissionStaffingPositionMutation = gql`
    mutation publishMissionStaffingPositionMutation($id: BigInt!) {
  publishMissionStaffingPosition(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UnpublishMissionStaffingPositionMutation = gql`
    mutation unpublishMissionStaffingPositionMutation($id: BigInt!) {
  unpublishMissionStaffingPosition(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const FillMissionStaffingPositionMutation = gql`
    mutation fillMissionStaffingPositionMutation($id: BigInt!, $force: Boolean) {
  fillMissionStaffingPosition(id: $id, force: $force) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const SetMissionTechnicalProposalMutation = gql`
    mutation setMissionTechnicalProposalMutation($missionId: BigInt!, $uploadFile: UploadFileInput!) {
  setMissionTechnicalProposal(missionId: $missionId, uploadFile: $uploadFile) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateMissionPricingProposalMutation = gql`
    mutation updateMissionPricingProposalMutation($id: ID!, $markup: Float, $currencyId: BigInt, $totalPrice: BigInt, $contractingEntity: ContractingEntity) {
  updateMissionPricingProposal(
    missionPricingProposalId: $id
    markup: $markup
    currencyId: $currencyId
    totalPrice: $totalPrice
    contractingEntity: $contractingEntity
  ) {
    error {
      ...ErrorFragment
    }
    missionPricingProposal {
      id
      totalPrice {
        ...MoneyFragment
      }
    }
  }
}
    ${ErrorFragment}
${MoneyFragment}`;
export const AddPricingProposalActivityMutation = gql`
    mutation addPricingProposalActivityMutation($missionPricingProposalId: ID!, $name: String!, $quantity: Int!, $unitCost: Float) {
  addMissionPricingProposalActivity(
    missionPricingProposalId: $missionPricingProposalId
    name: $name
    quantity: $quantity
    unitCost: $unitCost
  ) {
    activity {
      id
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdatePricingProposalActivityMutation = gql`
    mutation updatePricingProposalActivityMutation($id: BigInt!, $name: String, $unitCost: Int, $quantity: Int, $distribution: JSON) {
  updateMissionPricingProposalActivity(
    id: $id
    name: $name
    unitCost: $unitCost
    quantity: $quantity
    distribution: $distribution
  ) {
    activity {
      id
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const DeletePricingProposalActivityMutation = gql`
    mutation deletePricingProposalActivityMutation($id: BigInt!) {
  deleteMissionPricingProposalActivity(activityId: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const RequestApprovalOnPricingProposalMutation = gql`
    mutation requestApprovalOnPricingProposalMutation($id: BigInt!) {
  requestApprovalMissionPricingProposal(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const BeginMissionProposalMutation = gql`
    mutation beginMissionProposalMutation($id: BigInt!) {
  beginMissionProposal(missionId: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UpdateMissionProposalStaffingPositionMutation = gql`
    mutation updateMissionProposalStaffingPositionMutation($description: String!, $positionId: BigInt!, $milestones: [MissionProposalStaffingMilestoneInput!]!) {
  updateMissionProposalStaffingPosition(
    description: $description
    positionId: $positionId
    milestones: $milestones
  ) {
    error {
      ...ErrorFragment
    }
    missionStaffingPosition {
      id
    }
  }
}
    ${ErrorFragment}`;
export const ReworkPricingProposalMutation = gql`
    mutation reworkPricingProposalMutation($id: BigInt!) {
  reworkMissionPricingProposal(pricingProposalId: $id) {
    missionPricingProposal {
      id
      state
    }
  }
}
    `;
export const CountriesQuery = gql`
    query countriesQuery($name: String, $per: Int = 25) {
  countries(filter: {name: $name}, paginate: {per: $per}, sort: {name: asc}) {
    data {
      name
      id
    }
  }
}
    `;
export const LanguagesSearchQuery = gql`
    query languagesSearchQuery($name: String, $per: Int = 25) {
  languages(filter: {name: $name}, paginate: {per: $per}, sort: {name: asc}) {
    data {
      id
      name
    }
  }
}
    `;
export const InstitutionsSearchQuery = gql`
    query institutionsSearchQuery($name: String, $per: Int = 25) {
  institutions(filter: {name: $name}, paginate: {per: $per}, sort: {name: asc}) {
    data {
      id
      name
    }
  }
}
    `;
export const PeralteeSupportServicesSearchQuery = gql`
    query peralteeSupportServicesSearchQuery($name: String, $per: Int = 25) {
  peralteeSupportServices(
    filter: {search: $name}
    paginate: {per: $per}
    sort: {createdAt: asc}
  ) {
    data {
      id
      name
      description
    }
  }
}
    `;
export const CompaniesSearchQuery = gql`
    query companiesSearchQuery($name: String, $per: Int = 25) {
  companies(filter: {name: $name}, paginate: {per: $per}, sort: {name: asc}) {
    data {
      id
      name
    }
  }
}
    `;
export const ClientsSearchQuery = gql`
    query clientsSearchQuery($per: Int = 25, $filter: ClientsFilter) {
  clients(filter: $filter, paginate: {per: $per}, sort: {name: asc}) {
    data {
      id
      name
      primaryClientContact {
        email
        name
        phone
        role
      }
    }
  }
}
    `;
export const LeadSourcesQuery = gql`
    query leadSourcesQuery {
  leadSources(sort: {name: asc}) {
    id
    name
  }
}
    `;
export const BrandResourceLabelsQuery = gql`
    query brandResourceLabelsQuery {
  brandResourceLabels(sort: {name: desc}) {
    data {
      id
      name
    }
  }
}
    `;
export const ResourceCollectionsSearchQuery = gql`
    query resourceCollectionsSearchQuery {
  brandResourceCollections(sort: {name: desc}) {
    data {
      id
      name
    }
  }
}
    `;
export const LeadGradesQuery = gql`
    query leadGradesQuery {
  leadGrades(sort: {name: asc}) {
    id
    name
  }
}
    `;
export const PracticeGroupsQuery = gql`
    query practiceGroupsQuery {
  practiceGroups(sort: {name: asc}) {
    id
    name
    subGroups {
      id
      name
    }
  }
}
    `;
export const ConsultantsSearchQuery = gql`
    query consultantsSearchQuery($jobTitle: String = null, $per: Int = 25, $name: String, $search: String) {
  consultants(
    filter: {jobTitle: $jobTitle, status: APPROVED, nameContains: $name, search: $search}
    paginate: {per: $per}
    sort: {firstName: asc}
  ) {
    data {
      id
      name
      jobTitle {
        id
        name
      }
      user {
        id
        avatar {
          ...UploadFileFragment
        }
      }
    }
  }
}
    ${UploadFileFragment}`;
export const SurveyAssignmentQuery = gql`
    query surveyAssignmentQuery($id: BigInt!) {
  surveyAssignment(id: $id) {
    ...SurveyAssignmentFragment
  }
}
    ${SurveyAssignmentFragment}`;
export const SubmitSurveyResponseMutation = gql`
    mutation submitSurveyResponseMutation($id: BigInt!, $answer: JSON!) {
  submitSurveyResponse(id: $id, answer: $answer) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const CreateSurveyMutation = gql`
    mutation createSurveyMutation($name: String!, $description: String!, $surveyType: SurveyTypeEnum!, $surveyable: SurveyableInput!, $public: Boolean!) {
  createSurvey(
    name: $name
    description: $description
    surveyType: $surveyType
    surveyable: $surveyable
    public: $public
  ) {
    error {
      ...ErrorFragment
    }
    survey {
      ...SurveyFragment
      surveySections {
        id
        prn
        title
        description
        updatedAt
        createdAt
        surveyId
        questions {
          ...SurveyQuestionFragment
        }
      }
    }
  }
}
    ${ErrorFragment}
${SurveyFragment}
${SurveyQuestionFragment}`;
export const UpdateSurveyMutation = gql`
    mutation updateSurveyMutation($name: String, $description: String, $surveyId: ID!, $public: Boolean!) {
  updateSurvey(
    name: $name
    description: $description
    surveyId: $surveyId
    public: $public
  ) {
    error {
      ...ErrorFragment
    }
    survey {
      ...SurveyFragment
    }
  }
}
    ${ErrorFragment}
${SurveyFragment}`;
export const ListSurveyQuery = gql`
    query listSurveyQuery($filter: SurveyFilterInput, $sortSurveys: SurveysSortInput = {createdAt: desc}, $paginateSurveys: PaginationInput) {
  surveys(filter: $filter, sort: $sortSurveys, paginate: $paginateSurveys) {
    data {
      createdAt
      id
      prn
      name
      description
      scatterId
      public
      questions {
        id
      }
      responses {
        id
      }
      state
      surveyType
      updatedAt
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${PaginationFragment}`;
export const AddSurveyQuestionMutation = gql`
    mutation addSurveyQuestionMutation($label: String!, $surveyId: ID!, $questionType: SurveyQuestionTypeEnum!, $required: Boolean, $description: String, $helpText: String, $options: JSON, $surveySectionId: ID) {
  addSurveyQuestion(
    label: $label
    surveyId: $surveyId
    questionType: $questionType
    required: $required
    description: $description
    helpText: $helpText
    options: $options
    surveySectionId: $surveySectionId
  ) {
    error {
      ...ErrorFragment
    }
    question {
      ancestry
      category {
        id
        name
      }
      description
      helpText
      id
      label
      options
      position
      questionType
      required
    }
  }
}
    ${ErrorFragment}`;
export const ArchiveSurveyMutation = gql`
    mutation archiveSurveyMutation($surveyId: ID!) {
  archiveSurvey(surveyId: $surveyId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const PublishSurveyMutation = gql`
    mutation publishSurveyMutation($surveyId: ID!) {
  publishSurvey(surveyId: $surveyId) {
    error {
      ...ErrorFragment
    }
    survey {
      id
      state
    }
  }
}
    ${ErrorFragment}`;
export const UnpublishSurveyMutation = gql`
    mutation unpublishSurveyMutation($surveyId: ID!) {
  unpublishSurvey(surveyId: $surveyId) {
    error {
      ...ErrorFragment
    }
    survey {
      id
      state
    }
  }
}
    ${ErrorFragment}`;
export const EditSurveyQuestionMutation = gql`
    mutation editSurveyQuestionMutation($questionId: ID!, $label: String, $position: Int, $required: Boolean, $description: String, $helpText: String, $options: JSON, $questionType: SurveyQuestionTypeEnum, $surveySectionId: ID) {
  editSurveyQuestion(
    questionId: $questionId
    label: $label
    position: $position
    required: $required
    description: $description
    helpText: $helpText
    options: $options
    questionType: $questionType
    surveySectionId: $surveySectionId
  ) {
    error {
      ...ErrorFragment
    }
    question {
      ancestry
      category {
        id
        name
      }
      description
      helpText
      id
      label
      options
      position
      questionType
      required
    }
  }
}
    ${ErrorFragment}`;
export const DeleteSurveyMutation = gql`
    mutation deleteSurveyMutation($surveyId: ID!) {
  deleteSurvey(surveyId: $surveyId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const DeleteSurveyQuestionMutation = gql`
    mutation deleteSurveyQuestionMutation($surveyQuestionId: ID!) {
  deleteSurveyQuestion(surveyQuestionId: $surveyQuestionId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const RespondToSurveyMutation = gql`
    mutation respondToSurveyMutation($surveyId: ID!, $response: SurveyResponseInput!, $finalize: Boolean) {
  respondToSurvey(surveyId: $surveyId, response: $response, finalize: $finalize) {
    publicResponse {
      id
      averageScore
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const FetchSurveyQuery = gql`
    query fetchSurveyQuery($id: BigInt!) {
  publicSurveys(filter: {id: $id}) {
    id
    name
    description
    questions {
      surveySection {
        id
      }
      id
      label
      questionType
      options
    }
    surveySections {
      id
      title
      prn
      description
    }
    slug
  }
}
    `;
export const SurveyQuery = gql`
    query surveyQuery($filter: SurveyFilterInput) {
  surveys(filter: $filter) {
    data {
      ...SurveyFragment
      surveySections {
        id
        prn
        title
        description
        updatedAt
        createdAt
        surveyId
        questions {
          ...SurveyQuestionFragment
        }
      }
    }
  }
}
    ${SurveyFragment}
${SurveyQuestionFragment}`;
export const AddSurveySectionMutation = gql`
    mutation addSurveySectionMutation($surveyId: ID!, $title: String!, $description: String, $questionIds: [ID!]) {
  addSurveySection(
    title: $title
    description: $description
    surveyId: $surveyId
    questionIds: $questionIds
  ) {
    error {
      ...ErrorFragment
    }
    section {
      id
      updatedAt
      createdAt
    }
  }
}
    ${ErrorFragment}`;
export const UpdateSurveySectionMutation = gql`
    mutation updateSurveySectionMutation($sectionId: ID!, $title: String, $description: String) {
  updateSurveySection(
    title: $title
    sectionId: $sectionId
    description: $description
  ) {
    error {
      ...ErrorFragment
    }
    section {
      createdAt
      description
      id
      title
      updatedAt
    }
  }
}
    ${ErrorFragment}`;
export const TalentDashboardQuery = gql`
    query talentDashboardQuery {
  jobApplicationDeadlines {
    id
    date
    jobTitle {
      name
    }
  }
}
    `;
export const CreateJobApplicationDeadlineMutation = gql`
    mutation createJobApplicationDeadlineMutation($date: ISO8601Date!, $jobTitleId: Int!) {
  createJobApplicationDeadline(jobTitleId: $jobTitleId, date: $date) {
    jobApplicationDeadline {
      id
      date
      jobTitle {
        name
      }
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const DeleteJobApplicationDeadlineMutation = gql`
    mutation deleteJobApplicationDeadlineMutation($id: Int!) {
  deleteJobApplicationDeadline(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const FindJobTitleQuery = gql`
    query findJobTitleQuery($id: Int!) {
  jobTitles(filter: {id: $id}) {
    id
    name
    description
    questionsCount
    requirements
    questions {
      id
      identifier
      label
      type
      metadata
    }
  }
}
    `;
export const AssessmentsQuery = gql`
    query assessmentsQuery {
  assessments {
    id
    deadline
    duration
    jobTitle {
      name
    }
  }
}
    `;
export const FindAssessmentQuery = gql`
    query findAssessmentQuery($id: Int) {
  assessments(filter: {id: $id}) {
    id
    deadline
    jobTitle {
      name
    }
    participants {
      id
      firstName
      lastName
      createdAt
    }
    submissions {
      id
      answer
      consultant {
        id
        firstName
        lastName
      }
      score
      updatedAt
    }
  }
}
    `;
export const AddTrainingTagMutation = gql`
    mutation addTrainingTagMutation($name: String!) {
  addTrainingTag(name: $name) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const InviteConsultantMutation = gql`
    mutation inviteConsultantMutation($email: String!, $jobTitleId: ID!) {
  inviteConsultant(email: $email, jobTitleId: $jobTitleId) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const MyPerformanceAppraisalsQuery = gql`
    query myPerformanceAppraisalsQuery($page: Int, $per: Int!, $sort: MyPerformanceAppraisalsSortInput, $filter: MyPerformanceAppraisalsFilter) {
  myPerformanceAppraisals(
    paginate: {page: $page, per: $per}
    sort: $sort
    filter: $filter
  ) {
    data {
      ...performanceAppraisalFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${PerformanceAppraisalFragment}
${PaginationFragment}`;
export const MissionPerformanceAppraisalSchedulesQuery = gql`
    query missionPerformanceAppraisalSchedulesQuery($date: ISO8601DateTime) {
  missionPerformanceAppraisalSchedules(date: $date) {
    data {
      ...performanceAppraisalScheduleFragment
    }
  }
}
    ${PerformanceAppraisalScheduleFragment}`;
export const PerformanceAppraisalsQuery = gql`
    query performanceAppraisalsQuery($page: Int, $per: Int!, $sort: MissionPerformanceAppraisalsSortInput, $filter: MissionPerformanceAppraisalsFilter) {
  missionPerformanceAppraisals(
    paginate: {page: $page, per: $per}
    sort: $sort
    filter: $filter
  ) {
    data {
      ...performanceAppraisalFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${PerformanceAppraisalFragment}
${PaginationFragment}`;
export const TalentPoolListQuery = gql`
    query talentPoolListQuery($filter: TalentPoolFilter, $sort: TalentPoolSortInput = {lastName: asc}, $page: Int, $per: Int!) {
  talentPool(filter: $filter, sort: $sort, paginate: {page: $page, per: $per}) {
    data {
      id
      prn
      user {
        id
        avatar {
          id
          url
        }
      }
      name
      email
      phone
      jobTitle {
        id
        name
      }
      practiceArea
      jobFunctions
      yearJoined
      status
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${PaginationFragment}`;
export const TalentPoolQuery = gql`
    query talentPoolQuery($filter: TalentPoolFilter, $sort: TalentPoolSortInput = {lastName: asc}, $page: Int, $per: Int!) {
  talentPool(filter: $filter, sort: $sort, paginate: {page: $page, per: $per}) {
    data {
      ...ConsultantTalentFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${ConsultantTalentFragment}
${PaginationFragment}`;
export const UpdateConsultantTalentMutation = gql`
    mutation updateConsultantTalentMutation($consultantId: BigInt!, $talent: ConsultantTalentInput!) {
  updateConsultantTalent(consultantId: $consultantId, talent: $talent) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const TalentRatingsQuery = gql`
    query talentRatingsQuery($filter: TalentRatingsFilter) {
  talentDashboardData {
    talentRatings(filter: $filter) {
      ...TalentRatingsFragment
    }
  }
}
    ${TalentRatingsFragment}`;
export const TalentDistributionQuery = gql`
    query talentDistributionQuery($filter: TalentDistributionFilter) {
  talentDashboardData {
    talentDistribution(filter: $filter) {
      by
      values {
        count
        keys
      }
    }
  }
}
    `;
export const TalentApplicationsDistributionQuery = gql`
    query talentApplicationsDistributionQuery($filter: TalentApplicationsDistributionFilter) {
  talentDashboardData {
    talentApplicationsDistribution(filter: $filter) {
      by
      values {
        count
        keys
      }
    }
  }
}
    `;
export const TalentUtilizationQuery = gql`
    query talentUtilizationQuery($filter: TalentUtilizationFilter) {
  talentDashboardData {
    talentUtilization(filter: $filter) {
      by
      values {
        count
        total
        percent
        by {
          role
        }
      }
    }
  }
}
    `;
export const ConsultantPeralteeClaimsQuery = gql`
    query consultantPeralteeClaimsQuery($filter: ConsultantPeralteeClaimsFilter, $sort: ConsultantPeralteeClaimsSortInput, $page: Int, $per: Int) {
  consultantPeralteeClaims(
    filter: $filter
    sort: $sort
    paginate: {page: $page, per: $per}
  ) {
    data {
      ...ConsultantPeralteeClaimFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${ConsultantPeralteeClaimFragment}
${PaginationFragment}`;
export const ApproveConsultantPeralteeClaimMutation = gql`
    mutation approveConsultantPeralteeClaimMutation($id: BigInt!, $comment: String) {
  approveConsultantPeralteeClaim(id: $id, comment: $comment) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const RejectConsultantPeralteeClaimMutation = gql`
    mutation rejectConsultantPeralteeClaimMutation($id: BigInt!, $comment: String!) {
  rejectConsultantPeralteeClaim(id: $id, comment: $comment) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ConsultantPeralteeStatisticsQuery = gql`
    query consultantPeralteeStatisticsQuery($dateRange: DateFilterInput, $consultantId: BigInt) {
  consultantPeralteeClaimsStatistics(
    dateRange: $dateRange
    consultantId: $consultantId
  ) {
    total
    approved {
      ...PeralteeClaimsStats
    }
    pending {
      ...PeralteeClaimsStats
    }
    rejected {
      ...PeralteeClaimsStats
    }
  }
}
    ${PeralteeClaimsStats}`;
export const AllApplicantsQuery = gql`
    query allApplicantsQuery($page: Int, $per: Int, $nameContains: String) {
  pending: applicants(sort: {}, paginate: {page: $page, per: $per}) {
    data {
      id
    }
    pagination {
      ...PaginationFragment
    }
  }
  intro_call: applicants(sort: {}, paginate: {page: $page, per: $per}) {
    data {
      id
    }
    pagination {
      ...PaginationFragment
    }
  }
  shortlisted: applicants(sort: {}, paginate: {page: $page, per: $per}) {
    data {
      id
    }
    pagination {
      ...PaginationFragment
    }
  }
  first_case_study: applicants(sort: {}, paginate: {page: $page, per: $per}) {
    data {
      id
    }
    pagination {
      ...PaginationFragment
    }
  }
  second_case_study: applicants(sort: {}, paginate: {page: $page, per: $per}) {
    data {
      id
    }
    pagination {
      ...PaginationFragment
    }
  }
  first_final_interview: applicants(sort: {}, paginate: {page: $page, per: $per}) {
    data {
      id
    }
    pagination {
      ...PaginationFragment
    }
  }
  second_final_interview: applicants(sort: {}, paginate: {page: $page, per: $per}) {
    data {
      id
    }
    pagination {
      ...PaginationFragment
    }
  }
  rejected: applicants(sort: {}, paginate: {page: $page, per: $per}) {
    data {
      id
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${PaginationFragment}`;
export const ProceedApplicationMutation = gql`
    mutation proceedApplicationMutation($id: BigInt!, $nextEvent: String) {
  proceedApplication(id: $id, nextEvent: $nextEvent) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const TasksQuery = gql`
    query tasksQuery($filter: TasksFilter) {
  tasks(filter: $filter) {
    completedAt
    createdAt
    deadline
    description
    id
    metadata
    state
    title
    type
    taskableId
    taskableType
    taskableName
    priority
  }
}
    `;
export const ResolveTaskMutation = gql`
    mutation resolveTaskMutation($id: BigInt!) {
  resolveTask(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const RejectTaskMutation = gql`
    mutation rejectTaskMutation($id: BigInt!, $data: JSON!) {
  rejectTask(id: $id, data: $data) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const GeneratePresignedUrlMutation = gql`
    mutation generatePresignedUrlMutation($folder: String!, $filename: String!, $public: Boolean) {
  generatePresignedUrl(folder: $folder, filename: $filename, public: $public) {
    error {
      ...ErrorFragment
    }
    presignedUrl {
      key
      url
    }
  }
}
    ${ErrorFragment}`;
export const DeleteUploadFileMutation = gql`
    mutation deleteUploadFileMutation($id: BigInt!) {
  deleteUploadFile(id: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const UserSignaturesQuery = gql`
    query userSignaturesQuery($filter: SignatureFilter) {
  userSignatures(filter: $filter) {
    ...userSignaturesFragment
  }
}
    ${UserSignaturesFragment}`;
export const GenerateConsultantInvoicesMutation = gql`
    mutation generateConsultantInvoicesMutation($missionLifecycleId: ID!) {
  generateConsultantInvoices(missionLifecycleId: $missionLifecycleId) {
    consultantInvoices {
      id
      updatedAt
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ArchiveMissionMutation = gql`
    mutation archiveMissionMutation($id: ID!, $archivalReason: String!) {
  archiveMission(missionLifecycleId: $id, archivalReason: $archivalReason) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const MissionStaffingApplicationsQuery = gql`
    query missionStaffingApplicationsQuery($filter: MissionStaffingPositionsFilter, $sort: MissionStaffingPositionsSortInput, $page: Int, $per: Int!) {
  missionStaffingPositions(
    filter: $filter
    sort: $sort
    paginate: {page: $page, per: $per}
  ) {
    data {
      id
      prn
      missionStaffingApplications(page: $page, perPage: $per) {
        ...PendingMissionStaffingApplicationFragment
      }
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${PendingMissionStaffingApplicationFragment}
${PaginationFragment}`;
export const ApproveMissionStaffingApplicationMutation = gql`
    mutation approveMissionStaffingApplicationMutation($id: ID!) {
  approveMissionStaffingApplication(missionStaffingApplicationId: $id) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const RejectMissionStaffingApplicationMutation = gql`
    mutation rejectMissionStaffingApplicationMutation($id: ID!, $rejectionReason: String!) {
  rejectMissionStaffingApplication(
    missionStaffingApplicationId: $id
    rejectionReason: $rejectionReason
  ) {
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const ShortlistMissionStaffingApplicationMutation = gql`
    mutation shortlistMissionStaffingApplicationMutation($id: ID!) {
  shortlistMissionStaffingApplication(missionStaffingApplicationId: $id) {
    error {
      ...ErrorFragment
    }
    missionStaffingApplication {
      ...PendingMissionStaffingApplicationFragment
    }
  }
}
    ${ErrorFragment}
${PendingMissionStaffingApplicationFragment}`;
export const UpdateMissionExecutionStaffingPositionMutation = gql`
    mutation updateMissionExecutionStaffingPositionMutation($id: ID!, $name: String, $description: String, $milestones: [ID!], $contractPayments: [ConsultantContractPaymentInput!], $requiredExpertiseTags: [String!], $totalFee: MoneyInput) {
  updateMissionExecutionStaffingPosition(
    missionStaffingPositionId: $id
    name: $name
    description: $description
    missionScopeActivityIds: $milestones
    contractPayments: $contractPayments
    requiredExpertiseTags: $requiredExpertiseTags
    totalFee: $totalFee
  ) {
    error {
      ...ErrorFragment
    }
    missionStaffingPosition {
      id
      prn
      name
      description
      totalFee {
        ...MoneyFragment
      }
    }
  }
}
    ${ErrorFragment}
${MoneyFragment}`;
export const MissionStaffingPositionsQuery = gql`
    query missionStaffingPositionsQuery($filter: MissionStaffingPositionsFilter, $sort: MissionStaffingPositionsSortInput, $paginate: PaginationInput) {
  missionStaffingPositions(filter: $filter, sort: $sort, paginate: $paginate) {
    data {
      ...MissionStaffingPositionDetailFragment
    }
    pagination {
      ...PaginationFragment
    }
  }
}
    ${MissionStaffingPositionDetailFragment}
${PaginationFragment}`;
export const CreateConsultantContractPaymentMutation = gql`
    mutation createConsultantContractPaymentMutation($consultantContractPaymentInput: ConsultantContractPaymentInput!, $missionContractPaymentId: ID!, $missionStaffingPositionId: ID!) {
  createConsultantContractPayment(
    consultantContractPaymentInput: $consultantContractPaymentInput
    missionContractPaymentId: $missionContractPaymentId
    missionStaffingPositionId: $missionStaffingPositionId
  ) {
    error {
      ...ErrorFragment
    }
    payment {
      ...ConsultantContractPaymentFragment
    }
  }
}
    ${ErrorFragment}
${ConsultantContractPaymentFragment}`;
export const UpdateConsultantContractPaymentMutation = gql`
    mutation updateConsultantContractPaymentMutation($consultantContractPaymentId: ID!, $consultantContractPaymentInput: ConsultantContractPaymentInput!) {
  updateConsultantContractPayment(
    consultantContractPaymentId: $consultantContractPaymentId
    consultantContractPaymentInput: $consultantContractPaymentInput
  ) {
    error {
      ...ErrorFragment
    }
    payment {
      ...ConsultantContractPaymentFragment
    }
  }
}
    ${ErrorFragment}
${ConsultantContractPaymentFragment}`;
export const InitiateClientPaymentProcessingMutation = gql`
    mutation InitiateClientPaymentProcessingMutation($paymentId: ID!) {
  initiateClientPaymentProcessing(missionContractPaymentId: $paymentId) {
    payment {
      id
      state
    }
    error {
      ...ErrorFragment
    }
  }
}
    ${ErrorFragment}`;
export const AcceptInvitationMutation = gql`
    mutation acceptInvitationMutation($invitationId: ID!, $token: String!, $newUserInput: UserInput, $newConsultantInput: ConsultantOnboardingInput, $newClientInput: ClientInput, $newSubscriptionUserInput: SubscriptionUserInput) {
  acceptInvitation(
    invitationId: $invitationId
    token: $token
    newUserInput: $newUserInput
    newClientInput: $newClientInput
    newConsultantInput: $newConsultantInput
    newSubscriptionUserInput: $newSubscriptionUserInput
  ) {
    error {
      ...ErrorFragment
    }
    invitation {
      id
    }
  }
}
    ${ErrorFragment}`;
export const FindInvitationQuery = gql`
    query findInvitationQuery($token: String!) {
  findInvitation(token: $token) {
    createdAt
    email
    id
    prn
    state
    type
    updatedAt
    invitationForProduct {
      slug
    }
  }
}
    `;
export const CreateClientWithUserMutation = gql`
    mutation createClientWithUserMutation($newUserInput: UserInput!, $newClientInput: ClientInput!, $newSubscriptionInput: SubscriptionInput, $newCardInput: CardInput, $missionRequestInput: MissionRequestInput, $promoCodeInput: PromoCodeInput) {
  createClientWithUser(
    newUserInput: $newUserInput
    newClientInput: $newClientInput
    newSubscriptionInput: $newSubscriptionInput
    newCardInput: $newCardInput
    missionRequestInput: $missionRequestInput
    promoCodeInput: $promoCodeInput
  ) {
    error {
      ...ErrorFragment
    }
    clientPayload {
      subscriptionId
      payInvoiceUrl
    }
  }
}
    ${ErrorFragment}`;
export const FindProductPlansQuery = gql`
    query findProductPlansQuery($productSlug: String, $slug: String) {
  findProductPlans(productSlug: $productSlug, slug: $slug) {
    ...ProductPlanFragment
  }
}
    ${ProductPlanFragment}`;
export const FindPromoCodeQuery = gql`
    query findPromoCodeQuery($code: String!) {
  findPromoCode(code: $code) {
    code
    createdAt
    id
    offers
    prn
    updatedAt
    description
    freeTrial
    plan {
      ...ProductPlanFragment
    }
  }
}
    ${ProductPlanFragment}`;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "BillingAccount",
        "possibleTypes": [
          {
            "name": "ConsultantBankAccount"
          },
          {
            "name": "ConsultantPayoneerAccount"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "MissionUnion",
        "possibleTypes": [
          {
            "name": "MissionLifecycle"
          },
          {
            "name": "MissionLifecycleBasic"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "PaymentMethod",
        "possibleTypes": [
          {
            "name": "Card"
          }
        ]
      }
    ]
  }
};
      export default result;
    